const navItems = [
  { title: "Home", path: "/"},
  { title: "Events", path: "/events"},
  // { title: "MDnD Live", path: "/"},
  { title: "Season Tickets", path: "/SeasonTickets?ci=101&c=3659&eMode=Offline&Date=All&pr=All"},
  { title: "MDnD Records", path: "/MDnDRecords"},
  { title: "Raga Bank", path: "/RagaBank"},
  { title: "MDnD Store", path: "https://mdnd.store/" },
  //{ title: "Blogs", path: "/blog"},

  { title: "Artists", path: "/artist"},
  { title: "Organisers", path: "/organisers"},
  //{ title: "Franchisees", path: "/franchisees" },
  
  
  
  // { title: "Video Rental", path: "/" },
  
];


  export default navItems