import React, { useState, useEffect, useContext } from "react";
import "./Filterpage.css";
import { FaFilter } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { GlobalContext } from "../../global/provider";
import { useMutation } from "@tanstack/react-query";
import { GET_EVENTS_BY_CATEGORIES } from "../../Api/api";
import {
  ALLEVENT_DETAILS_IMAGES,
  countryParams,
  MainCategory,
  cityParams,
} from "../../global/constants.js";
import { BASEPATH } from "../../config/config.js";
import {
  GET_COUNTRIES,
  GET_CITIES_BY_COUNTRIES,
  GET_EVENTS,
} from "../../Api/api";
import axios from "axios";
import { SelectBox } from "devextreme-react";
import { CheckBox } from "devextreme-react";
import DateRangeBox from "devextreme-react/date-range-box";
import { IMAGE_URL } from "../../Subpages/MainPage/constant.js";
import NoDataFound from "../../global/NoDataFound.js";
import Noimage from "../../global/NoImage.js";
import { customEncrypt } from "../../utils/encryptAndDecrypt.js";
import { MdKeyboardArrowRight } from "react-icons/md";
import AuthContext from "../../context/AuthContext.js";
import { IoChevronBack } from "react-icons/io5";
import getEventKeys from "../../Api/getEventKeys.js";

function ListingEvents() {

 const COUNTRY_KEY= getEventKeys().countryKey;
 const EVENT_KEY= getEventKeys().eventKey;

  const token = localStorage.getItem("MDnD_auth");
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const main_cat_id = searchParams.get(MainCategory);
  const countryId = searchParams.get(countryParams);
  const cityId = searchParams.get(cityParams);

  const [filter, setFilter] = useState(false);

  const [dataSource, setDataSource] = useState({
    tempEventData: "",

    // NEWLY ADDED:-
    selectedEventMode: "",
    country: "",
    selectedCountry: "",
    city: "",

    selectedCity: "",
    selectedDate: "",
    startDate: null,
    endDate: null,
    checked: false,
    //dummydate: "",
    selectedDate_range: "",
    selectedPricing: "",

    maincategory_all: "",
    selectedMainCategory: "",
    selectedMainCategory_dummy: "",
  });

  const navigate = useNavigate();
  const doFilter = () => {
    setFilter(!filter);
  };

  const encodeBase64 = (str) => {
    const prefix = "xy4AT";
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr); 
  };

  const encodeBase64_new = (str,key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr); 
  };



  const selectedDetailsEvent = (event_id, title) => {
    const encyptedData = customEncrypt("no");
    const modifiedTitle = title ? title.split(" ").join("_") : "Event";
    navigate(
      `/event/${modifiedTitle}?ci=${encodeBase64_new(countryId,COUNTRY_KEY)}&e=${encodeBase64_new(event_id,EVENT_KEY)}&editEvent=${encyptedData}`
    );
  };

  const location = useLocation();
  const MID = location?.state?.mainCatId;
  const mainCatName = location?.state?.mainCatName;
  const [searchParameters] = useSearchParams();

  const sCity = Number(searchParameters?.get("c"));
  const sCountry = Number(searchParameters?.get("ci"));
  const mainCId = Number(searchParameters?.get("mc"));
  const [sCitystate, setsCitystate] = useState("");
  const [sCountrystate, setsCountrystate] = useState("");
  const [maincidstate, setmaincidstate] = useState("");
  const [CatName, setCatName] = useState("");
  useEffect(() => {
    if (mainCatName) {
      setCatName(mainCatName);
    } else {
      const params = new URLSearchParams(location?.search);

      setCatName(params.get("mcName") || "");
    }
  }, [location, mainCatName]);

  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES);

  const GET_COUNTRY = async (GET_COUNTRIES) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country_events"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setDataSource((prevState) => ({ ...prevState, country: data.data }));
      } else {
        setDataSource((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, country: "" }));
    },
  });

  // setting initial Event Mode..
  useEffect(() => {
    if (MID) {
      {
        setDataSource((prevState) => ({
          ...prevState,
          selectedEventMode: "Offline",
        }));
      }
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedEventMode: params.get("eMode") || "",
      }));
    }
  }, [location, MID]);

  // setting selectedPricing initial..
  useEffect(() => {
    if (MID) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedPricing: "All",
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedPricing: params.get("pr") || "",
      }));
    }
  }, [location, MID]);

  // set initial Date...
  useEffect(() => {
    if (MID) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedDate: "All",
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedDate: params.get("Date") || "",
      }));
    }
  }, [location, MID]);

  // set initial country..........
  useEffect(() => {
    if (MID) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: sCountry,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedCountry: Number(params.get("ci")) || "",
      }));
    }
  }, [location, MID]);

  // initial setting city...
  useEffect(() => {
    if (MID) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: sCity,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedCity: Number(params.get("c")) || "",
      }));
    }
  }, [location, MID]);

  //fetch cities...
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_BY_COUNTRIES}`,
          {
            country_id: dataSource.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          setDataSource((prevState) => ({
            ...prevState,
            city: response.data.data,
          }));
        } else {
          setDataSource((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };
    if (dataSource.selectedCountry) {
      GETCITIESDATA();
    }
  }, [dataSource.selectedCountry]);

  useEffect(() => {
    if (dataSource.selectedCountry && dataSource.selectedCity) {
      setLoading(true);
      mutationMainCategory.mutate();
    } else {
      setLoading(true);
      return;
    }
  }, [dataSource.selectedCountry, dataSource.selectedCity]);

  const GetMainCategory = () => GET_MainCategory(GET_EVENTS);

  const GET_MainCategory = async (GET_EVENTS) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_EVENTS}`,
        data: {
          country_id: dataSource.selectedCountry
            ? Number(dataSource.selectedCountry)
            : "",
          city_id: dataSource.selectedCity
            ? Number(dataSource.selectedCity)
            : "",
        },
        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationMainCategory = useMutation({
    mutationKey: ["get_maincategory_events"],
    mutationFn: () => GetMainCategory(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setDataSource((prevState) => ({
          ...prevState,
          maincategory_all: data.data,
        }));
      } else {
        setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
    },
  });
  useEffect(() => {
    if (MID) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: MID,
      }));
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory_dummy: MID,
      }));
    } else if (!MID && CatName) {
      const params = new URLSearchParams(location?.search);
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: Number(params.get("mc")) || "",
        selectedMainCategory_dummy: Number(params.get("mc")) || "",
      }));
    } else if (!CatName && !MID && dataSource.maincategory_all) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory_dummy: Number(
          dataSource?.maincategory_all[0]?.main_cat_id
        ),
      }));
      setCatName(dataSource?.maincategory_all[0]?.main_cat_name);
    }
  }, [location, dataSource.maincategory_all, MID]);

  useEffect(() => {
    if (
      dataSource.selectedCountry &&
      dataSource.selectedCity &&
      dataSource.selectedMainCategory_dummy &&
      dataSource.selectedEventMode &&
      CatName &&
      dataSource.selectedPricing &&
      dataSource.maincategory_all
    ) {
      const queryParams = new URLSearchParams({
        ci: dataSource.selectedCountry
          ? Number(dataSource.selectedCountry)
          : "",

        c: dataSource.selectedCity ? Number(dataSource.selectedCity) : "",
        eMode: dataSource.selectedEventMode ? dataSource.selectedEventMode : "",

        Date:
          dataSource.startDate === null
            ? dataSource.selectedDate
            : dataSource.selectedDate_range,
        mc: dataSource.selectedMainCategory_dummy
          ? Number(dataSource.selectedMainCategory_dummy)
          : "",
        pr: dataSource.selectedPricing ? dataSource.selectedPricing : "",
        mcName: CatName,
      });

      const newUrl = `/ViewEvents/${CatName}?${queryParams}`;

      navigate(newUrl);
    }
    // -----------------------------------------------------------------------------------------
    const metaTitle = `Top Upcoming ${CatName} events|Free & Ticketed`;
    document.title = metaTitle;

    const metaDescription = `Explore the top upcoming ${CatName} events happening near you and buy tickets for them`;
    const descriptionTag = document.querySelector('meta[name="description"]');
    if (descriptionTag) {
      descriptionTag.setAttribute("content", metaDescription);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = metaDescription;
      document.head.appendChild(meta);
    }
    // -----------------------------------------------------------------------------------------
  }, [
    dataSource.selectedCountry,
    dataSource.selectedCity,
    dataSource.selectedEventMode,
    CatName,
    dataSource.selectedDate,
    dataSource.selectedDate_range,
    dataSource.selectedMainCategory_dummy,
    dataSource.selectedPricing,
    navigate,
    dataSource.maincategory_all,
  ]);

  // onValueChaged functions.....
  const handleCountry = (e) => {
    //console.log("im inside");
    if (e.value) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setDataSource((prevState) => ({ ...prevState, city: [] }));
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: "",
        maincategory_all: [],
        selectedMainCategory: "",
        selectedMainCategory_dummy: "",
      }));
      setCatName("");
    }

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handleCity = (value) => {
    if (value) {
      //console.log("inside handleCity function")
      setDataSource((prevState) => ({ ...prevState, selectedCity: value }));

      setCatName("");

      setDataSource((prevState) => ({
        ...prevState,
        //tempEventData: [],
        maincategory_all: [],
        selectedMainCategory: "",
        selectedMainCategory_dummy: "",
      }));
    }
  };

  const handleDate_today = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "Today",
      //tempEventData: [],
    }));
  };

  const handleDate_all = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "All",
      //tempEventData: [],
    }));
  };

  const handleDate_weekend = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "Weekend",

      //tempEventData: [],
    }));
  };

  const handleChange = (e) => {
    setDataSource((prevState) => ({
      ...prevState,
      checked: e.value,
      startDate: null,
      endDate: null,
      selectedDate: e.value ? "" : prevState.selectedDate,
      selectedDate_range: "",
    }));
  };

  function formatDate_startEndDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };

  const handleDateRange = (e) => {
    const startDate_change = e.value[0];
    const endDate_change = e.value[1];
    const concatedDate = `${
      startDate_change ? formatDate(startDate_change) : ""
    }-${endDate_change ? formatDate(endDate_change) : ""}`;

    if (startDate_change && endDate_change && concatedDate)
      setDataSource((prev) => ({
        ...prev,
        selectedDate_range: concatedDate,
        startDate: startDate_change,
        endDate: endDate_change,
      }));
  };

  const handleEvent_offline = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedEventMode: "Offline",
    }));

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handleEvent_online = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedEventMode: "Online",
    }));

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handlePricing_All = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "All",
    }));

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handlePricing_Free = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "Free",
    }));

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handlePricing_Ticketed = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "Ticketed",
    }));

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  const handleMainCategory = (e) => {
    if (e) {
      //console.log(e.value, "e.value main category");
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: e.value,
      }));
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory_dummy: e.value,
      }));

      let filteredArray =
        dataSource.maincategory_all &&
        dataSource.maincategory_all.filter(
          (item) => item.main_cat_id === e.value
        );
      //console.log(filteredArray, "filteredArray");

      if (filteredArray) {
        setCatName(filteredArray[0]?.main_cat_name);
      }
    }

    //setDataSource((prevState) => ({ ...prevState, tempEventData: [] }));
  };

  // fetch events from backend..

  useEffect(() => {
    const loadMore = async () => {
      let from_date = "";
      let to_date = "";
      const today = new Date();

      if (dataSource.selectedDate === "All") {
        const today = new Date();
        const formattedDate = today && today.toISOString().split("T")[0];
        from_date = formattedDate;

        const nextYear = today && new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        to_date = nextYear && nextYear.toISOString().split("T")[0];
      } else if (dataSource.selectedDate === "Today") {
        const today = new Date();
        const formattedDate = today && today.toISOString().split("T")[0];
        from_date = formattedDate;
        to_date = formattedDate;
      } else if (dataSource.selectedDate === "Weekend") {
        const dayOfWeek = today.getDay();
        let daysUntilSaturday, daysUntilSunday;

        if (dayOfWeek === 0) {
          daysUntilSaturday = 6;
          daysUntilSunday = 7;
        } else {
          daysUntilSaturday = 6 - dayOfWeek;
          daysUntilSunday = 7 - dayOfWeek;
        }

        const nextSaturday = new Date(today);
        nextSaturday.setDate(today.getDate() + daysUntilSaturday);

        const nextSunday = new Date(today);
        nextSunday.setDate(today.getDate() + daysUntilSunday);

        const formattedSaturday =
          nextSaturday && nextSaturday.toISOString().split("T")[0];
        const formattedSunday =
          nextSunday && nextSunday.toISOString().split("T")[0];

        from_date = formattedSaturday;
        to_date = formattedSunday;
      } else {
        if (dataSource.startDate && dataSource.endDate) {
          from_date = formatDate_startEndDate(dataSource.startDate);
          to_date = formatDate_startEndDate(dataSource.endDate);
        } else {
          return;
        }
      }

      if (from_date && to_date) {
        setLoading(true);
        mutationgetFetchParticularEvents.mutate({ from_date, to_date });
      } else {
        setLoading(true);
        return;
      }
    };

    if (
      dataSource.selectedCity &&
      dataSource.selectedPricing &&
      dataSource.selectedMainCategory &&
      dataSource.selectedEventMode
    ) {
      //console.log("check here");
      loadMore();
    } else {
      return;
    }
  }, [
    dataSource.selectedCity,
    dataSource.selectedPricing,
    dataSource.selectedMainCategory,
    dataSource.selectedEventMode,
    dataSource.selectedDate,
    dataSource.selectedDate_range,
  ]);

  const GetParticularEvents = ({ from_date, to_date }) =>
    FETCH_EVENTS_FROM_BACKEND(GET_EVENTS_BY_CATEGORIES, from_date, to_date);

  const FETCH_EVENTS_FROM_BACKEND = async (
    GET_EVENTS_BY_CATEGORIES,
    from_date,
    to_date
  ) => {
    try {
      //console.log("fetch to backend");
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_EVENTS_BY_CATEGORIES}`,

        data: {
          main_cat_id: dataSource.selectedMainCategory
            ? dataSource.selectedMainCategory
            : "",

          country_id: dataSource.selectedCountry
            ? dataSource.selectedCountry
            : "",
          city_id: dataSource.selectedCity ? dataSource.selectedCity : "",
          event_mode: dataSource.selectedEventMode
            ? dataSource.selectedEventMode
            : "",
          ticketing_option: dataSource.selectedPricing
            ? dataSource.selectedPricing
            : "",
          from_date: from_date,
          to_date: to_date,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetFetchParticularEvents = useMutation({
    mutationKey: ["get_particular_events"],
    mutationFn: GetParticularEvents,

    onSuccess: (data) => {
      //console.log(data, "DATA");

      if (data.status === "success") {
        //console.log(data,"dt");

        setDataSource((prevState) => ({
          ...prevState,
          tempEventData: data.data,
        }));
        setLoading(false);
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, tempEventData: "" }));
      setLoading(false);
    },
  });

  //console.log(dataSource, "datasource");
 

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  //breadcrumbs.......

  const [breadCrumbCollection, setbreadCrumbCollection] = useState({
    breadCrumbCountry: "",
    breadCrumbCity: "",
    breadCrumbEventMode: "",
    breadCrumbDate: "",
    breadCrumbPricing: "",
    breadCrumbCategory: "",
    breadCrumbCurrent: "",
  });

  useEffect(() => {
    if (
      dataSource?.selectedCountry &&
      dataSource?.selectedCountry != "" &&
      dataSource.country
    ) {
      const filteredData =
        dataSource.country &&
        dataSource.country.filter(
          (item) => item.country_id === Number(dataSource.selectedCountry)
        );

      if (filteredData && filteredData.length > 0) {
        //setbreadcrumbCountry(filteredData[0]?.country_name)

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: filteredData[0]?.country_name,
          breadCrumbCurrent: "Country",
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: "",
        }));
      }
    }
  }, [dataSource?.selectedCountry, dataSource.country]);

  useEffect(() => {
    if (
      dataSource?.selectedCity &&
      dataSource?.selectedCity !== "" &&
      dataSource?.city
    ) {
      const cityData =
        dataSource?.city &&
        dataSource?.city.filter(
          (item) => item?.city_id === dataSource.selectedCity
        );

      if (cityData && cityData.length > 0) {
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: cityData[0].city_name,
          breadCrumbCurrent: "City",
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: "",
        }));
      }
    }
  }, [dataSource?.selectedCity, dataSource?.city]);

  useEffect(() => {
    if (dataSource.selectedEventMode) {
      //setbreadCrumbEventMode(dataSource.selectedEventMode);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbEventMode: dataSource.selectedEventMode,
        breadCrumbCurrent: "Event Mode",
      }));
    } else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbEventMode: "",
      }));
    }
  }, [dataSource.selectedEventMode]);

  function formatDateRange(dateRange) {
    const [start, end] = dateRange.split("-");
    let formattedStart;
    let formattedEnd;

    const startDay = start.substring(0, 2);
    const startMonth = start.substring(2, 4);
    const startYear = start.substring(4, 8);
    if (startDay && startMonth && startYear) {
      formattedStart = `${startDay}-${startMonth}-${startYear}`;
    }

    const endDay = end.substring(0, 2);
    const endMonth = end.substring(2, 4);
    const endYear = end.substring(4, 8);
    if (endDay && endMonth && endYear) {
      formattedEnd = `${endDay}-${endMonth}-${endYear}`;
    }

    return `${formattedStart} to ${formattedEnd}`;
  }

  useEffect(() => {
    if (dataSource.startDate === null && dataSource.selectedDate === "All") {
      //setbreadCrumbDate(`${dataSource.selectedDate} Dates `);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: `${dataSource.selectedDate} Dates`,
        breadCrumbCurrent: "Date",
      }));
    } else if (
      dataSource.startDate === null &&
      dataSource.selectedDate !== "All"
    ) {
      //setbreadCrumbDate(dataSource.selectedDate);
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: dataSource.selectedDate,
        breadCrumbCurrent: "Date",
      }));
    } else if (dataSource.startDate && dataSource.selectedDate_range) {
      //setbreadCrumbDate(formatDateRange(dataSource.selectedDate_range));

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: formatDateRange(dataSource.selectedDate_range),
        breadCrumbCurrent: "Date",
      }));
    } else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: "",
      }));
    }
  }, [
    dataSource.startDate,
    dataSource.selectedDate,
    dataSource.selectedDate_range,
  ]);

  useEffect(() => {
    if (dataSource.selectedPricing && dataSource.selectedPricing === "All") {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbPricing: "Free & Ticketed",
        breadCrumbCurrent: "Pricing",
      }));
    } else if (
      dataSource.selectedPricing &&
      dataSource.selectedPricing !== "All"
    ) {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbPricing: dataSource.selectedPricing,
        breadCrumbCurrent: "Pricing",
      }));
    }
  }, [dataSource.selectedPricing]);

  useEffect(() => {
    if (CatName) {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCategory: CatName,
        breadCrumbCurrent: "Main Category",
      }));
    } else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCategory: "",
      }));
    }
  }, [CatName]);

  const { setPrevURL } = useContext(AuthContext);

  useEffect(() => {
    setPrevURL(`${location.pathname}${location.search}`);
  }, [location]);

  const handlebackButton = () => {
    navigate("/events");
  };

  return (
    <div>
      <div className="section-padding pt-lg-4">
        <div className="container-fluid mt-5 mt-lg-0">
          <div className="row">
            <div className="d-lg-none filter-sticky d-flex flex-nowrap align-items-center gap-3 justify-content-between">
              {/* <div className="d-flex align-items-center gap-2">
                <button className="sample-button">India</button>
                <button className="sample-button">Chennai</button>
              </div> */}
              {/*Mobile breadcrumb */}
              <div
                className="w-100 d-flex align-items-center hide-scrollbar breadcrumbCountryWarp"
                style={{ gap: "2px", overflow: "scroll" }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "Country"
                        ? "#3F00FF"
                        : undefined,
                  }}
                >
                  {breadCrumbCollection.breadCrumbCountry}
                </p>
                <i style={{ fontSize: "16px" }}>
                  <MdKeyboardArrowRight />
                </i>
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "City"
                        ? "#3F00FF"
                        : undefined,
                  }}
                  //style={{ color: "#3F00FF" }}
                >
                  {breadCrumbCollection.breadCrumbCity}
                </p>

                <i style={{ fontSize: "16px" }}>
                  <MdKeyboardArrowRight />
                </i>
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "Event Mode"
                        ? "#3F00FF"
                        : undefined,
                  }}
                  //style={{ color: "#3F00FF" }}
                >
                  {breadCrumbCollection.breadCrumbEventMode}
                </p>

                <i style={{ fontSize: "16px" }}>
                  <MdKeyboardArrowRight />
                </i>
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "Date"
                        ? "#3F00FF"
                        : undefined,
                  }}
                  //style={{ color: "#3F00FF" }}
                >
                  {breadCrumbCollection.breadCrumbDate}
                </p>

                <i style={{ fontSize: "16px" }}>
                  <MdKeyboardArrowRight />
                </i>
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "Pricing"
                        ? "#3F00FF"
                        : undefined,
                  }}
                  //style={{ color: "#3F00FF" }}
                >
                  {breadCrumbCollection.breadCrumbPricing}
                </p>

                <i style={{ fontSize: "16px" }}>
                  <MdKeyboardArrowRight />
                </i>
                <p
                  className="m-0 p-0"
                  style={{
                    color:
                      breadCrumbCollection.breadCrumbCurrent === "Main Category"
                        ? "#3F00FF"
                        : undefined,
                  }}
                  //style={{ color: "#3F00FF" }}
                >
                  {breadCrumbCollection.breadCrumbCategory}
                </p>
              </div>
              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              <div className="filterBg pb-2">
                <div className="">
                  <h5>
                    <FaFilter /> Filters
                  </h5>
                </div>

                {loading ? (
                  <>
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} className="me-1" />
                    <Skeleton height={40} />
                  </>
                ) : (
                  <>
                    <div className="w-100">
                      <div className="d-flex flex-column gap-2">
                        <h6 className="m-0 p-0">Event Mode</h6>
                        <div className="d-flex gap-2">
                          <button
                            onClick={handleEvent_offline}
                            className={
                              dataSource.selectedEventMode === "Offline"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Offline
                          </button>

                          <button
                            onClick={handleEvent_online}
                            className={
                              dataSource.selectedEventMode === "Online"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Online
                          </button>
                        </div>
                      </div>

                      <div className="mt-4 d-flex flex-column gap-2">
                        <h6 className="mb-0">Location</h6>
                        <div className="d-flex flex-column gap-2">
                          <div>
                            <SelectBox
                              dataSource={dataSource.country || []}
                              displayExpr="country_name"
                              valueExpr="country_id"
                              label="Select Country"
                              searchEnabled={true}
                              onValueChanged={handleCountry}
                              labelMode="floating"
                              value={dataSource.selectedCountry}
                            />
                          </div>
                          <div>
                            <SelectBox
                              dataSource={dataSource.city || []}
                              label="Select City"
                              labelMode="floating"
                              displayExpr="city_name"
                              valueExpr="city_id"
                              searchEnabled={true}
                              onValueChanged={(e) => handleCity(e.value)}
                              value={dataSource.selectedCity}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-100 mt-4 d-flex flex-column gap-2">
                        <h6>Date</h6>
                        <div className="w-100 d-flex gap-2 align-items-center flex-wrap">
                          <button
                            onClick={handleDate_all}
                            className={
                              dataSource.selectedDate === "All"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            All
                          </button>{" "}
                          <button
                            onClick={handleDate_today}
                            className={
                              dataSource.selectedDate === "Today"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Today
                          </button>{" "}
                          <button
                            onClick={handleDate_weekend}
                            className={
                              dataSource.selectedDate === "Weekend"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Weekend
                          </button>{" "}
                          <div
                            className={
                              dataSource.checked === true
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                            onClick={() =>
                              handleChange({ value: !dataSource.checked })
                            }
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                margin: 0,
                              }}
                            >
                              <CheckBox
                                style={{ visibility: "hidden" }}
                                value={dataSource.checked}
                                checked={dataSource.checked}
                                onValueChanged={handleChange}
                              />
                              <span style={{ marginLeft: "-10px" }}>
                                Custom Dates
                              </span>
                            </label>
                          </div>
                        </div>

                        {dataSource.checked && (
                          <DateRangeBox
                            startDateLabel="From Date"
                            endDateLabel="To Date"
                            displayFormat="dd-MM-yyyy"
                            applyValueMode="useButtons"
                            startDate={dataSource.startDate}
                            endDate={dataSource.endDate}
                            onValueChanged={handleDateRange}
                          />
                        )}
                      </div>

                      <div className="mt-4 d-flex flex-column gap-2">
                        <h6>Pricing Option</h6>
                        <div className="d-flex gap-2 flex-wrap">
                          <button
                            onClick={handlePricing_All}
                            className={
                              dataSource.selectedPricing === "All"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            All
                          </button>
                          <button
                            onClick={handlePricing_Free}
                            className={
                              dataSource.selectedPricing === "Free" ||
                              dataSource.selectedPricing === "All"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Free
                          </button>
                          <button
                            onClick={handlePricing_Ticketed}
                            className={
                              dataSource.selectedPricing === "Ticketed" ||
                              dataSource.selectedPricing === "All"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap "
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Ticketed
                          </button>
                        </div>
                      </div>

                      <div className="mt-4 d-flex flex-column gap-2">
                        <h6 className="m-0">Select Category</h6>
                        <SelectBox
                          dataSource={dataSource.maincategory_all || []}
                          displayExpr="main_cat_name"
                          valueExpr="main_cat_id"
                          label="Change Category"
                          searchEnabled={true}
                          onValueChanged={handleMainCategory}
                          labelMode="floating"
                          value={dataSource.selectedMainCategory}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-9 col-xl-10 filtermh">
              <div>
                <Modal className="filterPopUp" show={filter} onHide={doFilter}>
                  <Modal.Header
                    closeButton
                    className="m-0 d-flex align-items-center text-center border-light"
                    style={{ padding: "10px 10px" }}
                  >
                    <div className="w-100 filterBg pb-2">
                      <h5 className="d-flex align-items-center gap-2">
                        <FaFilter /> Filters
                      </h5>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className="w-100">
                      <div className="d-flex flex-column gap-2">
                        <h6 className="m-0 p-0">Event Mode</h6>
                        <div className="d-flex gap-2">
                          <button
                            onClick={handleEvent_offline}
                            className={
                              dataSource.selectedEventMode === "Offline"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Offline
                          </button>

                          <button
                            onClick={handleEvent_online}
                            className={
                              dataSource.selectedEventMode === "Online"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Online
                          </button>
                        </div>
                      </div>

                      <div className="mt-4 d-flex flex-column gap-2">
                        <h6 className="mb-0">Location</h6>
                        <div className="d-flex flex-column gap-2">
                          <div>
                            <SelectBox
                              dataSource={dataSource.country || []}
                              displayExpr="country_name"
                              valueExpr="country_id"
                              label="Select Country"
                              searchEnabled={true}
                              onValueChanged={handleCountry}
                              labelMode="floating"
                              value={dataSource.selectedCountry}
                            />
                          </div>
                          <div>
                            <SelectBox
                              dataSource={dataSource.city || []}
                              label="Select City"
                              labelMode="floating"
                              displayExpr="city_name"
                              valueExpr="city_id"
                              searchEnabled={true}
                              onValueChanged={(e) => handleCity(e.value)}
                              value={dataSource.selectedCity}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-100 mt-4 d-flex flex-column gap-2">
                        <h6>Date</h6>
                        <div className="w-100 d-flex gap-2 align-items-center flex-wrap">
                          <button
                            onClick={handleDate_all}
                            className={
                              dataSource.selectedDate === "All"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            All
                          </button>{" "}
                          <button
                            onClick={handleDate_today}
                            className={
                              dataSource.selectedDate === "Today"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Today
                          </button>{" "}
                          <button
                            onClick={handleDate_weekend}
                            className={
                              dataSource.selectedDate === "Weekend"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Weekend
                          </button>{" "}
                          {/* <CheckBox
                            value={dataSource.checked}
                            checked={dataSource.checked}
                            onValueChanged={handleChange}
                          />
                        </div>
                        <button
                          onClick={() => {
                            handleChange();
                          }}
                          className={
                            dataSource.selectedDate === "Custom"
                              ? "btn py-1 px-2 act filterBtn m-0 text-nowrap w-50"
                              : "btn py-1 px-2 filterBtn m-0 text-nowrap w-50"
                          }
                        >
                          Custom Dates
                        </button> */}
                          <div
                            className={
                              dataSource.checked === true
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                            onClick={() =>
                              handleChange({ value: !dataSource.checked })
                            }
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                margin: 0,
                              }}
                            >
                              <CheckBox
                                style={{ visibility: "hidden" }}
                                value={dataSource.checked}
                                checked={dataSource.checked}
                                onValueChanged={handleChange}
                              />
                              <span style={{ marginLeft: "-10px" }}>
                                Custom Dates
                              </span>
                            </label>
                          </div>
                          {dataSource.checked && (
                            <DateRangeBox
                              startDateLabel="From Date"
                              endDateLabel="To Date"
                              displayFormat="dd-MM-yyyy"
                              applyValueMode="useButtons"
                              startDate={dataSource.startDate}
                              endDate={dataSource.endDate}
                              onValueChanged={handleDateRange}
                            />
                          )}
                        </div>

                        <div className="mt-4 d-flex flex-column gap-2">
                          <h6>Pricing Option</h6>
                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              onClick={handlePricing_All}
                              className={
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              All
                            </button>
                            <button
                              onClick={handlePricing_Free}
                              className={
                                dataSource.selectedPricing === "Free" ||
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Free
                            </button>
                            <button
                              onClick={handlePricing_Ticketed}
                              className={
                                dataSource.selectedPricing === "Ticketed" ||
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Ticketed
                            </button>
                          </div>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-2">
                          <h6 className="m-0">Select Category</h6>
                          <SelectBox
                            dataSource={dataSource.maincategory_all || []}
                            displayExpr="main_cat_name"
                            valueExpr="main_cat_id"
                            label="Change Category"
                            searchEnabled={true}
                            onValueChanged={handleMainCategory}
                            labelMode="floating"
                            value={dataSource.selectedMainCategory}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <button className="button" onClick={doFilter}>
                      Apply
                    </button>
                  </Modal.Footer>
                </Modal>
                {/* ---------------------------------------------------------- */}
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active">
                  {/* Website breadcrumb */}
                  <div className="d-none d-lg-block">
                    <div
                      className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                      style={{ gap: "2px", overflow: "scroll" }}
                    >
                      <div
                        className="p-auto me-3 rounded d-none d-md-block backBtn cursor-pointer"
                        style={{ border: "1px solid #ddd" }}
                      >
                        <IoChevronBack
                          style={{ fontSize: "24px" }}
                          onClick={handlebackButton}
                        />
                      </div>

                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent === "Country"
                              ? "#3F00FF"
                              : undefined,
                        }}
                      >
                        {breadCrumbCollection.breadCrumbCountry}
                      </p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent === "City"
                              ? "#3F00FF"
                              : undefined,
                        }}
                        //style={{ color: "#3F00FF" }}
                      >
                        {breadCrumbCollection.breadCrumbCity}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent ===
                            "Event Mode"
                              ? "#3F00FF"
                              : undefined,
                        }}
                        //style={{ color: "#3F00FF" }}
                      >
                        {breadCrumbCollection.breadCrumbEventMode}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent === "Date"
                              ? "#3F00FF"
                              : undefined,
                        }}
                        //style={{ color: "#3F00FF" }}
                      >
                        {breadCrumbCollection.breadCrumbDate}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent === "Pricing"
                              ? "#3F00FF"
                              : undefined,
                        }}
                        //style={{ color: "#3F00FF" }}
                      >
                        {breadCrumbCollection.breadCrumbPricing}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p
                        className="m-0 p-0"
                        style={{
                          color:
                            breadCrumbCollection.breadCrumbCurrent ===
                            "Main Category"
                              ? "#3F00FF"
                              : undefined,
                        }}
                        //style={{ color: "#3F00FF" }}
                      >
                        {breadCrumbCollection.breadCrumbCategory}
                      </p>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 ">
                    {loading ? (
                      Array(8)
                        .fill(0)
                        .map((_, index) => (
                          <div className="col overflow-hidden" key={index}>
                            <Skeleton height={250} />
                            <Skeleton height={15} />
                            <Skeleton height={15} />
                            <Skeleton height={30} />
                          </div>
                        ))
                    ) : (
                      <>
                        {dataSource.tempEventData &&
                        dataSource.tempEventData.length > 0 ? (
                          dataSource.tempEventData.map((data) => (
                            <div
                              className="col events-bg mb-1 bg-gray bg-sm-none"
                              key={data.event_id}
                            >
                              <div className="product-block mb-3 mb-lg-4">
                                <div
                                  className="image-wrap "
                                  style={{
                                    borderRadius: "6px",
                                    overflow: "hidden",
                                  }}
                                  onClick={() =>
                                    selectedDetailsEvent(
                                      data?.event_id,
                                      data?.event_title
                                    )
                                  }
                                >
                                  <div className="product-image-container">
                                    {data?.event_image && !imageError ? (
                                      <img
                                        src={`${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`}
                                        className="w-100"
                                        alt="productImg-"
                                        loading="lazy"
                                        onError={handleImageError}
                                      />
                                    ) : (
                                      <Noimage />
                                    )}
                                  </div>
                                  <div class="align-items-center blog-description bg-white p-2">
                                    <h5
                                      className="mb-0 text-ellipsis"
                                      title={data?.event_title}
                                      style={{
                                        maxHeight: "48px",
                                        fontSize: "16px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {data?.event_title}
                                    </h5>
                                    <p
                                      className="mb-0 text-ellipsis"
                                      style={{
                                        maxHeight: "24px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {data?.venue_name}
                                    </p>
                                    <div className="justify-content-between d-flex align-items-end gap-2">
                                      <div className="pe-0">
                                        <p class="mb-0 iq-title text-black">
                                          {data?.event_date}
                                          <br />
                                          {data?.event_time}
                                        </p>
                                      </div>
                                      <p
                                        className="button m-0 text-center"
                                        style={{
                                          padding: "6px 24px",
                                          height: "auto",
                                          fontSize: "12px",
                                          width: "40%",
                                          backgroundColor:
                                            data?.ticketing_option === "free" ||
                                            data?.ticketing_option ===
                                              "register"
                                              ? "green"
                                              : "#3f00ff",
                                        }}
                                      >
                                        {data?.ticketing_option_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingEvents;
