import React, { useEffect } from "react";
import { useState, useCallback, useRef } from "react";
import IMAGE1 from "../assets/images/genre/02.webp";
import { IoShareSocial } from "react-icons/io5";
import {
  ALL_ARTIST_DETAIL_RAGA,
  COMPOSERS_ITEM,
  FOLLOW_A_USER,
  FOLLOW_INITIAL_CHECK,
  GET_PERTICULAR_RAGABANK,
  GET_PROFILE_FOR_RECORD,
  GET_RAGABANK_DELETE_COMMENT,
  GET_THUMNAIL_FOR_RAGA,
  INSERT_COMMENT_FOR_RAGA,
  LANGUAGE_ITEMS,
  POST_VOTE_RAGA_DETAIL,
  RAGA_ITEMS,
  UNFOLLOW_A_USER,
  UPDATE_RAGA_VIEWS,
  UPDATE_VIDEOS_VIEW,
  V_TYPE_ITEMS,
} from "../Api/api";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../Api/requestMethods";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import VideoPath from "./HLSVimeoRagaPlayer.js";
import { GrPowerReset } from "react-icons/gr";

import {
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { DURATION, ERROR } from "../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { BsFillSendFill } from "react-icons/bs";
import { jwtDecode } from "jwt-decode";
import ShareModalRaga from "./ShareModelRaga.js";
import mlogo from "../MDnDRecords/Static/mlogo.png";
import { FaFilter } from "react-icons/fa";
import SelectBox from "devextreme-react/select-box";
import mdndimage from "./RagaImage/mdnd.png";
import { RAGABANK_IMAGEPATH } from "../config/config.js";
import { FaRegComment } from "react-icons/fa6";
import { ArtistallImg } from "../Subpages/MainPage/constant.js";
import FollowIcon from "../assets/Icons/FollowIcon.svg"
import FollowingIcon from "../assets/Icons/FollowingIcon.svg";
import RagaProfile from "../global/RagaProfile.js";
import ReadMore from "./ReadMore.js";
const dataSource = [
  { value: "value1", text: "Text 1" },
  { value: "value2", text: "Text 2" },
  { value: "value3", text: "Text 3" },
];



function VideoStore() {
  const textareaRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const [currentOffset, setCurrentOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const limit = 20;
  const [mdndData, setMdndData] = useState([]);
  const [ragaBankComment, setRagaBankComments] = useState([]);
  const [profileImages, setProfileImages] = useState({});
  const [value, setValue] = useState("");
  const [likeStatus, setLikeStatus] = useState(0);
  const [likeNumber, setlikeNumber] = useState(0);
  const [dislikeStatus, setDislikeStatus] = useState(0);
  const [count, setCount] = useState(0);
  const ragaslug = useParams();
  const navigate = useNavigate();
  const ragaobject = JSON.parse(atob(ragaslug.slug));
  const location = useLocation();
  const currentUrl = window.location.href;

  const videoid = ragaobject.video_id;
  const [videooid, setVideooId] = useState(ragaobject.video_id);
  const [roleeid, setRoleeId] = useState(ragaobject.role_id);
  const [followingState, setFollowingState] = useState({});
  const roleid = ragaobject.role_id;

  const previousAccompanistRef = useRef();
  //------------------------------------------------FocousFilter------------------------------------------------------
  const initialFilter = {
    ragaId: null,
    thalaId: null,
    vtypeId: null,
    languageId: null,
    composerId: null,
  }
  const [settings, setSetting] = useState({
    hasRagaFocousIn: true,
    hasThalaFocousIn: false,
    hasVtypeIdFocousIn: false,
    hasLanguageFocousIn: false,
    hasComposerFocousIn: false,
  });
  // -------------------------------------------FilterSideVideos------------------------------------------------------
  const [filters, setFilters] = useState(initialFilter);
  // --------------------------------------------------JWT-------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );


  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // ------------------------GetPerticulrVideo---------------------------------------------------
  const prevUrlRef = useRef(window.location.href);

  const fetchPerticulatRagaVideo = async (videooid, roleeid) => {
    const endpoint = `${GET_PERTICULAR_RAGABANK()}/${videooid}/${roleeid}`;
    return await makeGetRequest(endpoint);
  };

  const { data: PerticularDisplay, refetch } = useQuery({
    queryKey: ["RagaVideo", videooid, roleeid],
    queryFn: () => fetchPerticulatRagaVideo(videooid, roleeid),
    onError: (error) => {
      console.log(error);
    },
  });

  console.log(PerticularDisplay, 'PerticularDisplay')
  useEffect(() => {
    const extractParamsFromSlug = (slug) => {
      try {
        const decoded = atob(slug);
        const { video_id, role_id } = JSON.parse(decoded);
        return { video_id, role_id };
      } catch (error) {
        console.error("Failed to parse slug:", error);
        return null;
      }
    };
    const slug = location.pathname.split("/").pop(); // Extract slug from URL
    const params = extractParamsFromSlug(slug);
    if (params && location.pathname !== prevUrlRef.current && videoid) {
      setVideooId(params.video_id);
      setRoleeId(params.role_id);
      refetch();
      prevUrlRef.current = location.pathname;
    }
  }, [location.pathname, refetch, videoid, count]);

  useEffect(() => {
    setVideoDetail(PerticularDisplay?.data);
  }, [PerticularDisplay]);

  console.log(videoDetail, "ALLvIDEOdETAIL")
  // ------------------------------------------------------------------------------------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };

  // -------------------------------------------SideVideoUrl------------------------------------------------

  const fetchingRagaVideos = async (limit, offset, filters) => {
    const { ragaId, thalaId, vtypeId, languageId, composerId } = filters;
    const data = { limit, offset };

    if (ragaId !== null) data.raga_id = ragaId;
    if (thalaId !== null) data.thala_id = thalaId;
    if (vtypeId !== null) data.vtype_id = vtypeId;
    if (languageId !== null) data.language_id = languageId;
    if (composerId !== null) data.composer_id = composerId;

    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    const GET_MDND_RAGABANK_RECORD = `videos/raga_bank/filter/${base64String}`;

    try {
      const response = await makeGetRequest(GET_MDND_RAGABANK_RECORD);
      const records = response.data;
      const ragaWithThumbnails = await Promise.all(
        records.map(async (record) => {
          const thumbnailResponse = await makeGetRequest(
            `${GET_THUMNAIL_FOR_RAGA()}/${record?.id}`
          );
          return {
            ...record,
            thumbnail: thumbnailResponse?.data?.thumbnail,
          };
        })
      );
      return ragaWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`);
    }
  };

  const loadMoreVideos = useCallback(
    async (resetOffset = false) => {
      if (isFetching) return;

      setIsFetching(true);
      try {
        const offset = resetOffset ? 0 : currentOffset;
        const newRecords = await fetchingRagaVideos(limit, offset, filters);
        setMdndData((prevData) =>
          resetOffset ? newRecords : [...prevData, ...newRecords]
        );
        setCurrentOffset((prevOffset) => offset + limit);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsFetching(false);
      }
    },
    [currentOffset, isFetching, filters]
  );

  useEffect(() => {
    loadMoreVideos(true);
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.scrollHeight &&
        !isFetching
      ) {
        loadMoreVideos();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreVideos, isFetching]);

  // -------------------------------------------HandleSideVideo------------------------------------------------------------
  // const handleSideVideo = (e, sidedata) => {
  //   console.log(sidedata, "thissidedata");
  //   const json_string = JSON.stringify({
  //     video_id: sidedata.id,
  //     role_id: user.role_id,
  //   });
  //   const slug = btoa(json_string);
  //   navigate(`/RagaBankVideo/videos/${slug}`);
  //   window.scrollTo(0, 0);
  // };
  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '_')
      .replace(/(^-|-$)/g, '');
  };

  const handleSideVideo = (e, sidedata) => {
    const roleId = user && user.login_id && user.role_id ? user.role_id : null;

    // Create the JSON string with video and role IDs
    const json_string = JSON.stringify({
      video_id: sidedata.id,
      role_id: roleId, // Use roleId variable
    });

    // Encode the JSON string to base64
    const slug = btoa(json_string);

    const songTitleSlug = createSlug(sidedata?.song_title);

    navigate(`/RagaBank/${songTitleSlug}/${slug}`);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  // -----------------------------------------------FormatTime-------------------------------------------------------------
  const formatTime = (dateString) => {
    if (!dateString) return "Invalid date";

    const date = parseISO(dateString);
    const now = new Date();

    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const weeks = differenceInWeeks(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  // ---------------------------------------------CommentFetching-----------------------------------------------------------
  const GET_RAGA_COMMENT_DETAIL = `videos/raga_bank/${videoid}/comments/read_all`;

  const fetchComment = async () =>
    await makeGetRequest(GET_RAGA_COMMENT_DETAIL);
  const { data: CommentDataSource } = useQuery({
    queryKey: ["commentDetail"],
    quetyFn: () => fetchComment(),
    onError: () => {
      console.log("erroe Message");
    },
  });

  const fetchRagaComment = async () => {
    try {
      const response = await fetchComment();
      setRagaBankComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  useEffect(() => {
    fetchRagaComment(CommentDataSource);
  }, [videoid]);

  // ---------------------------------------------PerticularCommentIcon----------------------------------------------------------------------------------------------
  const fetchCommentProfile = async (roleid) => {
    const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleid}`;
    return await makeGetRequest(endPoint);
  };
  const { data: ProfileComment } = useQuery({
    queryKey: ["PerticularCommentProfile", roleid],
    queryFn: () => {
      return fetchCommentProfile(roleid);
    },
    onError: (error) => {
      console.log("Error occurred for roleid:", roleid);
      console.error(error);
    },
  });


  // ---------------------------------------------GetloginBaseRagaCommentIcon-------------------------------------------------------------------
  const fetchingProfile = async (roleid) => {
    const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleid}`;
    return await makeGetRequest(endPoint);
  };

  const fetchAllProfileData = async (comments) => {
    const profileDataPromises = comments.map((comment) =>
      fetchingProfile(comment.role_id).then((data) => ({
        role_id: comment.role_id,
        profile_data: data.data,
      }))
    );
    const profileDataArray = await Promise.all(profileDataPromises);
    return profileDataArray.reduce((acc, { role_id, profile_data }) => {
      acc[role_id] = profile_data;
      return acc;
    }, {});
  };
  useEffect(() => {
    const fetchingProfile = async () => {
      if (ragaBankComment.length > 0) {
        try {
          const images = await fetchAllProfileData(ragaBankComment);
          setProfileImages(images);
        } catch (error) {
          console.error("Error fetching profile images:", error);
        }
      }
    };

    fetchingProfile();
  }, [ragaBankComment]);


  // -------------------------------------------------DeleteRagaComment-------------------------------------------------------------------------
  const DeleteRagaComment = async (commentid) => {
    try {
      const response = await makeDeleteRequest(
        `${GET_RAGABANK_DELETE_COMMENT()}/${commentid}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  const deletCommetMutation = useMutation({
    mutationKey: ["deleteRagaCommet"],
    mutationFn: DeleteRagaComment,
    onSuccess: (data, commentid) => {
      const updatedComments = ragaBankComment.filter(
        (comment) => comment.id !== commentid
      );
      setRagaBankComments(updatedComments);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = async (commentid) => {
    if (commentid.login_id === user.login_id) {
      const commentedid = commentid.id;
      try {
        await deletCommetMutation.mutateAsync(commentedid);
      } catch (error) {
        console.error("Error deleteting comment :", error);
      }
    }
  };
  // -----------------------------------------------INSERTING==COMMENT----------------
  const InsertingCommentMessage = async (message) => {
    try {
      const endpoint = await makePostRequest(
        INSERT_COMMENT_FOR_RAGA(),
        message
      );
      return endpoint;
    } catch (error) {
      throw error;
    }
  };

  const InsertMessage = useMutation({
    mutationKey: ["insertRagaMessage"],
    mutationFn: (data) => InsertingCommentMessage(data),
    onSuccess: (data) => {
      console.log("succesfully got data", "data");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleRagaComment = () => {
    if (!user) {
      notify("Please register OR login to perform comment operation", Error);
    } else {
      const newComment = {
        video_id: videoid,
        login_id: user.login_id,
        role_id: user.role_id,
        message: value,
      };
      InsertMessage.mutate(newComment, {
        onSuccess: (data) => {
          setRagaBankComments([
            ...ragaBankComment,
            { ...newComment, id: data.id, created_at: data.created_at },
          ]);
          setValue("");
          fetchRagaComment();
        },
        onError: (error) => {
          console.log("Error posting comment:", error);
        },
      });
    }
  };
  const cancleComment = () => {
    setValue("");
  };
  //------------------------------------------------UpdatelikesAction--------------------------------------------------------------------------------
  const LikeorDislike = async (voteDetail) => {
    try {
      const response = await makePostRequest(
        POST_VOTE_RAGA_DETAIL(),
        voteDetail
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const AllRagaVoteMutation = useMutation({
    mutationKey: ["ragaVote"],
    mutationFn: (data) => LikeorDislike(data),
    onSuccess: (data) => {
      console.log("success fully liked");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleVote = (action, data1) => {
    if (!user) {
      notify("Please register OR logIn  to perform this action.", ERROR);
    } else {
      const ambivalent = action === "like" ? 1 : 0;

      const data = {
        video_id: videoid,
        login_id: user.login_id,
        role_id: user.role_id,
        ambivalent: ambivalent,
      };

      AllRagaVoteMutation.mutate(data, {
        onSuccess: () => {
          if (action === "like") {
            setLikeStatus(1);
            setCount(count + 1);
            setDislikeStatus("inactive");
          } else {
            setDislikeStatus(1);
            if (!likeNumber === 0) {
              setLikeStatus(1);
            } else {
              setlikeNumber(data1);
              setCount(count + 1);
            }

            setLikeStatus(null);
          }
        },
        onError: (error) => {
          console.error("Error posting vote:", error);
        },
      });
    }
  };



  const viewChangesCode = async (data) => {
    try {
      const endPoint = await makePostRequest(UPDATE_RAGA_VIEWS(), data);
      return endPoint;
    } catch (error) {
      throw new Error(`Failed to update view: ${error.message}`);
    }
  };

  const viewUpdateMutation = useMutation({
    mutationKey: ["viewDetail"],
    mutationFn: (viewData) => viewChangesCode(viewData),
    onSuccess: (data) => {
      if (data && data.status === 200) {
        console.log("View Changed successfully");
        console.log("Mutation Success Data:", data);
      }
    },
    onError: (error) => {
      console.error("Error updating view:", error);
    },
  });

  useEffect(() => {
    const fetchingAndUpdateView = async () => {
      try {
        if (videoDetail?.id && user?.login_id && user?.role_id) {
          const viewValue = {
            video_id: videoDetail.id,
            login_id: user.login_id,
            role_id: user.role_id,
          };
          await viewUpdateMutation.mutateAsync(viewValue);
        }
      } catch (error) {
        console.log(error, "tish");
      }
    };

    if (videoDetail) {
      fetchingAndUpdateView();
    }
  }, [videoDetail, user]);


  // --------------------------------------------------------FilterRelateFunction----------------------------------------------------------------------------------------

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  //Raga: ----->
  const RagaItemsApi = async () => await makeGetRequest(RAGA_ITEMS);

  const { data: RagaDataSource } = useQuery({
    queryKey: ["RagaItem"],
    queryFn: () => RagaItemsApi(),
    onError: () => {
      notify("No Raga Items", ERROR, DURATION);
    },
  });

  //Language:------->
  const LanguageApi = async () => await makeGetRequest(LANGUAGE_ITEMS);

  const { data: LanguageDataSource } = useQuery({
    queryKey: ["LanguageItem"],
    queryFn: () => LanguageApi(),
    onError: () => {
      notify("No Language Item");
    },
  });


  //Vtype:---------->
  const VTypeApi = async () => await makeGetRequest(V_TYPE_ITEMS);
  const { data: VTypeDataSource } = useQuery({
    queryKey: ["VTypeItem"],
    queryFn: () => VTypeApi(),
    onError: () => {
      notify("No vType Item");
    },
  });

  //Composers:--------->
  const ComposersApi = async () => await makeGetRequest(COMPOSERS_ITEM);

  const { data: ComposerDataSource } = useQuery({
    queryKey: ["ComposersItem"],
    queryFn: () => ComposersApi(),
    onError: () => {
      notify("No Composer Item");
    },
  });
  //ArtistName:------------>
  const ArtistNamaApi = async () => await makeGetRequest(ALL_ARTIST_DETAIL_RAGA());
  const { data: ArtistDataSource } = useQuery({
    queryKey: ["ArtisNameItem"],
    queryFn: () => ArtistNamaApi(),
    onError: () => {
      notify("No Artist Items");
    },
  });


  const combinedArtistAccompanist = videoDetail
    ? [
      ...(videoDetail?.Accompanist_details || []),
      ...(videoDetail?.Artist_details || []),
    ]
    : [];


  // ------------------------------------------------------------------------------
  const handleForwar = async (value) => {
    if (value === true) {
      try {
        const currentVideoIndex = mdndData?.findIndex(
          (video) => video.id === videoDetail?.id
        );
        if (currentVideoIndex === -1 || currentVideoIndex === undefined) {
          console.error("Current video not found in the list.");
          return;
        }
        const nextVideoIndex = currentVideoIndex + 1;

        if (nextVideoIndex < mdndData.length) {
          const nextVideo = mdndData[nextVideoIndex];
          const roleId = user && user.login_id && user.role_id ? user.role_id : null;
          const json_string = JSON.stringify({
            video_id: nextVideo.id,
            role_id: roleId
          })
          const slug = btoa(json_string);
          const songTitleSlug = createSlug(nextVideo?.song_title);
          navigate(`/RagaBank/${songTitleSlug}/${slug}`);
        } else {
          console.log("This is the last video in the list.")
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleRewine = async (value) => {
    if (value === true) {
      try {
        const currentVidoIndex = mdndData?.findIndex((video) => video.id === videoDetail?.id)
        if (currentVidoIndex === -1 || currentVidoIndex === undefined) {
          console.error("Current video not found in the list.");
          return;
        }
        const previousRagaIndex = currentVidoIndex - 1;
        if (previousRagaIndex >= 0) {
          const previousRagaData = mdndData[previousRagaIndex];
          const roleId = user && user.login_id && user.role_id ? user.role_id : null;
          const json_string = JSON.stringify({
            video_id: previousRagaData.id,
            role_id: roleId
          })
          const slug = btoa(json_string);
          const songTitleSlug = createSlug(previousRagaData?.song_title);
          navigate(`/RagaBank/${songTitleSlug}/${slug}`);
        } else {
          console.log("This is the first video in the list.");
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  // ----------------------------------------------------------------------------------------
  const followingUser = async (data) => {
    try {
      const response = await makePostRequest(FOLLOW_A_USER, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const unFollowingUser = async (data) => {
    try {
      const response = await makePostRequest(UNFOLLOW_A_USER, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  const mutationRagaFollow = useMutation({
    mutationKey: ["RagaFollow"],
    mutationFn: (data) => followingUser(data), // Pass the whole `data` object
  });

  const mutationRagaUnfollow = useMutation({
    mutationKey: ["UnFollow"],
    mutationFn: (data) => unFollowingUser(data),
  })

  const handleFollow = (loginid, roleid) => {
    const isFollowing = followingState[roleid] === "Following";

    if (isFollowing) {
      // Unfollow logic
      const data = {
        user1_loginid: user ? user.login_id : "",
        user2_loginid: loginid ? Number(loginid) : "",
        user1_roleid: user ? user.role_id : "",
        user2_roleid: roleid ? Number(roleid) : "",
        unfollower_roleid: user ? user.role_id : "",
        unfollowing_roleid: roleid ? Number(roleid) : ""
      };
      mutationRagaUnfollow.mutate(data, {
        onSuccess: () => {
          setFollowingState((prevState) => ({
            ...prevState,
            [roleid]: "Follow", // Update to "Follow" on successful unfollow
          }));
        },
      });
    } else {
      // Follow logic
      const data = {
        user1_loginid: user ? user.login_id : "",
        user2_loginid: loginid ? Number(loginid) : "",
        user1_roleid: user ? user.role_id : "",
        user2_roleid: roleid ? Number(roleid) : "",
        follower_roleid: user ? user.role_id : "",
        following_roleid: roleid ? Number(roleid) : ""
      };
      mutationRagaFollow.mutate(data, {
        onSuccess: () => {
          setFollowingState((prevState) => ({
            ...prevState,
            [roleid]: "Following", // Update to "Following" on successful follow
          }));
        },
      });
    }
  };


  console.log(followingState, "FOLLOWINGStatus")
  // ------------------------------ChekFollowFollowing------------------------------------------------
  const checkInitialFollowState = async (user1_roleid, user2_roleid) => {
    try {
      const response = await makePostRequest(FOLLOW_INITIAL_CHECK, {
        user1_roleid,
        user2_roleid,
      });
      return response;
    } catch (error) {
      console.error('Error checking follow state', error);
    }
  };

  useEffect(() => {


    if (
      combinedArtistAccompanist?.length > 0 &&
      combinedArtistAccompanist !== previousAccompanistRef.current // Avoid rerun if it's the same data
    ) {
      const fetchFollowState = async () => {
        const updates = {}; // This will store follow/unfollow states for each artist
        for (const artist of combinedArtistAccompanist) {
          try {
            const result = await checkInitialFollowState(user?.role_id, artist.role_id);

            if (result?.status === 'success' && result?.message === 'This user is follow') {
              updates[artist.role_id] = 'Following';
            } else if (result?.status === 'Failed' && result?.message === 'This user is not follow!') {
              updates[artist.role_id] = 'Follow';
            }
          } catch (error) {
            console.error('Error checking follow state for artist:', artist.role_id, error);
          }
        }

        // Check if state has changed before updating to avoid unnecessary re-renders
        setFollowingState((prevState) => {
          const newState = { ...prevState, ...updates };
          if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
            return newState; // Update only if there are actual changes
          }
          return prevState;
        });
      };

      fetchFollowState();

      // Update the ref with the current state
      previousAccompanistRef.current = combinedArtistAccompanist;
    }
  }, [combinedArtistAccompanist]);

  const resetFilters = () => {
    setFilters(initialFilter);
  };



  // ------------------------------ChekFollowFollowing------------------------------------------------
  console.log(videoDetail?.video_link, 'videoDetail')
  return (
    <div className="videoOverflow">
      <div className="videoPageMargin section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="ragabank-pg col-12 noFade">
            <div className=" d-flex col-lg-7 col-xl-8 col-xxl-9 flex-column me-lg-4 pb-3">
              <div className="bg-white">
                <VideoPath
                  videoUrl={videoDetail && videoDetail?.video_link_old_reference}
                  onActionPlayForward={handleForwar}
                  onActionPlayRewine={handleRewine}
                />
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                  <div className="w-100 mt-3 d-md-flex align-items-center justify-content-between gap-3">
                    <h5
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="m-0 P-0"
                    >
                      {videoDetail?.song_title}
                    </h5>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                      <div className="d-flex align-items-center gap-md-2 justify-content-between w-100 mt-3 mt-md-0">
                        <div className="like_dislike" id="like_dislike">
                          <div
                            className={`like ${videoDetail?.like_status === 1 && user.login_id
                                ? "fa-solid"
                                : "fa-regular"
                              } touch_feedback`}
                            id="like"
                            onClick={() => handleVote("like")}
                            data-status={likeStatus}
                          >
                            <i
                              id="like__icon"
                              className={`fa-regular fa-thumbs-up ${videoDetail?.like_status === 1 && user.login_id
                                  ? "like__animation"
                                  : ""
                                }`}
                            ></i>
                            <span className="Likenumber" id="like-number">
                              {/* {videoDetail?.likes} */}
                            </span>
                          </div>
                          <span className="divider"></span>
                          <div
                            className={`dislike ${videoDetail?.like_status === 0 && user.login_id
                                ? "fa-solid"
                                : "fa-regular"
                              } touch_feedback`}
                            id="dislike"
                            onClick={() => handleVote("dislike")}
                            data-status={dislikeStatus}
                          >
                            <i
                              id="dislike__icon"
                              className={`fa-regular fa-thumbs-down ${videoDetail?.like_status === 0 && user.login_id
                                  ? "dislike__animation"
                                  : ""
                                }`}
                            ></i>
                          </div>
                        </div>
                        {/* <button
                          className="button d-flex align-items-center"
                          style={{ borderRadius: "4px", height: "36px" }}
                          variant="primary"
                          onClick={handleShow}
                        >
                          <i aria-hidden="true">
                            {" "}
                            <IoShareSocial />
                          </i>
                          <span className="Likenumber">Share</span>
                        </button> */}
                        <button className="border-button d-sm-none">
                          Comments
                        </button>
                        <ShareModalRaga
                          show={show}
                          handleClose={handleClose}
                          currentUrl={currentUrl}
                          handleCopy={handleCopy}
                          inputRef={inputRef}
                          mlogo={mlogo}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="search mb-3 d-none">
                    <input
                      type="text"
                      className="searchTerm"
                      placeholder="Search Videos..."
                      id="input_text"
                    />
                    <button type="submit" className="searchButton">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <div className="profile-section gap-2 align-items-center">
                </div>

                <div className="bg-light commentBgBorder p-2 mt-2">
                  <p>
                    <b>
                      {videoDetail?.views} views{" "}
                      {formatTime(videoDetail?.created_date)}
                    </b>
                  </p>
                  <div>
                  </div>
                  <div className="bg-light rounded p-2 mt-2">
                    <div>
                      <h6>Description</h6>
                      <ReadMore html={videoDetail?.description} />
                    </div>

                  </div>
                  <div className="mb-2">
                    {combinedArtistAccompanist &&
                      combinedArtistAccompanist?.length > 0 && (
                        <>
                          <p className="mb-2">
                            <b>Artist</b>
                          </p>
                          {combinedArtistAccompanist.map((data, index) => (
                            <React.Fragment key={index}>
                              {
                                data?.name !== null && data.name !== "" &&                               <div className="d-flex mb-2 align-items-center">
                                {data?.profile_photo ? (
                                  <img
                                    className="img-fluid rounded-circle"
                                    src={`${ArtistallImg}/${data?.profile_photo}`}
                                    style={{ width: "38px", height: "auto" }}
                                   
                                  />
                                ) : (
                                  <RagaProfile />
                                )}
                                {

                                  data?.name !== null && data.name !== "" && <p className="mb-0 mx-2 ml-2">{data?.name}</p>
                                }

                                <button className="ml-2" onClick={() => handleFollow(data?.login_id, data?.role_id)}>
                                  {followingState[data?.role_id] === "Following" ? (
                                    <img src={FollowingIcon}  />
                                  ) : (
                                    <img src={FollowIcon}  />
                                  )}
                                </button>

                              </div>
                              }

                            </React.Fragment>
                          ))}
                        </>
                      )}
                  </div>

                </div>

                <div className="product-review-list d-none d-sm-block">
                  <ul className="list-inline m-0 p-0">
                    <li className="pb-3 mb-0">
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex gap-2 align-items-center justify-content-between mt-2">
                          <div className="videoCommentImg">
                            <img
                              src={`data:image/png;base64,${ProfileComment?.data?.profile_image}`}
                            />
                          </div>
                          <div className="w-100">
                            <textarea
                              className="autosize"
                              ref={textareaRef}
                              value={value}
                              onChange={handleChange}
                              rows={1}
                              placeholder="Add Comments"
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                          <button
                            className="border-button"
                            onClick={cancleComment}
                          >
                            Cancel
                          </button>
                          <button
                            className="button"
                            onClick={handleRagaComment}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </li>
                    <ul className="list-inline m-0 p-0">
                      {ragaBankComment &&
                        ragaBankComment
                          .slice()
                          .reverse()
                          .map((comment) => {
                            const profile = profileImages[comment.role_id];
                            const profilephoto = profile?.profile_image;
                            return (
                              <li
                                key={comment.id}
                                className="pb-3 d-noneSmallDevice"
                              >
                                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center align-items-start gap-2 w-100">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="flex-shrink-0 videoCommentImg overflow-hidden d-flex align-items-center justify-content-center">
                                      <img
                                        src={`data:image/png;base64,${profilephoto}`}
                                        alt="user-image"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="about-user">
                                      <div className="d-flex align-items-center flex-wrap gap-2">
                                        <h6 className="mb-0">
                                          {profile?.profile_name}
                                          {comment.login_id}
                                        </h6>
                                        <p className="p-0 m-0 published-date">
                                          {formatTime(comment?.created_at)}
                                        </p>
                                      </div>
                                      <p className="mt-0 mb-0">
                                        {comment.message}
                                      </p>
                                    </div>
                                  </div>
                                  <div id="container">
                                    <div id="menu-wrap">
                                      <input
                                        type="checkbox"
                                        className="toggler"
                                      // checked={openMenuId === comment.id}
                                      // onChange={() => toggleMenu(comment.id)}
                                      />
                                      <div className="dots">
                                        <div></div>
                                      </div>
                                      {/* {user && ( */}
                                      <div className="menu ">
                                        <div>
                                          <button
                                            onClick={() =>
                                              handleDelete(comment)
                                            }
                                            className="p-0"
                                          >
                                            <li>Delete</li>
                                          </button>
                                        </div>
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                    </ul>
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 d-none d-sm-block">
                  <p className="mb-0">
                  </p>
                  <div className="linkBlueText">
                    <p
                      className="commentMobileView mb-0"
                    >
                      Show Comments
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-5 col-xl-4 col-xxl-3">
              <div className="d-flex justify-content-end mb-2">
                <button className="button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" data-bs-backdrop="false">
                  <FaFilter /> Filters
                </button>
              </div>
              <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header d-flex">
                  <div className="d-flex align-items-center">
                    <button type="button" className="btn-close me-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 className="offcanvas-title " style={{ color: "#ff2e2e" }} id="offcanvasWithBothOptionsLabel"> <FaFilter /> Filters</h5>
                  </div>
                  <button className="button" type="button" >
                    <GrPowerReset className="me-1" /> Reset
                  </button>
                </div>
                <div className="offcanvas-body">
                  <ul className="p-0">
                    <li>
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: RagaDataSource?.data,
                            key: 'id'
                          },
                          sort: [{ selector: 'raaga_name', desc: false }]
                        }}
                        searchEnabled={true}
                        value={filters.ragaId}
                        className="mb-2"
                        label="Raga"
                        labelMode="floating"
                        displayExpr="raaga_name"
                        valueExpr="id"
                        onValueChanged={(e) =>
                          handleFilterChange("ragaId", e.value)
                        }
                      />
                    </li>
                    <li>
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: LanguageDataSource?.data,
                            key: 'id'
                          },
                          sort: [{ selector: 'song_language_name', desc: false }]
                        }}
                        searchEnabled={true}
                        displayExpr="song_language_name"
                        valueExpr="id"
                        label="Language"
                        labelMode="floating"
                        value={filters.languageId}
                        className="mb-2"
                        onValueChanged={(e) =>
                          handleFilterChange("languageId", e.value)
                        }
                      />
                    </li>
                    <li>
                      {" "}
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: VTypeDataSource?.data,
                            key: 'id'
                          },
                          sort: [{ selector: 'vtype_name', desc: false }]
                        }}
                        searchEnabled={true}
                        displayExpr="vtype_name"
                        valueExpr="id"
                        className="mb-2"
                        label="Type"
                        labelMode="floating"
                        value={filters.vtypeId}
                        onValueChanged={(e) =>
                          handleFilterChange("vtypeId", e.value)
                        }
                      />
                    </li>
                    <li>
                      {" "}
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: ComposerDataSource?.data,
                            key: 'id'
                          },
                          sort: [{ selector: 'composer_name', desc: false }]
                        }}
                        searchEnabled={true}
                        value={filters.composerId}
                        className="mb-2"
                        displayExpr="composer_name"
                        valueExpr="id"
                        label="Composer"
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleFilterChange("composerId", e.value)
                        }
                      />
                    </li>
                    <li>
                      {" "}
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: ArtistDataSource?.data,
                            key: 'id'
                          },
                          sort: [{ selector: 'artist_name', desc: false }]
                        }}
                        searchEnabled={true}
                        value={filters.thalaId}
                        className="mb-2"
                        displayExpr="artist_name"
                        valueExpr="artistid"
                        label="Artist "
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleFilterChange("thalaId", e.value)
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {mdndData &&
                mdndData.length > 0 &&
                mdndData.map(
                  (item, index) =>
                    item.id !== null && (
                      <div
                        key={index}
                        className="iq-watching-block mb-lg-3 d-flex"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="block-images position-relative"
                          style={{
                            width: "200px",
                            height: "112px",
                          }}
                        >
                          <div
                            className="overly-images imgRadius"
                            style={{
                              width: "200px",
                              height: "112px",
                            }}
                          >
                            <img
                              src={`${RAGABANK_IMAGEPATH}/${item?.thumbnail_url}`}
                              alt={item?.video_title}
                              className="img-fluid object-cover w-100 d-block border-0 videoOuter"
                              onClick={(e) => handleSideVideo(e, item)}
                            />
                          </div>
                        </div>
                        <div className="w-100 d-flex justify-content-between p-2">
                          <div className="w-100 me-3">
                            <h6
                              className="mb-0"
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={item.song_title}
                            >
                              <b>{item.song_title}</b>
                            </h6>
                            <p className="mb-0">MDnD</p>
                            <p className="mb-0 videoMinuts">
                              {formatTime(item?.created_date)}
                            </p>
                          </div>
                          <div>
                            <div id="container">
                              <div id="menu-wrap">
                                <input type="checkbox" className="toggler" />
                                <div className="dots">
                                  <div></div>
                                </div>
                                <div className="menu p-1">
                                  <div>
                                    <ul className="p-0">
                                      <li>
                                        <div className="d-flex align-items-center gap-2">
                                          <i
                                            className="fa fa-share"
                                            aria-hidden="true"
                                          ></i>
                                          Share
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoStore;
