import React, { useState, useEffect, useContext } from "react";
import MDnD from "../assets/images/shop/product/01.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { makeGetRequest } from "../Api/requestMethods";
import { ERROR, SUCCESS, DURATION } from "../global/ToastMsgConstants.tsx";
import { seasonEventListing } from "../Api/api";
import { countryIdIndia, cityIdChennai } from "../global/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ALLEVENT_DETAILS_IMAGES,
  countryParams,
  seasonEventId,
} from "../global/constants";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import notify from "devextreme/ui/notify";
import { MdKeyboardArrowRight } from "react-icons/md";

//newly added
import { BASEPATH } from "../config/config.js";
import axios from "axios";
import {
  GET_COUNTRIES,
  GET_CITIES_BY_COUNTRIES,
  SEASON_TICKETS_FILTER,
} from "../Api/api";
import { SelectBox } from "devextreme-react";
import DateRangeBox from "devextreme-react/date-range-box";
import { CheckBox } from "devextreme-react";
import { IMAGE_URL } from "../Subpages/MainPage/constant.js";
import { GlobalContext } from "../global/provider.js";
import Noimage from "../global/NoImage.js";
import { Helmet } from "react-helmet";

import getSeasonTicketKey from "../Api/getSeasonTicketKey.js";

function SkeletonLoader() {
  return (
    <div>
      <Skeleton height={250} />
      <Skeleton height={15} />
      <Skeleton height={15} />
      <Skeleton height={30} />
    </div>
  );
}

function SidebarSkeletonLoader() {
  return (
    <div>
      <Skeleton height={50} width="100%" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
      <Skeleton height={30} width="100%" className="mt-2" />
    </div>
  );
}

function SeasonTickets() {
  const SEASONTICKETCOUNTRYKEY = getSeasonTicketKey().SEASONTICKET_KEY;
  const SEASONEVENTKEY = getSeasonTicketKey().SEASONEVENT_KEY;

  const { setEventDataSource, setCartDetails } = useContext(GlobalContext);
  const [filter, setFilter] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const doFilter = () => {
    setFilter(!filter);
  };

  // const seasonEventListingFn = async () =>
  //   await makeGetRequest(seasonEventListing(countryIdIndia, cityIdChennai));
  // const {
  //   data: seasonEventsListingDs,
  //   error,
  //   isLoading,
  // } = useQuery({
  //   queryKey: ["__seasonEvents__"],
  //   queryFn: () => seasonEventListingFn(),
  //   onError: (error) => {
  //     notify(error.response.data.message, ERROR, DURATION);
  //   },
  // });

  const encodeBase64 = (str, key) => {
    const prefix = key?.toString();

    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const seasonEventsHandler = (data) => {
    const modifiedTitle = data.season_event_title
      ? data.season_event_title.split(" ").join("_")
      : "Season_Event";

    if (data && data.for_members === "Y") {
      navigate("/SeasonTickets/OrganizerLandingPage");
    }
    if (data && data.for_members === "N") {
      navigate(
        `/SeasonTickets/${modifiedTitle}?${countryParams}=${encodeBase64(
          countryIdIndia,
          SEASONTICKETCOUNTRYKEY
        )}&${seasonEventId}=${encodeBase64(
          data?.season_event_id,
          SEASONEVENTKEY
        )}`
      );
    }

    setEventDataSource((prev) => ({
      ...prev,
      multiple_tickets: data.multiple_tickets,
    }));
  };

  // new code by vignesh...

  const location = useLocation();

  const token = localStorage.getItem("MDnD_auth");
  const [dataSource, setDataSource] = useState({
    SeasonEventData: "",

    selectedEventMode: "",
    country: "",
    selectedCountry: "",
    city: "",

    selectedCity: "",
    selectedDate: "",
    startDate: null,
    endDate: null,
    checked: false,

    selectedDate_range: "",
    selectedPricing: "",
  });

  const MIDZ = location?.state?.MID;

  // countries fetch..
  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES);

  const GET_COUNTRY = async (GET_COUNTRIES) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country_events"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setDataSource((prevState) => ({ ...prevState, country: data.data }));
      } else {
        setDataSource((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, country: "" }));
    },
  });

  // setting selectedPricing initial..
  useEffect(() => {
    if (MIDZ) {
      setDataSource((prevState) => ({
        ...prevState,

        selectedPricing: "All",
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      if (params) {
        setDataSource((prevState) => ({
          ...prevState,

          selectedPricing: params.get("pr") || "",
        }));
      }
    }
  }, [location, MIDZ]);

  console.log(location, "location");

  // setting initial Event Mode..
  useEffect(() => {
    if (MIDZ) {
      console.log("inside");
      setDataSource((prevState) => ({
        ...prevState,

        selectedEventMode: "Offline",
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      if (params) {
        setDataSource((prevState) => ({
          ...prevState,

          selectedEventMode: params.get("eMode") || "",
        }));
      }
    }
  }, [location, MIDZ]);

  //set initial Date.....

  useEffect(() => {
    if (MIDZ) {
      setDataSource((prevState) => ({
        ...prevState,

        selectedDate: "All",
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      if (params) {
        setDataSource((prevState) => ({
          ...prevState,

          selectedDate: params.get("Date") || "",
        }));
      }
    }
  }, [location, MIDZ]);

  //set initial Country....
  useEffect(() => {
    if (MIDZ) {
      setDataSource((prevState) => ({
        ...prevState,

        selectedCountry: 101,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      if (params) {
        setDataSource((prevState) => ({
          ...prevState,

          selectedCountry: Number(params.get("ci")) || "",
        }));
      }
    }
  }, [location, MIDZ]);

  //initial setting of city...
  useEffect(() => {
    if (MIDZ) {
      setDataSource((prevState) => ({
        ...prevState,

        selectedCity: 3659,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      if (params) {
        setDataSource((prevState) => ({
          ...prevState,

          selectedCity: Number(params.get("c")) || "",
        }));
      }
    }
    const currenYear = new Date().getFullYear();
    const metaTitle = `Season Tickets ${currenYear}`;
    document.title = metaTitle;
  }, [location, MIDZ]);

  //fetch cities...
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_BY_COUNTRIES}`,
          {
            country_id: dataSource.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          setDataSource((prevState) => ({
            ...prevState,
            city: response.data.data,
          }));
        } else {
          setDataSource((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching CITIES for this country", error);
      }
    };
    if (dataSource.selectedCountry) {
      GETCITIESDATA();
    }
  }, [dataSource.selectedCountry]);

  console.log(MIDZ, "mid in season tickets");

  //dynamically changing the URL.................
  useEffect(() => {
    if (
      dataSource.selectedCountry &&
      dataSource.selectedCity &&
      dataSource.selectedEventMode &&
      dataSource.selectedPricing
    ) {
      const queryParams = new URLSearchParams({
        ci: dataSource.selectedCountry
          ? Number(dataSource.selectedCountry)
          : "",

        c: dataSource.selectedCity ? Number(dataSource.selectedCity) : "",
        eMode: dataSource.selectedEventMode ? dataSource.selectedEventMode : "",

        Date:
          dataSource.startDate === null
            ? dataSource.selectedDate
            : dataSource.selectedDate_range,

        pr: dataSource.selectedPricing ? dataSource.selectedPricing : "",
      });

      const newUrl = `/SeasonTickets/?${queryParams}`;

      navigate(newUrl);
    }

    // else{
    //   return;
    // }
  }, [
    dataSource.selectedCountry,
    dataSource.selectedCity,
    dataSource.selectedEventMode,
    dataSource.selectedDate,
    dataSource.selectedDate_range,

    dataSource.selectedPricing,
    navigate,
  ]);

  //onValueChanged functions..........

  const handleCountry = (e) => {
    //console.log("im inside");
    if (e.value) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setDataSource((prevState) => ({
        ...prevState,
        city: [],
        selectedCity: "",
      }));
    }
  };

  const handleCity = (value) => {
    if (value) {
      setDataSource((prevState) => ({ ...prevState, selectedCity: value }));
    }
  };

  const handleEvent_offline = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedEventMode: "Offline",
    }));
  };

  const handleEvent_online = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedEventMode: "Online",
    }));
  };

  const handleDateRange = (e) => {
    const startDate_change = e.value[0];
    const endDate_change = e.value[1];
    const concatedDate = `${
      startDate_change ? formatDate(startDate_change) : ""
    }-${endDate_change ? formatDate(endDate_change) : ""}`;

    if (startDate_change && endDate_change && concatedDate)
      setDataSource((prev) => ({
        ...prev,
        selectedDate_range: concatedDate,
        startDate: startDate_change,
        endDate: endDate_change,
      }));
  };

  const handleChange = (e) => {
    setDataSource((prevState) => ({
      ...prevState,
      checked: e.value,
      startDate: null,
      endDate: null,
      selectedDate: e.value ? "" : prevState.selectedDate,
      selectedDate_range: "",
    }));
  };
  const handleDate_today = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "Today",
    }));
  };

  const handleDate_All = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "All",
    }));
  };

  const handleDate_weekend = () => {
    setDataSource((prev) => ({
      ...prev,
      checked: prev.checked === true ? false : prev.checked,
      selectedDate: "Weekend",
      //tempEventData: [],
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };

  const handlePricing_All = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "All",
    }));
  };

  const handlePricing_Free = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "Free",
    }));
  };

  const handlePricing_Ticketed = () => {
    setDataSource((prev) => ({
      ...prev,
      selectedPricing: "Ticketed",
    }));
  };

  function formatDate_startEndDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //fetch events from backend..
  useEffect(() => {
    const loadMore = async () => {
      let from_date = "";
      let to_date = "";
      const today = new Date();

      if (dataSource.selectedDate === "All") {
        const today = new Date();
        const formattedDate = today && today.toISOString().split("T")[0];
        from_date = formattedDate;

        const nextYear = today && new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        to_date = nextYear && nextYear.toISOString().split("T")[0];
      } else if (dataSource.selectedDate === "Today") {
        const today = new Date();
        const formattedDate = today && today.toISOString().split("T")[0];
        from_date = formattedDate;
        to_date = formattedDate;
      } else if (dataSource.selectedDate === "Weekend") {
        const dayOfWeek = today.getDay();
        let daysUntilSaturday, daysUntilSunday;

        if (dayOfWeek === 0) {
          daysUntilSaturday = 6;
          daysUntilSunday = 7;
        } else {
          daysUntilSaturday = 6 - dayOfWeek;
          daysUntilSunday = 7 - dayOfWeek;
        }

        const nextSaturday = new Date(today);
        nextSaturday.setDate(today.getDate() + daysUntilSaturday);

        const nextSunday = new Date(today);
        nextSunday.setDate(today.getDate() + daysUntilSunday);

        const formattedSaturday =
          nextSaturday && nextSaturday.toISOString().split("T")[0];
        const formattedSunday =
          nextSunday && nextSunday.toISOString().split("T")[0];

        from_date = formattedSaturday;
        to_date = formattedSunday;
      } else {
        if (dataSource.startDate && dataSource.endDate) {
          from_date = formatDate_startEndDate(dataSource.startDate);
          to_date = formatDate_startEndDate(dataSource.endDate);
        } else {
          return;
        }
      }

      if (from_date && to_date) {
        //setLoading(true);
        mutationgetParticularSeasonEvents.mutate({ from_date, to_date });
      } else {
        //setLoading(true);
        return;
      }
    };

    if (
      dataSource.selectedCity &&
      dataSource.selectedPricing &&
      dataSource.selectedEventMode
    ) {
      //console.log("check here");
      loadMore();
    } else {
      return;
    }
  }, [
    dataSource.selectedCity,
    dataSource.selectedPricing,

    dataSource.selectedEventMode,
    dataSource.selectedDate,
    dataSource.selectedDate_range,
  ]);

  const GetParticularEvents = ({ from_date, to_date }) =>
    FETCH_EVENTS_FROM_BACKEND(SEASON_TICKETS_FILTER, from_date, to_date);

  const FETCH_EVENTS_FROM_BACKEND = async (
    SEASON_TICKETS_FILTER,
    from_date,
    to_date
  ) => {
    try {
      //console.log("fetch to backend");
      const response = await axios({
        method: "post",
        url: `${BASEPATH}${SEASON_TICKETS_FILTER}`,

        data: {
          country_id: dataSource.selectedCountry
            ? dataSource.selectedCountry
            : "",
          city_id: dataSource.selectedCity ? dataSource.selectedCity : "",
          event_mode: dataSource.selectedEventMode
            ? dataSource.selectedEventMode
            : "",
          ticketing_option: dataSource.selectedPricing
            ? dataSource.selectedPricing
            : "",
          from_date: from_date,
          to_date: to_date,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetParticularSeasonEvents = useMutation({
    mutationKey: ["get_particular_season_events"],
    mutationFn: GetParticularEvents,
    onSuccess: (data) => {
      if (data.status === 200) {
        setLoading(false);
        setDataSource((prevState) => ({
          ...prevState,
          SeasonEventData: data.data,
        }));
      }
    },
    onError: (error) => {
      setLoading(true);
      setDataSource((prevState) => ({ ...prevState, SeasonEventData: "" }));
    },
  });

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  //breadcrumbs.......

  const [breadCrumbCollection, setbreadCrumbCollection] = useState({
    breadCrumbCountry: "",
    breadCrumbCity: "",
    breadCrumbEventMode: "",
    breadCrumbDate: "",
    breadCrumbPricing: "",
    breadCrumbCurrent: "",
  });

  useEffect(() => {
    if (
      dataSource?.selectedCountry &&
      dataSource?.selectedCountry != "" &&
      dataSource.country
    ) {
      const filteredData =
        dataSource.country &&
        dataSource.country.filter(
          (item) => item.country_id === Number(dataSource.selectedCountry)
        );

      if (filteredData && filteredData.length > 0) {
        //setbreadcrumbCountry(filteredData[0]?.country_name)

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: filteredData[0]?.country_name,
          breadCrumbCurrent: "Country",
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: "",
        }));
      }
    }
  }, [dataSource?.selectedCountry, dataSource.country]);

  useEffect(() => {
    if (
      dataSource?.selectedCity &&
      dataSource?.selectedCity !== "" &&
      dataSource?.city
    ) {
      const cityData =
        dataSource?.city &&
        dataSource?.city.filter(
          (item) => item?.city_id === dataSource.selectedCity
        );

      if (cityData && cityData.length > 0) {
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: cityData[0].city_name,
          breadCrumbCurrent: "City",
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: "",
        }));
      }
    }
  }, [dataSource?.selectedCity, dataSource?.city]);

  useEffect(() => {
    if (dataSource.selectedEventMode) {
      //setbreadCrumbEventMode(dataSource.selectedEventMode);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbEventMode: dataSource.selectedEventMode,
        breadCrumbCurrent: "Event Mode",
      }));
    } else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbEventMode: "",
      }));
    }
  }, [dataSource.selectedEventMode]);

  function formatDateRange(dateRange) {
    const [start, end] = dateRange.split("-");
    let formattedStart;
    let formattedEnd;

    const startDay = start.substring(0, 2);
    const startMonth = start.substring(2, 4);
    const startYear = start.substring(4, 8);
    if (startDay && startMonth && startYear) {
      formattedStart = `${startDay}-${startMonth}-${startYear}`;
    }

    const endDay = end.substring(0, 2);
    const endMonth = end.substring(2, 4);
    const endYear = end.substring(4, 8);
    if (endDay && endMonth && endYear) {
      formattedEnd = `${endDay}-${endMonth}-${endYear}`;
    }

    return `${formattedStart} to ${formattedEnd}`;
  }

  useEffect(() => {
    if (dataSource.startDate === null && dataSource.selectedDate === "All") {
      //setbreadCrumbDate(`${dataSource.selectedDate} Dates `);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: `${dataSource.selectedDate} Dates`,
        breadCrumbCurrent: "Date",
      }));
    } else if (
      dataSource.startDate === null &&
      dataSource.selectedDate !== "All"
    ) {
      //setbreadCrumbDate(dataSource.selectedDate);
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: dataSource.selectedDate,
        breadCrumbCurrent: "Date",
      }));
    } else if (dataSource.startDate && dataSource.selectedDate_range) {
      //setbreadCrumbDate(formatDateRange(dataSource.selectedDate_range));

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: formatDateRange(dataSource.selectedDate_range),
        breadCrumbCurrent: "Date",
      }));
    } else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbDate: "",
      }));
    }
  }, [
    dataSource.startDate,
    dataSource.selectedDate,
    dataSource.selectedDate_range,
  ]);

  useEffect(() => {
    if (dataSource.selectedPricing && dataSource.selectedPricing === "All") {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbPricing: "Free & Ticketed",
        breadCrumbCurrent: "Pricing",
      }));
    } else if (
      dataSource.selectedPricing &&
      dataSource.selectedPricing !== "All"
    ) {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbPricing: dataSource.selectedPricing,
        breadCrumbCurrent: "Pricing",
      }));
    }
  }, [dataSource.selectedPricing]);

  return (
    <>
      <Helmet>
        <title>{document.title}</title>
        <meta name="title" content={document.title} />
        <meta
          name="description"
          content={`Top Sabhas conducting Carnatic concerts & Bharatanatyam events during December Margazhi season in Chennai.|Narada Gana Sabha, Madarasana, Kartik Fine Arts, Mylapore Fine Arts Club, Sri Parthasarathy Swamy Sabha, Brahma Gana Sabha, Krishna Gana Sabha, Music Academy, Indian Fine Arts Society, Hamsadhwani, Nada Sudha, Tamil Isai Sangam, Sri Tyaga Brahma Gana Sabha, Madhuradhwani, Arkay Convention Centre, Kalakshetra Foundation,Rasikapriya`}
        />
      </Helmet>
      <div className="filter-page iq-breadcrumb seasonBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Season Tickets
                </h2>
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Discover Music, Dance & Drama festivals
                </h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="mt-4">
        <div className="">
          <div className="container-fluid">
            <div className="row">
              <div className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between">
                {/* <div className="d-flex align-items-center gap-3">
                  <button className="button">
                    <span style={{ fontSize: "1.5vh" }}>India</span>
                  </button>
                  <button className="button">
                    <span style={{ fontSize: "1.5vh" }}>Chennai</span>
                  </button>
                </div> */}
                {/*Mobile breadcrumb */}
                <div
                  className="w-100 d-flex align-items-center hide-scrollbar breadcrumbCountryWarp"
                  style={{ gap: "2px", overflow: "scroll" }}
                >
                  <p
                    className="m-0 p-0"
                    style={{
                      color:
                        breadCrumbCollection.breadCrumbCurrent === "Country"
                          ? "#3F00FF"
                          : undefined,
                    }}
                  >
                    {breadCrumbCollection.breadCrumbCountry}
                  </p>
                  <i style={{ fontSize: "16px" }}>
                    <MdKeyboardArrowRight />
                  </i>
                  <p
                    className="m-0 p-0"
                    style={{
                      color:
                        breadCrumbCollection.breadCrumbCurrent === "City"
                          ? "#3F00FF"
                          : undefined,
                    }}
                    //style={{ color: "#3F00FF" }}
                  >
                    {breadCrumbCollection.breadCrumbCity}
                  </p>

                  <i style={{ fontSize: "16px" }}>
                    <MdKeyboardArrowRight />
                  </i>
                  <p
                    className="m-0 p-0"
                    style={{
                      color:
                        breadCrumbCollection.breadCrumbCurrent === "Event Mode"
                          ? "#3F00FF"
                          : undefined,
                    }}
                    //style={{ color: "#3F00FF" }}
                  >
                    {breadCrumbCollection.breadCrumbEventMode}
                  </p>

                  <i style={{ fontSize: "16px" }}>
                    <MdKeyboardArrowRight />
                  </i>
                  <p
                    className="m-0 p-0"
                    style={{
                      color:
                        breadCrumbCollection.breadCrumbCurrent === "Date"
                          ? "#3F00FF"
                          : undefined,
                    }}
                    //style={{ color: "#3F00FF" }}
                  >
                    {breadCrumbCollection.breadCrumbDate}
                  </p>

                  <i style={{ fontSize: "16px" }}>
                    <MdKeyboardArrowRight />
                  </i>
                  <p
                    className="m-0 p-0"
                    style={{
                      color:
                        breadCrumbCollection.breadCrumbCurrent === "Pricing"
                          ? "#3F00FF"
                          : undefined,
                    }}
                    //style={{ color: "#3F00FF" }}
                  >
                    {breadCrumbCollection.breadCrumbPricing}
                  </p>
                </div>

                <div
                  className="filter-button align-items-center"
                  onClick={doFilter}
                >
                  <FaFilter />
                  Filters
                </div>
              </div>
              <div className="col-lg-3 col-xl-2 sideBarFilter">
                <div className="filterBg pb-2">
                  <div className="">
                    <h5>
                      <FaFilter /> Filters
                    </h5>
                  </div>

                  {/* {isLoading ? (
                    <SidebarSkeletonLoader />
                  ) : ( */}
                  <>
                    {/* <div className="">
                        <div className="d-flex flex-column gap-2">
                          <h6 className="m-0 p-0">Event Mode</h6>
                          <div className="d-flex gap-2 align-items-center">
                            <button className="btn py-1 px-3 act filterBtn ms-0 m-0 ">
                              Offline
                            </button>
                            <button className="btn py-1 px-3 filterBtn m-0 ">
                              Online
                            </button>
                          </div>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-2">
                          <h6 className="mb-0">Location</h6>
                          <div className="d-flex flex-column gap-2">
                            <div>
                              <SelectBox
                                dataSource={dataSource.country || []}
                                displayExpr="country_name"
                                valueExpr="country_id"
                                label="Select Country"
                                searchEnabled={true}
                                onValueChanged={handleCountry}
                                labelMode="floating"
                                value={dataSource.selectedCountry}
                              />
                            </div>
                            <div>
                              <SelectBox
                                dataSource={dataSource.city || []}
                                label="Select City"
                                labelMode="floating"
                                displayExpr="city_name"
                                valueExpr="city_id"
                                searchEnabled={true}
                                onValueChanged={(e) => handleCity(e.value)}
                                value={dataSource.selectedCity}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-2">
                          <h6 className="mb-0">Date</h6>
                          <div className="d-flex align-items-center gap-2 flex-wrap">
                            <button className="btn m-0 py-1 px-3 filterBtn ms-0">
                              Today
                            </button>
                            <button className="btn m-0 py-1 px-3 filterBtn">
                              Weekend
                            </button>
                            <button className="btn m-0 py-1 px-3 filterBtn">
                              Date Range
                            </button>
                          </div>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-2">
                          <h6 className="mb-0">Pricing</h6>
                          <div className="d-flex align-items-center gap-2 flex-wrap">
                            <button className="btn m-0 py-1 px-3 act filterBtn ms-0">
                              All
                            </button>
                            <button className="btn m-0 py-1 px-3 filterBtn">
                              Free
                            </button>
                            <button className="btn m-0 py-1 px-3 filterBtn">
                              Ticketed
                            </button>
                          </div>
                        </div>
                      </div> */}

                    <div className="">
                      <h6 className="mb-0">Event Mode</h6>
                      <div className="d-flex align-items-center gap-2">
                        <button
                          onClick={handleEvent_offline}
                          className={
                            dataSource.selectedEventMode === "Offline"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          Offline
                        </button>

                        <button
                          onClick={handleEvent_online}
                          className={
                            dataSource.selectedEventMode === "Online"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          Online
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h6 className="mb-0">Location</h6>

                      <div>
                        <SelectBox
                          dataSource={dataSource.country || []}
                          displayExpr="country_name"
                          valueExpr="country_id"
                          label="Select Country"
                          searchEnabled={true}
                          onValueChanged={handleCountry}
                          labelMode="floating"
                          value={dataSource.selectedCountry}
                        />
                      </div>
                      <br></br>
                      <div>
                        <SelectBox
                          dataSource={dataSource.city || []}
                          label="Select City"
                          labelMode="floating"
                          displayExpr="city_name"
                          valueExpr="city_id"
                          searchEnabled={true}
                          onValueChanged={(e) => handleCity(e.value)}
                          value={dataSource.selectedCity}
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <div>
                        <p>Date</p>
                        <div className="d-flex flex-wrap flex-column gap-2">
                          <div className="flex-wrap d-flex align-items-center gap-2">
                            <button
                              onClick={handleDate_All}
                              className={
                                dataSource.selectedDate === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              All
                            </button>
                            <button
                              onClick={handleDate_today}
                              className={
                                dataSource.selectedDate === "Today"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Today
                            </button>{" "}
                            <button
                              onClick={handleDate_weekend}
                              className={
                                dataSource.selectedDate === "Weekend"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Weekend
                            </button>{" "}
                          </div>
                          <div
                            className={
                              dataSource.checked === true
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap col-7"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap col-7"
                            }
                            onClick={() =>
                              handleChange({ value: !dataSource.checked })
                            }
                          >
                            <label
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                margin: 0,
                              }}
                            >
                              <CheckBox
                                style={{ visibility: "hidden" }}
                                value={dataSource.checked}
                                checked={dataSource.checked}
                                onValueChanged={handleChange}
                              />
                              <span style={{ marginLeft: "-8px" }}>
                                Custom Dates
                              </span>
                            </label>
                          </div>

                          {dataSource.checked && (
                            <DateRangeBox
                              startDateLabel="From Date"
                              endDateLabel="To Date"
                              displayFormat="dd-MM-yyyy"
                              applyValueMode="useButtons"
                              startDate={dataSource.startDate}
                              endDate={dataSource.endDate}
                              onValueChanged={handleDateRange}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h6 className="mb-0">Pricing</h6>
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        <button
                          onClick={handlePricing_All}
                          className={
                            dataSource.selectedPricing === "All"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          All
                        </button>

                        <button
                          onClick={handlePricing_Free}
                          className={
                            dataSource.selectedPricing === "Free" ||
                            dataSource.selectedPricing === "All"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          Free
                        </button>
                        <button
                          onClick={handlePricing_Ticketed}
                          className={
                            dataSource.selectedPricing === "Ticketed" ||
                            dataSource.selectedPricing === "All"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          Ticketed
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="col-xl-10 col-lg-9 col-12">
                <div>
                  <Modal
                    className="filterPopUp"
                    show={filter}
                    onHide={doFilter}
                  >
                    <Modal.Header
                      closeButton
                      className="m-0 d-flex align-items-center text-center border-light"
                      style={{ padding: "10px 10px" }}
                    >
                      <div className="w-100 filterBg pb-2">
                        <h5 className="d-flex align-items-center gap-2">
                          <FaFilter /> Filters
                        </h5>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="p-3">
                      <div className="">
                        <h6 className="mb-0">Event Mode</h6>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            onClick={handleEvent_offline}
                            className={
                              dataSource.selectedEventMode === "Offline"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Offline
                          </button>

                          <button
                            onClick={handleEvent_online}
                            className={
                              dataSource.selectedEventMode === "Online"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Online
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <h6 className="mb-0">Location</h6>

                        <div>
                          <SelectBox
                            dataSource={dataSource.country || []}
                            displayExpr="country_name"
                            valueExpr="country_id"
                            label="Select Country"
                            searchEnabled={true}
                            onValueChanged={handleCountry}
                            labelMode="floating"
                            value={dataSource.selectedCountry}
                          />
                        </div>
                        <br></br>
                        <div>
                          <SelectBox
                            dataSource={dataSource.city || []}
                            label="Select City"
                            labelMode="floating"
                            displayExpr="city_name"
                            valueExpr="city_id"
                            searchEnabled={true}
                            onValueChanged={(e) => handleCity(e.value)}
                            value={dataSource.selectedCity}
                          />
                        </div>
                      </div>

                      <div className="w-100 mt-4 d-flex flex-column gap-2">
                        <div>
                          <p>Date</p>
                          <div className="w-100 d-flex gap-2 align-items-center flex-wrap">
                            <button
                              onClick={handleDate_All}
                              className={
                                dataSource.selectedDate === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              All
                            </button>
                            <button
                              onClick={handleDate_today}
                              className={
                                dataSource.selectedDate === "Today"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Today
                            </button>{" "}
                            <button
                              onClick={handleDate_weekend}
                              className={
                                dataSource.selectedDate === "Weekend"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Weekend
                            </button>{" "}
                            <div
                              className={
                                dataSource.checked === true
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                              onClick={() =>
                                handleChange({ value: !dataSource.checked })
                              }
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  margin: 0,
                                }}
                              >
                                <CheckBox
                                  style={{ visibility: "hidden" }}
                                  value={dataSource.checked}
                                  checked={dataSource.checked}
                                  onValueChanged={handleChange}
                                />
                                <span style={{ marginLeft: "-10px" }}>
                                  Custom Dates
                                </span>
                              </label>
                            </div>
                            {dataSource.checked && (
                              <DateRangeBox
                                startDateLabel="From Date"
                                endDateLabel="To Date"
                                displayFormat="dd-MM-yyyy"
                                applyValueMode="useButtons"
                                startDate={dataSource.startDate}
                                endDate={dataSource.endDate}
                                onValueChanged={handleDateRange}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-4">
                          <h6 className="mb-0">Pricing</h6>
                          <div className="d-flex flex-wrap align-items-center gap-2">
                            <button
                              onClick={handlePricing_All}
                              className={
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              All
                            </button>

                            <button
                              onClick={handlePricing_Free}
                              className={
                                dataSource.selectedPricing === "Free" ||
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Free
                            </button>
                            <button
                              onClick={handlePricing_Ticketed}
                              className={
                                dataSource.selectedPricing === "Ticketed" ||
                                dataSource.selectedPricing === "All"
                                  ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                  : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                              }
                            >
                              Ticketed
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="py-1 px-2 border-0">
                      <button className="button" onClick={doFilter}>
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                {/* Website breadcrumb */}
                <div className="d-none d-lg-block">
                  <div
                    className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                    style={{ gap: "2px", overflow: "scroll" }}
                  >
                    <p
                      className="m-0 p-0"
                      style={{
                        color:
                          breadCrumbCollection.breadCrumbCurrent === "Country"
                            ? "#3F00FF"
                            : undefined,
                      }}
                    >
                      {breadCrumbCollection.breadCrumbCountry}
                    </p>
                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i>
                    <p
                      className="m-0 p-0"
                      style={{
                        color:
                          breadCrumbCollection.breadCrumbCurrent === "City"
                            ? "#3F00FF"
                            : undefined,
                      }}
                      //style={{ color: "#3F00FF" }}
                    >
                      {breadCrumbCollection.breadCrumbCity}
                    </p>

                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i>
                    <p
                      className="m-0 p-0"
                      style={{
                        color:
                          breadCrumbCollection.breadCrumbCurrent ===
                          "Event Mode"
                            ? "#3F00FF"
                            : undefined,
                      }}
                      //style={{ color: "#3F00FF" }}
                    >
                      {breadCrumbCollection.breadCrumbEventMode}
                    </p>

                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i>
                    <p
                      className="m-0 p-0"
                      style={{
                        color:
                          breadCrumbCollection.breadCrumbCurrent === "Date"
                            ? "#3F00FF"
                            : undefined,
                      }}
                      //style={{ color: "#3F00FF" }}
                    >
                      {breadCrumbCollection.breadCrumbDate}
                    </p>

                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i>
                    <p
                      className="m-0 p-0"
                      style={{
                        color:
                          breadCrumbCollection.breadCrumbCurrent === "Pricing"
                            ? "#3F00FF"
                            : undefined,
                      }}
                      //style={{ color: "#3F00FF" }}
                    >
                      {breadCrumbCollection.breadCrumbPricing}
                    </p>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5 filtermh">
                      {isLoading
                        ? Array(10)
                            .fill(null)
                            .map((_, index) => (
                              <div
                                className="col mb-2 mb-lg-4 px-2"
                                key={index}
                              >
                                <SkeletonLoader />
                              </div>
                            ))
                        : dataSource.SeasonEventData &&
                          dataSource.SeasonEventData.map((data, index) => (
                            <div
                              className="col mb-2 mb-lg-4 px-2"
                              key={data?.season_event_id}
                            >
                              <div className="product-block mb-3 mb-lg-4">
                                <div
                                  className="image-wrap"
                                  onClick={() => seasonEventsHandler(data)}
                                >
                                  <div className="product-image-container">
                                    {data.event_image && !imageError ? (
                                      <img
                                        src={`${ALLEVENT_DETAILS_IMAGES}${data.event_image}`}
                                        className="img-fluid w-100"
                                        alt="productImg-"
                                        loading="lazy"
                                        onError={handleImageError}
                                      />
                                    ) : (
                                      <Noimage />
                                    )}
                                  </div>
                                  <div class="align-items-center blog-description bg-white p-2">
                                    <h5
                                      className="mb-0 text-ellipsis"
                                      title={data?.season_event_title}
                                      style={{
                                        maxHeight: "48px",
                                        fontSize: "16px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {" "}
                                      {data?.season_event_title}
                                    </h5>

                                    <div className="justify-content-between d-flex align-items-center gap-2">
                                      <div
                                        className="pe-0"
                                        style={{ width: "60%" }}
                                      >
                                        <p
                                          className="mb-0 text-ellipsis"
                                          style={{
                                            maxHeight: "24px",
                                            overflow: "hidden",
                                          }}
                                          title={data?.venue_name}
                                        >
                                          {data?.venue_name}
                                        </p>

                                        <p class="mb-0 iq-title text-white">
                                          {data?.event_start_date}
                                        </p>
                                      </div>
                                      <p
                                        className="button m-0 text-center"
                                        style={{
                                          padding: "6px 6px",
                                          height: "auto",
                                          fontSize: "12px",
                                          width: "40%",
                                        }}
                                      >
                                        {data?.ticketing_option_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="eventsDetails">
                                  <div className="eventsImgText py-2">
                                    <div className="row px-2">
                                      <ul className="d-flex align-items-center justify-content-between list-inline mb-0">
                                        <li className="d-flex align-items-center justify-content-center">
                                          <a
                                            className="cursor-pointer"
                                            data-bs-toggle="modal"
                                            data-bs-target="#woosq-popup"
                                          ></a>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-center"></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SeasonTickets;
