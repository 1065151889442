import React, { useState, useRef, useEffect, useContext } from "react";
import "./productDetails.css";
import IMAGE2 from "../../assets/images/genre/instamojo_n.png";
import IMAGE3 from "../../assets/images/genre/razorpay_n.png";
import { IoShareSocial } from "react-icons/io5";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import {
  GET_EVENT_DETAILS,
  GET_TICKET_DETAILS,
  addToCart,
} from "../../Api/api";
import { makePostRequest } from "../../Api/requestMethods";
import { ERROR, DURATION, SUCCESS } from "../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { useMutation } from "@tanstack/react-query";
import {
  capitalizeFirstLetter,
  isValidValue,
} from "../../utils/commonUtils.js";
import {
  EVENT_IMAGES_DETAILS,
  ticketOptionFree,
  EventModeOffline,
  EventModeOnline,
  buttonActionText,
  freewithregistration,
  ticketedonotherwebsites,
  ticketedonmdnd,
  seatingPlanNonNumbered,
  seatingPlanNumbered,
} from "../../global/constants.js";
import { LIMITATION_ERROR } from "../../Schemas/errorMessageConstants.js";
import OnlineDenomination from "./components/OnlineDenomination.js";
import OfflineDenomination from "./components/offlineDenomination.js";
import { ticketQtyRequired } from "../../Schemas/errorMessageConstants.js";
import { v4 as uuidv4 } from "uuid";
import { GlobalContext } from "../../global/provider.js";
import { singleCartDetails } from "../../Api/api";
import AuthContext from "../../context/AuthContext.js";
import FollowIcon from "../../assets/Icons/FollowIcon.svg";
import FollowingIcon from "../../assets/Icons/FollowingIcon.svg";
import { BASEPATH } from "../../config/config.js";
import axios from "axios";
import {
  FOLLOW_INITIAL_CHECK,
  FOLLOW_A_USER,
  UNFOLLOW_A_USER,
} from "../../Api/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MapPopup from "./components/mapPopup.js";
import { SHARE_BASEPATH } from "../../config/config.js";
import { customDecrypt } from "../../utils/encryptAndDecrypt.js";
import { showToPublic } from "../../Api/api";
import ShareSocialMediaEvents from "./ShareSocialMediaEvents.js";
import DOMPurify from "dompurify";
import mdndlogo from "../../global/mdndlogo.png";
import { jsonToBase64 } from "../../utils/commonUtils.js";
import { FREE_ONLINE_STREAM } from "../../config/config.js";
import LinkExpired from "./components/linkExpired.js";
import { customEncrypt } from "../../utils/encryptAndDecrypt.js";
import getEventKeys from "../../Api/getEventKeys.js";
import { useMemo } from "react";
import HandleErrorToast from "../../Toaster/Failure/Failure.js";
import getProfileKey from "../../Api/getProfileKey.js";
import { Width } from "devextreme-react/cjs/chart";
function ProductDetails() {
  //new code...
  const COUNTRY_KEY = getEventKeys().countryKey;
  const EVENT_KEY = getEventKeys().eventKey;
  const PROFILE_KEY = getProfileKey().profileKey;
  const addImg = "https://c4.wallpaperflare.com/wallpaper/578/919/794/3-316-16-9-aspect-ratio-s-sfw-wallpaper-preview.jpg"

  const curentLoc = useLocation();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [linkExpiredData, setLinkExpried] = useState({
    errorMsg: "",
    isEnabled: false,
  });
  const handleShow = () => setShow(true);
  const { setEventDataSource, setCartDetails } = useContext(GlobalContext);
  const inputRef = useRef(null);
  const handleMapClose = () => setShowMap(false);
  const enableMap = () => setShowMap(true);
  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };
  const [filter, setFilter] = useState(false);
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const doFilter = () => {
    setFilter(!filter);
  };
  const [searchParams] = useSearchParams();

  const [event_id, seteventid] = useState("");
  const [countryId, setcountryId] = useState("");
  const [myTrigger, setmyTrigger] = useState(false);

  //new code..

  const decodeBase64 = (encoded) => {
    const prefix = "xy4AT";
    try {
      const decodedStr = atob(encoded);
      if (decodedStr?.startsWith(prefix)) {
        return decodedStr.slice(prefix?.length);
      } else {
        console.error("Decoding error: Prefix not found");
        return null;
      }
    } catch (error) {
      console.error("Decoding error:", error);
      return null;
    }
  };

  const encodeBase64_new = (str, key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(curentLoc.search);
    const ci = searchParams?.get("ci");
    const e = searchParams?.get("e");
    const editEvent = searchParams?.get("editEvent");
    if (ci && e) {
      // const decodedCi = ci ? decodeBase64(ci) : '';
      //const decodedE = e ? decodeBase64(e) : '';

      const decodedCi = atob(ci).startsWith("xy4AT")
        ? atob(ci).replace(/^xy4AT/, "")
        : null;
      const decodedE = atob(e).startsWith("xy4AT")
        ? atob(e).replace(/^xy4AT/, "")
        : null;

      if (decodedCi && decodedE) {
        const newCi = encodeBase64_new(decodedCi, COUNTRY_KEY);
        const newE = encodeBase64_new(decodedE, EVENT_KEY);

        const newUrl = `${curentLoc.pathname}?ci=${newCi}&e=${newE}&editEvent=${
          editEvent || ""
        }`;
        setmyTrigger(true);
        navigate(newUrl, { replace: true });
      } else {
        setmyTrigger(true);
        return;
      }
    }
  }, []);

  const decodeBase64_new = (encoded, key) => {
    const prefix = key?.toString();
    try {
      const decodedStr = atob(encoded);
      if (decodedStr.startsWith(prefix)) {
        return decodedStr.slice(prefix.length);
      } else {
        console.error("Decoding error: Prefix not found");
        return null;
      }
    } catch (error) {
      console.error("Decoding error:", error);
      return null;
    }
  };

  //const event_id = Number(decodeBase64_new(searchParams?.get("e"),EVENT_KEY));
  //const countryId =  Number(decodeBase64_new(searchParams?.get("ci"),COUNTRY_KEY));

  useMemo(() => {
    if (myTrigger === true) {
      seteventid(Number(decodeBase64_new(searchParams?.get("e"), EVENT_KEY)));
      setcountryId(
        Number(decodeBase64_new(searchParams?.get("ci"), COUNTRY_KEY))
      );
    } else {
      return;
    }
  }, [myTrigger]);

  console.log(event_id, "event_id");
  console.log(countryId, "countryId");

  const isEditEvent = searchParams.get("editEvent");
  const [dataSource, setDataSource] = useState({
    eventDetails: "",
    buttonName: "",
    buttonAction: "",
    buttonUrl: "",
    gridData: "",
    changedRowCount: null,
    trigger: false,
    isEditEvent: false,
    isShowToPublic: "N",
  });

  useEffect(() => {
    if (isEditEvent) {
      const decryptData = customDecrypt(isEditEvent);
      setDataSource((prev) => ({
        ...prev,
        isEditEvent: decryptData === "yes" ? true : false,
      }));
    }
  }, [isEditEvent]);

  const [formData, setFormData] = useState([]);

  const getProductDetails = async (userData) => {
    try {
      const response = await makePostRequest(GET_EVENT_DETAILS, userData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getTicketDetails = async (userData) => {
    try {
      const response = await makePostRequest(GET_TICKET_DETAILS, userData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const productDetailsMutation = useMutation({
    mutationFn: (data) => getProductDetails(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        eventDetails: data?.data,
        buttonName: data.data[0]?.button_text,
      }));

      setLoading(false);
    },
    onError: (error) => {
      if (error.response?.data.status === "failure") {
        setLinkExpried((prev) => ({
          ...prev,
          isEnabled: true,
          errorMsg: error?.response?.data?.message,
        }));
      } else {
        // notify(error.response.data.message, ERROR, DURATION);
      }
      console.log(error, "error");
      setLoading(false);
    },
  });

  const getTicketDetailsMutation = useMutation({
    mutationFn: (data) => getTicketDetails(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        gridData: data?.data,
      }));
    },
    onError: (error) => {
      console.log(error, "error");
      notify(error.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (
      event_id &&
      event_id !== "" &&
      event_id !== null &&
      countryId &&
      countryId !== "" &&
      countryId !== null &&
      isValidValue(isEditEvent)
    ) {
      const decryptData = customDecrypt(isEditEvent);
      const body = {
        country_id: Number(countryId),
        event_id: Number(event_id),
        show_to_public: decryptData === "yes" ? "N" : "Y",
      };
      productDetailsMutation.mutate(body);
    }
  }, [location, isEditEvent, event_id, countryId]);
  const eventDetail = dataSource.eventDetails[0];

  useEffect(() => {
    if (dataSource?.eventDetails?.length > 0) {
      const {
        ticketing_option,
        event_mode,
        button_text,
        button_action,
        button_url,
        seating_option,
        season_flag,
      } = eventDetail;

      // Free
      if (ticketing_option === ticketOptionFree) {
        setDataSource((prev) => ({
          ...prev,
          buttonName: capitalizeFirstLetter(button_text),
          buttonAction: button_action === buttonActionText,
          buttonUrl:
            event_mode === EventModeOnline && button_action !== buttonActionText
              ? button_url.startsWith("https://")
                ? button_url
                : `https://${button_url}`
              : prev.buttonUrl,
        }));
      }

      // Free with registration

      // if (event_mode === EventModeOffline) {
      //   if (seating_option && seating_option === seatingPlanNonNumbered) {
      //     const body = {
      //       country_id: countryId,
      //       seating_option: seating_option,
      //       event_id: event_id,
      //     };
      //     getTicketDetailsMutation.mutate(body);
      //   }
      // }

      if (ticketing_option === freewithregistration) {
        if (event_mode === EventModeOffline) {
          const body = {
            country_id: countryId,
            seating_option: seatingPlanNonNumbered,
            event_id: event_id,
            ticketing_option: ticketing_option,
            event_mode: event_mode,
            season_flag: "N",
          };
          getTicketDetailsMutation.mutate(body);
        }
        if (event_mode === EventModeOnline) {
        }
      }

      // Ticketed on other websites
      if (
        ticketing_option === ticketedonotherwebsites &&
        event_mode === EventModeOffline
      ) {
        setDataSource((prev) => ({
          ...prev,
          buttonName: capitalizeFirstLetter(button_text),
          buttonAction: button_action === buttonActionText,
          buttonUrl:
            event_mode === EventModeOffline &&
            button_action !== buttonActionText
              ? button_url.startsWith("https://")
                ? button_url
                : `https://${button_url}`
              : prev.buttonUrl,
        }));
      }

      // Ticketed on MDnD - offline
      if (ticketing_option === ticketedonmdnd)
        if (event_mode) {
          const body = {
            country_id: countryId,
            ticket_type:
              event_mode === EventModeOffline
                ? seating_option
                : EventModeOnline,
            event_id: event_id,

            seating_option: seatingPlanNonNumbered,
            ticketing_option: ticketing_option,
            event_mode: event_mode,
            season_flag: "N",
          };
          getTicketDetailsMutation.mutate(body);
        }
    }
    // const metaTitle = `${dataSource?.eventDetails[0]?.event_title}`;
    // document.title = metaTitle;

    // const metaDescription = `Book tickets and secure your seats for ${dataSource?.eventDetails[0]?.event_title} `;
    // const descriptionTag = document.querySelector('meta[name="description"]');
    // if (descriptionTag) {
    //   descriptionTag.setAttribute("content", metaDescription);
    // } else {
    //   const meta = document.createElement("meta");
    //   meta.name = "description";
    //   meta.content = metaDescription;
    //   document.head.appendChild(meta);
    // }
  }, [dataSource.eventDetails, event_id, countryId]);

  useEffect(() => {
    if (dataSource && dataSource.gridData) {
      const initialFormData = dataSource.gridData.map((data) => ({
        no_of_tickets: null,
        amount: parseFloat(data.amount),
        symbol: data?.symbol,
        id: data.id,
      }));
      setFormData(initialFormData);
      setDataSource((prev) => ({
        ...prev,
        trigger: true,
      }));
    }
  }, [dataSource.gridData]);

  const ticketLimitationEvent = (
    index,
    value,
    maxLimit,
    rowName,
    totalTicketBalance
  ) => {
    setDataSource((prev) => ({ ...prev, changedRowCount: value }));
    var updatedFormData = [...formData];
    var newTicketLimit = null;
    if (totalTicketBalance === 0) {
      newTicketLimit = 0;
    } else if (totalTicketBalance === undefined) {
      newTicketLimit = maxLimit;
    } else if (totalTicketBalance && totalTicketBalance <= maxLimit) {
      newTicketLimit = totalTicketBalance;
    } else {
      newTicketLimit = maxLimit;
    }
    if (value > newTicketLimit) {
      updatedFormData[index].no_of_tickets = newTicketLimit;

      notify(LIMITATION_ERROR(newTicketLimit, rowName), ERROR, DURATION);
      return;
    } else {
      updatedFormData[index].no_of_tickets = value;
    }

    setFormData(updatedFormData);
  };

  const checkNull = async () => {
    // This function will validate the ticket qty is not equal to zero
    const CheckNullData = formData.filter(
      (data) => data.no_of_tickets !== null && data.no_of_tickets !== 0
    );
    // if the ticket qty is choosed in any of the denomination then it will return one row ie : the length would be 1 else the length would be 0
    return CheckNullData;
  };

  const TicketedClickToBuy = async () => {
    if (eventDetail && eventDetail.seating_option !== seatingPlanNumbered) {
      const CheckNull = await checkNull();
      if (CheckNull && CheckNull.length === 0) {
        notify(ticketQtyRequired, ERROR, DURATION);
        return;
      }

      const { ticketing_option } = eventDetail;

      if (ticketing_option === ticketedonmdnd) {
        await addToChartEvent(false);
        setCartDetails((prev) => ({ ...prev, fromCheckOutCart: true }));
      } else {
        setCartDetails((prev) => ({ ...prev, fromCheckOutCart: false }));
      }
      setEventDataSource((prev) => ({
        ...prev,
        formdata: formData,
        eventDetails: eventDetail,
      }));
      navigate("/checkOut");
    }
    if (eventDetail && eventDetail.seating_option === seatingPlanNumbered) {
      setEventDataSource((prev) => ({
        ...prev,
        eventDetails: eventDetail,
      }));
      const {
        country_id,
        event_id,
        event_mode,
        ticketing_option,
        seating_option,
      } = eventDetail;
      const url = `/events/numberedSeatings?ci=${country_id}&e=${event_id}&e_mode=${event_mode}&t_option=${ticketing_option}&s_option=${seating_option}&s_flag=${"N"}`;
      navigate(url);
    }
  };

  const checkCondtion =
    dataSource && dataSource.eventDetails && dataSource.eventDetails[0];

  // Add to cart event
  const addToChartEvent = async (IsClickToBuy) => {
    var uniqueId;
    const CheckNull = await checkNull(); // resuable null check function to check the ticket qty is choosen or not
    if (CheckNull && CheckNull.length === 0) {
      // if any of the ticket qty is not choosen this block will return with the error toster message
      notify(ticketQtyRequired, ERROR, DURATION);
      return;
    }
    uniqueId = localStorage.getItem("mdnd_cId"); // gettting Session Id from the local storage
    if (!uniqueId) {
      // if the session id is not exisits then this block will create an new session ID
      uniqueId = uuidv4();
      localStorage.setItem("mdnd_cId", uniqueId); // setting session id into the local storage
    }
    const ticketData = formData?.map((data) => {
      // modifing ticketDetails for backend structure - requirement
      return {
        id: data.id,
        qty: data.no_of_tickets === null ? 0 : data.no_of_tickets,
        // amount: data.amount,
      };
    });

    // required details for make the add to cart api post
    const body = {
      role_id: Number(user?.role_id) || "", // getting role_id from the auth context
      login_id: Number(user?.login_id) || "", // getting login_id  from the auth context
      session_id: !user?.role_id && !user?.login_id ? uniqueId : null, // checking whether role_id is availble if role_id is avaible the the session id will be null
      country_id: Number(countryId), // country_id
      item_id: Number(event_id), // item_id here eventid
      cart_purpose: "ticket", // hardcoded as per sudhakar's instruction
      total_quantity: ticketData, // modified ticketData
      season_flag: "N",
      is_multiple: "",
    };
    try {
      if (uniqueId) {
        // if unqiueId or session Id Exists then the if block will works
        const response = await makePostRequest(addToCart, [body]);
        if (response && response.status === SUCCESS) {
          const cartCount = response.data && response?.data?.cart_count;
          if (IsClickToBuy) {
            notify(response?.message, SUCCESS, DURATION);
          }
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (checkCondtion && checkCondtion.ticketing_option === ticketedonmdnd) {
      (async () => {
        try {
          if (dataSource.trigger) {
            var uniqueId = localStorage.getItem("mdnd_cId");
            const body = {
              session_id: user?.role_id ? "" : uniqueId,
              role_id: user?.role_id || "",
              country_id: countryId,
              item_id: checkCondtion?.event_id,
              season_flag: "N",
            };
            const response = await makePostRequest(singleCartDetails, body);
            if (response && response.status === SUCCESS) {
              let parsedData = false;
              try {
                parsedData = response?.data?.total_quantity
                  ? JSON.parse(response?.data?.total_quantity)
                  : false;
              } catch (e) {
                console.error(e);
              }
              if (parsedData && Array.isArray(formData)) {
                const modifiedFormData = formData.map((data, index) => ({
                  ...data,
                  no_of_tickets: parsedData[index]?.qty || 0,
                }));

                setFormData(modifiedFormData);
                setDataSource((prev) => ({
                  ...prev,
                  trigger: false,
                }));
              } else {
                console.error(
                  "formData is not an array or parsedData is invalid"
                );
                setDataSource((prev) => ({
                  ...prev,
                  trigger: false,
                }));
              }
            }
          }
        } catch (error) {
          setDataSource((prev) => ({
            ...prev,
            trigger: false,
          }));
          console.error(error);
        }
      })();
    }
  }, [checkCondtion, dataSource.trigger]);

  useEffect(() => {
    if (checkCondtion && checkCondtion.isShowToPublic) {
      setDataSource((prev) => ({
        ...prev,
        isShowToPublic: checkCondtion.isShowToPublic,
      }));
    }
  }, [checkCondtion]);

  const token = localStorage.getItem("MDnD_auth");
  const [TransformedData, setTransformedData] = useState([]);
  const [accompanistsData, setaccompanistsData] = useState([]);
  const [organisersData, setorganisersData] = useState([]);
  const [checkinitialFollow, setCheckInitialFollow] = useState({});

  useEffect(() => {
    if (checkCondtion.Artist_details) {
      const filteredData = checkCondtion.Artist_details.filter(
        (item) => item.role_id != null && item.artist_id != null
      );
      const uniqueData =
        filteredData &&
        filteredData.reduce((acc, current) => {
          const x = acc.find(
            (item) => item["artist_id"] === current["artist_id"]
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

      if (uniqueData) {
        setTransformedData(uniqueData);
      } else {
        setTransformedData([]);
      }
    }
  }, [checkCondtion.Artist_details]);

  useEffect(() => {
    if (checkCondtion.Accompanist_details) {
      const filteredData = checkCondtion.Accompanist_details.filter(
        (item) => item.role_id != null && item.accompanist_id != null
      );

      const uniqueData =
        filteredData &&
        filteredData.reduce((acc, current) => {
          const x = acc.find(
            (item) => item["accompanist_id"] === current["accompanist_id"]
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

      if (uniqueData) {
        setaccompanistsData(uniqueData);
      } else {
        setaccompanistsData([]);
      }
    }
  }, [checkCondtion.Accompanist_details]);

  //filtered data for organisers........

  useEffect(() => {
    if (checkCondtion.Organiser_details) {
      const filteredData = checkCondtion.Organiser_details.filter(
        (item) => item.role_id != null && item.organiser_id != null
      );

      const uniqueData =
        filteredData &&
        filteredData.reduce((acc, current) => {
          const x = acc.find(
            (item) => item["organiser_id"] === current["organiser_id"]
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

      if (uniqueData) {
        setorganisersData(uniqueData);
      } else {
        setorganisersData([]);
      }
    }
  }, [checkCondtion.Organiser_details]);

  const transformData = (dataSource, artistOrOrganisers) => {
    if (!dataSource || dataSource.length === 0) {
      return null;
    }

    const uniqueData = dataSource.reduce((acc, current) => {
      const x = acc.find(
        (item) => item[artistOrOrganisers] === current[artistOrOrganisers]
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return uniqueData.map((data) => {
      if (artistOrOrganisers === "artist_id") {
        return (
          <div key={data.artist_id} className="d-flex pb-2 j">
            <Link
              style={{ color: "#3f00ff" }}
              to={`/artist/${data?.profile_name}?rid=${encodeBase64_new(data?.role_id, PROFILE_KEY )}`}
            >
              {data.artist_name}
            </Link>
            
              <button>
                {checkinitialFollow[data.role_id] === "Unfollow" ? (
                  <>
                    <img
                      src={FollowingIcon}
                      onClick={() =>
                        handleFollow(data.artist_loginid, data.role_id)
                      }
                      alt="Following"
                    />
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleFollow(data.artist_loginid, data.role_id)
                    }
                    src={FollowIcon}
                    alt="Follow"
                  />
                )}
              </button>
         
            <br />{" "}
          </div>
        );
      } else if (artistOrOrganisers === "organiser_id") {
        return (
          <div key={data.organiser_id} className="d-flex pb-2">
            <Link
              style={{ color: "#3f00ff" }}
              to={`/organiser/${data?.profile_name}?rid=${encodeBase64_new(data?.role_id, PROFILE_KEY)}`}
            >
              {data?.organiser_name}
            </Link>
            {/* {data.organiser_name} */}
          
              <button>
                {data.organiser_name !== null &&
                checkinitialFollow[data.role_id] === "Unfollow" ? (
                  <>
                    <img
                      src={FollowingIcon}
                      onClick={() =>
                        handleFollow(data.organiser_loginid, data.role_id)
                      }
                      alt="Following"
                    />
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleFollow(data.organiser_loginid, data.role_id)
                    }
                    src={FollowIcon}
                    alt="Follow"
                  />
                )}
              </button>
          
            <br />{" "}
          </div>
        );
      } else if (artistOrOrganisers === "accompanist_id") {
        return (
          <div key={data.accompanist_id} className="d-flex pb-2">
            <Link
              style={{ color: "#3f00ff" }}
              to={`/artist/${data?.profile_name}?rid=${encodeBase64_new(data?.role_id, PROFILE_KEY)}`}
            >
              {data?.artist_name}
            </Link>

            {/* {data.artist_name} */}

           
              <button>
                {checkinitialFollow[data.role_id] === "Unfollow" ? (
                  <>
                    <img
                      src={FollowingIcon}
                      onClick={() =>
                        handleFollow(data.accompanist_loginid, data.role_id)
                      }
                      alt="Following"
                    />
                  </>
                ) : (
                  <img
                    onClick={() =>
                      handleFollow(data.accompanist_loginid, data.role_id)
                    }
                    src={FollowIcon}
                    alt="Follow"
                  />
                )}
              </button>
          

            <br />
          </div>
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (TransformedData && TransformedData.length > 0) {
      TransformedData.forEach(({ role_id }) => {
        if (role_id) {
          mutationcheckfollowInitial.mutate(role_id);
        }
      });
    }

    if (accompanistsData && accompanistsData.length > 0) {
      accompanistsData.forEach(({ role_id }) => {
        if (role_id) {
          mutationcheckfollowInitial.mutate(role_id);
        }
      });
    }

    if (organisersData && organisersData.length > 0) {
      organisersData.forEach(({ role_id }) => {
        if (role_id) {
          mutationcheckfollowInitial.mutate(role_id);
        }
      });
    }
  }, [TransformedData, accompanistsData, organisersData]);

  const handleFollow = (loginid, role_id) => {

  if(user)  
    {if (checkinitialFollow[role_id] === "Follow") {
      mutationFollow.mutate({ loginid, role_id });
    } else if (checkinitialFollow[role_id] === "Unfollow") {
      mutationUnfollow.mutate({ loginid, role_id });
    }}
     else {
      HandleErrorToast("error","Please Login to Follow", 4000);
    }
  };

  // to find out initial status of follow..............................

  const FollowIniitalStatus = (role_id) =>
    FOLLOWPROFILE(FOLLOW_INITIAL_CHECK, role_id);

  const FOLLOWPROFILE = async (FOLLOW_INITIAL_CHECK, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_INITIAL_CHECK}`,

        data: {
          user1_roleid: user ? Number(user.role_id) : 0,
          user2_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is ", error);
    }
  };

  const mutationcheckfollowInitial = useMutation({
    mutationKey: ["FollowIniitalStatus"],
    mutationFn: (role_id) => FollowIniitalStatus(role_id),

    onSuccess: (data) => {
      if (data.status === "success") {
        setCheckInitialFollow((prevState) => ({
          ...prevState,
          [Number(data?.data?.viewing_user_roleid)]: "Unfollow",
        }));
      } else if (data.status === "Failed") {
        setCheckInitialFollow((prevState) => ({
          ...prevState,
          [Number(data?.data?.viewing_user_roleid)]: "Follow",
        }));
      }
    },
    onError: (error, data) => {
      setCheckInitialFollow((prevState) => ({
        ...prevState,
        [data]: "Follow",
      }));
    },
  });

  // handle Follow mutation functions - FOLLOW..

  const Follow_function = (loginid, role_id) =>
    FollowtoBackend(FOLLOW_A_USER, loginid, role_id);

  const FollowtoBackend = async (FOLLOW_A_USER, loginid, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: role_id ? Number(role_id) : "",
          follower_roleid: user ? user.role_id : "",
          following_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationFollow = useMutation({
    mutationKey: ["FollowUser"],
    mutationFn: ({ loginid, role_id }) => Follow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        //setcounterforinitialStatus(counterforinitialStatus + 1);
        checkinitialFollow[data?.data?.following_role_id] = "Unfollow";
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching follow", error);
    },
  });

  // UNFOLLOW...
  const UnFollow_function = (loginid, role_id) =>
    UnFollowtoBackend(UNFOLLOW_A_USER, loginid, role_id);

  const UnFollowtoBackend = async (UNFOLLOW_A_USER, loginid, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${UNFOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: role_id ? Number(role_id) : "",
          unfollower_roleid: user ? user.role_id : "",
          unfollowing_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },
        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationUnfollow = useMutation({
    mutationKey: ["UnFollowUser"],
    mutationFn: ({ loginid, role_id }) => UnFollow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        checkinitialFollow[data?.data?.unfollowing_role_id] = "Follow";
      } else {
      }
    },
    onError: (error) => {
      console.error("Error fetching follow:", error);
    },
  });

  const shareCurrentURL = () => {
    return `${SHARE_BASEPATH}${curentLoc.pathname}${curentLoc.search}`;
  };

  const encodeBase64 = (str) => {
    const prefix = "xy4AT";
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const shareCurrentURL_updated = () => {
    const encyptedData = customEncrypt("no");
    const modifiedTitle = dataSource?.eventDetails[0]?.event_title
      ? dataSource?.eventDetails[0]?.event_title.split(" ").join("_")
      : "Event";
    return `${BASEPATH}events/${modifiedTitle}?ci=${encodeBase64_new(
      countryId,
      COUNTRY_KEY
    )}&e=${encodeBase64_new(event_id, EVENT_KEY)}&editEvent=${encyptedData}`;
  };

  const editEventNavigate = () => {
    navigate(`/viewEvents?event_id=${event_id}`);
  };

  const showToPublicEvent = async (e) => {
    try {
      e.preventDefault();
      const body = {
        country_id: countryId,
        event_id: event_id,
        show_to_public: e.target.checked ? "Y" : "N",
        role_id: user.role_id || null,
        is_season_event: "N",
      };
      const response = await makePostRequest(showToPublic, body);
      if (response && response.status === SUCCESS) {
        setDataSource((prev) => ({
          ...prev,
          isShowToPublic: response?.data,
        }));
        notify(response?.message, SUCCESS, DURATION);
      } else {
        setDataSource((prev) => ({
          ...prev,
          isShowToPublic: e.target.checked ? "Y" : "N",
        }));
      }
    } catch (error) {
      setDataSource((prev) => ({
        ...prev,
        isShowToPublic: e.target.checked ? "Y" : "N",
      }));
      console.log(error);
    }
  };

  const isValidValue_quickadd_new = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      return (
        Array.isArray(parsedValue) &&
        parsedValue.length > 0 &&
        parsedValue.some(
          (item) =>
            item !== null && typeof item === "string" && item.trim() !== ""
        )
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
    }
  };

  const sanitizedHTML =
    checkCondtion && DOMPurify.sanitize(checkCondtion?.event_description);

  const [showEventnow, setshowEventnow] = useState(false);
  const [base64imgevent, setbase64imgevent] = useState("");

  const handleMDNDevent = async () => {
    const imageUrl = `${EVENT_IMAGES_DETAILS}${dataSource?.eventDetails[0]?.event_image}`;

    try {
      const response = imageUrl && (await fetch(imageUrl));
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;

        if (base64Image) {
          setbase64imgevent(base64Image);
        }
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error converting image to Base64:", error);
    }

    if (base64imgevent) {
      setshowEventnow(true);
    }
  };

  const handleClose = () => {
    setbase64imgevent("");
    setshowEventnow(false);
    setShow(false);
  };

  const { setPrevURL } = useContext(AuthContext);

  useEffect(() => {
    setPrevURL(`${curentLoc.pathname}${curentLoc.search}`);
  }, [curentLoc]);

  const base64UrlEncrypt = () => {
    try {
      // Check if user is defined and has a role_id
      const role_id = user?.role_id ?? ""; // Use optional chaining and provide a default value

      const data = {
        country_id: checkCondtion?.country_id,
        event_id: checkCondtion?.event_id,
        season_flag: checkCondtion?.season_flag,
        role_id: role_id, // Use the extracted role_id
      };

      const base64ConvertedData = jsonToBase64(data);
      return base64ConvertedData;
    } catch (error) {
      console.log(error);
    }
  };

  const href = (() => {
    if (
      checkCondtion &&
      checkCondtion?.ticketing_option === "free" &&
      checkCondtion?.event_mode === "online"
    ) {
      return FREE_ONLINE_STREAM(base64UrlEncrypt);
    } else if (dataSource?.buttonAction) {
      return null;
    } else {
      return dataSource?.buttonUrl;
    }
  })();

  const isFreeOffline =
    checkCondtion?.ticketing_option === "free" &&
    checkCondtion?.event_mode !== "online";
  return (
    <>
      {!linkExpiredData.isEnabled ? (
        <>
          <div className="section-padding-top product-detail pb-lg-5 pt-3">
            <div className="container-fluid">
              <Modal
                className="filterPopUp"
                size="md"
                centered
                show={filter}
                onHide={doFilter}
              >
                <Modal.Header
                  closeButton
                  className="m-0 d-flex align-items-center text-center border-light"
                  style={{ padding: "10px 10px" }}
                >
                  <h5 className="text-nowrap text-center m-auto">Summary</h5>
                </Modal.Header>
                <hr></hr>
                <Modal.Body className="p-3 pt-0">
                  <h5>
                    {dataSource?.eventDetails &&
                      dataSource?.eventDetails[0]?.event_title}
                  </h5>
                  <hr></hr>
                  <div className="">
                    <h5>Select Payment Method</h5>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-lg-6 mt-3">
                      <button className="btn paymentBtn py-1">
                        <img src={IMAGE2} className="w-50"></img>
                      </button>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <button className="btn paymentBtn py-1">
                        <img src={IMAGE3} className="w-50"></img>
                      </button>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-3">
                    <div className="col-lg-6">
                      <button className="btn paymentBtn py-1 w-100">
                        PayPal
                      </button>
                    </div>
                    <div className="col"></div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="py-1 px-2 border-0"></Modal.Footer>
              </Modal>

              <div className="row d-flex justify-content-center mb-5 mb-lg-2 mt-4 mt-lg-2">
                <div className="col-lg-5 col-xl-3">
                  <div className="product-tab-slider">
                    <div
                      className="swiper product-tab-slider-thumb mb-2"
                      data-swiper="slider-prodcut-images"
                    >
                      <div className="swiper-wrapper m-0">
                        <div className="swiper-slide p-0">
                          {loading ? (
                            <>
                              <Skeleton height={400} className="mb-2" />
                              <Skeleton height={100} className="mb-1" />
                              <Skeleton height={100} />
                            </>
                          ) : (
                            <img
                              src={`${EVENT_IMAGES_DETAILS}${
                                dataSource?.eventDetails &&
                                dataSource?.eventDetails[0]?.event_image
                              }`}
                              className="img-fluid product-detail-image rounded"
                              alt="product-slide-image"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {checkCondtion &&
                    checkCondtion.ticketing_option !== ticketOptionFree &&
                    checkCondtion.ticketing_option !==
                      ticketedonotherwebsites &&
                    checkCondtion.seating_option !== seatingPlanNumbered && (
                      <div className="mt-3">
                        {checkCondtion &&
                        checkCondtion?.ticketing_option ===
                          freewithregistration ? (
                          <b>Choose your quantity</b>
                        ) : (
                          <b>Choose your denomination & quantity</b>
                        )}

                        {loading ? (
                          <Skeleton height={30} />
                        ) : dataSource.eventDetails &&
                          dataSource.eventDetails[0]?.event_mode !==
                            EventModeOffline ? (
                          <OnlineDenomination
                            dataSource={dataSource}
                            formData={formData}
                            ticketLimitationEvent={ticketLimitationEvent}
                            capitalizeFirstLetter={capitalizeFirstLetter}
                            EventModeOnline={EventModeOnline}
                          />
                        ) : (
                          <OfflineDenomination
                            dataSource={dataSource}
                            formData={formData}
                            ticketLimitationEvent={ticketLimitationEvent}
                            capitalizeFirstLetter={capitalizeFirstLetter}
                            EventModeOnline={EventModeOnline}
                          />
                        )}
                      </div>
                    )}

                  <div className="d-flex justify-content-end mt-3">
                    {checkCondtion &&
                      checkCondtion?.seating_option !== seatingPlanNumbered &&
                      (loading ? (
                        <Skeleton height={40} />
                      ) : (
                        <>
                          {checkCondtion &&
                            checkCondtion?.ticketing_option !==
                              ticketOptionFree &&
                            checkCondtion?.ticketing_option !==
                              freewithregistration &&
                            checkCondtion?.ticketing_option !==
                              ticketedonotherwebsites && (
                              <button
                                className={
                                  dataSource.selectedAge === "Junior"
                                    ? "btn py-1 px-3 act filterBtn m-0 text-nowrap  me-2 text-white"
                                    : "btn py-1 px-3 filterBtn m-0 text-nowrap  me-2 brandcolor d-none"
                                }
                                onClick={() => addToChartEvent(true)}
                              >
                                Add to cart
                              </button>
                            )}
                        </>
                      ))}

                    <div className="row w-100 d-flex justify-content-lg-between justify-content-end m-auto ">
                      {loading ? (
                        <>
                          <Skeleton height={40} />
                          <Skeleton height={50} className="my-3" />
                        </>
                      ) : (
                        <>
                          {eventDetail?.ticketing_option === ticketOptionFree ||
                          eventDetail?.ticketing_option ===
                            ticketedonotherwebsites ? (
                            <div className="d-flex justify-content-between justify-content-lg-end px-0">
                              <div
                                className="d-lg-none"
                                style={{ display: "contents" }}
                              >
                                <button
                                  className="button d-flex align-items-center"
                                  onClick={handleShow}
                                >
                                  <i aria-hidden="true">
                                    <IoShareSocial />
                                  </i>
                                  <span className="Likenumber">Share</span>
                                </button>
                              </div>
                              <a
                                className="d-flex justify-content-end"
                                href={isFreeOffline ? null : href}
                                target={isFreeOffline ? undefined : "_blank"}
                                rel={
                                  isFreeOffline
                                    ? undefined
                                    : "noopener noreferrer"
                                }
                                onClick={(e) => {
                                  if (
                                    dataSource?.buttonAction ||
                                    isFreeOffline
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                <button
                                  className="button"
                                  disabled={
                                    dataSource?.buttonAction || isFreeOffline
                                  } // Disable if buttonAction exists or free offline
                                >
                                  {dataSource?.buttonName}
                                </button>
                              </a>
                            </div>
                          ) : (
                            <>
                              <div className="d-flex justify-content-between justify-content-lg-end px-0">
                                <div
                                  className="d-lg-none"
                                  style={{ display: "contents" }}
                                >
                                  <button
                                    className="button d-flex align-items-center"
                                    onClick={handleShow}
                                  >
                                    <i aria-hidden="true">
                                      <IoShareSocial />
                                    </i>
                                    <span className="Likenumber">Share</span>
                                  </button>
                                </div>

                                <button
                                  className="button col-5"
                                  onClick={TicketedClickToBuy}
                                >
                                  {dataSource?.buttonName}
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {dataSource?.isEditEvent &&
                        checkCondtion &&
                        checkCondtion?.created_by === user?.role_id && (
                          <>
                            <div className="px-0 my-2 w-100">
                              <div className="d-flex justify-content-end mt-2">
                                <button
                                  className="btn py-1 px-3 filterBtn m-0 text-nowrap brandcolor "
                                  onClick={editEventNavigate}
                                >
                                  Edit Events
                                </button>
                              </div>
                              <div className="d-flex justify-content-end">
                                <div className="form-check mt-3 form-switch d-flex justify-content-between align-items-end rounded w-50 brandcolor">
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                  >
                                    Show to public
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={dataSource.isShowToPublic === "Y"}
                                    onChange={showToPublicEvent}
                                  />
                                </div>
                              </div>
                            </div>
                            {dataSource.isShowToPublic === "Y" ? (
                              <div className="text-center rounded p-4">
                                <p className="text-success">
                                  {" "}
                                  <b>Event is now Visible to Public</b>
                                </p>
                              </div>
                            ) : (
                              <div className="text-center rounded p-4">
                                <p className="text-danger">
                                  {" "}
                                  <b>Event is Not visible to Public</b>{" "}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-md-2 ps-lg-5 mt-4 mb-5 mb-lg-2">
                  <div className="d-flex align-items-baseline justify-content-between">
                    {loading ? (
                      <Skeleton height={30} />
                    ) : (
                      <h4 className="mb-0">
                        {dataSource?.eventDetails &&
                          dataSource?.eventDetails[0]?.event_title}
                      </h4>
                    )}

                    {loading ? (
                      <Skeleton height={50} />
                    ) : (
                      <div className="d-none d-lg-block">
                        <button
                          className="button d-flex align-items-center ms-3"
                          variant="primary"
                          onClick={handleShow}
                        >
                          <i aria-hidden="true">
                            {" "}
                            <IoShareSocial />
                          </i>
                          <span className="Likenumber">Share</span>
                        </button>
                      </div>
                    )}

                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header
                        closeButton
                        className="m-0 d-flex align-items-center text-center border-light"
                        style={{ padding: "10px 10px" }}
                      >
                        <h5 className="text-nowrap text-center m-auto">
                          Share
                        </h5>
                      </Modal.Header>

                      <Modal.Body className="px-2 pb-4">
                        <div className="d-flex gap-3 justify-content-around pb-3">
                          <ShareSocialMediaEvents
                            eventdescr={
                              dataSource?.eventDetails[0]?.event_title
                            }
                            eventimage={`${EVENT_IMAGES_DETAILS}${
                              dataSource?.eventDetails &&
                              dataSource?.eventDetails[0]?.event_image
                            }`}
                            shareUrl={shareCurrentURL_updated()}
                          />
                          {/* <img 
                     src={mdndlogo}
                     className="commentImg rounded-circle"
                     onClick={handleMDNDevent}
                     /> */}
                        </div>

                        <div className="copy-link-container">
                          <div className="copy-header"></div>
                          <div className="copy-link">
                            <div className="copy-link-inner">
                              <form onSubmit={handleCopy} className="d-flex">
                                <input
                                  type="text"
                                  value={shareCurrentURL_updated()}
                                  className="w-100 overflow-hidden text-nowrap"
                                  ref={inputRef}
                                  onClick={() => inputRef.current.select()}
                                  readOnly
                                />
                                <input
                                  type="submit"
                                  className="text-nowrap"
                                  value="Copy"
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  {loading ? (
                    <>
                      <Skeleton height={30} className="mb-2" />
                      <Skeleton height={30} className="mb-2" />
                      <Skeleton height={30} className="mb-2" />
                      <Skeleton height={30} className="mb-2" />
                      <Skeleton height={30} className="mb-2" />
                      <Skeleton height={30} />
                    </>
                  ) : (
                    <>
                      <div className="d-flex mt-2 gap-2 gap-lg-5">
                        <div className="despWidth mb-0 mb-lg-2">
                          <b>Date </b>
                        </div>
                        <p className="despSecondWidth mb-0">
                          {checkCondtion?.event_date}
                        </p>
                      </div>

                      <div className="d-flex mt-2 gap-2 gap-lg-5">
                        <div className="despWidth mb-0 mb-lg-2">
                          <b>Time</b>
                        </div>
                        <p className="despSecondWidth mb-0">
                          {checkCondtion?.event_time}
                        </p>
                      </div>

                      <div className="d-flex mt-2 gap-2 gap-lg-5">
                        <div className="despWidth mb-0 mb-lg-2">
                          <b>Event type </b>
                        </div>
                        <p className="despSecondWidth mb-0">
                          {checkCondtion?.main_cat_name}{" "}
                          {isValidValue(checkCondtion?.sub_cat_name)
                            ? `- ${checkCondtion?.sub_cat_name}`
                            : ""}
                        </p>
                      </div>

                      <>
                        <div className="d-flex mt-2 gap-2 gap-lg-5">
                          <div className="despWidth mb-0 mb-lg-2">
                            {(TransformedData && TransformedData.length > 0) ||
                            isValidValue_quickadd_new(
                              checkCondtion.quickadd_artists
                            ) ? (
                              <b>Artists</b>
                            ) : null}
                          </div>
                          <span>
                            <p
                              className="mb-0 mb-lg-2"
                              style={{
                                textDecoration: "underline",
                                color: "#3f00ff !important",
                                cursor: "pointer",
                              }}
                            >
                              {TransformedData &&
                                TransformedData.length > 0 &&
                                transformData(TransformedData, "artist_id")}
                            </p>
                            {isValidValue_quickadd_new(
                              checkCondtion.quickadd_artists
                            ) ? (
                              <p className="mb-0 mb-lg-2">
                                {JSON.parse(
                                  checkCondtion?.quickadd_artists
                                ).join(", ")}
                              </p>
                            ) : null}

                          </span>
                        </div>
                      </>

                      <>
                        <div className="d-flex mt-2 gap-2 gap-lg-5 ">
                          <div className="despWidth mb-0 mb-lg-2">
                            {(accompanistsData &&
                              accompanistsData.length > 0) ||
                            isValidValue_quickadd_new(
                              checkCondtion?.quickadd_accompanists
                            ) ? (
                              <b>Accompanists</b>
                            ) : null}
                          </div>
                          <div>
                            <p
                              className="mb-0 mb-lg-2"
                              style={{
                                textDecoration: "underline",
                                color: "#3f00ff",
                                cursor: "pointer",
                              }}
                            >
                              {accompanistsData &&
                                accompanistsData.length > 0 &&
                                transformData(
                                  accompanistsData,
                                  "accompanist_id"
                                )}
                            </p>
                            {isValidValue_quickadd_new(
                              checkCondtion?.quickadd_accompanists
                            ) ? (
                              <p className="mb-0 mb-lg-2">
                                {JSON.parse(
                                  checkCondtion?.quickadd_accompanists
                                ).join(", ")}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </>

                      <>
                        {" "}
                        <div className="d-flex mt-2 gap-2 gap-lg-5 ">
                          <div className="despWidth mb-0 mb-lg-2">
                            {(organisersData && organisersData.length > 0) ||
                            isValidValue_quickadd_new(
                              checkCondtion?.quickadd_organisers
                            ) ? (
                              <b>Organisers</b>
                            ) : null}
                          </div>
                          <div>
                            <p
                              className="mb-0 mb-lg-2"
                              style={{
                                textDecoration: "underline",
                                color: "#3f00ff",
                                cursor: "pointer",
                              }}
                            >
                              {organisersData &&
                                organisersData.length > 0 &&
                                transformData(organisersData, "organiser_id")}
                            </p>
                            {isValidValue_quickadd_new(
                              checkCondtion?.quickadd_organisers
                            ) ? (
                              <p className="mb-0 mb-lg-2">
                                {JSON.parse(checkCondtion?.quickadd_organisers)
                                  .filter(Boolean)
                                  .join(", ")}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </>

                      {dataSource?.eventDetails &&
                        dataSource?.eventDetails[0]?.venue_name !== "" &&
                        dataSource?.eventDetails[0]?.venue_name !== null && (
                          <div className="d-flex mt-2 gap-2 gap-lg-5">
                            <div className="despWidth mb-0 mb-lg-2">
                              <b>Venue</b>
                            </div>
                            <p
                              className="despSecondWidth mb-0"
                              style={{
                                textDecoration: "underline",
                                color: "#3f00ff",
                                cursor: "pointer",
                              }}
                              onClick={() => enableMap()}
                            >
                              {dataSource?.eventDetails[0]?.venue_name}
                              <i class="fa-solid fa-location-dot ms-2"></i>
                            </p>
                          </div>
                        )}
                    </>
                  )}

                  <div id="wrapDesp" className="d-flex mt-2 gap-2 gap-lg-5 ">
                    {loading ? (
                      <Skeleton height={50} />
                    ) : (
                      <>
                        {dataSource?.eventDetails &&
                          dataSource?.eventDetails[0]?.event_description &&
                          dataSource?.eventDetails[0]?.event_description
                            .length > 0 && (
                            <>
                              <div className="right despWidth mb-0 mb-lg-2">
                                <b>Description</b>
                              </div>
                            </>
                          )}
                        <p
                          className="despSecondWidth mb-0"
                          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                        />
                      </>
                    )}
                  </div>
                  {/* <div className="col-12 col-lg-6 rounded">
                    <img className="rounded" style={{width:"100%", height:"auto"}} src={addImg}/>
                    <h6 className="mt-2">Ad Name</h6>
                    <div className="d-flex justify-content-between">
                    <p>Despriction</p>
                    <a><p className="underline BrandColor" >Learn more</p></a></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <MapPopup
            show={showMap}
            onClose={handleMapClose}
            mapLocation={dataSource?.eventDetails[0]?.map_location}
          />
        </>
      ) : (
        <LinkExpired linkExpiredData={linkExpiredData} />
      )}
    </>
  );
}

export default ProductDetails;
