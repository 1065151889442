import "./RagaBank.css"; 
import { MdForward10, MdReplay10 } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player/vimeo"; 

const HLSVideoPlayer = ({ videoUrl, onActionPlayForward, onActionPlayRewine }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(1.0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [currentUrl, setCurrentUrl] = useState(null); 
  const [controlsVisible, setControlsVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false); 
  
//   const rewind = () => {
//     if (playerRef.current) {
//       const currentTime = playerRef.current.getCurrentTime();
//       playerRef.current.seekTo(Math.max(currentTime - 10, 0));
//     }
//   };

//   const forward = () => {
//     if (playerRef.current) {
//       const currentTime = playerRef.current.getCurrentTime();
//       playerRef.current.seekTo(currentTime + 10);
//     }
//   };

  useEffect(() => {
    if (videoUrl) {
      setCurrentUrl(videoUrl); 
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0); 
    }
  }, [currentUrl]);

//   const handlePlayPause = () => {
//     setPlaying((prev) => !prev);
//   };

//   const playForward = async () => {
//     onActionPlayForward(true);
//   };

//   const playPrevious = () => {
//     onActionPlayRewine(true);
//   };

  const toggleDropdownVisibility = () => {
    setDropdownVisible(prev => !prev);
  };

  const handleDropdownClick = (quality) => {
    console.log(`${quality} selected`);
    setDropdownVisible(false);  // Close dropdown after selection
  };

  return (
    <div
      className="player-wrapper"
      onMouseEnter={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      {currentUrl ? (
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          url={currentUrl}
          playing={playing}
          controls={true}
          volume={volume}
          playbackRate={playbackRate}
          width="100%"
          height="100%"
          config={{
            vimeo: {
              playerOptions: {
                autoplay: false,
                autopause: false,
                dnt: true, // Do Not Track option
              },
            },
          }}
        />
      ) : (
        <div>Loading video...</div> 
      )}
      {/* <div
        className={`play-pause d-flex align-items-center ${controlsVisible ? "visible" : ""}`}
      >
        <i aria-hidden="true" style={{ margin: "0 48px 0 0" }}>
          <MdReplay10
            style={{ fontSize: "36px", color: "rgba(128, 128, 128, 0.50)" }}
            onClick={rewind}
          />
        </i>
        <i
          className="fa fa-step-backward m-0 p-0"
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={playPrevious}
        />
        <i
          className={`fa ${playing ? "fa-pause-circle" : "fa-play-circle"}`}
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={handlePlayPause} 
        />
        <i
          className="fa fa-step-forward m-0 p-0"
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={playForward}
        />
        <i aria-hidden="true" style={{ margin: "0 0 0 48px" }}>
          <MdForward10
            style={{ fontSize: "36px", color: "rgba(128, 128, 128, 0.50)" }}
            onClick={forward}
          />
        </i>
      </div> */}
      <div className="quality-dropdown-wrapper">
        <button className="quality-button" onClick={toggleDropdownVisibility}>
          Select Quality
        </button>
        {dropdownVisible && (
          <ul className="quality-dropdown " >
            <li onClick={() => handleDropdownClick("720p")}>720p</li>
            <li onClick={() => handleDropdownClick("1080p")}>1080p</li>
            <li onClick={() => handleDropdownClick("1440p")}>1440p</li>
            <li onClick={() => handleDropdownClick("2160p")}>2160p</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default HLSVideoPlayer;



