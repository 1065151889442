import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { INCOMPLETE_PROFILE, PROFILE_COMPLETED } from "../global/constants";

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("MDnD_auth") ? localStorage.getItem("MDnD_auth") : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

  const [loading, setLoading] = useState(true);

  //new code..
  const [prevURL, setPrevURL] = useState('');



  const history = useNavigate();

  

  const LoginUserDetails = async (FinalResponse) => {
    if (FinalResponse != null) {
      if (
        FinalResponse !== null &&
        FinalResponse?.data?.isprofile_completion === INCOMPLETE_PROFILE
      ) {
        history(`/confirmRegistration?loginId=${FinalResponse?.data?.login_id}`);
      }
      if (
        FinalResponse !== null &&
        FinalResponse?.data?.isprofile_completion === PROFILE_COMPLETED
      ) {
        const tokenString = FinalResponse.MDnD_auth;
        setAuthTokens(tokenString);
        setUser(jwtDecode(tokenString));
        localStorage.setItem("MDnD_auth", tokenString);
        //history("/dashboard");
        history(prevURL || "/dashboard"); 
      }
    } else if (FinalResponse.status === 500) {
      // Handle server error
    } else {
      // Handle other errors
    }
  };

  const logoutUser = () => {
    setPrevURL('');
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("MDnD_auth");
    
    history("/");
   
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    LoginUserDetails,
    logoutUser,
    prevURL,
    setPrevURL,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};