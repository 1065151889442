import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import IMAGE1 from "../../assets/images/genre/Season.webp";
import { makeGetRequest, makePostRequest } from "../../Api/requestMethods";
import { ERROR, DURATION, SUCCESS } from "../../global/ToastMsgConstants.tsx";
import { seasonEventDetails } from "../../Api/api";
import notify from "devextreme/ui/notify";
import { EVENT_IMAGES_DETAILS } from "../../global/constants.js";
import { useQuery } from "@tanstack/react-query";
import { IoShareSocial } from "react-icons/io5";
import { Modal, Form } from "react-bootstrap";
import DisplayHtmlElement from "../../components/blobToHtmlElement.js";
import { getArtistOrgAccomNames } from "../../utils/commonUtils.js";
import { GET_TICKET_DETAILS } from "../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiOutlineMinusCircle } from "react-icons/hi";
import DOMPurify from "dompurify";

import {
  ticketOptionFree,
  EventModeOffline,
  EventModeOnline,
  ticketedonotherwebsites,
  ticketedonmdnd,
  seatingPlanNonNumbered,
} from "../../global/constants.js";
import SeasonOfflineDenomination from "./SeasonOfflineDenomination.js";
import SeasonOnlineDenomination from "./SeasonOnlineDenomination.js";
import { capitalizeFirstLetter } from "../../utils/commonUtils.js";
import { LIMITATION_ERROR } from "../../Schemas/errorMessageConstants.js";
import { singleCartDetails } from "../../Api/api";
import AuthContext from "../../context/AuthContext.js";
import { ticketQtyRequired } from "../../Schemas/errorMessageConstants.js";
import { v4 as uuidv4 } from "uuid";
import { GlobalContext } from "../../global/provider.js";
import { addToCart } from "../../Api/api";
import "./SeasonEventDetails.css";
import { ALLEVENT_DETAILS_IMAGES } from "../../global/constants.js";
import { NumberBox, TextBox, SelectBox } from "devextreme-react";
import Skeleton from "react-loading-skeleton";
import MapPopup from "../../Pages/productDetails/components/mapPopup.js";
import "react-loading-skeleton/dist/skeleton.css";
import getSeasonTicketKey from "../../Api/getSeasonTicketKey.js";
import { getMemberType } from "../../Api/api";
import { isValidValue } from "../../utils/commonUtils.js";
import { useFormik } from "formik";
import { organiser_landing_page_form } from "../../Schemas/Forms/LoginDashboard.js";
import { getMemberDetails } from "../../Api/api";
import EventDetailsDescription from "./Description_blobToHTML.js";
import SeasonOfflineMembersOnlyDenomination from "./seasonEventOfflineMembersOnly.js";
import { organiser_landing_page_Schema } from "../../Schemas/index.js";
import { useScreenDetector } from "../../components/deviceHook.js";
function SeasonEventDetails() {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  const [loading, setLoading] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const { setEventDataSource, setCartDetails, eventsDatasource } =
    useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const inputRef = useRef(null);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: organiser_landing_page_form,
    validationSchema: organiser_landing_page_Schema,
    onSubmit: async (values, { setSubmitting }) => {
      await forMembersOnlySubmit();
      setSubmitting(false);
    },
  });

  const [dataSource, setDataSource] = useState({
    eventDetails: "",
    buttonName: "",
    buttonAction: "",
    buttonUrl: "",
    gridData: "",
    changedRowCount: null,
    trigger: false,
    isMultipleEvent: false,
    membersType: "",
    membersDetails: "",
  })
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [multipleEventsFormData, setMultipleEventsFormData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchParams] = useSearchParams();
  const location = useLocation()?.pathname;
  const role_id = searchParams.get("r_id");
  const multiple_events = searchParams.get("is_multiple");

  const SEASONTICKETCOUNTRYKEY = getSeasonTicketKey().SEASONTICKET_KEY;
  const SEASONEVENTKEY = getSeasonTicketKey().SEASONEVENT_KEY;

  const decodeBase64 = (encoded, key) => {
    const prefix = key?.toString();
    try {
      const decodedStr = atob(encoded);
      if (decodedStr.startsWith(prefix)) {
        return decodedStr.slice(prefix.length);
      } else {
        console.error("Decoding error: Prefix not found");
        return null;
      }
    } catch (error) {
      console.error("Decoding error:", error);
      return null;
    }
  };

  const forMembersOnlySubmit = async () => {
    const CheckNull = await checkNull();
    if (CheckNull && CheckNull.length === 0) {
      notify(ticketQtyRequired, ERROR, DURATION);
      return;
    }
    const { ticketing_option } =
      dataSource?.eventDetails && dataSource?.eventDetails[0];
    if (ticketing_option === ticketedonmdnd) {
      await addToChartEvent(false, false);
    }
    setEventDataSource((prev) => ({
      ...prev,
      formdata: formData,
      eventDetails: dataSource?.eventDetails,
      membersOnlyName: values.member_name,
      membersOnlyEmail: values.member_email,
      membersOnlyMobile: values.member_mobile,
    }));
    navigate("/checkOut");
  };

  useEffect(() => {
    if (
      isValidValue(values.membership_type) &&
      isValidValue(values.membership_id)
    ) {
      (async () => {
        try {
          const body = {
            membertype: values.membership_type,
            memberid: values.membership_id,
          };

          const response = await makePostRequest(getMemberDetails, body);
          if (response.status === SUCCESS) {
            setDataSource((prev) => ({
              ...prev,
              membersDetails: response?.data,
            }));
            setValues(response?.data[0]);
          } else {
            notify(response?.error, ERROR, DURATION);

            setFieldValue("member_email", "");
            setFieldValue("member_name", "");
            setFieldValue("member_mobile", "");
          }
        } catch (error) {
          setFieldValue("member_email", "");
          setFieldValue("member_name", "");
          setFieldValue("member_mobile", "");

          notify(error?.response?.data?.message, ERROR, DURATION);
        }
      })();
    }
  }, [values.membership_type, values.membership_id]);

  const SeasonEvent_id = decodeBase64(searchParams?.get("se"), SEASONEVENTKEY);
  const countryId = decodeBase64(
    searchParams?.get("ci"),
    SEASONTICKETCOUNTRYKEY
  );

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };

  const getTicketDetails = async (userData) => {
    try {
      const response = await makePostRequest(GET_TICKET_DETAILS, userData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const seasonEventDetailsFetch = async () =>
    await makeGetRequest(
      seasonEventDetails(
        countryId,
        SeasonEvent_id,
        eventsDatasource.multiple_tickets || multiple_events
      )
    );
  const { data: seasonEventDetailsDs } = useQuery({
    queryKey: ["__seasonEventDetails__"],
    queryFn: () => seasonEventDetailsFetch(),
    enabled: countryId !== null && SeasonEvent_id !== null,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    const uniqueId = localStorage.getItem("mdnd_cId");
    try {
      setDataSource((prev) => ({
        ...prev,
        eventDetails: seasonEventDetailsDs?.data,
        buttonName: seasonEventDetailsDs?.data[0]?.season_button_text,
        isMultipleEvent:
          seasonEventDetailsDs?.data[0]?.multiple_tickets === "Y",
      }));
      const modifiedData =
        seasonEventDetailsDs?.data &&
        seasonEventDetailsDs?.data[0]?.Event_details.map((eventDetail) => {
          const modifiedTicketDetails = eventDetail.Event_Ticket_details.map(
            (ticketDetail) => {
              return {
                ...ticketDetail,
                row_total: 0,
                quantity: ticketDetail?.quantity || 0,
              };
            }
          );

          return {
            ...eventDetail,
            role_id: Number(user?.role_id) || "",
            login_id: Number(user?.login_id) || "",
            session_id: !user?.role_id && !user?.login_id ? uniqueId : null,
            country_id: Number(countryId),
            item_id: Number(eventDetail.event_id),
            cart_purpose: "ticket",
            Total_quantity: modifiedTicketDetails,
          };
        });

      setMultipleEventsFormData(modifiedData);
    } catch (error) {}
  }, [seasonEventDetailsDs]);

  const checkCondtion = dataSource?.eventDetails && dataSource?.eventDetails[0];

  useEffect(() => {
    if (checkCondtion && checkCondtion?.ticketing_option === ticketedonmdnd) {
      (async () => {
        try {
          if (dataSource.trigger) {
            var uniqueId = localStorage.getItem("mdnd_cId");
            const body = {
              session_id: user?.role_id ? "" : uniqueId,
              role_id: user?.role_id || "",
              country_id: countryId,
              item_id: checkCondtion?.season_event_id,
              season_flag: checkCondtion?.multiple_tickets === "Y" ? "N" : "Y",
            };
            const response = await makePostRequest(singleCartDetails, body);
            if (response && response.status === SUCCESS) {
              let parsedData = false;
              try {
                parsedData = response?.data?.total_quantity
                  ? JSON.parse(response?.data?.total_quantity)
                  : false;
              } catch (e) {
                console.error(e);
              }
              if (parsedData && Array.isArray(formData)) {
                const modifiedFormData = formData.map((data, index) => ({
                  ...data,
                  no_of_tickets: parsedData[index]?.qty || 0,
                }));

                setFormData(modifiedFormData);
                setDataSource((prev) => ({
                  ...prev,
                  trigger: false,
                }));
              } else {
                console.error(
                  "formData is not an array or parsedData is invalid"
                );
                setDataSource((prev) => ({
                  ...prev,
                  trigger: false,
                }));
              }
            }
          }
        } catch (error) {
          setDataSource((prev) => ({
            ...prev,
            trigger: false,
          }));
          console.error(error);
        }
      })();
    }
  }, [checkCondtion, dataSource.trigger]);

  useEffect(() => {
    if (dataSource && dataSource.gridData) {
      const initialFormData = dataSource.gridData.map((data) => ({
        no_of_tickets: null,
        amount: parseFloat(data.amount),
        id: data.id,
      }));
      setFormData(initialFormData);
      setDataSource((prev) => ({
        ...prev,
        trigger: true,
      }));
    }
  }, [dataSource.gridData]);

  const getTicketDetailsMutation = useMutation({
    mutationFn: (data) => getTicketDetails(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        gridData: data?.data,
      }));
      setLoading(false);
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
      setLoading(false);
    },
  });

  const addToChartEvent = async (IsClickToBuy, fromMultipleEvent) => {
    var uniqueId;

    if (!fromMultipleEvent) {
      const CheckNull = await checkNull();
      if (CheckNull && CheckNull.length === 0) {
        notify(ticketQtyRequired, ERROR, DURATION);
        return;
      }
    }
    if (fromMultipleEvent && !checkMultipleEventsNull()) {
      notify(ticketQtyRequired, ERROR, DURATION);
      return;
    }

    uniqueId = localStorage.getItem("mdnd_cId");
    if (!uniqueId) {
      uniqueId = uuidv4();
      localStorage.setItem("mdnd_cId", uniqueId);
    }

    const ticketData = formData?.map((data) => {
      return {
        id: data.id,
        qty: data.no_of_tickets === null ? 0 : data.no_of_tickets,
      };
    });

    const bodyTouse =
      checkCondtion && checkCondtion.multiple_tickets === "Y"
        ? multipleEventsFormData
            .map((event) => {
              const filteredQuantity = event.Total_quantity.filter(
                (ticket) => ticket.quantity > 0
              );

              if (filteredQuantity.length === 0) {
                return null; // Return null for events with all tickets having zero quantity
              }

              return {
                role_id: Number(user?.role_id) || "",
                login_id: Number(user?.login_id) || "",
                session_id: user?.role_id || user?.login_id ? null : uniqueId,
                country_id: Number(countryId),
                item_id: event.event_id,
                season_flag: "N",
                cart_purpose: "ticket",
                is_multiple: "Y",
                total_quantity: event.Total_quantity.map((ticket) => {
                  return {
                    id: ticket.id,
                    qty: ticket.quantity,
                  };
                }),
              };
            })
            .filter(Boolean)
        : [
            {
              role_id: Number(user?.role_id) || "",
              login_id: Number(user?.login_id) || "",
              session_id: !user?.role_id && !user?.login_id ? uniqueId : null,
              country_id: Number(countryId),
              item_id: Number(SeasonEvent_id),
              cart_purpose: "ticket",
              is_multiple: "N",
              total_quantity: ticketData,
              season_flag: "Y",
            },
          ];

    try {
      if (uniqueId) {
        const response = await makePostRequest(addToCart, bodyTouse);
        if (response && response.status === SUCCESS) {
          const cartCount = response.data && response?.data?.cart_count;
          if (IsClickToBuy) {
            notify(response?.message, SUCCESS, DURATION);
          }
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const ticketLimitationEvent = (
    index,
    value,
    maxLimit,
    rowName,
    totalTicketBalance
  ) => {
    setDataSource((prev) => ({ ...prev, changedRowCount: value }));
    var updatedFormData = [...formData];

    var newTicketLimit = null;
    if (totalTicketBalance === 0) {
      newTicketLimit = 0;
    } else if (totalTicketBalance === undefined) {
      newTicketLimit = maxLimit;
    } else if (totalTicketBalance && totalTicketBalance <= maxLimit) {
      newTicketLimit = totalTicketBalance;
    } else {
      newTicketLimit = maxLimit;
    }

    if (value > newTicketLimit) {
      updatedFormData[index].no_of_tickets = newTicketLimit;
      notify(LIMITATION_ERROR(newTicketLimit, rowName), ERROR, DURATION);
      return;
    } else {
      updatedFormData[index].no_of_tickets = value;
    }
    setFormData(updatedFormData);
  };
  const eventDetail = dataSource?.eventDetails && dataSource?.eventDetails[0];
  useEffect(() => {
    if (dataSource?.eventDetails?.length > 0) {
      const { ticketing_option, event_mode, seating_option } = eventDetail;
      // Ticketed on MDnD - offline
      if (ticketing_option === ticketedonmdnd)
        if (event_mode) {
          const body = {
            country_id: countryId,
            ticket_type:
              event_mode === EventModeOffline
                ? seating_option
                : EventModeOnline,
            event_id: SeasonEvent_id,

            seating_option: seatingPlanNonNumbered,
            ticketing_option: ticketing_option,
            event_mode: event_mode,
            season_flag: "Y",
          };
          getTicketDetailsMutation.mutate(body);
        }
    }
  }, [dataSource.eventDetails]);

  const checkNull = async () => {
    const CheckNullData = formData.filter(
      (data) => data?.no_of_tickets !== null && data?.no_of_tickets !== 0
    );
    return CheckNullData;
  };

  const checkMultipleEventsNull = () => {
    const allZero = multipleEventsFormData.every((data) =>
      data.Total_quantity.every(
        (ticket) => ticket.quantity === null || ticket.quantity === 0
      )
    );

    if (allZero) {
      return false;
    } else {
      return true;
    }
  };

  const buyTicketsEvent = async () => {
    const CheckNull = await checkNull();
    if (CheckNull && CheckNull.length === 0) {
      notify(ticketQtyRequired, ERROR, DURATION);
      return;
    }
    const { ticketing_option } =
      dataSource?.eventDetails && dataSource?.eventDetails[0];
    if (ticketing_option === ticketedonmdnd) {
      await addToChartEvent(false, false);
    }
    setEventDataSource((prev) => ({
      ...prev,
      formdata: formData,
      eventDetails: dataSource?.eventDetails,
    }));

    navigate("/checkOut");
  };
  const handleQuantityChange = (index, ticketDetailsIndex, value) => {
    const updatedFormData = [...multipleEventsFormData];
    const ticketDetails =
      updatedFormData[index].Total_quantity[ticketDetailsIndex];
    const rowName = ticketDetails.row_name;

    const totalTicketBalance = ticketDetails.balance_tickets;
    const maxLimit = ticketDetails.ticket_limitations;

    var newTicketLimit = null;
    if (totalTicketBalance === 0) {
      newTicketLimit = 0;
    } else if (totalTicketBalance === undefined) {
      newTicketLimit = maxLimit;
    } else if (totalTicketBalance && totalTicketBalance <= maxLimit) {
      newTicketLimit = totalTicketBalance;
    } else {
      newTicketLimit = maxLimit;
    }

    if (value > newTicketLimit) {
      updatedFormData[index].Total_quantity[ticketDetailsIndex].quantity = null;
      notify(LIMITATION_ERROR(newTicketLimit, rowName), ERROR, DURATION);
      return;
    } else {
      updatedFormData[index].Total_quantity[ticketDetailsIndex].quantity =
        value;
      updatedFormData[index].Total_quantity[ticketDetailsIndex].row_total =
        ticketDetails.amount * value;
    }

    setMultipleEventsFormData(updatedFormData);
  };

  const calculateGrandTotal = (ticketDetails) => {
    return ticketDetails.reduce((acc, ticket) => acc + ticket.row_total, 0);
  };

  const onMultipleEventsSubmit = async () => {
    if (!checkMultipleEventsNull()) {
      notify(ticketQtyRequired, ERROR, DURATION);
      return;
    } else {
      if (checkCondtion.ticketing_option === ticketedonmdnd) {
        await addToChartEvent(false, true);
      }
      navigate("/checkOut");
    }
  };

  const handleMapClose = () => setShowMap(false);
  const enableMap = () => setShowMap(true);

  // Members Only

  useEffect(() => {
    (async () => {
      if (isValidValue(role_id) && seasonEventDetailsDs?.status === 200) {
        try {
          const body = {
            role_id: role_id,
          };
          if (isValidValue(role_id)) {
            const response = await makePostRequest(getMemberType, body);
            if (response.status === SUCCESS) {
              setDataSource((prev) => ({
                ...prev,
                membersType: response.data,
              }));
            } else {
              notify(response?.error, ERROR, DURATION);
            }
          }
        } catch (error) {
          notify(error.response.data.message, ERROR, DURATION);
        }
      }
    })();
  }, [role_id, seasonEventDetailsDs]);

  return (
    <>
      <div className="section-padding-top product-detail pb-5 pt-3">
        <div className="container">
          {dataSource && !dataSource.isMultipleEvent && (

            <div className="row">
              {loading ? (
                <>
                  {
                    multiple_events === "N" ? <div className="d-block d-lg-flex col-12">
                      <Skeleton height={500} width={500} className="col-6 me-0 me-lg-2" />
                      <Skeleton count={10} height={40} width={500} className="col-6 ms-0 ms-lg-2" />
                    </div> : <div className="d-block d-lg-flex col-12">
                      <Skeleton height={500} width={500} className="col-6 me-0 me-lg-2" />
                      <Skeleton height={500} width={500} className="col-6 ms-0 ms-lg-2" />
                    </div>
                  }


                </>
              ) : (
                <>
                  <div className="col-lg-5">
                    <div className="product-tab-slider mt-4">
                      <div
                        className="swiper product-tab-slider-thumb mb-2"
                        data-swiper="slider-prodcut-images"
                      >
                        <div className="swiper-wrapper m-0">
                          <div className="swiper-slide p-0">

                            <>
                              <a data-fslightbox="product" href="#">
                                <img
                                  src={`${EVENT_IMAGES_DETAILS}${multipleEventsFormData &&
                                    multipleEventsFormData[0]?.event_image
                                    }`}
                                  className="img-fluid product-detail-image rounded"
                                  alt="product-slide-image"
                                />
                              </a>
                              <div className="d-none d-lg-block">
                                {!isMobile && checkCondtion &&
                                  checkCondtion?.ticketing_option !==
                                  ticketOptionFree &&
                                  checkCondtion?.ticketing_option !==
                                  ticketedonotherwebsites &&
                                  checkCondtion.for_members !== "Y" && (
                                    <div className="mt-3">
                                      <b>Choose your denomination & quantity</b>
                                      {dataSource.eventDetails &&
                                        dataSource?.eventDetails[0]?.event_mode ===
                                        EventModeOffline ? (
                                        <SeasonOfflineDenomination
                                          dataSource={dataSource}
                                          formData={formData}
                                          ticketLimitationEvent={
                                            ticketLimitationEvent
                                          }
                                          capitalizeFirstLetter={
                                            capitalizeFirstLetter
                                          }
                                          EventModeOnline={EventModeOnline}
                                        />
                                      ) : (
                                        <SeasonOnlineDenomination
                                          dataSource={dataSource}
                                          formData={formData}
                                          ticketLimitationEvent={
                                            ticketLimitationEvent
                                          }
                                          capitalizeFirstLetter={
                                            capitalizeFirstLetter
                                          }
                                          EventModeOnline={EventModeOnline}
                                        />
                                      )}
                                    </div>
                                  )}

                              {checkCondtion &&
                                checkCondtion?.for_members &&
                                checkCondtion?.for_members === "Y" &&
                                isValidValue(values.member_email) &&
                                isValidValue(values.member_name) &&
                                isValidValue(values.member_mobile) && (
                                  <SeasonOfflineMembersOnlyDenomination
                                    dataSource={dataSource}
                                    formData={formData}
                                    ticketLimitationEvent={
                                      ticketLimitationEvent
                                    }
                                    capitalizeFirstLetter={
                                      capitalizeFirstLetter
                                    }
                                    EventModeOnline={EventModeOnline}
                                    membersOnlyDetails={
                                      dataSource?.membersDetails
                                    }
                                  />
                                )}

                                {checkCondtion &&
                                  checkCondtion?.for_members === "Y" ? (
                                  <div className="mt-2">
                                    <button
                                      onClick={handleSubmit}
                                      className="button"
                                    >
                                      {dataSource?.buttonName}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-end mt-3">
                                    <button
                                      onClick={buyTicketsEvent}
                                      className="button"
                                    >
                                      {dataSource?.buttonName}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </>


                          </div>
                          <div className="swiper-slide p-0">
                            <a
                              data-fslightbox="product"
                              href="../assets/images/shop/product/02.webp"
                            >
                              <img
                                src={IMAGE1}
                                className="img-fluid product-detail-image"
                                alt="product-slide-image"
                              />
                            </a>
                          </div>
                          <div className="swiper-slide p-0">
                            <a
                              data-fslightbox="product"
                              href="../assets/images/shop/product/03.webp"
                            >
                              <img
                                src={IMAGE1}
                                className="img-fluid product-detail-image"
                                alt="product-slide-image"
                              />
                            </a>
                          </div>
                          <div className="swiper-slide p-0">
                            <a
                              data-fslightbox="product"
                              href="../assets/images/shop/product/04.webp"
                            >
                              <img
                                src={IMAGE1}
                                className="img-fluid product-detail-image"
                                alt="product-slide-image"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-2 ps-lg-5">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">
                        {checkCondtion?.season_event_title}
                      </h4>
                      <button
                        className="videoShareBtn d-flex align-items-center ms-3"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <i aria-hidden="true">
                          {" "}
                          <IoShareSocial />
                        </i>
                        <span className="Likenumber">Share</span>
                      </button>

                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header
                          closeButton
                          className="m-0 d-flex align-items-center text-center border-light"
                          style={{ padding: "10px 10px" }}
                        >
                          <h5 className="text-nowrap text-center m-auto">
                            Share
                          </h5>
                        </Modal.Header>
                        <Modal.Body className="px-2 pb-4">
                          <div className="d-flex gap-3 justify-content-around pb-3">
                            <div className="share-icon whatsapp">
                              <i
                                className="fa-brands fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="share-icon facebook">
                              <i
                                className="fa-brands fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="share-icon twitter">
                              <i class="fa-brands fa-x-twitter"></i>
                            </div>
                          </div>

                          <div className="copy-link-container">
                            <div className="copy-header"></div>
                            <div className="copy-link">
                              <div className="copy-link-inner">
                                <form onSubmit={handleCopy}>
                                  <input
                                    type="text"
                                    value="https://www.mdnd.in/mdndrecords"
                                    ref={inputRef}
                                    onClick={() => inputRef.current.select()}
                                    readOnly
                                  />
                                  <input type="submit" value="Copy" />
                                </form>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div className="d-flex mt-2">
                      <div className="despWidth">
                        <b>Time / Date </b>
                      </div>
                      <div className="despSecondWidth">
                        {checkCondtion?.event_start_date}
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <div className="despWidth">
                        <b>Organiser</b>
                      </div>

                      <div className="despSecondWidth">
                        {getArtistOrgAccomNames(
                          checkCondtion?.Organiser_details,
                          "organiser_id"
                        )}
                      </div>
                    </div>
                    {dataSource?.eventDetails &&
                      dataSource?.eventDetails[0]?.venue_name !== "" &&
                      dataSource?.eventDetails[0]?.venue_name !== null && (
                        <div className="d-flex mt-3">
                          <div className="despWidth">
                            <b>Venue</b>
                          </div>
                          <p
                            className="despSecondWidth"
                            style={{
                              textDecoration: "underline",
                              color: "#3f00ff",
                              cursor: "pointer",
                            }}
                            onClick={() => enableMap()}
                          >
                            {dataSource?.eventDetails[0]?.venue_name}
                            <i class="fa-solid fa-location-dot ms-2"></i>
                          </p>
                        </div>
                      )}

                    {dataSource?.eventDetails &&
                      dataSource?.eventDetails[0]?.event_description !== "" && (
                        <div id="wrapDesp" className="mt-3">
                          <div className="right despWidth">
                            <b>Description</b>
                          </div>
                          <div>
                            <EventDetailsDescription dataSource={dataSource} />
                          </div>
                        </div>
                      )}

                    <div className="col-12">
                      <form className="d-flex flex-column">
                        <div className="form-group mb-2">
                          <SelectBox
                            label="Select membership type"
                            labelMode="floating"
                            className="dx-field-item-required"
                            displayExpr="membership_type"
                            valueExpr="membership_type"
                            dataSource={dataSource.membersType || []}
                            searchEnabled={true}
                            value={values.membership_type || []}
                            onValueChanged={(e) =>
                              handleChange({
                                target: {
                                  name: "membership_type",
                                  value: e.value,
                                },
                              })
                            }
                            onFocusOut={(e) =>
                              handleBlur({
                                target: {
                                  name: "membership_type",
                                  value: e.value,
                                },
                              })
                            }
                          />
                          {errors.membership_type && touched.membership_type && (
                            <p className="error-message">
                              {errors.membership_type}
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-2">
                          <TextBox
                            type="text"
                            labelMode="floating"
                            className="dx-field-item-required"
                            required="required"
                            mode="floating"
                            label="Enter membership number"
                            value={values.membership_id}
                            onValueChanged={(e) =>
                              handleChange({
                                target: { name: "membership_id", value: e.value },
                              })
                            }
                            onFocusOut={(e) =>
                              handleBlur({
                                target: { name: "membership_id", value: e.value },
                              })
                            }
                          />
                          {errors.membership_id && touched.membership_id && (
                            <p className="error-message">
                              {errors.membership_id}
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="col-12 mb-2">
                      <form className="d-flex flex-column">
                        <div className="form-group mb-2">
                          <TextBox
                            type="text"
                            labelMode="floating"
                            mode="floating"
                            label="Name"
                            readOnly
                            value={values.member_name}
                            onFocusOut={(e) =>
                              handleBlur({
                                target: { name: "member_name", value: e.value },
                              })
                            }
                            onValueChanged={(e) =>
                              handleChange({
                                target: { name: "member_name", value: e.value },
                              })
                            }
                          />
                          {errors.member_name && touched.member_name && (
                            <p className="error-message">{errors.member_name}</p>
                          )}
                        </div>
                        <div className="form-group mb-2">
                          <TextBox
                            type="text"
                            labelMode="floating"
                            mode="floating"
                            label="E-mail ID"
                            value={values.member_email}
                            onFocusOut={(e) =>
                              handleBlur({
                                target: { name: "member_email", value: e.value },
                              })
                            }
                            onValueChanged={(e) =>
                              handleChange({
                                target: { name: "member_email", value: e.value },
                              })
                            }
                          // readOnly
                          />
                          {errors.member_email && touched.member_email && (
                            <p className="error-message">{errors.member_email}</p>
                          )}
                        </div>
                        <div className="form-group mb-2">
                          <TextBox
                            type="text"
                            labelMode="floating"
                            mode="floating"
                            label="Mobile"
                            value={values.member_mobile}
                            onFocusOut={(e) =>
                              handleBlur({
                                target: { name: "member_mobile", value: e.value },
                              })
                            }
                            onValueChanged={(e) =>
                              handleChange({
                                target: { name: "member_mobile", value: e.value },
                              })
                            }
                            readOnly
                          />
                          {errors.member_mobile && touched.member_mobile && (
                            <p className="error-message">
                              {errors.member_mobile}
                            </p>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </>

              )}
              <div className="d-block d-lg-none mb-5">
                {isMobile &&
                  checkCondtion &&
                  checkCondtion?.ticketing_option !== ticketOptionFree &&
                  checkCondtion?.ticketing_option !== ticketedonotherwebsites &&
                  checkCondtion.for_members !== "Y" && (
                    <div className="mt-3">
                      <b>Choose your denomination & quantity</b>
                      {dataSource.eventDetails &&
                      dataSource?.eventDetails[0]?.event_mode ===
                        EventModeOffline ? (
                        <SeasonOfflineDenomination
                          dataSource={dataSource}
                          formData={formData}
                          ticketLimitationEvent={ticketLimitationEvent}
                          capitalizeFirstLetter={capitalizeFirstLetter}
                          EventModeOnline={EventModeOnline}
                        />
                      ) : (
                        <SeasonOnlineDenomination
                          dataSource={dataSource}
                          formData={formData}
                          ticketLimitationEvent={ticketLimitationEvent}
                          capitalizeFirstLetter={capitalizeFirstLetter}
                          EventModeOnline={EventModeOnline}
                        />
                      )}
                    </div>
                  )}

                {checkCondtion &&
                  checkCondtion?.for_members &&
                  checkCondtion?.for_members === "Y" &&
                  isValidValue(values.member_email) &&
                  isValidValue(values.member_name) &&
                  isValidValue(values.member_mobile) && (
                    <SeasonOfflineMembersOnlyDenomination
                      dataSource={dataSource}
                      formData={formData}
                      ticketLimitationEvent={ticketLimitationEvent}
                      capitalizeFirstLetter={capitalizeFirstLetter}
                      EventModeOnline={EventModeOnline}
                      membersOnlyDetails={
                        dataSource?.membersDetails
                      }
                    />
                  )}

                {checkCondtion && checkCondtion?.for_members === "Y" ? (
                  <div className="mt-2 mb-5">
                    <button onClick={handleSubmit} className="button">
                      {dataSource?.buttonName}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mt-3">
                    <button onClick={buyTicketsEvent} className="button">
                      {dataSource?.buttonName}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {dataSource && dataSource.isMultipleEvent && (
            <div id="mutipleScroll">
              {loading && dataSource.isMultipleEvent ? (
                <Skeleton height={40} />
              ) : (
                <>
                  <h5 className="multipleHaeder my-3 mt-5  mt-lg-0">
                    Select concerts and buy at one go
                  </h5>
                </>
              )}

              <div className="row">
                {multipleEventsFormData &&
                  multipleEventsFormData.map((event, index) => (
                    <div className="col-lg-12 mb-3" key={index}>
                      <div className="row shadow-sm rounded m-0  py-2">
                        <div className="col-lg-4 cart-table">
                          <div className="d-lg-block d-flex justify-content-baseline">
                            <img
                              style={{
                                maxWidth: "250px",
                                height: "auto",
                                maxHeight: "250px",
                              }}
                              className="avatar-80 multiTableImg mb-2 mb-lg-0"
                              src={`${ALLEVENT_DETAILS_IMAGES}${event?.event_image}`}
                              alt={event?.event_title}
                            />
                            <div className="mb-0 ms-2 ms-lg-0 mt-2 fs-12 ">
                              <h5 className="mb-0">
                                <b>{event?.event_title}</b>
                              </h5>
                              <p className="mb-0 fs-12">{event?.event_date}</p>
                              <p className="mb-0 fs-12">{event?.city_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="table-responsive">
                            {event.Total_quantity && (
                              <table className="table m-0 text-center">
                                <thead className="border-bottom">
                                  <tr>
                                    <th className="multiTextbox py-2">
                                      Row / Category
                                    </th>
                                    <th className="multiTextbox py-2">Rate</th>

                                    <th className="multiTextbox py-2">Qty</th>
                                    <th className="multiTextbox py-2">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {event.Total_quantity.map(
                                    (ticket, ticketIndex) => (
                                      <tr key={ticketIndex}>
                                        <td>{ticket.row_name}</td>
                                        <td>
                                          {ticket.symbol} {ticket.amount}
                                        </td>
                                        <td>
                                          <div
                                            className="quantity-control text-center rounded"
                                            style={{
                                              border: "1px solid #e0e0e0",
                                            }}
                                          >
                                            <button
                                              style={{
                                                borderRadius: "4px 0px 0px 4px",
                                                backgroundColor: "#ffffff",
                                              }}
                                              className="btn-decrement text-dark px-1 px-sm-2"
                                              onClick={() => {
                                                if (ticket.quantity > 0) {
                                                  handleQuantityChange(
                                                    index,
                                                    ticketIndex,
                                                    ticket.quantity - 1
                                                  );
                                                }
                                              }}
                                            >
                                              <HiOutlineMinusCircle
                                                style={{ color: "#757575" }}
                                              />
                                            </button>
                                            <NumberBox
                                              min={0}
                                              max={10}
                                              showSpinButtons={false}
                                              onValueChanged={(e) =>
                                                handleQuantityChange(
                                                  index,
                                                  ticketIndex,
                                                  e.value
                                                )
                                              }
                                              value={ticket.quantity}
                                            />
                                            <button
                                              style={{
                                                borderRadius: "0px 4px 4px 0px",
                                                backgroundColor: "#ffffff",
                                              }}
                                              className="btn-increment text-dark px-1 px-sm-2"
                                              onClick={() => {
                                                if (ticket.quantity < 10) {
                                                  handleQuantityChange(
                                                    index,
                                                    ticketIndex,
                                                    ticket.quantity + 1
                                                  );
                                                }
                                              }}
                                            >
                                              <IoMdAddCircleOutline
                                                style={{ color: "#757575" }}
                                              />
                                            </button>
                                          </div>
                                        </td>

                                        <td>
                                          {ticket.symbol} {ticket.row_total}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="text-end fw-bold"
                                    >
                                      Grand Total:
                                    </td>
                                    <td className="fw-bold">
                                      {event?.Total_quantity[0]?.symbol}{" "}
                                      {calculateGrandTotal(
                                        event.Total_quantity
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex justify-content-end mb-5 mb-lg-2">
                <button
                  className={
                    dataSource.selectedAge === "Junior"
                      ? "btn py-1 px-3 act filterBtn m-0 text-nowrap  me-2 text-white"
                      : "btn py-1 px-3 filterBtn m-0 text-nowrap  me-2 brandcolor"
                  }
                  onClick={() => addToChartEvent(true, true)}
                >
                  <p className="m-0 p-0">Add to cart</p>
                </button>

                <button className="button" onClick={onMultipleEventsSubmit}>
                  {dataSource?.buttonName}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <MapPopup
        show={showMap}
        onClose={handleMapClose}
        mapLocation={
          dataSource &&
          dataSource?.eventDetails &&
          dataSource?.eventDetails[0]?.location
        }
      />
    </>
  );
}

export default SeasonEventDetails;
