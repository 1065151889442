import React, { useState, useEffect, useCallback, useContext } from "react";
import { SelectBox } from "devextreme-react";
import { useMutation } from "@tanstack/react-query";
import { ArtistallImg, ORGANISER_URL } from "../Subpages/MainPage/constant";
import { FaFilter } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import NoDataFound from "../global/NoDataFound";
import {
  getSeasonEventAvailableCountries,
  getOrganiserDetailsById,
} from "../Api/api";
import { makeGetRequest, makePostRequest } from "../Api/requestMethods";
import { ERROR, DURATION, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { isValidValue } from "../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { generateAlphabetCharacters } from "../utils/commonUtils";
import { isValidImage } from "../global/ImageCheck.js";
import { imageExists } from "../global/ImageCheck.js";

const SeasonOrganizerLandingPage = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState({
    SeasonEventData: "",

    selectedEventMode: "",
    country: "",
    selectedCountry: "",
    city: "",

    selectedCity: "",
    selectedDate: "",
    startDate: null,
    endDate: null,
    checked: false,

    selectedDate_range: "",
    selectedPricing: "",
  });

  const [organiserCollection, setOrganiserCollection] = useState({
    finalData: [],
    country: "",
    selectedCountry: "",
    state: "",
    //selectedState:"",
    city: "",
    selectedCity: "",
  });

  //   const organiserCollection = [];

  const getAvailCountries = async () =>
    await makeGetRequest(getSeasonEventAvailableCountries);
  const { data: availCountriesDs } = useQuery({
    queryKey: ["__seasonEventsAvailCountries__"],
    queryFn: () => getAvailCountries(),
    onError: (error) => {
      // notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (availCountriesDs && availCountriesDs) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: 101,
      }));
    }
  }, [availCountriesDs]);

  const handleClick = (data) => {
    try {
      const { role_id } = data;
      navigate(
        `/SeasonTickets/OrganizerLandingPage?ci=${organiserCollection.selectedCountry}&r_id=${role_id}`
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (isValidValue(organiserCollection.selectedCountry)) {
          const response = await makeGetRequest(
            getOrganiserDetailsById(organiserCollection.selectedCountry)
          );
          if (response.status === SUCCESS || response) {
            setOrganiserCollection((prev) => ({
              ...prev,
              finalData: response?.data || [],
            }));
          } else {
            // notify(response?.error, ERROR, DURATION);
          }
        }
      } catch (error) {
        // notify(error.response.data.message, ERROR, DURATION);
      }
    })();
  }, [organiserCollection.selectedCountry]);

  const handleCountry = (e) => {
    if (e.value) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setOrganiserCollection((prevState) => ({
        ...prevState,
        city: [],
        selectedCity: "",
      }));
    }
  };



  //dynamicaly changing the URL
  useEffect(() => {
    if (
      organiserCollection.selectedCountry

    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(organiserCollection.selectedCountry),


      });

      const newUrl = `/SeasonTickets/organiserListing?${queryParams}`;
      navigate(newUrl);
    }
  }, [
    organiserCollection.selectedCountry,
    navigate,
  ]);




  return (
    <>
      <div className="filter-page SeasonBG">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Season Tickets
                </h2>
                <h5 className="mt-4 mt-lg-0 text-white">
                  Experience the world famous Chennai kutcheri season's concerts and canteens.
                  <br/>
                  Search by sabha, organiser, canteen.
                </h5>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
            // onClick={doFilter}
            >
              <div
                className="w-100 d-flex align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              ></div>
              <div
                className="filter-button align-items-center"
              // onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              <div className="filterBg pb-2">
                <div className="">
                  <h5>
                    <FaFilter /> Filters
                  </h5>
                </div>
                <div className="mt-4 d-flex flex-column gap-2">
                  <>
                    <h6 className="mb-2">Location</h6>
                    <SelectBox
                      dataSource={availCountriesDs?.data || []}
                      displayExpr="country_name"
                      valueExpr="country_id"
                      label="Select Country"
                      searchEnabled={true}
                      onValueChanged={handleCountry}
                      labelMode="floating"
                      value={organiserCollection?.selectedCountry}
                    />
                  </>
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                <Modal
                  className="filterPopUp"
                  //  show={filter}
                  //  onHide={doFilter}
                  style={{ zIndex: "10001" }}
                >
                  <Modal.Header className="py-1 px-2 border-0" closeButton>
                    <Modal.Title className="ms-2">Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className="mt-4 d-flex flex-column gap-2">
                      <h6 className="mb-2">Location</h6>

                      <SelectBox
                        dataSource={availCountriesDs}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        //    value={organiserCollection.selectedCountry}
                        dropDownOptions={{ container: ".modal" }}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <Button
                      className="px-3 py-1 bg-green text-white btn border-0"
                    //    onClick={doFilter}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                <>
                  {organiserCollection?.finalData &&
                    organiserCollection?.finalData?.length > 0 ? (
                    organiserCollection?.finalData.map((person, index) => (
                      <div className="col" key={index}>
                        <div className="iq-cast rounded-3 overflow-hidden">
                          <div
                            className="img-container artistImgSize"
                            style={{
                              backgroundColor: person.profile_photo
                                ? "transparent"
                                : "#dddddd",
                              color: "#ffffff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {person.role_name === "artist" ? (
                              isValidImage(person.profile_photo) &&
                                imageExists(
                                  `${ArtistallImg}/${person.profile_photo}`
                                ) ? (
                                <img
                                  src={`${ArtistallImg}/${person?.profile_photo}`}
                                  onClick={() => handleClick(person)}
                                  className="img-fluid w-100"
                                  alt={person?.name}
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <>
                                <h1
                                  style={{ color: '#ffffff', fontSize: '4rem', cursor: 'pointer' }}
                                  onClick={() => handleClick(person)}
                                >
                                  {generateAlphabetCharacters(person?.name)}
                                </h1>
                                </>)
                            ) : person.role_name === "organiser" ? (
                              isValidImage(person.profile_photo) &&
                                imageExists(
                                  `${ORGANISER_URL}/${person?.profile_photo}`
                                ) ? (
                                <img
                                  src={`${ORGANISER_URL}/${person?.profile_photo}`}
                                  onClick={() => handleClick(person)}
                                  className="img-fluid w-100"
                                  alt={person?.name}
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <h1
                                  style={{
                                    color: "#ffffff",
                                    fontSize: "4rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleClick(person)}
                                >
                                  {generateAlphabetCharacters(person?.name)}
                                </h1>
                              )
                            ) : null}
                          </div>

                          <div className="card-img-overlay d-flex justify-content-between artistTextBg position-relative">
                            <p
                              className="cast-title fw-500 text-black mb-0"
                              onClick={() => handleClick(person)}
                            >
                              {person.name}
                            </p>
                          </div>
                          {/* <span>
                            {checkinitialFollow && (
                              <button>
                                {checkinitialFollow[`${person?.role_id}`] ===
                                "Unfollow" ? (
                                  <>
                                    <img
                                      src={FollowingIcon}
                                      onClick={() =>
                                        handleFollow(
                                          person.login_id,
                                          person.role_id
                                        )
                                      }
                                      alt="Following"
                                    />
                                  </>
                                ) : (
                                  checkinitialFollow[`${person?.role_id}`] ===
                                    "Follow" && (
                                    <>
                                      <img
                                        src={FollowIcon}
                                        onClick={() =>
                                          handleFollow(
                                            person.login_id,
                                            person.role_id
                                          )
                                        }
                                        alt="Follow"
                                      />
                                    </>
                                  )
                                )}
                              </button>
                            )}
                          </span> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeasonOrganizerLandingPage;
