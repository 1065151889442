import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { IMAGE_URL, ORGANISER_URL } from "../../Subpages/MainPage/constant";
import { BASEPATH } from "../../config/config";
import { FaFilter } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Noimage from "../../global/NoImage.js";
import {
  GET_COUNTRIES_ORGANISER,
  GET_STATES_ORGANISER,
  GET_CITIES_ORGANISER,
  ORGANISER_FILTER,
} from "../../Api/api";
import { SelectBox } from "devextreme-react";
import { Modal, Button, Form } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";

import NoDataFound from "../../global/NoDataFound";
import { generateAlphabetCharacters } from "../../utils/commonUtils.js";

import AuthContext from "../../context/AuthContext.js";
import { GET_FOLLOWING, FOLLOW_A_USER, UNFOLLOW_A_USER } from "../../Api/api";
import HandleErrorToast from "../../Toaster/Failure/Failure.js";
import FollowIcon from "./../../assets/Icons/FollowIcon.svg";
import FollowingIcon from "./../../assets/Icons/FollowingIcon.svg";
import getProfileKey from "../../Api/getProfileKey.js";

const Organisers = () => {
  const PROFILE_KEY = getProfileKey().profileKey;

  const [data, setData] = useState([]);
  const [checkinitialFollow, setCheckInitialFollow] = useState({});
  const [counterfollowing, setcounterfollowing] = useState(0);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("MDnD_auth");
  const location = useLocation();
  const MID = location?.state?.MID;

  //console.log(MID, "MID");

  const [organiserCollection, setOrganiserCollection] = useState({
    finalData: [],
    country: "",
    selectedCountry: "",
    state: "",
    //selectedState:"",
    city: "",
    selectedCity: "",
  });

  const [breadCrumbCollection, setbreadCrumbCollection] = useState({
    breadCrumbCountry: "",
    breadcountryid: "",
    breadCrumbCity: "",
    breadcityid: "",

    check_country: true,
    check_city: true,
  });
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const doFilter = () => {
    setFilter(!filter);
  };

  const encodeBase64 = (str, key) => {
    const combinedStr = key + str.toString();
    return btoa(combinedStr);
  };

  const handleClick = (profileData, role) => {
    let orgName = "";

    if (profileData.organiser_name) {
      for (let i = 0; i < profileData.organiser_name.length; i++) {
        const char = profileData.organiser_name[i];

        if (char !== " " && char !== ".") {
          orgName += char;
        }
      }
    }

    if (orgName) {
      navigate(
        `/organiser/${orgName}?rid=${encodeBase64(
          profileData.role_id,
          PROFILE_KEY
        )}`,
        {
          state: {
            profileData,
            userId: profileData.organiser_id,
            role,
            roleId: profileData.role_id,
          },
        }
      );
    }
  };

  console.log(organiserCollection, "organisercollection");

  // new code added by vignesh....................

  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limit = 15;

  const loadMore = useCallback(async () => {
    if (isFetching || !hasMoreData) {
      //console.log("1st condition");
      return;
    }
    setLoading(true);

    setIsFetching(true);
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${ORGANISER_FILTER}`,
        data: {
          country_id: organiserCollection.selectedCountry
            ? organiserCollection.selectedCountry
            : "",
          city_id: organiserCollection.selectedCity
            ? organiserCollection.selectedCity
            : "",

          limit: limit,
          offset: currentOffset,
        },
        // headers: {
        //   MDnD_auth: `${token}`,
        // },

        timeout: 60000,
        responseType: "json",
      });

      if (response.data.status == "success") {
        setOrganiserCollection((prevState) => {
          const existingOrganiserIds = prevState.finalData
            ? new Set(prevState.finalData?.map((item) => item.organiser_id))
            : "";

          const filteredNewData = existingOrganiserIds
            ? response?.data.data.filter(
                (item) => !existingOrganiserIds.has(item.organiser_id)
              )
            : response?.data.data;

          const isSameData =
            JSON.stringify(prevState?.finalData) ===
            JSON.stringify(filteredNewData);
          return {
            ...prevState,
            finalData:
              currentOffset === 0
                ? response.data.data
                : currentOffset > 0 && !isSameData && hasMoreData
                ? [...prevState.finalData, ...filteredNewData]
                : organiserCollection.finalData,
          };
        });

        setHasMoreData(response.data.data.length === limit);
        setIsFetching(false);  

        if (response?.data?.data?.length > 0) {
          setCurrentOffset((prevOffset) => prevOffset + limit);
        }
      } else {
        // setOrganiserCollection((prevState) => ({
        //   ...prevState,
        //   finalData: [],
        // }));
        setHasMoreData(false);
        setIsFetching(true);
      }

      //setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      // setOrganiserCollection((prevState) => ({
      //   ...prevState,
      //   finalData: [],
      // }));
    } finally {
      setIsFetching(false);
      setLoading(false);
    }
  }, [currentOffset, isFetching, organiserCollection.selectedCity]);

  useEffect(() => {
    if (organiserCollection.selectedCity) {
      loadMore();
    } else {
      return;
    }
  }, [organiserCollection.selectedCity]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.scrollHeight &&
        !isFetching &&
        hasMoreData
      ) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, hasMoreData]);

  //country, state, city...
  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES_ORGANISER);

  const GET_COUNTRY = async (GET_COUNTRIES_ORGANISER) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES_ORGANISER}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country_organiser"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        let filteredArray = data?.data.filter(
          (item) => item.country_id !== 0 && item.country_id !== null
        );

        if (filteredArray) {
          setOrganiserCollection((prevState) => ({
            ...prevState,
            country: filteredArray,
          }));
        }
      } else {
        setOrganiserCollection((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      //setAddress((prevState) => ({ ...prevState, country: ""}));
    },
  });

  //setting initial selected...
  // useEffect(() => {
  //   {
  //     setOrganiserCollection((prevState) => ({
  //       ...prevState,
  //       selectedCountry: 101,
  //       //selectedState: 35,
  //       selectedCity: 3659,
  //     }));
  //   }
  // }, []);

  //initial country

  useEffect(() => {
    if (MID) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: 101,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setOrganiserCollection((prevState) => ({
        ...prevState,

        selectedCountry: Number(params.get("countryid")) || "",
      }));
    }
  }, [location, MID]);


  //initial city

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const urlCityId = Number(params.get("cityid"));

    if (urlCityId && !MID && !organiserCollection.city) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCity: urlCityId,
      }));
    } else if (MID) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCity: 3659,
      }));
    } else if (
      !MID &&
      organiserCollection.selectedCity &&
      breadCrumbCollection.check_city === false
    ) {
      const params = new URLSearchParams(location?.search);

      setOrganiserCollection((prevState) => ({
        ...prevState,

        selectedCity: Number(params.get("cityid")) || "",
      }));
    } else if (
      organiserCollection.city &&
      !MID &&
      !organiserCollection.selectedCity
    ) {
      setOrganiserCollection((prevState) => ({
        ...prevState,

        selectedCity: organiserCollection.city[0]?.city_id,
      }));
    }
  }, [
    location,
    MID,
    breadCrumbCollection.check_city,
    organiserCollection.city,
  ]);

  //states fetch

  //cities fetch..
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_ORGANISER}`,
          {
            country_id: organiserCollection.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          let filteredArray = response?.data?.data.filter(
            (item) => item.city_id !== 0 && item.city_id !== null
          );

          if (filteredArray) {
            setOrganiserCollection((prevState) => ({
              ...prevState,
              city: response.data.data,
            }));
          }
        } else {
          setOrganiserCollection((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching cities for this country", error);
      }
    };
    if (organiserCollection.selectedCountry) {
      GETCITIESDATA();
    }
  }, [organiserCollection.selectedCountry]);

  //onValueChanged functions..................................

  const handleCountry = (e) => {
    if (e) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
        city: [],
        selectedCity: "",
      }));
      const CountryData =
        organiserCollection?.country &&
        organiserCollection?.country.filter(
          (item) => item.country_id === Number(e?.value)
        );

      if (CountryData && CountryData.length > 0) {
        setbreadCrumbCollection((prev) => ({
          ...prev,
          breadCrumbCountry: CountryData[0].country_name,
          check_country: true,
        }));
      }
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  const handleCity = (value) => {
    if (value) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCity: value,
      }));

      const CityData =
        organiserCollection.city &&
        organiserCollection.city.filter(
          (item) => item.city_id === Number(value)
        );

      if (CityData && CityData.length > 0) {
        setbreadCrumbCollection((prev) => ({
          ...prev,
          breadCrumbCity: CityData[0].city_name,
          check_city: true,
        }));
      }
      setHasMoreData(true);
      setIsFetching(false);

      setCurrentOffset(0);

      //setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  //dynamicaly changing the URL
  useEffect(() => {
    if (
      organiserCollection.selectedCountry &&
      organiserCollection.selectedCity
    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(organiserCollection.selectedCountry),
        //stateid: organiserCollection.selectedState ? Number(organiserCollection.selectedState) : "",
        cityid: organiserCollection.selectedCity
          ? Number(organiserCollection.selectedCity)
          : "",
      });

      const newUrl = `/organisers?${queryParams}`;
      navigate(newUrl);
    } else if (
      !breadCrumbCollection.check_country ||
      !breadCrumbCollection.check_city
    ) {
      const queryParams = new URLSearchParams({
        countryid: breadCrumbCollection.breadcountryid
          ? Number(breadCrumbCollection.breadcountryid)
          : "",
        cityid: breadCrumbCollection.breadcityid
          ? Number(breadCrumbCollection.breadcityid)
          : "",
      });

      const newUrl = `/organisers?${queryParams}`;
      navigate(newUrl);
    }
  }, [
    organiserCollection.selectedCountry,

    organiserCollection.selectedCity,
    breadCrumbCollection.check_country,
    breadCrumbCollection.check_city,
    navigate,
  ]);

  console.log(organiserCollection, "organiserCollection");

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  //********************BREAD CRUMBS ******************** */

  useEffect(() => {
    if (
      organiserCollection?.selectedCountry &&
      organiserCollection?.selectedCountry != "" &&
      organiserCollection.country
    ) {
      const filteredData =
        organiserCollection?.country &&
        organiserCollection?.country.filter(
          (item) =>
            item.country_id === Number(organiserCollection.selectedCountry)
        );

      if (filteredData && filteredData.length > 0) {
        //setbreadcrumbCountry(filteredData[0]?.country_name)

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: filteredData[0]?.country_name,
          check_country: true,
          breadcountryid: filteredData[0]?.country_id,
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: "",
          breadcountryid: "",
        }));
      }
    }
  }, [organiserCollection?.selectedCountry, organiserCollection.country]);

  useEffect(() => {
    if (
      organiserCollection?.selectedCity &&
      organiserCollection?.selectedCity !== "" &&
      organiserCollection?.city
    ) {
      const cityData =
        organiserCollection?.city &&
        organiserCollection?.city.filter(
          (item) => item?.city_id === organiserCollection.selectedCity
        );

      if (cityData && cityData.length > 0) {
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: cityData[0].city_name,
          check_city: true,
          breadcityid: cityData[0].city_id,
        }));
      } else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: "",
          breadcityid: "",
        }));
      }
    }
  }, [organiserCollection.selectedCity, organiserCollection.city]);

  const handleBreadCountry = () => {
    console.log("here");
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_country: false,
      breadCrumbCountry: "",
      breadcountryid: "",

      breadcityid: "",
      check_city: false,
      breadCrumbCity: "",
    }));

    setOrganiserCollection((prevState) => ({
      ...prevState,
      selectedCountry: "",
      city: [],
      selectedCity: "",

      finalData: [],
    }));
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
  };

  const handleBreadCity = () => {
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_city: false,
      breadCrumbCity: "",
      breadcityid: "",
    }));
    setOrganiserCollection((prevState) => ({
      ...prevState,
      selectedCity: "",
      finalData: [],
    }));
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
  };

  console.log(breadCrumbCollection, "breadCrumbCollection");

  //FOLLOW/UNFOLLOW........................
  const handleFollow = (loginid, role_id) => {
    if (user) {
      if (checkinitialFollow[role_id] === "Follow") {
        mutationFollow.mutate({ loginid, role_id });
      } else if (checkinitialFollow[role_id] === "Unfollow") {
        mutationUnfollow.mutate({ loginid, role_id });
      } else if (Object.entries(checkinitialFollow).length === 0) {
        mutationFollow.mutate({ loginid, role_id });
      }
    } else {
      HandleErrorToast("error", "Please Login to Continue", 4000);
      return;
    }
  };

  const Follow_function = (loginid, role_id) =>
    FollowtoBackend(FOLLOW_A_USER, loginid, role_id);

  const FollowtoBackend = async (FOLLOW_A_USER, loginid, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? Number(user.login_id) : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? Number(user.role_id) : "",
          user2_roleid: role_id ? Number(role_id) : "",
          follower_roleid: user ? Number(user.role_id) : "",
          following_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationFollow = useMutation({
    mutationKey: ["FollowUser"],
    mutationFn: ({ loginid, role_id }) => Follow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        //setcounterforinitialStatus(counterforinitialStatus + 1);
        checkinitialFollow[data?.data?.following_role_id] = "Unfollow";
        setcounterfollowing(counterfollowing + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching follow", error);
    },
  });

  // UNFOLLOW...
  const UnFollow_function = (loginid, role_id) =>
    UnFollowtoBackend(UNFOLLOW_A_USER, loginid, role_id);

  const UnFollowtoBackend = async (UNFOLLOW_A_USER, loginid, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${UNFOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: role_id ? Number(role_id) : "",
          unfollower_roleid: user ? user.role_id : "",
          unfollowing_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationUnfollow = useMutation({
    mutationKey: ["UnFollowUser"],
    mutationFn: ({ loginid, role_id }) => UnFollow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        checkinitialFollow[data?.data?.unfollowing_role_id] = "Follow";
        setcounterfollowing(counterfollowing + 1);
      } else {
      }
    },
    onError: (error) => {
      console.error("Error fetching follow:", error);
    },
  });

  // new code for FOLLOW/unfollow...

  const [followCollection, setFollowCollection] = useState({
    following: "",
  });

  useEffect(() => {
    if (user) {
      mutationfetchFollowingList.mutate();
    } else {
      return;
    }
  }, [counterfollowing]);

  const Getting_Following = () => GET_FOLLOWING_ALL(GET_FOLLOWING);

  const GET_FOLLOWING_ALL = async (GET_FOLLOWING) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FOLLOWING}`,
        data: {
          role_id: user ? user.role_id : "",
        },

        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationfetchFollowingList = useMutation({
    mutationKey: ["get_All_following"],
    mutationFn: (fid) => Getting_Following(),

    onSuccess: (data) => {
      let filteredData =
        data?.data &&
        data?.data.filter(
          (item) => item.role_name !== null && item.role_name !== ""
        );

      const uniqueData =
        filteredData &&
        filteredData?.reduce((acc, current) => {
          const duplicate = acc.find(
            (item) => item.following_id === current.following_id
          );

          if (!duplicate) {
            acc.push(current);
          }

          return acc;
        }, []);

      if (data.Status === "success") {
        if (uniqueData) {
          setFollowCollection((prevState) => ({
            ...prevState,
            following: uniqueData,
          }));
        }
      } else {
        setFollowCollection((prevState) => ({
          ...prevState,
          following: uniqueData ? uniqueData : "",
        }));
      }
    },
    onError: (error) => {
      setFollowCollection((prevState) => ({ ...prevState, following: "" }));
    },
  });

  console.log(followCollection, "followCollection");

  //checkinitialfollow set............

  useEffect(() => {
    if (
      followCollection.following &&
      followCollection.following.length > 0 &&
      organiserCollection.finalData &&
      organiserCollection.finalData.length > 0
    ) {
      const newCheckInitialFollow = { ...checkinitialFollow };

      organiserCollection.finalData.map((Item) => {
        let roleId = Number(Item["role_id"]);
        const filteredIndex = followCollection.following.findIndex(
          (item) => item.following_id === roleId
        );

        if (filteredIndex !== -1) {
          newCheckInitialFollow[roleId] = "Unfollow";
        } else {
          newCheckInitialFollow[roleId] = "Follow";
        }
      });

      if (newCheckInitialFollow) {
        setCheckInitialFollow((prevState) => ({
          ...prevState,
          ...newCheckInitialFollow,
        }));
      }
    }
  }, [followCollection.following, organiserCollection.finalData]);

  console.log(checkinitialFollow, "checkinitialfollow");

  return (
    <>
      <div className="filter-page iq-breadcrumb Organiser-Banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Organisers
                </h2>
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  All organiser profiles will be up in a few days. Thank you for
                  your patience.
                </h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

    {/*<section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
              onClick={doFilter}
            >

              <div
                className="w-100 d-flex align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              >

                {breadCrumbCollection.check_country &&
                  breadCrumbCollection?.breadCrumbCountry !== '' &&
                  <>
                    <button
                      //className={breadCrumbCollection.breadCrumbCurrent === "Country" ? "button" : undefined}
                      //style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      className=""
                    //onClick={handleBreadCountry}
                    >
                      {breadCrumbCollection?.breadCrumbCountry || ''}
                    </button>
                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i></>
                }


                {breadCrumbCollection.check_city &&
                  breadCrumbCollection?.breadCrumbCity !== '' &&
                  <><button
                    //className={breadCrumbCollection.breadCrumbCurrent === "Country" ? "button" : undefined}
                    //style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                    className=""
                    onClick={handleBreadCity}
                  >
                    {breadCrumbCollection?.breadCrumbCity || ''}
                  </button>
                  </>
                }

              </div>
              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              <div className="filterBg pb-2">
                <div className="">
                  <h5>
                    <FaFilter /> Filters
                  </h5>
                </div>
                <div className="mt-4 d-flex flex-column gap-2">

                  <>
                    <h6 className="mb-2">Location</h6>
                    <SelectBox
                      dataSource={organiserCollection.country}
                      displayExpr="country_name"
                      valueExpr="country_id"
                      label="Select Country"
                      searchEnabled={true}
                      onValueChanged={handleCountry}
                      labelMode="floating"
                      value={organiserCollection.selectedCountry}
                    />


                    <SelectBox
                      dataSource={organiserCollection.city || []}
                      label="Select City"
                      labelMode="floating"
                      displayExpr="city_name"
                      valueExpr="city_id"
                      searchEnabled={true}
                      onValueChanged={(e) => handleCity(e.value)}
                      value={organiserCollection.selectedCity}
                    />
                  </>

                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                <Modal
                  className="filterPopUp"
                  show={filter}
                  onHide={doFilter}
                  style={{ zIndex: "10001" }}
                >
                  <Modal.Header className="py-1 px-2 border-0" closeButton>
                    <Modal.Title className="ms-2">Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className="mt-4 d-flex flex-column gap-2">
                      <h6 className="mb-2">Location</h6>

                      <SelectBox
                        dataSource={organiserCollection.country}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        value={organiserCollection.selectedCountry}
                        dropDownOptions={{ container: ".modal" }}

                      />

                      <SelectBox
                        dataSource={organiserCollection.city || []}
                        label="Select City"
                        labelMode="floating"
                        displayExpr="city_name"
                        valueExpr="city_id"
                        searchEnabled={true}
                        onValueChanged={(e) => handleCity(e.value)}
                        value={organiserCollection.selectedCity}
                        dropDownOptions={{ container: ".modal" }}

                      //ref={element => inputRefs.current[key] = element}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <Button
                      className="px-3 py-1 bg-green text-white btn border-0"
                      onClick={doFilter}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal>

              </div>

              <div className="d-none d-lg-block mb-2">
                <div
                  className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                  style={{ gap: "2px", overflow: "scroll", whiteSpace: "nowrap" }}
                >

                  {breadCrumbCollection.check_country &&
                    breadCrumbCollection?.breadCrumbCountry !== '' &&
                    <>
                      <p className="m-0 p-0" style={{ flexShrink: 0 }}
                      //onClick={handleBreadCountry}
                      >
                        {breadCrumbCollection?.breadCrumbCountry || ''}
                      </p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i></>
                  }


                  {breadCrumbCollection.check_city &&
                    breadCrumbCollection?.breadCrumbCity !== '' &&
                    <> <p className="m-0 p-0" style={{ color: "#3F00FF", whiteSpace: "nowrap" }}
                    //onClick={handleBreadCity}
                    >
                      {breadCrumbCollection?.breadCrumbCity || ''}
                    </p>
                    </>
                  }


                </div>
              </div>
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                
                  <>
                    {organiserCollection.finalData && organiserCollection.finalData.length > 0 ? (
                      organiserCollection.finalData.map((person) => (
                        <div className="col" key={person.organiser_id}>
                          <div className="iq-cast rounded-3 overflow-hidden">
                            <div
                              className="img-container artistImgSize"
                              style={{
                                backgroundColor: person.organiser_photo ? 'transparent' : '#dddddd',
                                color: '#ffffff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              {person.organiser_photo ? (
                                <img
                                  src={`${ORGANISER_URL}/${person.organiser_photo}`}
                                  onClick={() => handleClick(person, "organiser")}
                                  className="img-fluid w-100"
                                  alt={person.organiser_name}
                                  style={{ objectFit: 'cover', height: '100%', cursor: 'pointer' }}
                                />
                              ) : (
                                <h1 
                                style={{ color: '#ffffff', fontSize: '4rem', cursor: 'pointer' }}
                                onClick={() => handleClick(person, "organiser")}
                                >
                                  {generateAlphabetCharacters(person?.organiser_name)}
                                </h1>
                              )}
                            </div>

                            <div className="card-img-overlay d-flex justify-content-between artistTextBg position-relative">
                              <p
                                className="cast-title fw-500 text-black mb-0"
                                onClick={() => handleClick(person, "organiser")}
                              >
                                {person.organiser_name}
                              </p>
                              
                            </div>
                            <span>
                              { checkinitialFollow &&
                                <button>
                                  {checkinitialFollow[`${person?.role_id}`] === "Unfollow" ? (
                                    <>
                                    <img
                                      src={FollowingIcon}
                                      onClick={() => handleFollow(person.login_id, person.role_id)}
                                      alt="Following"
                                    />
                                    </>
                                  ) :
                                  checkinitialFollow[`${person?.role_id}`] === "Follow" &&
                                   (
                                    <>
                                    <img
                                      src={FollowIcon}
                                      onClick={() => handleFollow(person.login_id, person.role_id)}
                                      alt="Follow"
                                    />
                                    </>
                                  )}
                                </button>}
                              </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFound />
                    )}
                  </>
               


              </div>

            </div>
          </div>
        </div>
      
    </section > */}     
    </>
  );
};

export default Organisers;
