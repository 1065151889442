import React from "react";
import { useState } from "react";
import Button from "devextreme-react/button";
import { NumberBox, TextBox, SelectBox } from "devextreme-react";
import { useFormik } from "formik";
import { addPromoCodeValidationSchema } from "./Validation/Auth";
import { DiscountType } from "../../../global/constants";
import notify from "devextreme/ui/notify";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { makePostRequest } from "../../../Api/requestMethods";
import { PROMOCODE_CREATE_API } from "../../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
const initialValues = {
  promo_code: "",
  description: "",
  discount_type: "",
  discount_value: "",
};

const CreatePromoCode = () => {
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addPromoCodeValidationSchema,
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
        role_id: user?.role_id,
      };

      try {
        await promocodeDetailMutation.mutateAsync(updatedValues);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleOptionChange = (option) => {
    handleChange({ target: { name: "discount_type", value: option } });
    setFieldValue("discount_value", "");
    setFieldValue("discount_value_percent", "");
  };

  // --------------------------------------Handle Mutate in Promocode-----------
  const promocodeFunction = async (data) => {
    try {
      const response = await makePostRequest(PROMOCODE_CREATE_API(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const promocodeDetailMutation = useMutation({
    mutationKey: ["promodetail"],
    mutationFn: (promodata) => promocodeFunction(promodata),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  return (
    <form className="tab-content tabBodyBg" onSubmit={handleSubmit}>
      <div className="tab-content tabBodyBg">
        <div
          id="createevent"
          className="tab-pane animated fadeInUp active show"
          role="tabpanel"
        >
          <div className="description-content">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <TextBox
                    type="text"
                    name="promo_code"
                    className="dx-field-item-required"
                    required="required"
                    labelMode="floating"
                    label="Promocode"
                    value={values.promo_code}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "promo_code", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "promo_code", value: e.value },
                      })
                    }
                  />
                  {errors.promo_code && touched.promo_code && (
                    <div className="text-danger">{errors.promo_code}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <TextBox
                    type="text"
                    name="description"
                    className="dx-field-item-required"
                    required="required"
                    labelMode="floating"
                    label="Description"
                    value={values.description}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "description", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "description", value: e.value },
                      })
                    }
                  />
                  {errors.description && touched.description && (
                    <div className="text-danger">{errors.description}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <SelectBox
                    className="dx-field-item-required"
                    name="discount_type"
                    displayExpr="type"
                    valueExpr="value"
                    labelMode="floating"
                    dataSource={DiscountType}
                    label="Discount Type"
                    onValueChanged={(e) => handleOptionChange(e.value)}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "discount_type", value: e.value },
                      })
                    }
                  />
                  {errors.discount_type && touched.discount_type && (
                    <div className="text-danger">{errors.discount_type}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  {values.discount_type === "% of ticket value" ? (
                    <NumberBox
                      name="discount_value"
                      className="dx-field-item-required"
                      required="required"
                      labelMode="floating"
                      label="Discount Value (%)"
                      value={values.discount_value}
                      min={0}
                      max={100}
                      showSpinButtons={true}
                      onValueChanged={(e) =>
                        setFieldValue("discount_value", e.value)
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "discount_value", value: e.value },
                        })
                      }
                    />
                  ) : (
                    <NumberBox
                      type="text"
                      name="discount_value"
                      className="dx-field-item-required"
                      required="required"
                      labelMode="floating"
                      label="Discount Value"
                      value={values.discount_value}
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "discount_value", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "discount_value", value: e.value },
                        })
                      }
                    />
                  )}
                  {errors.discount_value && touched.discount_value && (
                    <div className="text-danger">{errors.discount_value}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="iq-button">
          <Button
            type="submit"
            text="Submit"
            className="btn position-relative h-auto"
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};

export default CreatePromoCode;
