import React, { useEffect, useRef, useState } from "react";
import { GOOGLE_API_KEY } from "../../global/constants";
import "./googlePlaces.css";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput = ({
  setSelectedLocation,
  setFieldValue,
  tempLocationData,
}) => {
  const [query, setQuery] = useState(tempLocationData || "");
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });

    // Populate location if tempLocationData is provided
    if (tempLocationData) {
      setQuery(tempLocationData);
      // Trigger a new search in Google Places for the current query
      setTimeout(() => {
        const googlePlacesService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );

        // Perform a text search to simulate the first option selection
        googlePlacesService.textSearch(
          { query: tempLocationData },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const firstPlace = results[0];
              if (firstPlace) {
                setQuery(firstPlace.formatted_address);
                setFieldValue("tempLocationData", firstPlace.formatted_address);

                const latLng = {
                  lat: firstPlace.geometry.location.lat(),
                  lng: firstPlace.geometry.location.lng(),
                };
                setSelectedLocation(latLng);
              }
            }
          }
        );
      }, 500); // Delay to ensure the input is loaded and script is ready
    }
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setFieldValue("tempLocationData", query);

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    setSelectedLocation(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        className="form-control mapLocation"
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Location"
        value={query}
      />
    </div>
  );
};

export default SearchLocationInput;
