import React, { useEffect, useState, useContext } from "react";
import "../assets/vendor/font-awesome/css/all.min.css";
import "../assets/images/favicon.ico";
import "../assets/css/core/libs.min.css";
import "../assets/vendor/font-awesome/css/all.min.css";
import "../assets/vendor/iconly/css/style.css";
import "../assets/vendor/animate.min.css";
import "../assets/vendor/swiperSlider/swiper.min.css";
import "../assets/vendor/sweetalert2/sweetalert2.min.css";
import "../assets/css/streamit.min.css?v=1.0.0";
import "../assets/css/rtl.min.css?v=1.0.0";
import "../assets/css/custom.min.css?v=1.0.0";
import "../assets/css/streamit.css";
import { IoMoonSharp } from "react-icons/io5";
import { IoSunny } from "react-icons/io5";
import navItems from "./constant";
import { Link, NavLink } from "react-router-dom";
import { SelectBox } from "devextreme-react/select-box";
import "./Header.css";
import Logo from "./Static/logo.png";
import { VscThreeBars } from "react-icons/vsc";
import { FiShoppingCart } from "react-icons/fi";
import { IoPersonOutline } from "react-icons/io5";
import { FiX } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { useTheme } from "../Theme/themeProvider";
import AuthContext from "../context/AuthContext";
import DarkModeLogo from "./Static/logoWhite.png";
import { GET_USER_PROFILE_DETAILS } from "../Api/api";
import { jwtDecode } from "jwt-decode";
import { makePostRequest, makeGetRequest } from "../Api/requestMethods";
import { useMutation } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { ERROR, DURATION, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { GlobalContext } from "../global/provider.js";
import {
  ArtistallImg,
  IMAGE_URL,
  Fan_images,
  AdvertiserImg,
  ORGANISER_URL,
} from "../Subpages/MainPage/constant.js";
import { headerDisabledPath } from "../global/constants.js";
import { HeadProvider, Meta } from "react-head";
import FirstLetterAvatar from "../components/firstLetterAvatar.js";
import { BASEPATH } from "../config/config.js";
import axios from "axios";
import { SWITCH_ROLE } from "../Api/api";
import { useNavigate } from "react-router-dom";
import { PiUserSwitchDuotone } from "react-icons/pi";
import { getCartCount } from "../Api/api";
import cartSvg from "../assets/images/Cart.svg";
import LanguageSwitcher from "../components/GoogleTranslate.js";
import { useLocation, useSearchParams } from "react-router-dom";
import { GET_POSTS_PROFILE } from "../Api/api";
import { GET_IMAGES_PROFILE, GET_DETAILED_FORM_FROM_BACKEND } from "../Api/api";
import { useMemo } from "react";
import { GET_NAMES_BY_LOGINID } from "../Api/api";
import { generateAlphabetCharacters } from "../utils/commonUtils.js";
import Noimage_circle from "../global/NoImage_circle.js";
import { useScreenDetector } from "../components/deviceHook.js";
import { v4 as uuidv4 } from "uuid";

const Header = () => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  const sessionId = localStorage.getItem("mdnd_cId");
  const { theme, toggleTheme, isDarkMode } = useTheme();
  const { user, logoutUser, setUser } = useContext(AuthContext);
  const [switchroleCollection, setSwitchRoleCollection] = useState({
    dataSource: "",
    selectedRole: "",
    filteredDataSource: "",
  });

  const location = useLocation();

  //*******new code */
  const [searchtext, setSearchText] = useState("");
  const token = localStorage.getItem("MDnD_auth");

  //**end of new code */
  const navigate = useNavigate();
  const {
    userDetails,
    setUserDetails,
    cartDetails,
    setCartDetails,
    eventsDatasource,
  } = useContext(GlobalContext);
  const toggleMode = () => {
    toggleTheme();
  };

  const [userImage, setUserImage] = useState("");
  const [isActive, setIsActive] = useState(false);
  const handleOpenMenu = () => {
    setIsActive(true);
  };
  const handleCloseMenu = () => {
    setIsActive(false);
  };
  const [activeNavItem, setActiveNavItem] = useState(null);
  const handleNavItemClick = (index) => {
    setActiveNavItem(index);

    handleCloseMenu();
  };

  const getProfileDetails = async (userId) => {
    try {
      if (userId) {
        const response = await makePostRequest(
          GET_USER_PROFILE_DETAILS,
          userId
        );
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  const profileMutation = useMutation({
    mutationFn: (userId) =>
      userId !== "" && userId !== null && getProfileDetails(userId),
    onSuccess: (data) => {
      setUserDetails((prev) => ({
        ...prev,
        profileDetails: data?.data[0],
      }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    var uniqueId;
    try {
      const getCartCountfN = async () => {
        uniqueId = localStorage.getItem("mdnd_cId"); // gettting Session Id from the local storage
        if (!uniqueId) {
          // if the session id is not exisits then this block will create an new session ID
          uniqueId = uuidv4();
          localStorage.setItem("mdnd_cId", uniqueId); // setting session id into the local storage
        }
        const body = {
          role_id: Number(user?.role_id) || null,
          session_id: !user?.role_id && !user?.login_id ? uniqueId : null,
        };
        const response = await makePostRequest(getCartCount, body);
        if (response && response.status === SUCCESS) {
          const cartCount = response?.data && response?.data?.cart_count;
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        } else {
          notify(response.error.response.data.message, ERROR, DURATION);
        }
      };
      getCartCountfN();
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const body = {
        roleid: user.role_id,
      };
      profileMutation.mutate(body);
    }
  }, [user]);

  useEffect(() => {
    var newImagePath = "";
    if (user?.rolename === "artist") {
      //newImagePath = AristiImg;
      newImagePath = ArtistallImg;
    }
    if (user?.rolename === "organiser") {
      newImagePath = ORGANISER_URL;
    }
    if (user?.rolename === "fan") {
      newImagePath = Fan_images;
    }
    //newly added
    if (user?.rolename === "advertiser") {
      newImagePath = AdvertiserImg;
    }
    setUserImage(`${newImagePath}/${userDetails?.profileDetails?.photo}`);
  }, [userDetails.profileDetails]);

  const roles = ["organiser", "artist", "fan"];

  const [NamesCollection, setNamesCollection] = useState({
    list_names: "",
  });

  useEffect(() => {
    if (user) {
      mutationGetNames.mutate();
    } else {
      return;
    }
  }, [user]);

  const Getting_Names = () => GET_NAMES_ALL(GET_NAMES_BY_LOGINID);

  const GET_NAMES_ALL = async (GET_NAMES_BY_LOGINID) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_NAMES_BY_LOGINID}`,
        data: {
          login_id: user ? user.login_id : 0,
        },
        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationGetNames = useMutation({
    mutationKey: ["get_list_names"],
    mutationFn: () => Getting_Names(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setNamesCollection((prevState) => ({
          ...prevState,
          list_names: data?.data,
        }));
      } else {
        setNamesCollection((prevState) => ({ ...prevState, list_names: "" }));
      }
    },
    onError: (error) => {
      setNamesCollection((prevState) => ({ ...prevState, list_names: "" }));
    },
  });



  useEffect(() => {
    if (switchroleCollection.dataSource && NamesCollection.list_names) {
      if ("artist" in NamesCollection.list_names) {
        let copyArtistArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterArtistindex =
          copyArtistArray &&
          copyArtistArray.findIndex((item) => item.rolename === "artist");

        if (
          filterArtistindex !== -1 &&
          NamesCollection.list_names?.artist?.artistname
        ) {
          copyArtistArray[filterArtistindex]["displayname"] = `${
            NamesCollection.list_names?.artist?.artistname
          } ( ${capitalizeFirstLetter("artist")} )`;

          if (copyArtistArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyArtistArray,
            }));
          }
        } else {
          copyArtistArray[filterArtistindex][
            "displayname"
          ] = `No Name ( ${capitalizeFirstLetter("artist")} )`;

          if (copyArtistArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyArtistArray,
            }));
          }
        }
      } else {
        let copyArtistArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterArtistindex =
          copyArtistArray &&
          copyArtistArray.findIndex((item) => item.rolename === "artist");

        if (filterArtistindex !== -1) {
          copyArtistArray[filterArtistindex][
            "displayname"
          ] = `Create Artist Profile`;

          if (copyArtistArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyArtistArray,
            }));
          }
        }
      }

      if ("organiser" in NamesCollection.list_names) {
        let copyOrganiserArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterOrganiserindex =
          copyOrganiserArray &&
          copyOrganiserArray.findIndex((item) => item.rolename === "organiser");

        if (
          filterOrganiserindex !== -1 &&
          NamesCollection.list_names?.organiser?.organisername
        ) {
          copyOrganiserArray[filterOrganiserindex]["displayname"] = `${
            NamesCollection.list_names?.organiser?.organisername
          } ( ${capitalizeFirstLetter("organiser")} )`;

          if (copyOrganiserArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyOrganiserArray,
            }));
          }
        } else {
          copyOrganiserArray[filterOrganiserindex][
            "displayname"
          ] = `No Name ( ${capitalizeFirstLetter("organiser")} )`;

          if (copyOrganiserArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyOrganiserArray,
            }));
          }
        }
      } else {
        let copyOrganiserArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterOrganiserindex =
          copyOrganiserArray &&
          copyOrganiserArray.findIndex((item) => item.rolename === "organiser");

        if (filterOrganiserindex !== -1) {
          copyOrganiserArray[filterOrganiserindex][
            "displayname"
          ] = `Create Organiser Profile`;

          if (copyOrganiserArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyOrganiserArray,
            }));
          }
        }
      }

      if ("fan" in NamesCollection.list_names) {
        let copyFanArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterFanindex =
          copyFanArray &&
          copyFanArray.findIndex((item) => item.rolename === "fan");

        if (filterFanindex !== -1 && NamesCollection.list_names?.fan?.fanname) {
          copyFanArray[filterFanindex]["displayname"] = `${
            NamesCollection.list_names?.fan?.fanname
          } ( ${capitalizeFirstLetter("fan")} )`;

          if (copyFanArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyFanArray,
            }));
          }
        } else {
          copyFanArray[filterFanindex][
            "displayname"
          ] = `No Name ( ${capitalizeFirstLetter("fan")} )`;

          if (copyFanArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyFanArray,
            }));
          }
        }
      } else {
        let copyFanArray = switchroleCollection.dataSource
          ? [...switchroleCollection.dataSource]
          : "";

        const filterFanindex =
          copyFanArray &&
          copyFanArray.findIndex((item) => item.rolename === "fan");

        if (filterFanindex !== -1) {
          copyFanArray[filterFanindex]["displayname"] = `Create Fan Profile`;

          if (copyFanArray) {
            setSwitchRoleCollection((prevState) => ({
              ...prevState,
              filteredDataSource: copyFanArray,
            }));
          }
        }
      }
    }
  }, [switchroleCollection.dataSource, NamesCollection.list_names]);

  const getUserData = (user) => {
    if (user) {
      const currentRoleIndex = roles.indexOf(user.rolename);
      let data = [];
      data.push({
        id: 1,
        rolename: user.rolename,
        displayname: `${
          userDetails?.profileDetails?.name
        } ( ${capitalizeFirstLetter(user.rolename)} )`,
      });

      for (let i = 1; i < roles.length; i++) {
        const nextIndex = (currentRoleIndex + i) % roles.length;
        data.push({
          id: i + 1,
          rolename: roles[nextIndex],
          displayname: `${
            userDetails?.profileDetails?.name
          } ( ${capitalizeFirstLetter(roles[nextIndex])} )`,
        });
      }

      return data;
    } else {
      return "";
    }
  };

  useEffect(() => {
    const createDataSource = async () => {
      let dataX = getUserData(user);
      if (dataX) {
        setSwitchRoleCollection((prev) => ({ ...prev, dataSource: dataX }));
      }
    };

    if (userDetails) {
      createDataSource();
    }
  }, [user, userDetails]);

  const handleSwitchRole = (e) => {
    setSwitchRoleCollection((prev) => ({ ...prev, selectedRole: e?.value }));

    if (e?.value) {
      mutationSwitchRole.mutate(e.value);
      setSwitchRoleCollection((prev) => ({ ...prev, show: false }));
    } else {
      return;
    }
  };

  const SWITCHTHEROLE = (rolename) => SWITCHROLE(SWITCH_ROLE, rolename);

  const mutationSwitchRole = useMutation({
    mutationKey: ["fetchPostsProfile"],
    mutationFn: (rolename) => SWITCHTHEROLE(rolename),
    onSuccess: (data) => {
      if (data.status === "success") {
        setUser(jwtDecode(data.MDnD_auth));
        localStorage.setItem("MDnD_auth", data.MDnD_auth);
        navigate(`/dashboard`);
      } else if (data.status === "failed") {
        setSwitchRoleCollection((prev) => ({ ...prev, selectedRole: "" }));
      }
    },
    onError: (error) => {
      let selectedRole = switchroleCollection.selectedRole;
      navigate("/confirmRegistration", {
        state: { rolename: selectedRole, loginid_user: user?.login_id },
      });
    },
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const SWITCHROLE = async (SWITCH_ROLE, rolename) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${SWITCH_ROLE}`,

        data: {
          loginid: user ? Number(user.login_id) : "",
          rolename: rolename,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const navigateEvent = () => {
    navigate("/myCart");
  };

  //*** new code */

  const handlesearchtext = (e) => {
    setSearchText(e?.target.value);
  };

  const handleSearch = () => {
    if (searchtext && searchtext.length > 0) {
      navigate("/GlobalSearch", { state: { searchtext } });
    } else {
      return;
    }
  };

  //** end of new code */

  const currentLocation = location.pathname;

  const handleregister = () => {
    navigate("/RegisterHere");
  };
 

  return (
    <>
      <main className="main-content">
        <>
          {currentLocation !== headerDisabledPath && (
            <>
              <header className="header-center-home header-merchandise ">
                <div
                  className={`header-top ${
                    isDarkMode ? "bg-black" : "bg-white"
                  } d-lg-block d-none`}
                >
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      <div className="col-md-3  px-lg-4">
                        <div className="d-flex gap-3">
                          <header className="header">
                            <nav className="navbar">
                              <div
                                className={`burger ${isActive ? "active" : ""}`}
                                id="burger"
                                onClick={handleOpenMenu}
                              >
                                <span
                                  className={`burger-open ${
                                    isDarkMode ? "text-white" : "text-black"
                                  }`}
                                >
                                  <FiMenu className="w-100 h-100" />
                                </span>
                                <span
                                  className="burger-close"
                                  onClick={handleCloseMenu}
                                >
                                  <FiX />
                                </span>
                              </div>
                              <div
                                className="menu-container"
                                onMouseLeave={handleCloseMenu}
                              >
                                <ul
                                  className={`menu p-0 pt-5 ${
                                    isActive ? "active" : ""
                                  } menu-link  `}
                                  activeClassName="active"
                                  id="menu"
                                  onMouseLeave={handleCloseMenu}
                                >
                                  {navItems.map((item, index) => (
                                    <li
                                      key={index}
                                      className={`menu-item mt-2 menu-link ${
                                        activeNavItem === index ? "active" : ""
                                      }`}
                                      activeClassName="active"
                                      onClick={() => handleNavItemClick(index)}
                                    >
                                      <NavLink
                                        exact={item.exact}
                                        to={item.path}
                                        className={`pb-2 ${
                                          activeNavItem === index
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleNavItemClick(index)
                                        }
                                      >
                                        {item.title}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </nav>
                          </header>

                          <div className="logo-default ms-2">
                            <NavLink exact to="/" activeClassName="active">
                              <img
                                className=" logo mdnd-logo"
                                src={isDarkMode ? DarkModeLogo : Logo}
                                loading="lazy"
                                alt="streamit"
                              />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex gap-4 align-items-center justify-content-center">
                          <div className="wrap w-75">
                            <div className="search">
                              <input
                                type="text"
                                className="searchTerm"
                                placeholder="Search events, artists, videos, organisers, franshisees and more"
                                id="input_text"
                                onChange={handlesearchtext}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleSearch();
                                  }
                                }}
                              ></input>
                              <button type="submit" className="searchButton" onClick={handleSearch}>
                                <i
                                  className="fa fa-search"
                                  onClick={handleSearch}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <ul className="d-flex align-items-center justify-content-end gap-4 list-inline mb-0">
                          <LanguageSwitcher />
                          <li>
                            <div
                              onClick={navigateEvent}
                              className={`${
                                isDarkMode ? "text-white" : "text-black"
                              } cartValue`}
                              data-bs-toggle="offcanvas"
                            >
                              <span>
                                <img className="cartIcon" src={cartSvg}></img>
                                <span className="cartvaluePostion">
                                  {cartDetails?.cartValue || 0}
                                </span>
                              </span>
                            </div>
                          </li>

                          <li>
                            {user ? (
                              <li
                                className="nav-item dropdown"
                                id="itemdropdown1"
                              >
                                <a
                                  className="nav-link d-flex align-items-center"
                                  href="#"
                                  id="navbarDropdown"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="btn-icon rounded-pill user-icons">
                                    <span className="btn-inner">
                                      {userDetails?.profileDetails.photo ===
                                        "" ||
                                      userDetails?.profileDetails?.photo ===
                                        null ? (
                                        <FirstLetterAvatar
                                          data={userDetails?.profileDetails}
                                        />
                                      ) : (
                                        <img
                                          src={userImage}
                                          className="img-fluid rounded-circle"
                                          alt=""
                                          loading="lazy"
                                        />
                                      )}
                                    </span>
                                  </div>
                                </a>
                                <ul
                                  className="dropdown-menu dropdown-menu-end dropdown-user border-0 p-0 m-0"
                                  style={{ width: "240px" }}
                                  aria-labelledby="navbarDropdown"
                                >
                                  <li className="user-info d-flex align-items-center gap-3 mb-0">
                                    {userDetails?.profileDetails?.photo ===
                                      "" ||
                                    userDetails?.profileDetails?.photo ===
                                      null ? (
                                      <FirstLetterAvatar
                                        data={userDetails?.profileDetails}
                                      />
                                    ) : (
                                      <img
                                        src={userImage}
                                        className="img-fluid rounded-circle"
                                        alt=""
                                        loading="lazy"
                                      />
                                    )}
                                    <span className="font-size-14 fw-500 text-capitalize text-black">
                                      {userDetails?.profileDetails?.name}
                                      <br></br>
                                      <span className="d-flex justify-content-between align-items-center gap-1">
                                        <p className="mb-0">{user?.rolename}</p>
                                      </span>
                                    </span>
                                  </li>
                                  {user?.rolename !== "advertiser" && (
                                    <div>
                                      <SelectBox
                                        dataSource={
                                          switchroleCollection.filteredDataSource
                                            ? switchroleCollection.filteredDataSource.filter(
                                                (role) =>
                                                  role.rolename !==
                                                  user.rolename
                                              )
                                            : null
                                        }
                                        valueExpr="rolename"
                                        placeholder="Switch Role"
                                        searchEnabled={false}
                                        displayExpr="displayname"
                                        value={
                                          switchroleCollection.selectedRole
                                        }
                                        onValueChanged={handleSwitchRole}
                                      />
                                    </div>
                                  )}

                                  <li>
                                    <NavLink to="dashboard">
                                      <a
                                        href="#"
                                        className="iq-sub-card d-flex align-items-center gap-3"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 16 22"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.84455 20.6621C4.15273 20.6621 1 20.0876 1 17.7868C1 15.486 4.13273 13.3621 7.84455 13.3621C11.5364 13.3621 14.6891 15.4654 14.6891 17.7662C14.6891 20.066 11.5564 20.6621 7.84455 20.6621Z"
                                            stroke="black"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.83725 10.1738C10.26 10.1738 12.2236 8.21015 12.2236 5.78742C12.2236 3.36469 10.26 1.40015 7.83725 1.40015C5.41452 1.40015 3.44998 3.36469 3.44998 5.78742C3.4418 8.20196 5.3918 10.1656 7.80634 10.1738C7.81725 10.1738 7.82725 10.1738 7.83725 10.1738Z"
                                            stroke="black"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        <h6 className="mb-0 font-size-14 fw-normal">
                                          My Account
                                        </h6>
                                      </a>
                                    </NavLink>
                                  </li>
                                  <li>
                                    <Link
                                      className="iq-sub-card iq-logout-2 mt-1 d-flex justify-content-center gap-2"
                                      onClick={logoutUser}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M1.82209 15.9999C1.46654 15.9999 1.16283 15.874 0.910981 15.6221C0.659129 15.3703 0.533203 15.0666 0.533203 14.711V1.73322C0.533203 1.37767 0.659129 1.07397 0.910981 0.822114C1.16283 0.570262 1.46654 0.444336 1.82209 0.444336H7.95543V1.44434H1.82209C1.74802 1.44434 1.68135 1.47397 1.62209 1.53322C1.56283 1.59248 1.5332 1.65915 1.5332 1.73322V14.711C1.5332 14.7851 1.56283 14.8517 1.62209 14.911C1.68135 14.9703 1.74802 14.9999 1.82209 14.9999H7.95543V15.9999H1.82209ZM12.0888 11.5999L11.3554 10.8888L13.5332 8.73322H5.68876V7.711H13.511L11.3332 5.55545L12.0665 4.82211L15.4665 8.24434L12.0888 11.5999Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                      <h6 className="mb-0 font-size-14 text-white fw-normal">
                                        Logout
                                      </h6>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <div className="d-flex ">
                                <div className="iq-button me-2">
                                  <Link to="/Login">
                                    <a
                                      href="javascript:void(0)"
                                      className="btn p-2 position-relative"
                                    >
                                      <span className="button-text">Login</span>
                                    </a>
                                  </Link>
                                </div>
                                <div>
                                  <button
                                    className="button"
                                    onClick={handleregister}
                                  >
                                    Register
                                  </button>
                                </div>
                              </div>
                            )}
                          </li>

                          <li>
                            <button
                              className={`theme ${
                                theme === "dark"
                                  ? "dark-mode p-2 rounded"
                                  : "light-mode p-2 rounded"
                              }`}
                              onClick={toggleMode}
                              style={{
                                backgroundColor:
                                  theme === "dark" ? "#000" : "#fff",
                              }}
                            >
                              {theme === "dark" ? (
                                <IoSunny />
                              ) : (
                                <IoMoonSharp style={{ color: "#262626" }} />
                              )}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`header-bottom header-sticky ${
                    isDarkMode ? "bg-black" : "bg-white"
                  } d-lg-none`}
                >
                  <nav className="navbar navbar-expand-xl navbar-light iq-navbar header-hover-menu py-xl-0 py-1">
                    <div className="container-fluid navbar-inner px-2">
                      <div className="d-flex align-items-center w-100 landing-header">
                        <div className="d-flex justify-content-between w-100 gap-1 gap-xl-0 align-items-center">
                          <div className="w-100 logo-default ps-0">
                            <NavLink exact to="/" activeClassName="active">
                              <img
                                className=" logo"
                                src={isDarkMode ? DarkModeLogo : Logo}
                                loading="lazy"
                                style={{ height: "42px" }}
                                alt="streamit"
                              />
                            </NavLink>
                          </div>
                          <LanguageSwitcher />
                          <div>
                            <li style={{ listStyleType: 'none',}}>
                              <button
                                className={`theme ${
                                  theme === "dark"
                                    ? "dark-mode p-2 rounded"
                                    : "light-mode p-2 rounded"
                                }`}
                                onClick={toggleMode}
                                style={{
                                  backgroundColor:
                                    theme === "dark" ? "#000" : "#fff",
                                }}
                              >
                                {theme === "dark" ? (
                                  <IoSunny />
                                ) : (
                                  <IoMoonSharp style={{ color: "#262626" }} />
                                )}
                              </button>
                            </li>
                          </div>

                          <nav
                            id="navbar_main"
                            className="offcanvas mobile-offcanvas nav navbar navbar-expand-xl hover-nav horizontal-nav py-xl-0"
                            onClick={handleCloseMenu}
                          >
                            <div
                              className="container-fluid p-lg-0"
                              onClick={handleCloseMenu}
                            >
                              <div className="offcanvas-header px-0 pb-3">
                                <div className="navbar-brand ms-3">
                                  <div className="logo-default">
                                    <a
                                      className="navbar-brand text-primary"
                                      href="./index.html"
                                    >
                                      <img
                                        className=" logo"
                                        src={Logo}
                                        loading="lazy"
                                        alt="streamit"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn-close float-end px-3"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                ></button>
                              </div>

                              <div
                                className="menu-container"
                                onClick={handleCloseMenu}
                              >
                                <ul
                                  //className="navbar-nav iq-nav-menu  list-unstyled"
                                  className={`menu p-0 pt-2 ${
                                    isActive ? "active" : ""
                                  } menu-link  `}
                                  //activeClassName="active"
                                  id="header-menu"
                                  onClick={handleCloseMenu}
                                >
                                  {navItems.map((item, index) => (
                                    <li
                                      className={`menu-item mt-2 py-2 menu-link ${
                                        activeNavItem === index ? "active" : ""
                                      }`}
                                      key={index}
                                      //activeClassName="active"
                                      onClick={() => handleNavItemClick(index)}
                                    >
                                      <NavLink
                                        //exact={item.exact}
                                        to={item.path}
                                        //activeClassName="active"
                                        className={`pb-2 ${
                                          activeNavItem === index
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleNavItemClick(index)
                                        }
                                      >
                                        {item.title}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </nav>
                          <div className="right-panel">
                            {/* mobile view */}

                            <div
                              className="collapse navbar-collapse p-1 show"
                              id="navbarSupportedContent"
                            >
                              <div className="iq-meta-menu-container d-xl-block d-none"></div>
                              <div className="d-xl-none">
                                <ul className="d-flex align-items-center justify-content-center gap-3 list-inline mb-0">
                                  <li className="w-100">
                                    <div className="wrap px-1 pe-2">
                                      <div className="search">
                                        <input
                                          type="text"
                                          className="searchTerm"
                                          placeholder="Search events, artists, videos, organisers, franshisees and more"
                                          id="input_text"
                                          onChange={handlesearchtext}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              handleSearch();
                                            }
                                          }}
                                        ></input>
                                        <button
                                          type="submit"
                                          className="searchButton"
                                          onClick={handleSearch}
                                        >
                                          <i
                                            className="fa fa-search"
                                            onClick={handleSearch}
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </header>

              {/* <LanguageSwitcher /> */}

              <header
                className={`mobileFooterMenu ${
                  isDarkMode ? "bg-black" : "bg-white"
                } d-lg-none shadow-lg`}
              >
                <div>
                  <div className="d-flex gap-2 align-items-center justify-content-between px-3 py-2">
                    <div className="d-flex align-items-center justify-content-center">
                      <ul className="m-0 p-0">
                        <li>
                          {user ? (
                            <li
                              className="nav-item dropdown"
                              id="itemdropdown1"
                            >
                              <a
                                className="nav-link d-flex align-items-center"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <div className="btn-icon rounded-pill user-icons">
                                  <span className="btn-inner">
                                    {userDetails?.profileDetails.photo === "" ||
                                    userDetails?.profileDetails.photo ===
                                      null ? (
                                      <FirstLetterAvatar
                                        data={userDetails?.profileDetails}
                                      />
                                    ) : (
                                      <img
                                        src={userImage}
                                        className="img-fluid rounded-circle"
                                        alt=""
                                        loading="lazy"
                                      />
                                    )}
                                  </span>
                                </div>
                              </a>
                              <ul
                                className="dropdown-menu dropdown-menu-end dropdown-user border-0 p-0 m-0"
                                style={{ width: "240px" }}
                                aria-labelledby="navbarDropdown"
                              >
                                <li className="user-info d-flex align-items-center gap-3 mb-0">
                                  {userDetails?.profileDetails?.photo === "" ||
                                  userDetails?.profileDetails?.photo ===
                                    null ? (
                                    <FirstLetterAvatar
                                      data={userDetails?.profileDetails}
                                    />
                                  ) : (
                                    <img
                                      src={userImage}
                                      className="img-fluid rounded-circle"
                                      alt=""
                                      loading="lazy"
                                    />
                                  )}
                                  <span className="font-size-14 fw-500 text-capitalize text-black">
                                    {userDetails?.profileDetails?.name}
                                    <br></br>
                                    <span className="d-flex justify-content-between align-items-center gap-1">
                                      <p className="mb-0">{user?.rolename}</p>
                                    </span>
                                  </span>
                                </li>
                                <li>
                                  {user?.rolename !== "advertiser" && (
                                    <div>
                                      <SelectBox
                                        dataSource={
                                          switchroleCollection.filteredDataSource
                                            ? switchroleCollection.filteredDataSource.filter(
                                                (role) =>
                                                  role.rolename !==
                                                  user.rolename
                                              )
                                            : null
                                        }
                                        valueExpr="rolename"
                                        placeholder="Switch Role"
                                        searchEnabled={false}
                                        displayExpr="displayname"
                                        value={
                                          switchroleCollection.selectedRole
                                        }
                                        onValueChanged={handleSwitchRole}
                                      />
                                    </div>
                                  )}
                                </li>

                                <li>
                                  <NavLink to="dashboard">
                                    <a
                                      href="#"
                                      className="iq-sub-card d-flex align-items-center gap-3"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 22"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.84455 20.6621C4.15273 20.6621 1 20.0876 1 17.7868C1 15.486 4.13273 13.3621 7.84455 13.3621C11.5364 13.3621 14.6891 15.4654 14.6891 17.7662C14.6891 20.066 11.5564 20.6621 7.84455 20.6621Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.83725 10.1738C10.26 10.1738 12.2236 8.21015 12.2236 5.78742C12.2236 3.36469 10.26 1.40015 7.83725 1.40015C5.41452 1.40015 3.44998 3.36469 3.44998 5.78742C3.4418 8.20196 5.3918 10.1656 7.80634 10.1738C7.81725 10.1738 7.82725 10.1738 7.83725 10.1738Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      <h6 className="mb-0 font-size-14 fw-normal">
                                        My Account
                                      </h6>
                                    </a>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink to="/MyProfile">
                                    <a
                                      href="#"
                                      className="iq-sub-card d-flex align-items-center gap-3"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 22"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.84455 20.6621C4.15273 20.6621 1 20.0876 1 17.7868C1 15.486 4.13273 13.3621 7.84455 13.3621C11.5364 13.3621 14.6891 15.4654 14.6891 17.7662C14.6891 20.066 11.5564 20.6621 7.84455 20.6621Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.83725 10.1738C10.26 10.1738 12.2236 8.21015 12.2236 5.78742C12.2236 3.36469 10.26 1.40015 7.83725 1.40015C5.41452 1.40015 3.44998 3.36469 3.44998 5.78742C3.4418 8.20196 5.3918 10.1656 7.80634 10.1738C7.81725 10.1738 7.82725 10.1738 7.83725 10.1738Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      <h6 className="mb-0 font-size-14 fw-normal">
                                        My Profile
                                      </h6>
                                    </a>
                                  </NavLink>
                                </li>
                                <li>
                                  <Link
                                    className="iq-sub-card iq-logout-2 mt-1 d-flex justify-content-center gap-2"
                                    onClick={logoutUser}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.82209 15.9999C1.46654 15.9999 1.16283 15.874 0.910981 15.6221C0.659129 15.3703 0.533203 15.0666 0.533203 14.711V1.73322C0.533203 1.37767 0.659129 1.07397 0.910981 0.822114C1.16283 0.570262 1.46654 0.444336 1.82209 0.444336H7.95543V1.44434H1.82209C1.74802 1.44434 1.68135 1.47397 1.62209 1.53322C1.56283 1.59248 1.5332 1.65915 1.5332 1.73322V14.711C1.5332 14.7851 1.56283 14.8517 1.62209 14.911C1.68135 14.9703 1.74802 14.9999 1.82209 14.9999H7.95543V15.9999H1.82209ZM12.0888 11.5999L11.3554 10.8888L13.5332 8.73322H5.68876V7.711H13.511L11.3332 5.55545L12.0665 4.82211L15.4665 8.24434L12.0888 11.5999Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    <h6 className="mb-0 font-size-14 text-white fw-normal">
                                      Logout
                                    </h6>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            <div className="d-flex">
                              <div className="iq-button me-2">
                                <Link to="/Login">
                                  <a
                                    href="javascript:void(0)"
                                    className="button px-1 py-0 position-relative"
                                  >
                                    <span className="button text-nowrap">
                                      Login
                                    </span>
                                  </a>
                                </Link>
                              </div>
                              <div>
                                <button
                                  className="button"
                                  onClick={handleregister}
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          )}
                        </li>
                      </ul>

                      <div className="d-none">
                        <Link to="/Login">
                          <a
                            href="javascript:void(0)"
                            className="py-0 position-relative"
                          >
                            <IoPersonOutline
                              className={`headerCart ${
                                isDarkMode ? "text-white" : "text-black"
                              } d-xl-none`}
                            />
                          </a>
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <ul className="m-0 p-0">
                        <li>
                          <div
                            onClick={navigateEvent}
                            className={`${
                              isDarkMode ? "text-white" : "text-black"
                            } cartValue`}
                            data-bs-toggle="offcanvas"
                          >
                            <span>
                              <img className="cartIcon" src={cartSvg}></img>
                              <span className="cartvaluePostion">
                                {cartDetails?.cartValue || 0}
                              </span>
                            </span>
                          </div>
                        </li>
                      </ul>

                      <ul className="mb-0 p-0 d-none">
                        <li>
                          <Link to="#offcanvasCart" className="text-black">
                            <span
                              className={`btn-icon rounded-pill user-icons ${
                                isDarkMode ? "bg-black" : "bg-white"
                              } d-xl-none`}
                            >
                              <FiShoppingCart
                                className={`headerCart ${
                                  isDarkMode ? "text-white" : "text-black"
                                } d-xl-none`}
                              />
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="">
                        <div>
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#navbar_main"
                            aria-controls="navbar_main"
                            className={`btn user-icons rounded-pill p-1 pt-0 toggle-rounded-btn ${
                              isDarkMode
                                ? "text-white bg-black"
                                : "text-black bg-white"
                            } d-xl-none`}
                          >
                            <VscThreeBars className="fs-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </>
          )}
        </>
      </main>
    </>
  );
};

export default Header;
