import React, { useState, useEffect, useContext, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import TextArea from "devextreme-react/text-area";
import { makeGetRequest, makePostRequest } from "../../Api/requestMethods";
import notify from "devextreme/ui/notify";
import IMAGE1 from "../../assets/images/genre/03.webp";
import { DURATION, ERROR, SUCCESS } from "../../global/ToastMsgConstants.tsx";
import { ADD_STREAM_MESSAGE_GO, GETTING_TRUE_API, GET_STREAM_COMMENT_DETAIL, GET_STREAM_LOGIN_TOKEN, GET_USER_PROFILE_DETAILS, POST_STREAM_VOTE_DETAIL } from "../../Api/api.js";
import AuthContext from "../../context/AuthContext.js";
import StreamCountDownPage from "./StreamCountDownPage.js";
import { IoShareSocial } from "react-icons/io5";
import { CountrySelectorDropdown } from "react-international-phone";
import { customEncrypt } from "../../utils/encryptAndDecrypt.js";
import { CommonPaneSettings } from "devextreme-react/cjs/chart";
import { useLocation } from "react-router-dom";
import StreamPopup from "./StreamPopup.js";
import { jwtDecode } from "jwt-decode";
import { BASEPATH } from "../../config/config.js";
import {
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import getEventKeys from "../../Api/getEventKeys.js";
function Stream() {

  const COUNTRY_KEY= getEventKeys().countryKey;
 const EVENT_KEY= getEventKeys().eventKey;

  const location = useLocation();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [base64Detail, setbase64Detail] = useState(null);
  const [urlData, setUrlData] = useState({});
  const [likeStatus, setLikeStatus] = useState(0);
  const [count, setCount] = useState(0);
  const [streamValue,setStreamValue]=useState("");
  const [streamComment,setStreamComment] = useState([]);
  const [commentAdded, setCommentAdded] = useState(false);
  const [isEventLive,setIsEventLive] = useState(false);
  const [likeNumber, setlikeNumber] = useState(0);
  const [dislikeStatus, setDislikeStatus] = useState(0);
  const [openStreamPopUp,setOpenStreamPopUp] = useState(false);
  const [userDetailPro,setUserDetailsPro]=useState({});
  const navigate = useNavigate();
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true)
  const extractBase64FromUrl = () => {
    const url = window.location.href;
    const urlobj = new URL(url);
    const pathSegments = urlobj.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  }
  const textareaRef = useRef(null);
  const decodeBase64String = (base64String) => {
    try {
      // Decode base64 string
      const jsonString = atob(base64String);

      // Parse JSON string into an object
      const parsedData = JSON.parse(jsonString);

      return parsedData;
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return null;
    }
  };
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  console.log(user,"TokenFile")

  useEffect(() => {
    if (base64Detail) {
      const decodedData = decodeBase64String(base64Detail);
      console.log(decodedData, "DecodeD")
      setUrlData(decodedData)
    }
  }, [base64Detail]);

  // -----------------------------------------------------------------------
  const likeANDdislikeInStream = async (voteDetail) => {
    try {
      const response = await makePostRequest(POST_STREAM_VOTE_DETAIL(), voteDetail);
      return response;
    } catch (error) {
      throw error;
    }
  }
  const AllStreamVoteMutation = useMutation({
    mutationKey: ["StreamVoteDetail"],
    mutationFn: (data) => likeANDdislikeInStream(data),
    onSuccess: (data) => {
      console.log("u liked Video")
    },
    onError: (error) => {
      console.log(error);
    }
  })
  const handleVote = (action, data1) => {
    const likedislikeValue = action === "like" ? 1 : 0;
    const data = {
      event_id: urlData.event_id,
      role_id: urlData.role_id,
      country_id: urlData.country_id,
      is_like: likedislikeValue
    }
    AllStreamVoteMutation.mutate(data, {
      onSuccess: () => {
        if (action === "like") {
          setLikeStatus(1);
          setCount(count + 1);
          setDislikeStatus("inactive");
        } else {
          setDislikeStatus(1);
          if (!likeNumber === 0) {
            setLikeStatus(1);
          } else {
            setlikeNumber(data1);
            setCount(count + 1);
          }
          setLikeStatus(null)
        }
      },
      onError: (error) => {
        console.log(error)
      }
    });
  }

  const encodeBase64 = (str) => {
    const prefix = "xy4AT";
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const encodeBase64_new = (str,key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  // --------------------------------------------------------------------------------------------------------
  const handleClick = (ticketDetail, from) => {

    const encyptedData = customEncrypt("no");
    const modifiedTitle = ticketDetail.event_title ? ticketDetail.event_title.split(" ").join("_") : "Event";
    navigate(`/event/${modifiedTitle}?ci=${encodeBase64_new(ticketDetail.country_id, COUNTRY_KEY)}&e=${encodeBase64_new(ticketDetail.event_id, EVENT_KEY)}&editEvent=${encyptedData}`);

  };
  const fetchingVideo = async () => {
    const base64String = extractBase64FromUrl();
    setbase64Detail(base64String);
    const endpoint = `stream/event_details/${base64String}`;
    return await makeGetRequest(endpoint);
  };

  const { data: VideoDetail, refetch } = useQuery({
    queryKey: ["VideoDetail"],
    queryFn: fetchingVideo,
    onSuccess: (data) => {
      notify(data.message === "success", SUCCESS, DURATION);
    },
    onError: (error) => {
      console.log(error, ERROR, DURATION);
    }
  });
  useEffect(() => {
    if (VideoDetail?.data) {
      refetch();
    }
  }, [VideoDetail?.data, count]);

  function convertToBase64forSideThumbnail(data) {
    if (!data || Object.keys(data).length === 0) {
      console.log("Invalid or undefined data");
      return "";
    }
    const jsonString = JSON.stringify(data);
    return btoa(jsonString);
  }


  // --------------------------------------------------------------------------------------------------------
  const data = {
    "country_id": urlData?.country_id,
    "season_flag": urlData?.season_flag,
    "event_id": urlData?.event_id
  };

  console.log(data, "upcomingData");

  const fetchSideVideo = async () => {
    if (data.country_id === null || data.season_flag === null || data.event_id === null) {
      console.log("Required data is missing, not hitting the endpoint.");
      throw new Error("Missing required data."); 
    }
    const base64String = convertToBase64forSideThumbnail(data);


    const endpoint = `stream/upcoming_events/${base64String}`;
    return await makeGetRequest(endpoint);
  };

  const { data: SideVideo, error } = useQuery({
    queryKey: ["SideVideo", data.country_id, data.season_flag, data.event_id], 
    queryFn: fetchSideVideo,
    onError: () => {
      console.log("Error fetching side video");
    },
    enabled: !!(data.country_id && data.season_flag && data.event_id), 
  });

  // ---------------------IsEventLive-------------------------------
  const handleEventStart = (eventStarted) => {

    if (eventStarted) { 
      setIsEventLive(true)
    }
  };
// -----------------------------------insertComments-------------------------------------------

const handleStreamChange = (e) => {
  setStreamValue(e.target.value);
}

const InsertintStreamMessage = async (message) => {
  if (isEventLive) {
    try {
      const response = await makePostRequest(ADD_STREAM_MESSAGE_GO(), message);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
const InsertStreamMessage = useMutation({
  mutationKey: ["insertStreamMessage"],
  mutationFn: (data) => InsertintStreamMessage(data),
  onSuccess: (data) => {
    if (data.status === "success") {
      setCommentAdded(true);   
      fetchStreamComment();  
      setStreamValue("");  
    }
  },
  onError: (error) => {
    console.error("InsertStreamMessage error:", error);
  },
});
const handleStreamComment = () => {
  if (!user) {
    notify("Please register or login to perform comment operation", "error");
  } else if (!streamValue.trim()) {
    notify("Comment cannot be empty", ERROR);
  } else {
    const newComment = {
      country_id: urlData?.country_id,
      event_id: urlData?.event_id,
      role_id: user?.role_id,
      comments: streamValue,
    };
    InsertStreamMessage.mutate(newComment);  
  }
};
//--------------------------------------DisplayComment--------------------------------------
const streamFetchStream = async () => {
  if (urlData?.country_id && urlData?.event_id && isEventLive) {
    const endpoint = `${GET_STREAM_COMMENT_DETAIL}?country_id=${urlData.country_id}&event_id=${urlData.event_id}`;
    return await makeGetRequest(endpoint);
  }
  return Promise.reject("Invalid urlData");
};
// const { data: StreamCommentDataSource } = useQuery({
//   queryKey: ["streamComment", urlData?.country_id, urlData?.event_id],
//   queryFn: streamFetchStream,
//   enabled: !!urlData?.country_id && !!urlData?.event_id,
//   onSuccess: (data) => {
//     setStreamComment(data);
//   },
//   onError: () => {
//     console.error("Error fetching stream comment data");
//   },
// });
const fetchStreamComment = async () => {
  try {
    const response = await streamFetchStream();
    setStreamComment(response.data);
  } catch (error) {
    console.error("Error fetching comments: ", error);
  }
};
  useEffect(() => {
    if (commentAdded) {
      fetchStreamComment();  
      setCommentAdded(false); 
    }
  }, [isEventLive,commentAdded]);
//-------------------------------------------PassingParameterTickted ----------------------------------------
const GettingTrue = async (tickted) => {
  if(VideoDetail?.data?.ticketing_option !=='free'){
  try {
    const response = await makePostRequest(GETTING_TRUE_API(), tickted);
    console.log(response,"RESPONSE")
    if (response?.status === "failure") {
      
      throw new Error(response.message);
    }
    return response;
  } catch (error) {
    throw error;
  }
}
};
const CheckValidationTicket = useMutation({
  mutationKey: ["checkTicket"],
  mutationFn: (data) => GettingTrue(data),
  onSuccess: (data) => {
    console.log(data, "Successfullyvalidatedticket");
  },
  onError: (error) => {
    if (error.response?.data?.status === 'failure') {
      setOpenStreamPopUp(true);
    }
    
    console.log("Error: ", error);
  },
});
useEffect(() => {
  if (urlData?.country_id && urlData?.event_id && urlData?.role_id) {
    const ticketData = {
      country_id: urlData.country_id,
      event_id: urlData.event_id,
      role_id: urlData.role_id,
    };
    CheckValidationTicket.mutate(ticketData);
  } else {
    console.log("Missing required fields in urlData for ticket validation");
  }
}, [urlData]); 
const hasUpcomingEvents = SideVideo?.data && SideVideo.data.length > 0;
//GetLogiProfileImage-----------
useEffect(() => {
  if (user) {
    const body = {
      roleid: user.role_id,
    };
    profileMutation.mutate(body);
  }
}, [user]);
const profileMutation = useMutation({
  mutationFn:(userId)=> userId !== "" && userId !== null && getProfileDetails(userId),
    onSuccess:(data)=>{
      setUserDetailsPro((prev) => ({
        ...prev,
        profileDetails: data?.data[0],
        photo: getProfilePhotoUrl(data?.data[0].rolename),
        imgurl: data?.data[0].photo
      }));
    },
    onError:(error)=>{
      notify(error.response.data.message, ERROR, DURATION);
    }
  }
  )
  const getProfileDetails = async (userId) => {
    try {
      if (userId) {
        const response = await makePostRequest(
          GET_USER_PROFILE_DETAILS,
          userId
        );
        return response;
      }
    } catch (error) {
      throw error;
    }
  };
console.log(userDetailPro,"LOGINPHOTIO")

const getProfilePhotoUrl = (roleName) => {
  switch (roleName) {
    case 'organiser':
      return `${BASEPATH}public/images/organiser_images/`;
    case 'artist':
      return `${BASEPATH}public/images/artist_images/`;
    case 'fan':
      return `${BASEPATH}public/images/fan_images/`;
    default:
      return ''; // Return a default image URL or an empty string if no match
  }
}
//-----------------------------------------------------------------------------------
  return (
    <div className="">
      <div className="videoPageMargin section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="ragabank-pg col-12">
            <div className=" d-flex col-lg-8 col-xl-8 col-xxl-9 flex-column me-lg-4 pb-3">
              {isEventLive ? (
                <div className="">
                  <div style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
                    <iframe
                      src={VideoDetail?.data?.stream_link.match(/src="([^"]+)"/)[1]}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                        border: "none"
                      }}
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      title="Vimeo Video"
                    />
                  </div>

                  <div className="mt-1">
                    <h4><b>{VideoDetail?.data?.event_title}</b></h4>
                    {/* <div className="d-lg-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="videoCommentImg">
                        <img src={IMAGE1} alt="Profile" />
                        </div>
                        <div className="ms-2">
                          <h6 className="mb-0"><b>Channel Name</b></h6>
                          <p className="mb-0 videoMinuts">31 minutes ago</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center gap-3 mt-2 mt-lg-0">
                        <div className="">
                          <div className="like_dislike" id="like_dislike">
                            <div
                              className={`like ${VideoDetail?.data?.likes === 1 ? "fa-solid" : "fa-regular"} touch_feedback`}
                              id="like"
                              onClick={() => handleVote("like")}
                              data-status={likeStatus}
                            >
                              <i
                                id="like__icon"
                                className={`fa-regular fa-thumbs-up ${VideoDetail?.data?.likes === 1 ? "like__animation" : ""}`}
                              ></i>
                              <span className="Likenumber" id="like-number">
                                {VideoDetail?.data?.likes}
                              </span>
                            </div>
                            <span className="divider"></span>
                            <div
                              className={`dislike ${VideoDetail?.data?.dislikes === 1 ? "fa-solid" : "fa-regular"} touch_feedback`}
                              id="dislike"
                              onClick={() => handleVote("dislike")}
                              data-status={dislikeStatus}
                            >
                              <i
                                id="dislike__icon"
                                className={`fa-regular fa-thumbs-down ${VideoDetail?.data?.dislikes === 1 ? "dislike__animation" : ""}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                        <button className="border-button d-sm-none">
                          Comments
                        </button>
                      </div>
                    </div> */}
                    {/* <div className="bg-light commentBgBorder p-2 mt-2">
                      <p><b>Description</b></p>
                      <p className="mb-0" dangerouslySetInnerHTML={{ __html: VideoDetail?.data?.event_description }} />
                    </div> */}
                    <ul className="ps-0">
                      <li className="pb-3 mb-0">
                        <div className="d-flex gap-2 align-items-center justify-content-between mt-2">
                          <div className="videoCommentImg">
                            <img src={`${userDetailPro.photo}${userDetailPro.imgurl}`} alt="User" />
                          </div>
                          <textarea
                            className="autosize"
                            ref={textareaRef}
                            value={streamValue}
                            onChange={handleStreamChange}
                            placeholder="Add Comments"
                          />

                        </div>
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                          <button className="border-button">
                            Cancel
                          </button>
                          <button
                            className="button"
                            onClick={handleStreamComment}
                          >
                            Submit
                          </button>
                        </div>
                        </li>
                         <ul className="list-inline m-0 p-0 ps-0">
                      {streamComment &&
                        streamComment
                          .slice()
                          .reverse()
                          .map((comment) => {
                            let imagePath = "";
                            if (comment.role_name === "organiser") {
                              imagePath = `${BASEPATH}public/images/organiser_images/${comment.profile_photo}`;
                            } else if (comment.role_name === "artist") {
                              imagePath = `${BASEPATH}public/images/artist_images/${comment.profile_photo}`;
                            } else if (comment.role_name === "fan") {
                              imagePath = `${BASEPATH}public/images/fan_images/${comment.profile_photo}`;
                            }
                            return (
                              <li
                                key={comment.id}
                                className="pb-3 d-noneSmallDevice"
                              >
                                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center align-items-start gap-2 w-100">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="flex-shrink-0 videoCommentImg overflow-hidden d-flex align-items-center justify-content-center">
                                    {imagePath ? (
                                          <img
                                            src={imagePath}
                                            alt="user-image"
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <div className="default-placeholder d-flex align-items-center justify-content-center">
                                            <span className="default-initial">
                                              {comment.role_name?.charAt(0).toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                    <div className="about-user">
                                      <div className="d-flex align-items-center flex-wrap gap-2">
                                         <h6 className="mb-0">
                                          {/* {profile?.profile_name} */}
                                          {/* {comment.login_id} */}
                                        </h6> 
                                        <p className="p-0 m-0 published-date">
                                          {/* {formatTime(comment?.created_at)} */}
                                        </p>
                                      </div>
                                      <p className="mt-0 mb-0">
                                        {comment.message}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                    </ul> 
                  </ul>
                    </div>
                  </div>
                ):(<StreamCountDownPage targetDate={VideoDetail?.data.event_date} itsData={VideoDetail?.data} targetTime={VideoDetail?.data.event_start_time} onEventStart={handleEventStart}/>)
              }
            </div>

            <div className=" col-lg-4 col-xl-4 col-xxl-3">
              <h5 className="mb-2 mb-lg-3"><b>{hasUpcomingEvents ? "Upcoming Events" : "No Upcoming Events"}</b></h5>
              {SideVideo?.data?.map((event) => (
                <div className="iq-watching-block mb-lg-3 d-flex"
                  style={{ cursor: "pointer" }} key={event.event_id} >
                  <div className="block-images position-relative"
                    style={{
                      width: "112px",
                      height: "112px",
                    }} onClick={() => handleClick(event, "streaming")}>
                    <div className="overly-images imgRadius"
                      style={{
                        width: "112px",
                        height: "112px",
                      }}>
                      <img src={`data:image/jpeg;base64,${event.thumbnail}`} className="img-fluid object-cover w-100 d-block border-0 videoOuter" alt="Side Thumbnail" />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-2">
                    <div className="w-100 ms-2">
                      <h6
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="mb-0"
                      >
                        <b>{event.event_title}</b>
                      </h6>
                      <p className="mb-0"> Name</p>
                      <p className="mb-0 videoMinuts">
                        {event.event_time}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isEventLive && VideoDetail?.data?.ticketing_option !=='free' && (
     <StreamPopup showModal={openStreamPopUp} />
      )}
    </div>
  );
}

export default Stream;
