import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";

const addonValidationSchema = Yup.object({
    addon_code: Yup.string().required(COMMONFIELDREQUIRED),
    description: Yup.string().required(COMMONFIELDREQUIRED),
    addon_type: Yup.string().required(COMMONFIELDREQUIRED),
    orginal_value: Yup.string().required(COMMONFIELDREQUIRED),
    amount: Yup.number().required(COMMONFIELDREQUIRED),
    // amount_usd: Yup.number().required(COMMONFIELDREQUIRED),
});

 const questionValidationSchema = Yup.object().shape({
    addon_code: Yup.string().required(COMMONFIELDREQUIRED),
    addon_type: Yup.string().required(COMMONFIELDREQUIRED),
    description: Yup.string().required(COMMONFIELDREQUIRED),
    addon_type: Yup.string().required(COMMONFIELDREQUIRED),
    question: Yup.string().required(COMMONFIELDREQUIRED)
     });

const addPromoCodeValidationSchema = Yup.object().shape({
    promo_code: Yup.string()
      .required(COMMONFIELDREQUIRED)
      .matches(/^[a-zA-Z0-9]+$/, 'Promo code can only contain letters and numbers'),
    description: Yup.string().required(COMMONFIELDREQUIRED),
    discount_type: Yup.string().required(COMMONFIELDREQUIRED),
    discount_value: Yup.number().required(COMMONFIELDREQUIRED)
  });










export{ addonValidationSchema,
    addPromoCodeValidationSchema,
    questionValidationSchema
}