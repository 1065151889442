import React from "react";
import { useLocation } from "react-router-dom";
import { BASEPATH } from "../../../config/config";
import DOMPurify from 'dompurify';
import { DateBox } from 'devextreme-react/date-box';
import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Validator, NumericRule, PatternRule, RequiredRule, EmailRule, RangeRule } from 'devextreme-react/validator';
import { TagBox } from 'devextreme-react/tag-box';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useState, useEffect, useRef, useMemo } from "react";
import { COUNTRIES_FROM_BACKEND, STATES_FROM_BACKEND, CITIES_FROM_BACKEND, SAVE_FORM_DATA, SEND_CREATEFORM_SUBMIT } from "../../../Api/api";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { GET_DETAILED_FORM_FROM_BACKEND } from "../../../Api/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from "react-phone-input-2";
import RazorpayPayment from "../../../Pages/paymentGateWay/razorPay";
import "./RegisterForm.css"


import notify from "devextreme/ui/notify";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import NoDataFound from "../../../global/NoDataFound";
import { Helmet } from "react-helmet";
import Noimage from "../../../global/NoImage";
import Noimage_profilepic from "../../../global/NoImage_profilepic";
import Noimage_circle from "../../../global/NoImage_circle";

import { HeadProvider, Meta } from 'react-head';
import { Button } from "devextreme-react";
import { FiUpload } from "react-icons/fi";
import NumberedSeatingPurchase from "../../../Pages/numbered_seating_purchase/numberedSeatingPurchase";
import { isValidImage, imageExists } from "../../../global/ImageCheck";


export default function RegisterForm() {

    const razorpayRef = useRef(null);

    //newly added
    const phoneRef = useRef(null);
    const [NewFormCollection, setNewFormCollection] = useState({});
    const [ArrayTagBox, setArrayTagBox] = useState([]);
    const dateBoxRef = useRef(null);
    const tagBoxRef = useRef(null);
    const picInputRef = useRef(null);


    const [counter, setCounter] = useState(0);
    const [formData, setFormData] = useState({});
    const [showCropper, setShowCropper] = useState(false);
    const [image, setImage] = useState(null);
    const [imagechecker, setImageChecker] = useState(true);
    const cropperRef = useRef(null);
    const [dummyimg, setdummyimg] = useState("");
    const [showSaveandCancelbuttonPic, setShowSaveandCancelbuttonPic] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const [selectedValue, setSelectedValue] = useState(null);

    const [keyforimage, setkeyforimage] = useState('');

    const token = localStorage.getItem("MDnD_auth");

    const location = useLocation();
    const urlParams = new URLSearchParams(location?.search);

    const [condition, setcondition] = useState({
        show_to_public: "",
        status: "",
        message: "",
    })


    const decodeBase64 = (encoded) => {
        const prefix = "xy4AT";
        try {
            const decodedStr = atob(encoded); // Base64 decoding
            if (decodedStr.startsWith(prefix)) {
                return decodedStr.slice(prefix.length); // Remove the prefix
            } else {
                console.error("Decoding error: Prefix not found");
                return null;
            }
        } catch (error) {
            console.error("Decoding error:", error);
            return null;
        }
    };

    const[encodedFormId, setencodedFormId] = useState('');
    //const encodedFormId = urlParams?.get('fid');

    //console.log(encodedFormId, "encodedFormId");

    useEffect(()=>{
        setencodedFormId(urlParams?.get('fid'));
    },[urlParams])

    const [formidParam, setformidParam] = useState('');

    useMemo(() => {


        if (encodedFormId) {
            const formid = encodedFormId && decodeBase64(encodedFormId);

            if (formid) {
                setformidParam(formid);
            }
        }

    }, [encodedFormId])






    const [formidmain, setFormidmain] = useState(null);

    //console.log(localStorage.getItem('formidmain'),"localStorage.getItem('formidmain')");



    useEffect(() => {

        const storedFormid = localStorage.getItem('formidmain');
        if (storedFormid) {
            setFormidmain(storedFormid);

        } else if (formidParam) {
            setFormidmain(formidParam);
        }
    }, [formidParam]);

    //console.log(formidParam, "formidParam");

    //console.log(formidmain, "formidmain");






    useEffect(() => {
        if (formidmain) {
            //let finalformid= decryptFormid(encryptedFormid);

            mutationgetFormDetailed.mutate(formidmain);


        }
    }, [formidmain, counter]);


    const GetSingleDetailedForm = (fid) =>
        GET_Detailed_form(GET_DETAILED_FORM_FROM_BACKEND, fid);

    const GET_Detailed_form = async (
        GET_DETAILED_FORM_FROM_BACKEND, fid) => {
        //console.log("im inside");


        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_DETAILED_FORM_FROM_BACKEND}`,
                data: {
                    form_id: Number(fid),
                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });
            //console.log(response, "response");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetFormDetailed = useMutation({
        mutationKey: ["get_form_detailed"],
        mutationFn: (fid) => GetSingleDetailedForm(fid),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                setcondition((prevState) => ({
                    ...prevState,
                    message: data?.message,
                    show_to_public: data?.data[0]?.show_to_public,
                    status: data?.data[0]?.status,
                }));

                // if (data?.data[0]?.show_to_public === "Y" && data?.data[0]?.status === "Y") {
                //     setFormData(data.data[0]);


                // }
                // else if (data?.data[0]?.show_to_public === "N" && data?.data[0]?.status === "Y") {
                //     setFormData({});
                // }
                // else if (data?.data[0]?.show_to_public === "Y" && data?.data[0]?.status === "N") {
                //     setFormData({});
                // }
                // else if (data?.data[0]?.show_to_public === "N" && data?.data[0]?.status === "N") {
                //     setFormData({});
                // }

                setFormData(data?.data[0]);




                //localStorage.removeItem('formidmain');



            }
            else {
                setFormData({});
            }
        },
        onError: (error) => {

            //HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
            setFormData({});
        },
    });

    //console.log(formData, "FD");


    //const location = useLocation();
    //let individual_data = location.state.onedata;

    //const [individualdata, setIndividualData] = useState({});
    const [address, setAddress] = useState({
        country: "",
        city: "",
        state: "",
    })

    //const [dataTypeObj, setDataTypeObj] = useState({});
    //const [columnNameObj, setColumnNameObj] = useState({});
    //const [Mandatoryobj, setMandatoryObj] = useState({});
    //const [rawFormDesc, setRawFormDesc] = useState({});
    //const [result, setResult] = useState({});
    const [formCollection, setformCollection] = useState({
        amount: null,


    });

    const [CorrectnessCollection, setCorrectnessCollection] = useState({});

    const [tenruleformobile, setTenRuleForMobile] = useState("");
    //const [initialvalidation, setInitialValidation] = useState({});
    //const [validation, setValidation] = useState({});
    //const [transformedColumnNameObj, setTransformedColumnNameObj] = useState({});
    //const [output, setOutput] = useState({});


    const [audioPreviews, setAudioPreviews] = useState({});
    const [outputstate, setoutputstate] = useState({});
    const inputRefs = useRef({});

    const [validationmessage, setValidationMessage] = useState({});
    const [correctmessage, setCorrectMessage] = useState({});


    const [checker, setChecker] = useState({})



    const dataTypeObj = formData && formData.data_type ? JSON.parse(formData.data_type) : null;


    const inrRadioObj = formData && formData.inr ? JSON.parse(formData.inr) : null;
    //console.log(inrRadioObj, "inrradioobj");

    const field_name_desc = formData && formData.field_name_desc ? JSON.parse(formData.field_name_desc) : null;

    //console.log(field_name_desc, "field_name_desc");

    const columnNameObj = formData && formData.column_name ? JSON.parse(formData.column_name) : null;



    const Mandatoryobj = formData && formData.column_mandatory ? JSON.parse(formData.column_mandatory) : null;



    const rawFormDesc = formData && formData.form_desc ? formData.form_desc : null;


    let sanitizedFormDesc = rawFormDesc ? DOMPurify.sanitize(rawFormDesc) : "";


    //for any selectbox.................................................
    const [selectListValue, setSelectListValue] = useState({});




    useMemo(() => {
        try {
            if (formData.select_list_value) {
                const parsedObject = JSON.parse(formData.select_list_value);
                setSelectListValue(parsedObject);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }, [formData]);


    const result = {};

    if (columnNameObj && selectListValue) {
        for (let key in columnNameObj) {
            if (columnNameObj.hasOwnProperty(key) && selectListValue.hasOwnProperty(key)) {
                result[columnNameObj[key]] = selectListValue[key];
            }
        }
    }





    //first object............................................

    let obj = {
        1: "date",
        2: "single_select",
        3: "amount",
        4: "multiple_select",
        5: "mobile",
        6: "percentage",
        7: "time",
        8: "text",
        9: "audio",
        10: "video",
        11: "image",
        12: "email",
        13: "link",
        14: "number",
        15: "country",
        16: "state",
        17: "city",
        18: "pan",
        19: "select declaration",
        20: "declare"
    }





    //console.log(dataTypeObj, "dataTypeObj ");
    //console.log(columnNameObj, "columnNameObj");

    //validation code...........................................................
    let initialvalidation = {};


    if (Mandatoryobj && columnNameObj) {
        for (let key in columnNameObj) {
            if (Mandatoryobj.hasOwnProperty(key)) {
                initialvalidation[columnNameObj[key]] = Mandatoryobj[key];
            }
        }
    }



    let validation = {};

    // to remove the spaces within the keys...
    if (initialvalidation) {
        for (let key in initialvalidation) {
            let newKey = key.replace(/\s+/g, '_');
            validation[newKey] = initialvalidation[key];
        }
    }



    //end of validation obj code.........................................................


    const transformedColumnNameObj = {};
    if (columnNameObj) {
        Object.entries(columnNameObj).forEach(([key, value]) => {
            const newValue = value.replace(/\s+/g, '_');
            transformedColumnNameObj[key] = newValue;
        });
    }


    const output = {};


    if (obj && transformedColumnNameObj && dataTypeObj) {
        Object.keys(dataTypeObj).forEach(key => {
            const columnName = transformedColumnNameObj[key];
            const dataTypeValue = dataTypeObj[key];

            if (columnName && dataTypeValue) {
                output[columnName] = obj[dataTypeValue];
            }
        });
    }


    const updatedFieldNameDesc = {};
    const outputKeys = output && Object.keys(output);

    if (field_name_desc && outputKeys) {
        Object.keys(field_name_desc).forEach((key, index) => {
            if (index < outputKeys.length) {
                const newKey = outputKeys[index];
                updatedFieldNameDesc[newKey] = field_name_desc[key];
            }
        });
    }

    //console.log(updatedFieldNameDesc, "updatedFieldNameDesc");



    // to fetch country, city, state.............................

    useEffect(() => {
        if (formData) { mutationCountry.mutate(); }
    }, [formData])

    const GetCountries = () =>
        GET_COUNTRY(COUNTRIES_FROM_BACKEND);

    const GET_COUNTRY = async (
        COUNTRIES_FROM_BACKEND) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${BASEPATH}${COUNTRIES_FROM_BACKEND}`,
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationCountry = useMutation({
        mutationKey: ["get_Country"],
        mutationFn: () => GetCountries(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {

                setAddress((prevState) => ({ ...prevState, country: data.data }));

            }
            else {
                setAddress((prevState) => ({ ...prevState, country: "" }));
            }
        },
        onError: (error) => {
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            HandleErrorToast("error", "Countries Could not be Fetched. Something went Wrong!", 3000);
            //setAddress((prevState) => ({ ...prevState, country: ""}));
        },
    });

    //console.log(address,"address");







    //onValueChanged functions............................

    // const handleLinkKeys = (key, value) => {
    //     if (key) {
    //         setformCollection(prevState => ({
    //             ...prevState,
    //             [key]: value
    //         }));
    //         if (value.length > 0) {
    //             //validationmessage[key]="";
    //             setValidationMessage(prevState => ({
    //                 ...prevState,
    //                 [key]: ""
    //             }));
    //         }

    //     }
    // }
    const handleLinkKeys = (key, value) => {
        if (key) {

            const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d-]+(\/[\w\d- ;,./?%&=]*)?$/;
            if (!urlPattern.test(value)) {
                // setValidationMessage(prevState => ({
                //     ...prevState,
                //     [key]: "Invalid URL"
                // }));

                setCorrectnessCollection(prevState => ({
                    ...prevState,
                    [`isValid_${key}`]: false
                }));

                setformCollection(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }


            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));

            setCorrectnessCollection(prevState => ({
                ...prevState,
                [`isValid_${key}`]: true
            }));
            if (value.length > 0) {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));

                setCorrectMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }
    }

    const handleVideoKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value.length > 0) {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }
    }

    const handleTextKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value.length > 0) {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }


    };

    const handleNumberKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));

            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }
        }
    }

    const handleDateKeys = (key, value) => {
        if (key) {

            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));




            if (value instanceof Date) {

                const date = new Date(value);
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;


                setNewFormCollection(prevState => ({
                    ...prevState,
                    [key]: formattedDate
                }));

                setChecker(prevState => ({
                    ...prevState,
                    [key]: "valid"
                }));

            }
            else {


                setChecker(prevState => ({
                    ...prevState,
                    [key]: "invalid",
                }));


            }

            if (value !== "") {
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }

    }


    const handleEmailKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value.length > 0) {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }
    }

    const handleAmountKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));

            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }
        }


    }

    const handlePercentageKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }
        }

    }

    // const handleTimeKeys = (key, value) => {
    //     if (key && value) {
    //         const dateValue = value && new Date(value);


    //         let hours = dateValue && dateValue.getHours();
    //         const minutes = dateValue && String(dateValue.getMinutes()).padStart(2, '0');
    //         const ampm = hours && hours >= 12 ? 'PM' : 'AM';


    //         hours = hours % 12;
    //         hours = hours ? hours : 12;


    //         let formattedTime = `${hours}:${minutes} ${ampm}`;


    //           if(formattedTime)
    //         {setformCollection(prevState => ({
    //             ...prevState,
    //             [key]: formattedTime
    //         }));}
    //         if (value !== "") {
    //             //validationmessage[key]="";
    //             setValidationMessage(prevState => ({
    //                 ...prevState,
    //                 [key]: ""
    //             }));
    //         }
    //     }


    // }

    const handleTimeKeys = (key, value) => {

        //console.log(value, "value inisde handleTime");
        //console.log("Type of value:", typeof value);
        if (key && value) {
            try {
                // Convert 12-hour time string to 24-hour time string
                if (value instanceof String) {
                    //console.log("here");
                    return;
                }
                let dateValue;


                if (typeof value === "string" || value instanceof String) {
                    dateValue = new Date(`1970-01-01T${convertTo24HourFormat(value)}`);
                } else if (value instanceof Date) {
                    dateValue = value;
                } else {
                    console.error("Unexpected value type", typeof value);
                    return;

                }

                //console.log(dateValue, "dateValue");

                // Check if dateValue is a valid date
                if (!isNaN(dateValue.getTime())) {
                    const hours = String(dateValue.getHours()).padStart(2, '0');
                    const minutes = String(dateValue.getMinutes()).padStart(2, '0');
                    const formattedTime = `${hours}:${minutes}`;
                    //console.log(formattedTime, "formattedTime");


                    setformCollection(prevState => ({
                        ...prevState,
                        [key]: formattedTime
                    }));

                    setValidationMessage(prevState => ({
                        ...prevState,
                        [key]: ""
                    }));
                } else {
                    console.error("Invalid date value", value);
                }
            } catch (error) {
                console.error("Error formatting time", error);
            }
        }
    };


    const convertTo24HourFormat = (time) => {
        const [timeString, period] = time.split(" ");
        let [hours, minutes] = timeString.split(":");
        if (period === "PM" && hours !== "12") hours = parseInt(hours) + 12;
        if (period === "AM" && hours === "12") hours = "00";
        return `${hours}:${minutes}`;
    };

    // const handleMobileKeys = (key, e) => {

    //     console.log(key, "key of mobile");
    //     console.log(e, "e event of mobile");

    //     const countryCode = e?.slice(0, 2);
    //     const actualNumber = e?.slice(2);


    //     //if (actualNumber && actualNumber.length === 10) {



    //         setformCollection(prevState => ({
    //             ...prevState,
    //             [key]: `${countryCode}-${actualNumber}`
    //         }));

    // }

    // const handleMobileKeys = (key, value) => {
    //     if (phoneRef.current) {

    //         const countryCode = phoneRef.current?.state?.selectedCountry?.countryCode;


    //         const phoneNumber = value.slice(countryCode.length); // Extract the phone number part

    //         const formattedNumber = `${countryCode}-${phoneNumber}`;

    //         // Update the state
    //         setformCollection(prevState => ({
    //             ...prevState,
    //             [key]: formattedNumber
    //         }));
    //     } else {
    //         console.error('Phone input reference is not available');
    //     }
    // };
    const handleMobileKeys = (key, value, countryData) => {
        if (countryData && value) {
            const countryCode = countryData.dialCode || "";
            const phoneNumber = value.replace(countryCode, ""); // Extract the phone number part by removing the country code

            setformCollection(prevState => ({
                ...prevState,
                [key]: `${countryCode}-${phoneNumber.trim()}`
            }));
        }
    };


    const handleRazorpaySuccess = (response) => {

        notify("Payment Successful!", "success");
        //console.log(response, "success Response from RAZORPAY");
    };

    const handleRazorpayFailure = (response) => {
        //console.log(response, "response error from rpay")

        notify("Payment Failed or Cancelled", "error");
    };


    const handleCountryKeys = async (key, value) => {

        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

            setAddress((prevState) => ({ ...prevState, city: "", state: "" }));

            try {
                const response = await axios.post(
                    `${BASEPATH}${STATES_FROM_BACKEND}`,
                    {
                        country_id: value,
                    }, {
                    headers: {
                        'MDnD_auth': `${token}`,

                    },

                    timeout: 60000,
                    responseType: 'json',
                }
                );
                if (response.data.status == "success") {
                    setAddress((prevState) => ({ ...prevState, state: response.data.data }));
                }
                else {
                    setAddress((prevState) => ({ ...prevState, state: "" }));
                }



            }

            catch (error) {
                console.error("error fetching States for this country", error);
            }




        }

    }

    const handleStateKeys = async (key, value) => {


        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

            setAddress((prevState) => ({ ...prevState, city: "" }));

            try {
                const response = await axios.post(
                    `${BASEPATH}${CITIES_FROM_BACKEND}`,
                    {
                        state_id: value,
                    }, {
                    headers: {
                        'MDnD_auth': `${token}`,

                    },

                    timeout: 60000,
                    responseType: 'json',
                }
                );
                if (response.data.status == "success") {
                    setAddress((prevState) => ({ ...prevState, city: response.data.data }));
                }
                else {
                    setAddress((prevState) => ({ ...prevState, city: "" }));
                }



            }

            catch (error) {
                console.error("error fetching States for this country", error);
            }



        }




    }

    const handleCityKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }
        }

    }

    //newly added..
    const handlePanKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value !== "") {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }
        }
    }

    const handleRadioKeys = (key, e) => {
        //console.log(e.target.value, "e.value checkbox");
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: prevState[key] === 'Yes' ? '' : 'Yes'
            }));
            // if (e.target.value !== "") {
            //     //validationmessage[key]="";
            //     setValidationMessage(prevState => ({
            //         ...prevState,
            //         [key]: ""
            //     }));
            // }
        }
    }

    const handleChange = (event) => {

        setformCollection(prevState => ({
            ...prevState,
            amount: Number(event?.target.value),
            paymentgateway: "razorpay",
            currency: "INR"
        }));
    };

    //console.log(selectedValue, "selected value");

    const handleAudioKeys = (key, value) => {
        // const key = event.target.name;
        // const file = event.target.files[0];

        // if (key && file) {
        //     const audioURL = URL.createObjectURL(file);

        //     setformCollection(prevState => ({
        //         ...prevState,
        //         [key]: file
        //     }));

        //     setAudioPreviews(prevState => ({
        //         ...prevState,
        //         [key]: audioURL
        //     }));
        // }

        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
            if (value.length > 0) {
                //validationmessage[key]="";
                setValidationMessage(prevState => ({
                    ...prevState,
                    [key]: ""
                }));
            }

        }


    };

    const handleFileChange_userpic = (key, event) => {
        //console.log("inside this pic function 1st");
        //console.log(event, "event")
        //const key = event.target.name;
        const file = event.target.files[0];

        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                HandleErrorToast("error", 'Please select a valid image file (JPEG, PNG)', 3000);
                event.target.value = null;
                return;
            }
        }

        if (key) {
            //const imageURL = URL.createObjectURL(file);

            setImage(URL.createObjectURL(file));
            setShowCropper(true);
            setImageChecker(true);

            setkeyforimage(key);


        }



    }

    //console.log(image, "setimage");
    //console.log(showCropper, "showCropper");
    //console.log(imagechecker, "image cheker");

    const handleCrop = async () => {

        //console.log(key,"key in handle crop");
        const cropper = cropperRef.current.cropper;
        const croppedDataUrl = cropper.getCroppedCanvas().toDataURL('image/jpeg');
        setCroppedImage(croppedDataUrl);
        setShowCropper(false);


        const response = await fetch(croppedDataUrl);
        const blob = await response.blob();
        const objectUrl = blob && URL.createObjectURL(blob);

        if (keyforimage && croppedDataUrl) {
            setformCollection((prevState) => ({
                ...prevState,

                [keyforimage]: croppedDataUrl,


            }));
        }

        setImageChecker(false);

        if (objectUrl) { setdummyimg(objectUrl); }

        setShowSaveandCancelbuttonPic(true);
        //setformCollection((prevState) => ({ ...prevState, validation_picture: "" }));


    };

    const handleCancelforuserpic = (key) => {
        setformCollection((prevState) => ({
            ...prevState,

            [keyforimage]: "",
            //dummyimg: "",
            //imagechecker: true
        }));

        setImageChecker(true);
        setdummyimg("");
        setShowCropper(false);
        setImage(null);
        setkeyforimage('');
        setCroppedImage(null);
        picInputRef.current.value = '';
        //fileInputRef_userpic.current.value = "";
        setShowSaveandCancelbuttonPic(false);
    }


    // dataSource for selectbox....
    const getDataSourceForSingleSelect = (key) => {
        let dataArray = [];
        for (let resultKey in result) {
            if (result.hasOwnProperty(resultKey) && key === resultKey) {

                dataArray = result[resultKey].split(',');
                break;

            }
        }

        return (
            <SelectBox
                label={single_selectMandatoryField(key)}
                labelMode="floating"
                required={textRequiredField(key)}
                //dataSource={formCollection[`${key}_dataSource`]}
                dataSource={dataArray}
                value={formCollection[key] || ""}
                //ref={element => inputRefs.current[key] = element}
                onValueChanged={(e) => handleSingleSelectKeys(key, e.value)}
            />
        )
    };

    const handleSingleSelectKeys = (key, value) => {
        if (key) {
            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
        if (value !== "") {
            //validationmessage[key]="";
            setValidationMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }
    }

    const handleMultipleSelectKeys = (key, value) => {
        if (key) {



            const updatedArray = Array.isArray(value) ? value : [value];



            const commaSeparatedValue = updatedArray && updatedArray.join(',');


            setformCollection(prevState => ({
                ...prevState,
                [key]: value
            }));

            setNewFormCollection(prevState => ({
                ...prevState,
                [key]: commaSeparatedValue
            }));
        }
        if (value !== "") {
            //validationmessage[key]="";
            setValidationMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }

    }

    const getDataSourceForMultipleSelect = (key) => {
        let dataArray = [];
        for (let resultKey in result) {
            if (result.hasOwnProperty(resultKey) && key === resultKey) {

                dataArray = result[resultKey].split(',').filter(value => value.trim() !== '');
                break;

            }
        }



        return (


            <TagBox
                label={multiple_selectMandatoryField(key)}
                dataSource={dataArray}
                //value={formCollection[key] || ""}
                labelMode="floating"
                required={textRequiredField(key)}
                //ref={element => inputRefs.current[key] = element}
                ref={tagBoxRef}
                onValueChanged={(e) => handleMultipleSelectKeys(key, e.value)}
            />
        )

    }

    //console.log(inputRefs, "inputRefs");




    const handleResetAudio = (key) => {
        setformCollection(prevState => {
            const updatedState = { ...prevState };
            delete updatedState[key];
            return updatedState;
        });

        setAudioPreviews(prevState => {
            const updatedState = { ...prevState };
            delete updatedState[key];
            return updatedState;
        });

        if (inputRefs.current[key]) {
            inputRefs.current[key].value = null;
        }
    };

    //console.log(inputRefs,"inputRefs");

    //handling red * button.....
    const textRequiredField = (key) => {
        if (validation) {
            return validation[key] === "Mandatory" ? true : false;
        }
    }

    //handling mandatory fields as key............................................


    // const formatLabel = (text) => {
    //     if (!text) return '';
    
       
    //     return text
    //         .replace(/_/g, ' ')  
    //         .toLowerCase()  
    //         .replace(/\b\w/g, char => char.toUpperCase()); 
    // };
    const formatLabel = (text) => {
        if (!text) return '';
    
        return text
            .replace(/_/g, ' ')  
            .split(' ')  
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  
            .join(' ');  
    };

    const textMandatoryField = (key) => {
        if (validation) {
            return validation[key] === "Mandatory" ? `${formatLabel(key)} ` : formatLabel(key);
        }
    }




    const dateMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const amountMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };

    const single_selectMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };


    const multiple_selectMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const mobileMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? ` ${formatLabel(key)} *` : formatLabel(key); }
    };
    const percentageMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const timeMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const audioMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const videoMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const imageMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const emailMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const linkMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const numberMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const panMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const countryMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const stateMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };
    const cityMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    };

    const declareMandatoryField = (key) => {
        if (validation) { return validation[key] === "Mandatory" ? `${formatLabel(key)} *` : formatLabel(key); }
    }



    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;




    //end of mandatory fields as key........................
    //console.log(formCollection, "formCollection");
    //console.log(output, "output");
    //console.log(validation, "validation");
    //console.log(checker, "CHECKER");
    //console.log(formData, "formData");

    // validating correctness of each..........................................
    const handleValidation_email = (key) => ({ isValid }) => {
        setCorrectnessCollection(prevState => ({
            ...prevState,
            [`isValid_${key}`]: isValid
        }));

        if (isValid === true) {
            setCorrectMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }


    };
    const handleValidation_date = (key) => ({ isValid }) => {
        setCorrectnessCollection(prevState => ({
            ...prevState,
            [`isValid_${key}`]: isValid
        }));

        if (isValid === true) {
            setCorrectMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }


    };
    const handleValidation_number = (key) => ({ isValid }) => {
        setCorrectnessCollection(prevState => ({
            ...prevState,
            [`isValid_${key}`]: isValid
        }));

        if (isValid === true) {
            setCorrectMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }


    };

    const handleValidation_audio = (key) => ({ isValid }) => {
        setCorrectnessCollection(prevState => ({
            ...prevState,
            [`isValid_${key}`]: isValid
        }));

        if (isValid === true) {
            setCorrectMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }


    };
    const handleValidation_link = (key) => ({ isValid }) => {
        setCorrectnessCollection(prevState => ({
            ...prevState,
            [`isValid_${key}`]: isValid
        }));

        if (isValid === true) {
            setCorrectMessage(prevState => ({
                ...prevState,
                [key]: ""
            }));
        }

    };


    //console.log(formData,"F")
    //console.log(CorrectnessCollection,"correctnesscollection");
    //console.log(NewFormCollection, "NewFormCollection");
    //console.log(formCollection, "formcollection");


    //console.log(correctmessage, "correctmessage");

    //FINAL SUBMISSION...........................................................


    //console.log(output, "output");

    const dummySubmit = () => {

        let duplicate_formCollection = { ...formCollection };
        if (output && Object.keys(output).length > 0) {
            Object.keys(output).forEach((key) => {
                if (output[key] === "date") {
                    if (formCollection[key] !== "") {
                        //do this

                        if (duplicate_formCollection[key] instanceof Date) {
                            const formattedDate = `${String(duplicate_formCollection[key].getDate()).padStart(2, '0')}-${String(duplicate_formCollection[key].getMonth() + 1).padStart(2, '0')}-${duplicate_formCollection[key].getFullYear()}`;

                            if (formattedDate) {
                                duplicate_formCollection[key] = formattedDate;
                            }
                        }
                    }

                }
            });
            setformCollection(duplicate_formCollection);
        }


    }


    const handleSubmit = async () => {




        let messages = {};
        let correctness = {};

        for (const key in validation) {
            if (validation[key] === 'Mandatory' && !formCollection[key]) {
                messages[key] = "This Field is Required!";

            }

            if (key === 'Mobile' && validation[key] === 'Mandatory') {
                const mobileValue = formCollection[key];
                const [countryCode, phoneNumber] = mobileValue ? mobileValue.split('-') : ['', ''];

                if (!phoneNumber || phoneNumber.trim() === '') {
                    messages[key] = "This Field is Required!";
                }
            }

            if (CorrectnessCollection[`isValid_${key}`] === false && formCollection[key] !== "") {
                correctness[key] = "Enter correct Data!";
            }



        }

        if(formData.form_mode === "Paid"){
            if(formCollection.amount === null){
                HandleErrorToast("error","Please Select a Fee", 4000);
                return;
            }
        }

        



        //console.log(messages, "messages");

        setValidationMessage(messages);
        setCorrectMessage(correctness);

        if (NewFormCollection) {

            const [commaSeparatedKey] = Object.keys(NewFormCollection);



            const updatedNewFormCollection = {
                [commaSeparatedKey]: NewFormCollection[commaSeparatedKey]
            };


            for (let key in formCollection) {
                if (formCollection.hasOwnProperty(key) && key !== commaSeparatedKey) {
                    updatedNewFormCollection[key] = formCollection[key];
                }
            }

            // Update NewFormCollection with the new object
            setNewFormCollection(updatedNewFormCollection);
        }
        else {
            setNewFormCollection(formCollection);
        }
        //console.log(formCollection,"formCollection");



        if (Object.keys(messages).length === 0 && Object.keys(correctness).length === 0) {

            setValidationMessage({});
            mutationFinalSubmitDynamicForm.mutate();

        } else {

            return;
        }

    }

    const FinalSubmitForm = () =>
        SAVE_Detailed_form(SAVE_FORM_DATA);

    const SAVE_Detailed_form = async (
        SAVE_FORM_DATA) => {




        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${SAVE_FORM_DATA}`,
                data: {
                    //formCollection: formCollection,
                    formCollection: NewFormCollection,
                    formid: formData.form_id
                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',


            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    //console.log(phoneRef, "phoneref")

    const mutationFinalSubmitDynamicForm = useMutation({
        mutationKey: ["final_submit_dynamicForm"],
        mutationFn: () => FinalSubmitForm(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA from backend");



            if (data.status === "success") {

                if (formData.form_mode === "Paid") {
                    const newdata = {...data.data};
                    


                    newdata.success_url = "/Success";

                    console.log(newdata,"newdata");

                    //razorpayRef.current.triggerPayment(data?.data);
                    if(newdata)
                    {razorpayRef.current.triggerPayment(newdata);}

                }
                else {
                    SuccessNewToaster("success", "Form Submitted Successfully", 3000);

                    window.location.reload();
                 
                    handleCancelforuserpic();



                    setCorrectnessCollection({});

                    for (let key in formCollection) {
                        setformCollection(prevState => ({
                            ...prevState,
                            [key]: ""
                        }));
                    }

                    setformCollection({});
                    setDefaultCountry("");

                    setNewFormCollection({});

                    setcondition(prevState => ({
                        ...prevState,
                        message: "",
                        status: "",
                        show_to_public: "",
                    }));


                    

                    window.location.reload();

                    if (dateBoxRef.current) {
                        dateBoxRef.current.instance.option('value', null);
                    }



                    if (tagBoxRef.current) {
                        tagBoxRef.current.instance.option('value', null);
                    }

                    if (phoneRef.current) {
                        // phoneRef.current.instance.option('value', null);
                        // phoneRef.current.instance.option('defaultCountry', "");

                        // phoneRef.current.setState({
                        //     selectedCountry: {},
                        // });
                        phoneRef.current.props.option('defaultCountry', "");
                        phoneRef.current.props.value = "";
                        phoneRef.current.state.selectedCountry = {};
                        phoneRef.current.state.formattedNumber = "";

                    }




                }











            }
            else {
                setCorrectnessCollection({});
                // for(let key in formCollection){
                //     setformCollection(prevState => ({
                //         ...prevState,
                //         [key]: ""
                //     }));
                // }

                setformCollection({});
                setDefaultCountry("");
                handleCancelforuserpic();



                if (dateBoxRef.current) {
                    dateBoxRef.current.instance.option('value', null);
                }



                if (tagBoxRef.current) {
                    tagBoxRef.current.instance.option('value', null);
                }

                if (phoneRef.current) {
                    phoneRef.current.instance.option('value', null);
                }

                setNewFormCollection({});
                //setCounter(counter+1);
            }
        },
        onError: (error) => {

            console.error(error);

        },
    });


    //refetch the main list forms after successful submit..

    const currentDate = new Date();

    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    const [defaultCountry, setDefaultCountry] = useState("in");


    //console.log(output, "output");

    //field name desc...
    const textdesc = (key) => {
        if (updatedFieldNameDesc &&
            updatedFieldNameDesc.hasOwnProperty(key) &&
            updatedFieldNameDesc[key] !== undefined &&
            updatedFieldNameDesc[key] !== null &&
            updatedFieldNameDesc[key] !== "") {
            return <p>{updatedFieldNameDesc[key]}</p>;
        }
        return <p></p>;
    };

    //new code..
    const handleButtonClick = () => {

        if (picInputRef.current) {
            picInputRef.current.value = null;
          }
        picInputRef.current.click(); 
    };

    //console.log(inrRadioObj,"inrRadioObj");

    // const [images, setImages] = useState([]);

    // const extractImagesFromHtml = (htmlContent) => {
    //     const imagePattern = /<img\s+src="([^"]*)"/g;
    //     const images = [];
    //     let match;
    
    //     while ((match = imagePattern.exec(htmlContent)) !== null) {
    //       images.push(match[1]); // Extract base64 string from src attribute
    //     }
    
    //     return images;
    //   };
    
    //   useEffect(() => {
    //     if (sanitizedFormDesc) {
    //       const extractedImages = extractImagesFromHtml(sanitizedFormDesc);
    //       setImages(extractedImages);
    //     }
    //   }, [sanitizedFormDesc]);

      console.log(formCollection,"formCollection");

    return (

        <>

            {/* 
           {formData &&
            <Helmet>
                <meta property="og:url" content={window.location?.href} />
                <meta property="og:type" content="website" />
                {
                    formData?.form_name && (
                        <meta property="og:description" content={String(formData?.form_name)} />
                    )
                }


                {formData?.form_image && (
                    <>
                    <meta property="og:image" content={`${BASEPATH}public/images/form_images/${formData.form_image}`} />
                    <meta property="og:image:width" content="600" />  
                    <meta property="og:image:height" content="315" /> 
                    </>)}

            </Helmet> } */}

            {/* {formData && (
    <Helmet>
        <meta property="og:url" content={String(window.location?.href)} />
        <meta property="og:type" content="website" />
        {formData?.form_name && (
            <meta property="og:description" content={String(formData?.form_name || "Default description")} />
        )}
        {formData?.form_image && (
            <>
                <meta property="og:image" content={`${String(BASEPATH)}public/images/form_images/${String(formData.form_image)}`} />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="315" />
            </>
        )}
    </Helmet>
)} */}

{/* <Helmet>
        <meta property="og:url" content={window.location?.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={formData?.form_name || "Default description"} />
        <meta property="og:description" content="Check out this Form on MDnD" />
        <meta property="og:image" content={`${BASEPATH}public/images/form_images/${formData.form_image}`} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
      </Helmet>  */}

            {/* <HeadProvider>
                <div>
                    {formData && (
                        <>
                            <Meta property="og:url" content={window.location?.href} />
                            <Meta property="og:type" content="website" />
                            {formData?.form_name && (
                                <Meta property="og:description" content={String(formData?.form_name || "Default description")} />
                            )}
                            {formData?.form_image && (
                                <>
                                    <Meta property="og:image" content={`${BASEPATH}public/images/form_images/${formData.form_image}`} />
                                    <Meta property="og:image:width" content="600" />
                                    <Meta property="og:image:height" content="315" />
                                </>
                            )}
                        </>
                    )}
                </div>
            </HeadProvider> */}




            <div className="container mb-5 mb-lg-2">
                <div className="m-auto mb-3 mt-5 mt-lg-2 rounded">
                    { isValidImage(formData.form_image) && imageExists(`${BASEPATH}public/images/form_images/${formData.form_image}`)  ?
                        (
                            <img
                                className="d-flex m-auto RegisterForm"
                                src={`${BASEPATH}public/images/form_images/${formData.form_image}`}
                                alt="Form Photo"
                                //onError={handleImageError}
                                style={{ width: '800px', height: 'auto', cursor: 'pointer', }}
                            //onClick={() => handleImageClick(data.blog_id)}
                            />
                        ) : (
                            <Noimage_circle />
                        )}
                </div>
                <div className="bgForm m-auto RegisterForm mb-2 py-3 mt-3">

                    <h5 className="text-center mb-2 mt-1"><b>{formData.form_name}</b></h5>


                <div>
               
                </div>
                    <div>
                        <div className="text-center px-5" dangerouslySetInnerHTML={{ __html: sanitizedFormDesc }} />
                    </div>

                    <ul className="p-0 mx-lg-3 mx-2 row ">





                        { condition?.show_to_public === "Y" && condition?.status === "Y" && output && Object.keys(output).length > 0 ? (

                            <div>

                                {Object.keys(output).map(key => (
                                    <li className="col-12 mb-2" key={key}>



                                        {output[key] === "date" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{dateMandatoryField(key)}</label> */}
                                                <DateBox
                                                    type="date"
                                                    label={dateMandatoryField(key)}
                                                    labelMode="floating"
                                                    displayFormat="dd-MM-yyyy"
                                                    required={textRequiredField(key)}
                                                    value={formCollection[key] || ""}
                                                    max={currentDate}
                                                    acceptCustomValue={false}
                                                    onValueChanged={(e) => handleDateKeys(key, e.value)}
                                                //ref={element => inputRefs.current[key] = element}
                                                >


                                                </DateBox>
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                {
                                                    correctmessage[key] && correctmessage[key] !== "" && (
                                                        <span style={{ color: 'red' }}>{correctmessage[key]}</span>
                                                    )
                                                }
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}

                                        {output[key] === "single_select" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label> {single_selectMandatoryField(key)}</label> */}

                                                {getDataSourceForSingleSelect(key)}
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}

                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>
                                        }




                                        {output[key] === "amount" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label> {amountMandatoryField(key)}</label> */}
                                                <NumberBox
                                                    label={amountMandatoryField(key)}
                                                    labelMode="floating"
                                                    required={textRequiredField(key)}
                                                    onValueChanged={(e) => handleAmountKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                >
                                                    <Validator onValidated={handleValidation_number(key)}>

                                                        {/* <NumericRule message="Only numeric values are allowed" /> */}
                                                        <PatternRule
                                                            pattern="^\d*\.?\d*$"
                                                            message="Only numeric values are allowed"
                                                        />
                                                    </Validator>
                                                </NumberBox>
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                {
                                                    correctmessage[key] && correctmessage[key] !== "" && (
                                                        <span style={{ color: 'red' }}>{correctmessage[key]}</span>
                                                    )
                                                }
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>
                                        }


                                        {output[key] === "multiple_select" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{multiple_selectMandatoryField(key)} </label> */}

                                                {/* <TagBox
            label={multiple_selectMandatoryField(key)}
            labelMode="floating"
            ref={element => inputRefs.current[key] = element}
        /> */}
                                                {getDataSourceForMultipleSelect(key)}
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}

                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}

                                        {output[key] === "mobile" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{mobileMandatoryField(key)} </label> */}
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}


                                                {/* new code    */}
                                                <PhoneInput
                                                    country={defaultCountry}
                                                    //defaultValue={`+91`}
                                                     placeholder={`        ${mobileMandatoryField(key)}`}

                                                    value={formCollection[key] || ""}
                                                    name="mobile"
                                                    ref={phoneRef}
                                                    onChange={(value, countryData) => handleMobileKeys(key, value, countryData)}


                                                    countryCodeEditable={false}
                                                    enableSearch={true}
                                                    className="dx-texteditor-input phoneFieldBox mt-2 bg-white"
                                                    areaCodes={true}

                                                />

                                                {/* {tenruleformobile && <span style={{ color: 'red' }}>{tenruleformobile}</span>} */}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}


                                        {output[key] === "percentage" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{percentageMandatoryField(key)}</label> */}
                                                <NumberBox
                                                    label={percentageMandatoryField(key)}
                                                    labelMode="floating"
                                                    onValueChanged={(e) => handlePercentageKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                    required={textRequiredField(key)}
                                                //ref={element => inputRefs.current[key] = element}
                                                >
                                                    <Validator>

                                                        <NumericRule message="Only numeric values are allowed" />
                                                    </Validator>
                                                </NumberBox>

                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}



                                        {output[key] === "time" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{timeMandatoryField(key)}</label> */}
                                                <DateBox
                                                    type="time"
                                                    label={timeMandatoryField(key)}
                                                    displayFormat="HH:mm"
                                                    pickerType="rollers"
                                                    labelMode="floating"
                                                    required={textRequiredField(key)}
                                                    onValueChanged={(e) => handleTimeKeys(key, e.value)}
                                                    ref={dateBoxRef}
                                                //value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}


                                        {output[key] === "text" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{textMandatoryField(key)}</label> */}
                                                <TextBox
                                                    //label={key}
                                                    label={textMandatoryField(key)}
                                                    className="dx-field-item-required"
                                                    required={textRequiredField(key)}

                                                    labelMode="floating"
                                                    onValueChanged={(e) => handleTextKeys(key, e.value)}
                                                    //ref={(element) => { inputRefs.current[key] = element }}
                                                    value={formCollection[key] || ""}
                                                />

                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}

                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                                {/* <p className="mt-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
                                            </div>}


                                        {output[key] === "audio" && (
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{audioMandatoryField(key)}</label> */}


                                                <TextBox
                                                    type="url"
                                                    label={audioMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    labelMode="floating"
                                                    onValueChanged={(e) => handleAudioKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                >
                                                    <Validator onValidated={handleValidation_audio(key)}>

                                                        <PatternRule pattern={urlPattern} message="Please enter a valid audio URL" />
                                                    </Validator>
                                                </TextBox>






                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                {
                                                    correctmessage[key] && correctmessage[key] !== "" && (
                                                        <span style={{ color: 'red' }}>{correctmessage[key]}</span>
                                                    )
                                                }
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>
                                        )}
                                        {output[key] === "video" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{videoMandatoryField(key)}</label> */}

                                                <TextBox
                                                    type="url"
                                                    label={videoMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    labelMode="floating"
                                                    onValueChanged={(e) => handleVideoKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                >
                                                    <Validator>

                                                        <PatternRule pattern={urlPattern} message="Please enter a valid audio URL" />
                                                    </Validator>
                                                </TextBox>




                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}

                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}


                                        {output[key] === "image" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{imageMandatoryField(key)}</label> */}

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    ref={picInputRef}
                                                    style={{ display: 'none' }}
                                                    //ref={element => inputRefs.current[key] = element}
                                                    //style={{ display: "none" }}
                                                    //value={formCollection[key] || ""}
                                                    onChange={(event) => handleFileChange_userpic(key, event)}

                                                />
                                                <Button
                                                    className="btn-primary text-white py-2 px-3 text-center mb-2"
                                                    onClick={handleButtonClick}
                                                   
                                                >
                                                    <FiUpload className="me-1" />
                                                    Upload Image
                                                </Button>

                                                {showCropper ? (
                                                    <>
                                                        <Cropper
                                                            src={image}
                                                            style={{ height: 200, width: '50%' }}
                                                            // Cropper.js options
                                                            aspectRatio={1} // Adjust the aspect ratio for a small but lengthy rectangle
                                                            guides={false}
                                                            ref={cropperRef}
                                                            viewMode={1} // Restrict cropping within the canvas
                                                            dragMode="move" // Allow moving the image within the crop box
                                                            cropBoxMovable={true} // Prevent the crop box from being moved
                                                            cropBoxResizable={true} // Prevent the crop box from being resized
                                                            autoCropArea={1} // Automatically fit the crop box within the canvas
                                                        //cropend={handleCrop}
                                                        />
                                                        <button onClick={handleCrop}> <FontAwesomeIcon icon={faSave} /></button>
                                                        <button onClick={handleCancelforuserpic}><FontAwesomeIcon icon={faTimes} /></button>
                                                    </>
                                                ) : (null)}

                                                {imagechecker ? (null) : (

                                                    <img
                                                        src={dummyimg}
                                                        className="img-fluid object-cover"
                                                        alt="cropped"
                                                        loading="lazy"
                                                        style={{ width: '200px', height: '200px' }}
                                                    //onMouseEnter={() => setIsHovered(true)}
                                                    //onMouseLeave={() => setIsHovered(false)}
                                                    />
                                                )}

                                                {showSaveandCancelbuttonPic &&
                                                    <div>

                                                        <i class="fas fa-times cancelIcon"
                                                            onClick={handleCancelforuserpic}
                                                        ></i></div>}

                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}

                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}
                                        {output[key] === "email" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{emailMandatoryField(key)}</label> */}

                                                <TextBox
                                                    type="email"
                                                    label={emailMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    labelMode="floating"
                                                    value={formCollection[key] || ""}
                                                    //ref={element => inputRefs.current[key] = element}
                                                    onValueChanged={(e) => handleEmailKeys(key, e.value)}
                                                >
                                                    <Validator onValidated={handleValidation_email(key)}>

                                                        <EmailRule message="Please enter a valid email address" />
                                                    </Validator>

                                                </TextBox>
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                {
                                                    correctmessage[key] && correctmessage[key] !== "" && (
                                                        <span style={{ color: 'red' }}>{correctmessage[key]}</span>
                                                    )
                                                }
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}
                                        {output[key] === "link" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{linkMandatoryField(key)}</label> */}
                                                <TextBox
                                                    type="url"
                                                    label={linkMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    labelMode="floating"
                                                    onValueChanged={(e) => handleLinkKeys(key, e.value)}
                                                    //ref={element => inputRefs.current[key] = element}
                                                    value={formCollection[key] || ""}
                                                >
                                                    <Validator >

                                                        <PatternRule pattern={urlPattern} message="Please enter a valid URL" />
                                                    </Validator>
                                                </TextBox>
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                {
                                                    correctmessage[key] && correctmessage[key] !== "" && (
                                                        <span style={{ color: 'red' }}>{correctmessage[key]}</span>
                                                    )
                                                }
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}
                                        {output[key] === "number" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{numberMandatoryField(key)} </label> */}
                                                <NumberBox
                                                    label={numberMandatoryField(key)}
                                                    labelMode="floating"
                                                     required={textRequiredField(key)}
                                                    ref={element => inputRefs.current[key] = element}
                                                    onValueChanged={(e) => handleNumberKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}


                                        {output[key] === "pan" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{panMandatoryField(key)}</label> */}
                                                <TextBox

                                                    label={panMandatoryField(key)}
                                                    labelMode="floating"
                                                     required={textRequiredField(key)}
                                                    value={formCollection[key] || ""}
                                                    onValueChanged={(e) => handlePanKeys(key, e.value)}
                                                //ref={element => inputRefs.current[key] = element}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}


                                        {output[key] === "country" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{countryMandatoryField(key)}</label> */}
                                                <SelectBox
                                                    dataSource={address.country}
                                                    displayExpr="country_name"
                                                    valueExpr="country_id"
                                                    label={countryMandatoryField(key)}
                                                    value={formCollection[key] || ""}
                                                     required={textRequiredField(key)}
                                                    searchEnabled={true}
                                                    onValueChanged={(e) => handleCountryKeys(key, e.value)}
                                                    labelMode="floating"
                                                //ref={element => inputRefs.current[key] = element}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}
                                        {output[key] === "state" &&

                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{stateMandatoryField(key)}</label> */}
                                                <SelectBox
                                                    dataSource={address.state}
                                                    label={stateMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    displayExpr="state_name"
                                                    valueExpr="state_id"
                                                    labelMode="floating"
                                                    searchEnabled={true}
                                                    onValueChanged={(e) => handleStateKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}
                                        {output[key] === "city" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{cityMandatoryField(key)}</label> */}
                                                <SelectBox
                                                    dataSource={address.city || null}
                                                    label={cityMandatoryField(key)}
                                                     required={textRequiredField(key)}
                                                    labelMode="floating"
                                                    displayExpr="city_name"
                                                    valueExpr="city_id"
                                                    searchEnabled={true}
                                                    onValueChanged={(e) => handleCityKeys(key, e.value)}
                                                    value={formCollection[key] || ""}
                                                //ref={element => inputRefs.current[key] = element}
                                                />
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}

                                        {output[key] === "declare" &&
                                            <div className="bg-white py-2 px-2 RegisterFormdiv">
                                                {/* <label>{declareMandatoryField(key)}</label> */}
                                                <label>
                                                    <input
                                                        type="checkbox"

                                                        checked={formCollection[key] === 'Yes'}
                                                        onChange={(e) => handleRadioKeys(key, e)}
                                                    />
                                                    {declareMandatoryField(key)}
                                                </label>
                                                {validationmessage[key] && validationmessage[key] !== "" && (
                                                    <span style={{ color: 'red' }}>{validationmessage[key]}</span>
                                                )}
                                                <p className="mt-2 mb-0">{textdesc(key)}</p>
                                            </div>}

                                    </li>
                                ))}

                                {formData.form_mode === "Paid" ? (
                                    <div className="d-flex mx-lg-5 mx-2 justify-content-center mb-5">
                                        {Object.keys(inrRadioObj).map((key) => (
                                            <div key={key} className="me-4">
                                                <input
                                                    type="radio"
                                                    id={`radio${key}`}
                                                    name="inrradios"
                                                    value={inrRadioObj[key]}
                                                    checked={formCollection.amount === inrRadioObj[key]}
                                                    onChange={handleChange}
                                                    className="me-1"
                                                />
                                                <label>{`Rs. ${inrRadioObj[key]}`}</label>
                                            </div>
                                        ))}



                                    </div>


                                ) : (null)}

                                <div className="d-flex justify-content-center mt-2">
                                    <button className="button" onClick={handleSubmit}>Submit</button>
                                </div>





                            </div>) : (
                            <>
                                <h5>{condition?.message || ""}</h5>

                                {/* <NoDataFound /> */}
                            </>)}
                    </ul>




                    <RazorpayPayment
                        ref={razorpayRef}
                        onSuccess={handleRazorpaySuccess}
                        onFailure={handleRazorpayFailure}
                    />
                </div>
            </div>
        </>)
}