import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { customEncrypt } from "../utils/encryptAndDecrypt";
import getEventKeys from "../Api/getEventKeys";

const PreviewEvent = ({ show, onClose, message, imageSource }) => {

  const COUNTRY_KEY = getEventKeys().countryKey;
  const EVENT_KEY = getEventKeys().eventKey;

  const navigate = useNavigate();
  const encodeBase64 = (str) => {
    try {
      const prefix = "xy4AT";
      const combinedStr = prefix + str.toString();
      return btoa(combinedStr);
    } catch (error) {console.log(error)}
  };

  const encodeBase64_new = (str,key) => {
    try {
      const prefix = key?.toString();
      const combinedStr = prefix + str.toString();
      return btoa(combinedStr);
    } catch (error) {console.log(error)}
  };

  const navigatePreview = () => {
    try {
      if (message && message[0]) {
        const { country_id, event_id, event_title } = message[0];
        const modifiedTitle = event_title
          ? event_title.split(" ").join("_")
          : "Event";
        const encyptedData = customEncrypt("yes");
        navigate(
          `/event/${modifiedTitle}?ci=${encodeBase64_new(
            country_id, COUNTRY_KEY)}&e=${encodeBase64_new(event_id,EVENT_KEY)}&editEvent=${encyptedData}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="mx-auto warningPopupWidth">
        <Modal.Body className="text-center">
          <img src={imageSource} className="w-100"></img>
          <div className="d-flex justify-content-end mt-3">
            <button
              className={
                "btn py-1 px-3 filterBtn m-0 text-nowrap me-2 brandcolor"
              }
              onClick={() => onClose()}
            >
              Cancel
            </button>

            <button className="button" onClick={(e) => navigatePreview(e)}>
              Preview Event
            </button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PreviewEvent;
