import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { useFormik } from "formik";
import { signupSchema } from "../Schemas/index.js";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles/Regsiter.css";
import Button from "devextreme-react/button";
import { REGISTRATION } from "../Api/api.js";
import { makePostRequest } from "../Api/requestMethods.js";
import notify from "devextreme/ui/notify";
import { ERROR, DURATION, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import Notification from "../components/Notification.js";
import { EmailValidation } from "../utils/commonUtils.js";
import { CHECK_VALID_EMAIL } from "../Api/api.js";
import successImage from "./static/Green-Check.png";
import Loading from "../components/Loading.js";

const initialValues = {
    name: "",
    email: "",
    password: "",
    country_code: "",
    mobile: "",
};

export default function RegisterInHeader() {
    const [showModal, setShowModal] = useState(false);
    const [messageData, setMessageData] = useState(null);
    const [mobileValues, setMobileValues] = useState("");
    const [passwordMode, setPasswordMode] = useState("password");
    const [emailError, setError] = useState({
        isError: false,
        errorMsg: "",
    });

    const phoneRef = useRef(null);
    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: signupSchema,
        onSubmit: async (values, { setSubmitting }) => {
            // if (emailError.isError && emailError.errorMsg !== "") return;
            setSubmitting(true);
            const updatedValues = { ...values, mobile: mobileValues };
            await registrationMutation.mutateAsync(updatedValues);
            setSubmitting(false);
        },
    });



    const validateEmailFn = async (data) => {
        try {
            const response = await makePostRequest(CHECK_VALID_EMAIL, data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const validateEmail = useMutation({
        mutationFn: (email) => validateEmailFn(email),
        onSuccess: (data) => {
            if (data.status === SUCCESS) {
                setError((prev) => ({
                    ...prev,
                    isError: false,
                    errorMsg: "",
                }));
            }
        },
        onError: (error) => {
            setError((prev) => ({
                ...prev,
                isError: true,
                errorMsg: error?.response?.data?.message,
            }));
        },
    });

    useEffect(() => {
        if (EmailValidation(values.email)) {
            const body = {
                email: values.email,
            };
            validateEmail.mutate(body);
        }
    }, [values.email]);

    const handleMobileChange = (value) => {
        if (phoneRef && phoneRef !== null) {
            const selectedCountry = phoneRef.current?.state?.selectedCountry;
            const countryCode = selectedCountry?.countryCode || "";
            const dialCode = selectedCountry?.dialCode || "";
            const mobile = value.replace(`${dialCode}`, "");
            setMobileValues(mobile);
            setFieldValue("country_code", `+${countryCode}`);
            setFieldValue("mobile", mobile);
        }

        handleChange({ target: { name: "mobile", value: value } });

        handleBlur({ target: { name: "mobile", value: value } });
    };

    const registration = async (data) => {
        try {
            const response = await makePostRequest(REGISTRATION, data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const passwordButton = useMemo(
        () => ({
            icon: passwordMode === "password" ? "eyeclose" : "eyeopen",
            stylingMode: "text",
            onClick: () => {
                setPasswordMode((prevPasswordMode) =>
                    prevPasswordMode === "text" ? "password" : "text"
                );
            },
        }),
        [passwordMode, setPasswordMode]
    );

    const registrationMutation = useMutation({
        mutationKey: ["registration"],
        mutationFn: (registrationData) => registration(registrationData),
        onSuccess: (data) => {
            if (data.status === "failed") {
                notify(data.message, ERROR, DURATION);
            }
            if (data.status === "success") {
                notify(data.message, SUCCESS);
                setMessageData(data.message);
                setShowModal(true);
                resetForm();
            }
        },
        onError: (error) => {
            notify(error.message, ERROR, DURATION);
        },
    });
    const ExitPopup = () => {
        setShowModal(false);
    };

    var CHECK_CONDTION;
    if (errors.email && touched.email) {
        CHECK_CONDTION = false;
    } else {
        CHECK_CONDTION = true;
    }

    return (
        <>
            <div className="loginBgBanner d-flex ">
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center height-self-center mt-lg-5">
                        <div className="col-lg-4 col-md-12 align-self-center">
                            <div className="user-login-card bg-body shadow-sm">
                                <div className="text-center">
                                    <h4>Register</h4>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <Notification
                                        show={showModal}
                                        onClose={ExitPopup}
                                        message={messageData}
                                        imageSource={successImage}
                                        isWelcomeEnabled={true}
                                    />
                                    <div className="mb-3">
                                        <TextBox
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            className="dx-field-item-required"
                                            // label="Name"
                                            label="Name"
                                            labelMode="floating"
                                            onFocusOut={(e) =>
                                                handleBlur({
                                                    target: { name: "name", value: e.value },
                                                })
                                            }
                                            onValueChanged={(e) =>
                                                handleChange({
                                                    target: { name: "name", value: e.value },
                                                })
                                            }
                                        />

                                        {errors.name && touched.name && (
                                            <p className="error-message mt-1">{errors.name}</p>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        <TextBox
                                            type="text"
                                            name="email"
                                            label="Email"
                                            labelMode="floating"
                                            value={values.email}
                                            className="dx-field-item-required"
                                            onFocusOut={(e) =>
                                                handleBlur({
                                                    target: { name: "email", value: e.value },
                                                })
                                            }
                                            onValueChanged={(e) =>
                                                handleChange({
                                                    target: { name: "email", value: e.value },
                                                })
                                            }
                                        />

                                        {errors.email && touched.email && (
                                            <p className="error-message mt-1">{errors.email}</p>
                                        )}
                                        {CHECK_CONDTION &&
                                            emailError.isError &&
                                            emailError.errorMsg !== "" && (
                                                <p className="error-message mt-1">{emailError.errorMsg}</p>
                                            )}
                                    </div>

                                    <div className="d-flex align-items-center mb-2 phoneNumberFieldPad">
                                        <PhoneInput
                                            defaultCountry="USA"
                                            placeholder="Phone number"
                                            value={values.mobile}
                                            name="mobile"
                                            onChange={(e) => handleMobileChange(e)}
                                            onBlur={() => {
                                                handleBlur({ target: { name: "mobile" } });
                                            }}
                                            countryCodeEditable={false}
                                            enableSearch={true}
                                            className="dx-texteditor-input phoneFieldBox mt-2 bg-white"
                                            areaCodes={true}
                                            ref={phoneRef}
                                        />
                                    </div>

                                    {errors.mobile && touched.mobile && (
                                        <p className="error-message mt-1">
                                            {errors.mobile}
                                            {touched.mobile}
                                        </p>
                                    )}

                                    <div className="mb-3">
                                        <TextBox
                                            label="Password"
                                            labelMode="floating"
                                            className="dx-field-item-required"
                                            mode={passwordMode}
                                            value={values.password}
                                            onFocusOut={(e) =>
                                                handleBlur({
                                                    target: { name: "password", value: e.value },
                                                })
                                            }
                                            onValueChanged={(e) =>
                                                handleChange({
                                                    target: { name: "password", value: e.value },
                                                })
                                            }
                                        >
                                            <TextBoxButton
                                                name="password"
                                                location="after"
                                                options={passwordButton}
                                            />
                                        </TextBox>
                                        {errors.password && touched.password && (
                                            <p className="error-message mt-1">{errors.password}</p>
                                        )}
                                    </div>

                                    <div className="full-button">
                                        <div className="iq-button">
                                            <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                                className="btn position-relative h-auto"
                                                text={isSubmitting ? "" : "Register"}
                                            >
                                                {isSubmitting && <Loading />}
                                            </Button>
                                        </div>
                                        <p className="mt-3 text-center">Create profile as artist, fan, organiser.</p>
                                    </div>
                                </form>
                            </div>



                        </div></div></div></div>
        </>
    );
}
