import React from "react";
import RagaBank from "./RagaBank.png"

export default function RagaBank_logo(){

    return(
        <>
         <img src={RagaBank} alt="No Data Found" className="BannerImg" />
        
        </>
    )
}