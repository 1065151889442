import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import { jwtDecode } from "jwt-decode";
import TextArea from "devextreme-react/text-area";
import Modal from "react-bootstrap/Modal";
import TextBox, { TextBoxTypes } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import About_myProfile from "./About_myProfile";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import Messages_myprofile from "./Messages_myprofile";
import MyCalendar_myprofile from "./MyCalendar_myprofile";
import AvatarEditor from "react-avatar-editor";
import ContinueWatching from "../../../MDnDRecords/MDnDRecords";
import { IoChevronBack } from "react-icons/io5";
import { IoShareSocial } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import { isValidImage } from "../../../global/ImageCheck";
import { imageExists } from "../../../global/ImageCheck";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  POST_SINGLE_PROFILE,
  GET_IMAGES_PROFILE,
  LIKE_SINGLE_POST,
  EDITCOMMENT_SINGLE_POST,
  DELETECOMMENT_SINGLE_POST,
  DELETE_SINGLE_POST,
  GET_POSTS_PROFILE,
  COMMENT_SINGLE_POST,
  GET_POSTS_COMMENTS,
  GET_LIKES,
  GET_LIKES_BY_USER,
  GET_USERPROFILE,
  CHANGE_PROFILE_PIC_USER,
} from "../../../Api/api";

import SocialMedia_myprofile from "./SocialMedia_myprofile";
import ShareToSocialMedia_myprofile from "./ShareToSocialMedia_myprofile";

import { BASEPATH } from "../../../config/config";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaArrowLeft } from "react-icons/fa";
import { SHARE_BASEPATH } from "../../../config/config";
import MyForms from "./MyForms";
import LandingPageDetails from "./LandingPageDetails";

import {
  ArtistallImg,
  Fan_images,
  AdvertiserImg,
  ORGANISER_URL,
} from "../../../Subpages/MainPage/constant";
// import LikeYesorNo from "./LikeYesorNo";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import mlogo from "./Static/mlogo.png";
import "./MyProfile.css";
import TaggedBlogs_myprofile from "./TaggedBlogs_myprofile";
import AccountDetails_myprofile from "./AccountDetails_myprofile";
import MyVideos_myprofile from "./MyVideos_myprofile";
import Followers_myprofile from "./Followers_myprofile";

import { useParams } from "react-router-dom";
import Following_myprofile from "./Following_myprofile";

import { Helmet } from "react-helmet";
import Edit_myprofile from "./Edit_myprofile";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//newly added
import { FiUpload } from "react-icons/fi";
import { formatDistanceToNow } from "date-fns";
import { Dropdown } from "react-bootstrap";
import Noimage_circle from "../../../global/NoImage_circle";
import Noimage_profilepic from "../../../global/NoImage_profilepic";
import NoDataFound from "../../../global/NoDataFound";
import getProfileKey from "../../../Api/getProfileKey";
const MyProfile = () => {

  const PROFILE_KEY = getProfileKey().profileKey;

  //newly added
  const [loading, setLoading] = useState(true);

  const [loadingLikes, setLoadingLikes] = useState({});

  const { divid } = useParams();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (user?.rolename === "advertiser") {
      setActiveTab("about");
    } else if (user?.rolename === "fan") {
      setActiveTab("about");
    } else if (divid) {
      if (divid === "video") {
        setActiveTab("videos");
      } else if (divid === "followers") {
        setActiveTab("followers");
      } else if (divid === "following") {
        setActiveTab("following");
      }
    } else {
      setActiveTab("post");
    }
  }, []);

  const token = localStorage.getItem("MDnD_auth");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [photoOrganiser, setPhotoOrganiser] = useState({});

  const eventhandleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // -----------------------------------------------------------------------------------------
  const [show, setShow] = useState(false);

  const ehandleShow = () => setShow(false);
  const eventhandleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };

  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // ------------------------------------------D--------------------------------------------------------------------

  const queryClient = useQueryClient();
  const fileInputRef = useRef();
  const fileInputRef_userpic = useRef();
  const [counter, setCounter] = useState(0);
  const [filteredPostCollection, setFilteredPostCollection] = useState({});
  const [newlikedstatus, setnewLikedStatus] = useState("");

  const [showSaveandCancelbuttonPic, setShowSaveandCancelbuttonPic] =
    useState(false);

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef(null);

  const [popup, setPopup] = useState({
    popupVisible: false,
    deleteornot: false,
    commentbox_visible: false,
    names_likes: false,
    //popupDetails: "",
  });

  const SuccessSVG = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 11.8333L10.1667 14.3333L14.3333 8.5M21 11C21 12.3132 20.7413 13.6136 20.2388 14.8268C19.7363 16.0401 18.9997 17.1425 18.0711 18.0711C17.1425 18.9997 16.0401 19.7363 14.8268 20.2388C13.6136 20.7413 12.3132 21 11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7363 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 8.34784 2.05357 5.8043 3.92893 3.92893C5.8043 2.05357 8.34784 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11Z"
        stroke="#008000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const [displayProfilePosts, setDisplayProfilePosts] = useState({
    gridData: "",
    CommentsData: "",
    LikesData: "",
    id_for_like: "",
    likeStatus: "",
    LikesData_likeorUnlike: "",
    gridTrigger: false,
    newfilteredGridData: "",
  });

  const [newupdatedComments, setNewUpdatedComments] = useState([]);

  const [Counterforuserprofile, setCounterforuserprofile] = useState(0);
  const [counterlike, setCounterLike] = useState(0);
  const [arrayoflikenames, setArrayofLikeNames] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [postIdAfterLike, setPostIdAfterLike] = useState(null);
  const [postLikeStatus, setPostLikeStatus] = useState({});
  const [namesofLikesforpostid, setNamesofLikesforPostid] = useState({});
  const [hoveredPostId, setHoveredPostId] = useState(null);

  const [editedComment, setEditedComment] = useState([]);
  const [deleteComment, setDeleteComment] = useState([]);
  const [checkereditComment, setCheckerEditComment] = useState(0);

  const [userProfile, setUserProfile] = useState({
    user_profile_pic: "",
    base64user_profile_pic: "",
    dummyimg: "",
    imagechecker: true,
    validationmsg_profile_pic: "",
  });

  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

  const [postCollection, setPostCollection] = useState({
    post_textbox: "",
    post_image: "",
    base64Image: "",
    videolink: "",
    audiolink: "",
    validation_msg1: "",
    validation_msg2: "",
    regex_msg_video: "",
    regex_msg_audio: "",
    something_about: "",
    id_for_delete: "",
    comment_text: "",
    id_for_comment: "",
    validation_for_comments: "",
    name_of_image: "",

    //newly added
    isValidUrl: false,
  });

  // fetching the user profile..
  const [profileData, setProfileData] = useState({});
  const [isHovered, setIsHovered] = useState(false);

  const [checkerforMenuClassname, setcheckerforMenuClassname] = useState(true);

  //newly added by vignesh july 25th...

  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    //const fetchDataofuser = async () => {
    mutationgetUser.mutate();
    //};
    //fetchDataofuser();
  }, [Counterforuserprofile, updateCounter]);

  const handleProfileUpdateSuccess = () => {
    setUpdateCounter(updateCounter + 1);
  };

  const fetchSingleUser = () => GETUSER(GET_USERPROFILE);

  const mutationgetUser = useMutation({
    mutationKey: ["fetchUser"],
    mutationFn: () => {
      setLoading(true); // Set loading to true when the request starts
      return fetchSingleUser();
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        setProfileData(data?.data[0]);
      } else {
        setProfileData("");
      }
      setLoading(false); // Set loading to false after successful response
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setProfileData("");
      setLoading(false); // Set loading to false after error
    },
  });

  //console.log(profileData, "profileData");

  //new code added by vignesh july 26th..
  const [profileLinkShare, setprofileLinkShare] = useState("");
  const encodeBase64 = (str,key) => {
    //const prefix = "xy4AT";
    const combinedStr = key + str.toString();
    return btoa(combinedStr); 
  };

  useEffect(() => {
    if (profileData) {

      const encodedroleid= encodeBase64(user?.role_id,PROFILE_KEY);
      // artist
      if (profileData.rolename === "artist" && encodedroleid) {


        setprofileLinkShare(`${BASEPATH}profiles/artist/${profileData.name.replace(/[\s.]+/g, '')}?rid=${encodedroleid}`);
      }
      if (profileData.rolename === "organiser" && encodedroleid) {
        setprofileLinkShare(`${BASEPATH}profiles/organiser/${profileData.name.replace(/[\s.]+/g, '')}?rid=${encodedroleid}`);
      }
      
    } else {
      setprofileLinkShare("");
    }
  }, [profileData]);

  
console.log(profileData,"profileData");
  //end

  //only for videos...................

  // end of videos

  const GETUSER = async (GET_USERPROFILE) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_USERPROFILE}`,

        data: {
          roleid: user ? user.role_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error during user fetch profile is", error);
    }
  };

  //POSTING A POST......................

  const handleFileChange = (event) => {
    if (postCollection.videolink === "" && postCollection.audiolink === "") {
      const file = event.target.files[0];

      if (file) {
        const allowedTypes = ["image/jpeg", "image/png", "image/webp"];

        // Check if the file type is valid
        if (!allowedTypes.includes(file.type)) {
          HandleErrorToast(
            "error",
            "Please select a valid image file (JPEG, PNG)",
            3000
          );
          event.target.value = null;
          return;
        }

        // Check if the file size exceeds 2 MB
        if (file.size > 2 * 1024 * 1024) {
          HandleErrorToast(
            "error",
            "File size exceeds 2 MB. Please choose a smaller file.",
            3000
          );
          event.target.value = null;
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setPostCollection((prevState) => ({
            ...prevState,
            post_image: file,
            base64Image: reader.result,
            name_of_image: file?.name,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      HandleErrorToast("error", "You cannot upload more than 1", 3000);
      fileInputRef.current.value = "";
    }
  };

  const handleEditButtonClick = () => {
    if (postCollection.videolink == "" && postCollection.audiolink == "") {
      fileInputRef.current.click();
    } else {
      HandleErrorToast("error", "You cannot upload more than 1", 3000);
      fileInputRef.current.value = "";
    }
  };

  const handleResetImage = () => {
    setPostCollection((prevState) => ({
      ...prevState,
      post_image: "",
      base64Image: "",
      name_of_image: "",
    }));

    fileInputRef.current.value = "";
  };

  const handleAudioorVideo = () => {
    // if (!postCollection.base64Image)
    //   //setPopup({ popupVisible: true });
    //   setPopup((prevState) => ({ ...prevState, popupVisible: true }));
    // else {
    //   //setPopup({ popupVisible: false });
    //   setPopup((prevState) => ({ ...prevState, popupVisible: false }));
    //   HandleErrorToast("error", "You cannot upload more than 1", 3000);
    // }

    setPopup((prevState) => ({ ...prevState, popupVisible: true }));
  };

  //console.log(postCollection, "postCollection");

  const handleClose = () => {
    setPostCollection((prevState) => ({ ...prevState, validation_msg2: "" }));
    setPostCollection((prevState) => ({ ...prevState, regex_msg_audio: "" }));
    setPostCollection((prevState) => ({
      ...prevState,
      regex_msg_video: "",
      audiolink: "",
      videolink: "",
      base64Image: "",
      post_image: "",
      name_of_image: "",
    }));
    //setPopup({ popupVisible: false });

    setPopup((prevState) => ({ ...prevState, popupVisible: false }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
    setSelectedOption("");
  };

  const handlelinkAudio = (e) => {
    if (
      postCollection.videolink == "" &&
      postCollection.base64Image == "" &&
      postCollection.post_image == ""
    ) {
      const urlRegex =
        /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;

      if (e && e?.value !== "" && urlRegex.test(e?.value) == true) {
        setPostCollection((prev) => ({ ...prev, audiolink: e?.value }));
        setPostCollection((prevState) => ({
          ...prevState,
          regex_msg_audio: "",
        }));
      } else if (urlRegex.test(e?.value) == false && e?.value !== "") {
        setPostCollection((prevState) => ({
          ...prevState,
          regex_msg_audio: "Invalid Audio Link",
        }));
        setPostCollection((prev) => ({ ...prev, audiolink: "" }));
      } else {
        setPostCollection((prev) => ({ ...prev, audiolink: "" }));
      }
    } else {
      HandleErrorToast("error", "You cannot upload more than 1", 3000);
      return;
    }
  };

  const handleSomethingAbout = (e) => {
    setPostCollection((prev) => ({ ...prev, something_about: e?.value }));
  };

  const handlelinkVideo = (e) => {
    if (
      postCollection.audiolink == "" &&
      postCollection.base64Image == "" &&
      postCollection.post_image == ""
    ) {
      const urlRegex =
        /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;

      if (e && e?.value !== "" && urlRegex.test(e?.value) == true) {
        let x = urlRegex.test(e?.value);
        setPostCollection((prev) => ({ ...prev, videolink: e?.value }));
        setPostCollection((prevState) => ({
          ...prevState,
          regex_msg_video: "",
        }));
      } else if (urlRegex.test(e?.value) == false && e?.value !== "") {
        setPostCollection((prevState) => ({
          ...prevState,
          regex_msg_video: "Invalid Video Link",
        }));
        setPostCollection((prev) => ({ ...prev, videolink: "" }));
      } else {
        setPostCollection((prev) => ({ ...prev, videolink: "" }));
      }
    } else {
      HandleErrorToast("error", "You cannot upload more than 1", 3000);

      return;
    }
  };

  const handleSubmitAudorV = () => {
    if (
      !postCollection.videolink &&
      !postCollection.audiolink &&
      !postCollection.post_image
    ) {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_msg2: "This Field is required",
      }));
    } else {
      setPostCollection((prevState) => ({ ...prevState, validation_msg2: "" }));
      //setPopup({ popupVisible: false });
      setPopup((prevState) => ({ ...prevState, popupVisible: false }));
    }
  };

  const handleValueChanged_posttextbox = (e) => {
    setPostCollection((prev) => ({
      ...prev,
      post_textbox: e?.value,
      isValidUrl: isValidUrl(e?.value),
    }));

    if (e.value.length > 0) {
      setPostCollection((prev) => ({ ...prev, validation_msg1: "" }));
    }
  };

  const handleFinalPosttoBackend = () => {
    if (!postCollection.post_textbox) {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_msg1: "This Field is Required",
      }));
      //setFormDataFinal(new FormData());
      setFilteredPostCollection("");
      return;
    } else {
      setPostCollection((prevState) => ({ ...prevState, validation_msg1: "" }));

      const filteredPostCollection =
        postCollection &&
        Object.fromEntries(
          Object.entries(postCollection).filter(
            ([key, value]) =>
              value !== "" && value !== null && value !== undefined
          )
        );

      setFilteredPostCollection(filteredPostCollection);

      mutationpostprofile.mutate();
    }
  };

  const handleCloseafterSubmit = () => {
    setPostCollection({
      post_textbox: "",
      post_image: "",
      base64Image: "",
      videolink: "",
      audiolink: "",
      validation_msg1: "",
      validation_msg2: "",
      regex_msg_video: "",
      regex_msg_audio: "",
      something_about: "",
      name_of_image: "",
    });
  };

  const PostSingleProfile = () =>
    POSTONEPROFILE(POST_SINGLE_PROFILE, filteredPostCollection);

  const mutationpostprofile = useMutation({
    mutationKey: ["PostSingleProfile"],
    mutationFn: () => PostSingleProfile(),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchUser"],
      });

      if (data.status === "success") {
        SuccessNewToaster("success", "Successfully Posted!", 3000);
        setCounter(counter + 1);
        setCounterforuserprofile(Counterforuserprofile + 1);

        //alert("successfully updated by vicki");
        handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      handleCloseafterSubmit();
    },
  });

  const POSTONEPROFILE = async (
    POST_SINGLE_PROFILE,
    filteredPostCollection
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${POST_SINGLE_PROFILE}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          posttype: 1,
          //loginid: 101,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          message: filteredPostCollection.post_textbox
            ? filteredPostCollection.post_textbox
            : "",
          image: filteredPostCollection.base64Image
            ? filteredPostCollection.base64Image
            : "",
          videolink: filteredPostCollection.videolink
            ? filteredPostCollection.videolink
            : "",
          audiolink: filteredPostCollection.audiolink
            ? filteredPostCollection.audiolink
            : "",
        },
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // DISPLAYING ALL POSTS...................................
  useEffect(() => {
    mutationgetprofile.mutate(user?.role_id);
  }, [counter, counterlike]);

  const fetchSingleProfile = (roleid) => GETPROFILE(GET_POSTS_PROFILE, roleid);

  const mutationgetprofile = useMutation({
    mutationKey: ["fetchPostsProfile"],
    mutationFn: (roleid) => fetchSingleProfile(roleid),
    onSuccess: (data) => {
      if (data.status === "success") {
        setDisplayProfilePosts((prev) => ({
          ...prev,
          newfilteredGridData: data.data,
        }));
      } else {
        setDisplayProfilePosts((prev) => ({
          ...prev,
          newfilteredGridData: "",
        }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //alert("Did not fetch profile")
    },
  });

  const GETPROFILE = async (GET_POSTS_PROFILE, roleid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_PROFILE}`,

        data: {
          login_user_roleid: user ? user.role_id : "",
          user_roleid: user ? user.role_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  //console.log(displayProfilePosts.gridData, "displayProfilePosts.gridData");

  //to DELETE A POST...........................

  const handleModalDelete = (pmeter1) => {
    //console.log(pmeter1, "pmeter1");

    if (user) {
      setPopup((prevState) => ({ ...prevState, deleteornot: true }));
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_delete: pmeter1,
      }));
    } else {
      return;
    }
  };

  const handleYesDelete = () => {
    if (postCollection.id_for_delete) {
      mutationDeleteprofile.mutate(postCollection.id_for_delete);

      setPopup((prevState) => ({ ...prevState, deleteornot: false }));
    } else {
      return;
    }
  };

  const DeleteSingleProfile = (deleteid) =>
    DELETEONEPOST(DELETE_SINGLE_POST, deleteid);

  const DELETEONEPOST = async (DELETE_SINGLE_POST, deleteid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETE_SINGLE_POST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: deleteid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          posttype: 1,
          status: "N",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteprofile = useMutation({
    mutationKey: ["DeleteSingleProfile"],
    mutationFn: (deleteid) => DeleteSingleProfile(deleteid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from DELETE");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster("success", "Post Deleted Successfully", 3000);

        setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
        setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      //HandleErrorToast("error", "Could Not Delete the Post", 3000);
      setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleNoDelete = () => {
    setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
  };

  //console.log(displayProfilePosts, "displayProfilePosts");

  // CODE FOR COMMENTS IN PROFILE.......................................

  const handleComment = (pmeter1) => {
    //console.log(pmeter1, "pmeter1 comment");

    if (user) {
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: pmeter1,
      }));

      setPopup((prevState) => ({ ...prevState, commentbox_visible: true }));

      if (pmeter1) {
        mutationgetComment.mutate(pmeter1);
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const handleCommentTextbox = (e) => {
    //console.log(e, "EVENT");
    if (e) {
      setPostCollection((prevState) => ({
        ...prevState,
        comment_text: e.value,
      }));

      if (e?.value.length > 0) {
        setPostCollection((prev) => ({ ...prev, validation_for_comments: "" }));
      }
    } else {
      return;
    }
  };

  const handleCommentReset = () => {
    setPostCollection((prevState) => ({
      ...prevState,
      comment_text: "",
      id_for_comment: "",
      validation_for_comments: "",
    }));

    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
  };

  const handleCommentSubmitButton = () => {
    if (postCollection.comment_text === "") {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "This field is required",
      }));
    } else {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "",
      }));
      mutationCommentprofile.mutate(postCollection.id_for_comment);
    }
  };

  const CommentSingleProfile = (commentid) =>
    COMMENTONEPOST(COMMENT_SINGLE_POST, commentid);

  const COMMENTONEPOST = async (COMMENT_SINGLE_POST, commentid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${COMMENT_SINGLE_POST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: commentid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: postCollection.comment_text
            ? postCollection.comment_text
            : "",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationCommentprofile = useMutation({
    mutationKey: ["CommentSingleProfile"],
    mutationFn: (commentid) => CommentSingleProfile(commentid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from Comments");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster("success", "Comment Posted Successfully!", 3000);

        setPostCollection((prevState) => ({
          ...prevState,
          id_for_comment: "",
          comment_text: "",
        }));

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment + 1,
                });
              } else {
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });

        //setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
        //setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      //HandleErrorToast("error", "Comment could not be Posted", 3000);
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: "",
        comment_text: "",
      }));
      setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  // new changes

  const uniquePostIds = [];

  useEffect(() => {
    if (displayProfilePosts.CommentsData) {
      let comments_for_that_id =
        displayProfilePosts.CommentsData && displayProfilePosts.CommentsData[0];
      setNewUpdatedComments(comments_for_that_id);
    }
  }, [displayProfilePosts.CommentsData]);

  const handleCommentsEditing = (e, commentId) => {
    const index =
      newupdatedComments &&
      newupdatedComments.findIndex(
        (comment) => comment.post_comm_id === commentId
      );

    if (index !== -1) {
      const updated = [...newupdatedComments];
      updated[index] = { ...updated[index], comments_text: e.target.value };
      setNewUpdatedComments(updated);
    }
  };

  function formatDate(timestamp) {
    if (timestamp) {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    } else {
      return;
    }
  }

  const handleCancelforCommentEdit = () => {
    setCheckerEditComment(0);
    setcheckerforMenuClassname(false);
  };
  const handleEdit = (pmeter1) => {
    //setCheckerEditComment(true);
    let Comment_Edit =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(Comment_Edit, "comment_edit");

    setCheckerEditComment(Comment_Edit[0].post_comm_id);
    setcheckerforMenuClassname(false);
  };

  //newly added..
  const [imageErrorComment, setImageErrorComment] = useState(false);

  const handleImageError_comment = () => {
    setImageErrorComment(true);
  };
  const handleImageSuccess_comment = () => {
    setImageErrorComment(false);
  };

  const commentsComponent = () => {
    return (
      <>
        <div>
          {newupdatedComments &&
            newupdatedComments.map((comment) => (
              <div key={comment.post_comm_id}>
                <ul className="list-inline m-0 p-0">
                  <li className="py-2">
                    <div className="d-flex align-items-sm-center align-items-start borderLine">
                      <div className="about-user w-100 ms-2 d-flex flex-column gap-2 commentPopText">
                        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 ">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="flex-shrink-0 ">
                              {comment.role_name === "artist" ? (
                                isValidImage(comment?.profile_photo) &&
                                imageExists(
                                  `${ArtistallImg}/${comment?.profile_photo}`
                                ) ? (
                                  <img
                                    src={`${ArtistallImg}/${comment?.profile_photo}`}
                                    //onError={handleImageError_comment}
                                    //onLoad={handleImageSuccess_comment}
                                    className="commentImg rounded-circle"
                                    alt="Artist"
                                  />
                                ) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "organiser" ? (
                                isValidImage(comment?.profile_photo) &&
                                imageExists(
                                  `${ORGANISER_URL}/${comment?.profile_photo}`
                                ) ? (
                                  <>
                                    <img
                                      src={`${ORGANISER_URL}/${comment?.profile_photo}`}
                                      //onError={handleImageError_comment}
                                      //onLoad={handleImageSuccess_comment}
                                      className="commentImg rounded-circle "
                                      alt="Organiser"
                                    />
                                  </>
                                ) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "fan" ? (
                                isValidImage(comment?.profile_photo) &&
                                imageExists(
                                  `${Fan_images}/${comment?.profile_photo}`
                                ) ? (
                                  <>
                                    <img
                                      src={`${Fan_images}/${comment?.profile_photo}`}
                                      //onError={handleImageError_comment}
                                      //onLoad={handleImageSuccess_comment}
                                      className="commentImg rounded-circle "
                                      alt="Fan"
                                    />
                                  </>
                                ) : (
                                  <Noimage_circle />
                                )
                              ) : (
                                <Noimage_circle />
                              )}
                            </div>
                            <div style={{ width: "80%" }}>

                            <div className="d-flex flex-column">
                              <p className="mb-0 commentPopName">
                                <b>{comment.artist_name}</b>
                                <i className="commentDayText ms-2"></i>{" "}
                                {formatDate(comment.created_date)}
                              </p>
                              <p className="destText">{comment.role_name}</p>
                             </div>



                              <div className="d-flex align-items-center justify-content-between">
                                <p className="w-100 mb-1 commentText">
                                  {checkereditComment !==
                                  comment.post_comm_id ? (
                                    comment.comments_text
                                  ) : (
                                    <div className="d-flex flex-column gap-2">
                                      <input
                                        type="text"
                                        //className="py-1 h-auto"
                                        placeholder="Write a Comment!"
                                        value={comment.comments_text}
                                        onChange={(e) => {
                                          if (
                                            user?.login_id ===
                                              comment.login_id &&
                                            user?.role_id === comment.role_id
                                          ) {
                                            handleCommentsEditing(
                                              e,
                                              comment.post_comm_id
                                            );
                                          }
                                        }}
                                      />
                                      <div className="d-flex gap-2 align-items-center ">
                                        {user?.login_id === comment.login_id &&
                                          user?.role_id === comment.role_id && (
                                            <button
                                              className="button"
                                              onClick={() =>
                                                handleSaveComment(
                                                  comment.post_comm_id
                                                )
                                              }
                                            >
                                              Save
                                            </button>
                                          )}

                                        <button
                                          className="border-button"
                                          onClick={handleCancelforCommentEdit}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div id="container">
                              {checkereditComment === 0 && (
                                <>
                                  <Dropdown
                                    style={{ width: "40px", height: "40px" }}
                                  >
                                    <Dropdown.Toggle
                                      variant="primary"
                                      id="dropdown-basic"
                                      className="bg-white button"
                                      style={{
                                        border: "none",
                                        width: "40px",
                                        height: "40px",
                                        color: "black",
                                        padding: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <BsThreeDots style={{ color: "black" }} />{" "}
                                      {/* Custom Icon */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {user?.login_id === comment.login_id &&
                                        user?.role_id === comment.role_id && (
                                          <>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleEdit(comment.post_comm_id)
                                              }
                                            >
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleDeleteComment(
                                                  comment.post_comm_id
                                                )
                                              }
                                            >
                                              Delete
                                            </Dropdown.Item>
                                          </>
                                        )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </div>
      </>
    );
  };

  //fetch comments from backend- tanstack

  const fetchComments = (postid_comment) =>
    GETCOMMENTS(GET_POSTS_COMMENTS, postid_comment);

  const GETCOMMENTS = async (GET_POSTS_COMMENTS, postid_comment) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_COMMENTS}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: postid_comment,
        },
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationgetComment = useMutation({
    mutationKey: ["fetchComments"],
    mutationFn: (postid_comment) => fetchComments(postid_comment),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data.data, "data.data");

        setDisplayProfilePosts((prev) => ({
          ...prev,
          CommentsData: data.data,
        }));
        //setCounter(counter + 1);
      } else {
        setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  //console.log(displayProfilePosts.CommentsData, "CommentsData");

  const handleSaveComment = (pmeter1) => {
    let EditC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(EditC, "Edit this Comments");

    if (EditC) {
      setEditedComment(EditC);

      mutationEditComment.mutate(editedComment);
    } else {
      return;
    }
  };

  const EditCommentSingleProfile = (editedComment) =>
    EDITCOMMENTONEPOST(EDITCOMMENT_SINGLE_POST, editedComment);

  const EDITCOMMENTONEPOST = async (EDITCOMMENT_SINGLE_POST, editedComment) => {
    //console.log(editedComment, "editedComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${EDITCOMMENT_SINGLE_POST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: editedComment ? editedComment[0].postid : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: editedComment ? editedComment[0].comments_text : "",
          postcommentid: editedComment ? editedComment[0].post_comm_id : "",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationEditComment = useMutation({
    mutationKey: ["EditComment"],
    mutationFn: () => EditCommentSingleProfile(editedComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster("success", "Comment Edited Successfully", 3000);

        setEditedComment("");

        setCounter(counter + 1);
        handleCancelforCommentEdit();

      
      }
    },
    onError: (error) => {
      // HandleErrorToast(
      //   "error",
      //   "Could Not Edit the Comment. Something Went Wrong!",
      //   3000
      // );
      setEditedComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleDeleteComment = (pmeter1) => {
    let DeleteC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(DeleteComment,"Delete this Comment");

    if (DeleteC) {
      setDeleteComment(DeleteC);
      mutationDeleteComment.mutate(deleteComment);
    } else {
      return;
    }
  };

  const DeleteCommentSingleProfile = (deleteComment) =>
    DELETECOMMENTONEPOST(DELETECOMMENT_SINGLE_POST, deleteComment);

  const DELETECOMMENTONEPOST = async (
    DELETECOMMENT_SINGLE_POST,
    deleteComment
  ) => {
    //console.log(deleteComment, "deleteComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETECOMMENT_SINGLE_POST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: deleteComment ? deleteComment[0].post_id : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          status: "N",
          postcommentid: deleteComment ? deleteComment[0].post_comm_id : "",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteComment = useMutation({
    mutationKey: ["DeleteComment"],
    mutationFn: () => DeleteCommentSingleProfile(deleteComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster("success", "Comment deleted Successfully!", 3000);

        setDeleteComment("");

        //setCounter(counter + 1);

        setNewUpdatedComments((prev) =>
          prev.filter(
            (comment) => comment.post_comm_id !== data?.data?.post_comm_id
          )
        );

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                // Update the specific post
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment - 1,
                });
              } else {
                // Add the post as is
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      // HandleErrorToast(
      //   "error",
      //   "Could Not Delete the Comment. Something Went Wrong!",
      //   3000
      // );
      setDeleteComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const [imageErrorHeader, setImageErrorHeader] = useState(false);

  const handleImageError_Header = () => {
    setImageErrorHeader(true);
  };
  const handleImageSuccess_Header = () => {
    setImageErrorHeader(false);
  };

  const GridPostHeader = (postid) => {
    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    return (
      <div className="headers">
        <div className="left-info d-flex justify-content-start align-items-center gap-2">
          <div className="thumbnail">
            <div className="position-relative commentPopImg text-center">
              {user.rolename === "artist" ? (
                isValidImage(profileData?.photo) &&
                imageExists(`${ArtistallImg}/${profileData?.photo}`) ? (
                  <img
                    src={`${ArtistallImg}/${profileData?.photo}`}
                    //onError={handleImageError_Header}
                    //onLoad={handleImageSuccess_Header}
                    className="img-fluid object-cover"
                    alt="Artist"
                  />
                ) : (
                  <Noimage_circle />
                )
              ) : user.rolename === "organiser" ? (
                isValidImage(profileData?.photo) &&
                imageExists(`${ORGANISER_URL}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${ORGANISER_URL}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                      // onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Organiser"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : user.rolename === "fan" ? (
                isValidImage(profileData?.photo) &&
                imageExists(`${Fan_images}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${Fan_images}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                      //onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Fan"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : (
                <Noimage_circle />
              )}
            </div>
          </div>
          <div className="name-info d-flex flex-column">
            <div className="name">
              <b>{profileData.name || ""}</b>
            </div>

            <div className="time justify-content-start gap-1">
              <p className="m-0 p-0">
                {formatDate(filteredGridData_popup[0].postcreateddate)}
              </p>
              <i className="global-icon"></i>
            </div>
          </div>
        </div>
        <div className="right-info"></div>
      </div>
    );
  };

  const GridPostDisplayinPopup = (postid) => {
    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="headers">
              <div className="left-info">
                <div className="thumbnail"></div>
                <div className="name-info"></div>
              </div>
              <div className="right-info"></div>
            </div>

            <div className="content ">
              {/* <p>{filteredGridData_popup[0].postdescr}</p> */}

              {filteredGridData_popup[0].postdescr &&
                (ReactPlayer.canPlay(filteredGridData_popup[0].postdescr) ? (
                  <ReactPlayer
                    className="w-100"
                    url={filteredGridData_popup[0].postdescr}
                    controls={true}
                  />
                ) : (
                  <p>{filteredGridData_popup[0].postdescr}</p>
                ))}

              {isValidImage(filteredGridData_popup[0].postimage) &&
                imageExists(
                  `${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`
                ) && (
                  <img
                    src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                    alt={filteredGridData_popup[0].postdescr}
                    className="w-100"
                  />
                )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleClose_commentpopup = () => {
    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
    setPostCollection((prevState) => ({
      ...prevState,
      comment_text: "",
      validation_for_comments: "",
      id_for_comment: "",
    }));

    setCheckerEditComment(0);
  };

  const handleLikePost = async (postId, postlikestatus) => {
    if (user && postId) {
      setLoadingLikes((prevState) => ({ ...prevState, [postId]: true }));
      try {
        await mutationLikePost.mutateAsync({ postId, postlikestatus });
      } finally {
        setLoadingLikes((prevState) => ({ ...prevState, [postId]: false }));
      }
    }
  };

  const LikeProfile = (postId, postlikestatus) =>
    LIKEONEPOST(LIKE_SINGLE_POST, postId, postlikestatus);

  const LIKEONEPOST = async (LIKE_SINGLE_POST, postId, postlikestatus) => {
    let NLS;
    let isLiked;
    let checker_status = postlikestatus;

    isLiked =
      checker_status === "Y"
        ? true
        : checker_status === "N"
        ? false
        : undefined;

    NLS = isLiked === true ? "N" : "Y";

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${LIKE_SINGLE_POST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          postid: postId ? postId : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          likestatus: NLS,
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const mutationLikePost = useMutation({
    mutationKey: ["LikeAPost"],
    mutationFn: ({ postId, postlikestatus }) =>
      LikeProfile(postId, postlikestatus),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ["getLikes"],
      // });
      //console.log(data, "data like");

      if (data.data.status === "success") {
        // setCounterLike(counterlike + 1);

        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));

        //optimization code..

        //Check if postIndex is valid

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.data.post_id) {
                const isLiked = data.data.data.status === "Y";

                acc.push({
                  ...post,
                  postlikestatus: data.data.data.status.toString(),
                  countpostlike: isLiked
                    ? post.countpostlike + 1
                    : post.countpostlike - 1,
                });
              } else {
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });
      } else {
        //setPostIdAfterLike("");
        //setRefresh("");
        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));
      }
    },
    onError: (error) => {
      // HandleErrorToast(
      //   "error",
      //   "Could Not Like the Post. Something Went Wrong!",
      //   3000
      // );
      //setLoadingPosts(prevState => ({ ...prevState, [data.data.data.post_id]: false }));
      setnewLikedStatus("");
      setPostIdAfterLike("");
      setRefresh("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  //LIKE BUTTON TANSTACK- LIST OF LIKES...............................

  // names of all those who Liked....................................

  const handleNamesofAllThoseLiked = async (postid) => {
    //console.log(postid, "postid");
    setPopup((prevState) => ({ ...prevState, names_likes: true }));

    const namesofLikes = async (postid) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${BASEPATH}${GET_LIKES}`,
          data: {
            postid: Number(postid),
          },
          headers: {
            MDnD_auth: `${token}`,
          },

          timeout: 60000,
          responseType: "json",
        });

        if (response?.data.status === "success") {
          let result = response?.data.data[0];
          let filterednames =
            result &&
            result.map((item) => ({
              name: item.artist_name,
              photo: item.profile_photo,
              role_name: item.role_name,
              role_id: item.role_id,
              profile_name: item.profile_name,
            }));

          let uniqueFilterednames =
            filterednames &&
            filterednames.reduce((accumulator, currentItem) => {
              const isDuplicate = accumulator.some(
                (item) => item.role_id === currentItem.role_id
              );

              if (!isDuplicate) {
                accumulator.push(currentItem);
              }

              return accumulator;
            }, []);

          if (uniqueFilterednames) {
            setArrayofLikeNames(uniqueFilterednames);
          }

          let all_names_string =
            filterednames && filterednames.map((item) => item.name).join(", ");

          return all_names_string;
        } else {
          return "";
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
        return "error";
      }
    };

    let names = await namesofLikes(postid);

    let like_names = {};
    like_names[postid] = names;

    setNamesofLikesforPostid(like_names);
  };
  //console.log(arrayoflikenames, "Arrayoflikenames");

  const handleClose_namesLikes = () => {
    setPopup((prevState) => ({ ...prevState, names_likes: false }));
    setNamesofLikesforPostid({});
    setArrayofLikeNames([]);
  };

  const handleLikeNameLeadstoProfile = (profileData) => {
    if (profileData.role_name === "artist") {
      navigate(`/artist/${profileData.profile_name}`, {
        state: { artistId: profileData.role_id },
      });
    } else if (profileData.role_name === "organiser") {
      navigate(`/organiser/${profileData.profile_name}`, {
        state: { organiserId: profileData.role_id },
      });
    } else {
      return;
    }
  };

  // CHANGIN USER PICTURE....

  const handleFileChange_userpic = (event) => {
    const file_pic = event.target.files[0];

    if (file_pic) {
      // Validate file type
      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!allowedTypes.includes(file_pic.type)) {
        HandleErrorToast(
          "error",
          "Please select a valid image file (JPEG, PNG).",
          3000
        );
        event.target.value = null;
        return;
      }
    }
    if (file_pic) {
      // Load the image and reduce size/quality
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 800; // Max width for resizing
          const MAX_HEIGHT = 800; // Max height for resizing
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas to data URL with reduced quality
          const resizedImageDataUrl = canvas.toDataURL("image/jpeg", 0.8); // Adjust quality here (0.8 is 80% quality)

          // Update state with resized image
          setImage(resizedImageDataUrl);
          setShowCropper(true);
          setUserProfile((prevState) => ({
            ...prevState,
            //user_profile_pic: file_pic,
            //base64user_profile_pic: resizedImageDataUrl,
            imagechecker: true,
          }));
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file_pic);
    }
  };

  const handleCrop = async () => {
    if (!cropperRef.current) {
      return;
    }

    const editor = cropperRef.current;

    const canvas = editor.getImage();

    const croppedDataUrl = canvas.toDataURL("image/jpeg");

    setCroppedImage(croppedDataUrl);
    setShowCropper(false);
    setUserProfile((prevState) => ({
      ...prevState,
      user_profile_pic: croppedDataUrl,
      base64user_profile_pic: croppedDataUrl,
      //dummyimg: objectUrl,
      //imagechecker: false
    }));

    mutationchangeProfilePic.mutate();
  };
  //console.log(userProfile, "userProfile");

  const handleEditButtonClick_userpic = () => {
    //if (userProfile.user_profile_pic == "") {

    fileInputRef_userpic.current.click();
    //}
    // else {
    //  fileInputRef_userpic.current.value = "";
    //}
  };

  const handleCancelforuserpic = () => {
    setUserProfile((prevState) => ({
      ...prevState,
      user_profile_pic: "",
      base64user_profile_pic: "",
      dummyimg: "",
      imagechecker: true,
    }));

    setShowCropper(false);
    setImage(null);
    setCroppedImage(null);
    fileInputRef_userpic.current.value = "";
    setShowSaveandCancelbuttonPic(false);
  };

  const handleSubmituserprofilepic = () => {
    if (!userProfile.user_profile_pic) {
      HandleErrorToast("error", "Please upload a Picture!", 3000);

      return;
    } else {
      mutationchangeProfilePic.mutate();
    }
  };

  const ChangeProfilePic = () => CHANGEONEPROFILEPIC(CHANGE_PROFILE_PIC_USER);

  const mutationchangeProfilePic = useMutation({
    mutationKey: ["ChangeProfilePic"],
    mutationFn: () => ChangeProfilePic(),

    onSuccess: (data) => {
      setUserProfile((prevState) => ({
        ...prevState,
        user_profile_pic: "",
        base64user_profile_pic: "",
        //dummyimg: "",
        //imagechecker: true
      }));

      // queryClient.invalidateQueries({
      //   queryKey: ["fetchUser"],
      // });
      //console.log(data, "DATA");

      if (data.status === "success") {
        setCounterforuserprofile(Counterforuserprofile + 1);
        setShowSaveandCancelbuttonPic(false);
        //handleCancelforuserpic();
      }
    },
    onError: (error) => {
      //HandleErrorToast("error", "Could Not Update the Profile Picture", 3000);
      //alert("Did not post profile");
      //setShowSaveandCancelbuttonPic(false);
      handleCancelforuserpic();
    },
  });

  const CHANGEONEPROFILEPIC = async (CHANGE_PROFILE_PIC_USER) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${CHANGE_PROFILE_PIC_USER}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",

        data: {
          profilephoto: userProfile.base64user_profile_pic
            ? userProfile.base64user_profile_pic
            : "",
          roleid: user ? user.role_id : "",
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // FOLLOW/UNFOLLOW..........................................

  // new code august 2rd..
  const [showModal, setShowModal] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [ShareSocialMediaURL, setShareSocialMediaURL] = useState("");

  const handleShareClick = (post) => {
    setCurrentPost(post);
    setShowModal(true);

    const shareUrl = `${window.location.origin}/SpecificPost?roleidstate=${encodeBase64(user?.role_id,PROFILE_KEY)}&postid=${post.postid}`;
    setShareSocialMediaURL(shareUrl);
  };

  const handleClose_share = () => {
    setShowModal(false);
    setCurrentPost(null);
    setShareSocialMediaURL("");
  };

  const isValidUrl = (text) => {
    try {
      new URL(text);
      return true;
    } catch (e) {
      return false;
    }
  };

  // new code..
  const [imageErrorMainPicture, setimageErrorMainPicture] = useState(false);

  const handleImageErrorMainPicture = () => {
    setimageErrorMainPicture(true);
  };
  const handleImageSuccessMainPicture = () => {
    setimageErrorMainPicture(false);
  };
  const [imageErrorDisplayPost, setimageErrorDisplayPost] = useState(false);

  const handleimageErrorDisplayPost = () => {
    setimageErrorDisplayPost(true);
  };
  const handleimageSuccessDisplayPost = () => {
    setimageErrorDisplayPost(false);
  };

  const [imageErrorNamesLikes, setimageErrorNamesLikes] = useState(false);

  const handleimageErrorNamesLikes = () => {
    setimageErrorNamesLikes(true);
  };
  const handleimageSuccessNamesLikes = () => {
    setimageErrorNamesLikes(false);
  };

  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    if (event?.target.value === "option1") {
      setPostCollection((prevState) => ({ ...prevState, validation_msg2: "" }));
      setPostCollection((prevState) => ({ ...prevState, regex_msg_audio: "" }));
      setPostCollection((prevState) => ({
        ...prevState,
        regex_msg_video: "",
        audiolink: "",
        videolink: "",
      }));
    } else if (event?.target.value === "option2") {
      setPostCollection((prevState) => ({
        ...prevState,
        base64Image: "",
        post_image: "",
        name_of_image: "",
      }));
    }
  };

  const handlebackButton = () => {
    navigate(`/dashboard`);
  };

  //console.log(profileData, "profileData");

  //console.log(loading, "loading");

  //console.log(imageErrorMainPicture, "imageErrorMainPicture");

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="section-padding p-lg-5">
        <div className="continer-fluid">
          <div className="profile-grid">
            {user &&
              (loading ? (
                <div className="col">
                  <Skeleton className="skeleton-Profile" />
                  <Skeleton className="skeleton-follow" />
                </div>
              ) : (
                <div className="w-100 profile-left-section">
                  <div
                    className="mb-2 p-auto rounded d-none d-md-block backBtn cursor-pointer"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <IoChevronBack
                      style={{ fontSize: "24px" }}
                      onClick={handlebackButton}
                    />
                  </div>
                  <div
                    className="w-100 shadow-sm p-3 mb-2 d-flex align-items-center flex-column"
                    style={{ borderRadius: "6px" }}
                  >
                    <div
                      className="position-relative profileImg text-center"
                      style={{ maxWidth: "200px" }}
                    >
                      {showCropper ? (
                        <>
                          <AvatarEditor
                            ref={cropperRef}
                            image={image}
                            border={50} // Size of the circular border
                            borderRadius={250} // Half of width and height to make it circular
                            color={[255, 255, 255, 0.6]} // Background color with transparency
                            scale={1} // Initial scale (optional, default is 1)
                            rotate={0} // Initial rotation (optional, default is 0)
                            style={{ maxWidth: "200px", maxHeight: "200px" }}
                            //onMouseUp={handleCrop}
                          />
                          <div className="d-flex justify-content-center">
                            <i
                              class="fas fa-save saveIcon me-3 buttonSave color-white"
                              onClick={handleCrop}
                            ></i>
                            <i
                              class="fas fa-times cancelIcon CancelBtn d-flex align-items-center"
                              onClick={handleCancelforuserpic}
                            ></i>
                          </div>
                        </>
                      ) : (
                        <>
                          {loading ? (
                            <Skeleton height={200} className="rounded-circle" />
                          ) : user.rolename === "artist" ? (
                            isValidImage(profileData?.photo) &&
                            imageExists(
                              `${ArtistallImg}/${profileData?.photo}`
                            ) ? (
                              <>
                                <img
                                  src={`${ArtistallImg}/${profileData?.photo}`}
                                  //onError={handleImageErrorMainPicture}
                                  //onLoad={handleImageSuccessMainPicture}
                                  style={{
                                    borderRadius: "50%",
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                  }}
                                  alt="Artist"
                                />
                              </>
                            ) : (
                              <Noimage_profilepic />
                            )
                          ) : user.rolename === "organiser" ? (
                            isValidImage(profileData?.photo) &&
                            imageExists(
                              `${ORGANISER_URL}/${profileData?.photo}`
                            ) ? (
                              <>
                                <img
                                  src={`${ORGANISER_URL}/${profileData?.photo}`}
                                  //onError={handleImageErrorMainPicture}
                                  //onLoad={handleImageSuccessMainPicture}
                                  style={{
                                    borderRadius: "50%",
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                  }}
                                  alt="Organiser"
                                />
                              </>
                            ) : (
                              <Noimage_profilepic />
                            )
                          ) : user.rolename === "fan" ? (
                            isValidImage(profileData?.photo) &&
                            imageExists(
                              `${Fan_images}/${profileData?.photo}`
                            ) ? (
                              <>
                                <img
                                  src={`${Fan_images}/${profileData?.photo}`}
                                  //onError={handleImageErrorMainPicture}
                                  //onLoad={handleImageSuccessMainPicture}
                                  style={{
                                    borderRadius: "50%",
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                  }}
                                  alt="Fan"
                                />
                              </>
                            ) : (
                              <Noimage_profilepic />
                            )
                          ) : user.rolename === "advertiser" ? (
                            isValidImage(profileData?.photo) &&
                            imageExists(
                              `${AdvertiserImg}/${profileData?.photo}`
                            ) ? (
                              <>
                                <img
                                  src={`${AdvertiserImg}/${profileData?.photo}`}
                                  //onError={handleImageErrorMainPicture}
                                  //onLoad={handleImageSuccessMainPicture}
                                  style={{
                                    borderRadius: "50%",
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                  }}
                                  alt="Fan"
                                />
                              </>
                            ) : (
                              <Noimage_profilepic />
                            )
                          ) : (
                            <Noimage_profilepic />
                          )}
                        </>
                      )}
                      {!showCropper && (
                        <div
                          className="position-absolute"
                          style={{
                            width: "fit-content",
                            bottom: "10px",
                            right: "20px",
                            borderRadius: "50%",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange_userpic}
                            style={{ display: "none" }}
                            ref={fileInputRef_userpic}
                          />
                          <Button
                            className="fas fa-camera align-center ps-2"
                            style={{ borderRadius: "50%" }}
                            onClick={handleEditButtonClick_userpic}
                          />
                        </div>
                      )}
                      {showSaveandCancelbuttonPic && (
                        <div>
                          <i
                            className="fas fa-save saveIcon"
                            onClick={handleSubmituserprofilepic}
                          ></i>
                          <i
                            className="fas fa-times cancelIcon"
                            onClick={handleCancelforuserpic}
                          ></i>
                        </div>
                      )}
                    </div>

                    <div className="seperator my-3 text-center">
                      <h4 className="fw-semibold">{profileData?.name}</h4>
                      <p className="fw-semibold mb-1">
                        {capitalizeFirstLetter(profileData?.rolename)}
                        {/* <span>
                          {" "}
                          (Carnatic <span> - Vocal</span>)
                        </span> */}
                      </p>
                      <p className="fw-semibold mb-0">
                        {profileData?.cityname}, {profileData?.countryname}
                      </p>
                      <p className="fw-semibold m-0">{profileData?.email}</p>
                    </div>

                    <SocialMedia_myprofile profilelink={profileLinkShare} />
                  </div>

                  <div className="shadow-sm p-3 my-2">
                    <div className="row">
                      {user && (
                        <div className="d-flex align-items-center justify-content-around gap-3 gap-sm-5">
                          <div className="text-center">
                            <h4 className="counts">
                              <b>{profileData?.totpost || 0}</b>
                            </h4>
                            <p style={{ marginBottom: "0" }}>Posts</p>
                          </div>
                          <div className="text-center">
                            <h4 className="counts">
                              <b>{profileData?.totfollowers || 0}</b>
                            </h4>
                            <p style={{ marginBottom: "0" }}>Followers</p>
                          </div>
                          <div className="text-center">
                            <h4 className="counts">
                              <b>{profileData?.totfollowing || 0}</b>
                            </h4>
                            <p style={{ marginBottom: "0" }}>Following</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {user ? (
              <div className="w-100 overflow-hidden">
                {loading ? (
                  <>
                    <Skeleton height={40} />
                  </>
                ) : (
                  <>
                    <div
                      className="w-100 nav nav-underline nav-pills d-flex align-items-center hide-scrollbar nav nav-underline"
                      style={{
                        gap: "10px",
                        overflow: "scroll",
                        padding: "0px 20px",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "about" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("about")}
                          role="tab"
                          aria-selected={activeTab === "about"}
                        >
                          About
                        </button>
                      </div>

                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "post" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("post")}
                          role="tab"
                          aria-selected={activeTab === "post"}
                        >
                          Post
                        </button>
                      </div>

                      {user?.rolename !== "fan" && (
                        <div className="nav-item">
                          <button
                            className={`nav-link text-nowrap ${
                              activeTab === "contact" ? "active show" : ""
                            }`}
                            onClick={() => handleTabClick("contact")}
                            role="tab"
                            aria-selected={activeTab === "contact"}
                          >
                            Messages
                          </button>
                        </div>
                      )}

                      {user?.rolename === "organiser" && (
                        <div className="nav-item">
                          <button
                            className={`nav-link text-nowrap ${
                              activeTab === "landingPage" ? "active show" : ""
                            }`}
                            onClick={() => handleTabClick("landingPage")}
                            role="tab"
                            aria-selected={activeTab === "landingPage"}
                          >
                            LandingPage Details
                          </button>
                        </div>
                      )}

                      {user?.rolename !== "advertiser" &&
                        user?.rolename !== "fan" && (
                          <div className="nav-item">
                            <button
                              className={`nav-link text-nowrap ${
                                activeTab === "calender" ? "active show" : ""
                              }`}
                              onClick={() => handleTabClick("calender")}
                              role="tab"
                              aria-selected={activeTab === "calender"}
                            >
                              My Calendar
                            </button>
                          </div>
                        )}

                      {user?.rolename !== "fan" && (
                        <div className="nav-item">
                          <button
                            className={`nav-link text-nowrap ${
                              activeTab === "videos" ? "active show" : ""
                            }`}
                            onClick={() => handleTabClick("videos")}
                            role="tab"
                            aria-selected={activeTab === "videos"}
                          >
                            Videos
                          </button>
                        </div>
                      )}
                      {user?.rolename !== "fan" && (
                        <div className="nav-item">
                          <button
                            className={`nav-link text-nowrap ${
                              activeTab === "account" ? "active show" : ""
                            }`}
                            onClick={() => handleTabClick("account")}
                            role="tab"
                            aria-selected={activeTab === "account"}
                          >
                            Account Details
                          </button>
                        </div>
                      )}

                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "editprofile" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("editprofile")}
                          role="tab"
                          aria-selected={activeTab === "editprofile"}
                        >
                          Edit Profile
                        </button>
                      </div>

                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "followers" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("followers")}
                          role="tab"
                          aria-selected={activeTab === "followers"}
                        >
                          Followers
                        </button>
                      </div>

                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "following" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("following")}
                          role="tab"
                          aria-selected={activeTab === "following"}
                        >
                          Following
                        </button>
                      </div>
                      <div className="nav-item">
                        <button
                          className={`nav-link text-nowrap ${
                            activeTab === "forms" ? "active show" : ""
                          }`}
                          onClick={() => handleTabClick("forms")}
                          role="tab"
                          aria-selected={activeTab === "forms"}
                        >
                          My Forms
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className="tab-content tabBodyBg">
                  {activeTab === "about" && (
                    <div
                      id="about"
                      //className="tab-pane animated fadeInUp active show"
                      //role="tabpanel"
                    >
                      <About_myProfile />
                    </div>
                  )}

                  {/* for post  */}
                  {activeTab === "post" && (
                    <div
                      id="post"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <div className="description-content">
                        {user ? (
                          <div
                            className="mainDiv mb-3"
                            style={{ maxWidth: "500px" }}
                          >
                            {/* CODE FOR POST */}
                            {loading ? (
                              <>
                                <Skeleton height={90} />
                              </>
                            ) : (
                              <div className="d-flex flex-column">
                                <TextArea
                                  value={
                                    postCollection.post_textbox
                                      ? postCollection.post_textbox
                                      : ""
                                  }
                                  height={90}
                                  placeholder="Write Something!"
                                  onValueChanged={
                                    handleValueChanged_posttextbox
                                  }
                                />
                                {postCollection.validation_msg1 && (
                                  <p className="error-message mt-1 p-0 m-0">
                                    {postCollection.validation_msg1}
                                  </p>
                                )}
                              </div>
                            )}

                            <div className="w-100 d-flex gap-1 mt-2 align-items-center">
                              {loading ? (
                                <Skeleton height={40} />
                              ) : (
                                <>
                                  <button
                                    onClick={handleAudioorVideo}
                                    style={{
                                      backgroundColor: "blue",
                                      color: "white",
                                      width: "fit-content",
                                    }}
                                    className="button"
                                  >
                                    Upload Image, Audio / Video
                                  </button>
                                  <div
                                    className="Dnone iq-button"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "12px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      onClick={handleFinalPosttoBackend}
                                      className="button"
                                      style={{
                                        backgroundColor: "blue",
                                        color: "white",
                                      }}
                                    >
                                      Post
                                    </button>
                                  </div>
                                </>
                              )}

                              <div>
                                {postCollection.name_of_image && (
                                  <p> {postCollection.name_of_image}</p>
                                )}

                                {/* {postCollection.videolink && (
                                  <p>Video link is Ready for Upload!</p>
                                )}
                                {postCollection.audiolink && (
                                  <p>Audio link is Ready for Upload!</p>
                                )} */}
                              </div>
                              <div>
                                <Modal
                                  show={popup.popupVisible}
                                  onHide={handleClose}
                                  centered
                                >
                                  <Modal.Header
                                    closeButton
                                    className="m-0 d-flex align-items-center"
                                    style={{ padding: "20px 24px 10px 24px" }}
                                  >
                                    <h5 className="w-100 text-center text-wrap">
                                      <b>Upload Image, Audio / Video</b>
                                    </h5>
                                  </Modal.Header>
                                  <Modal.Body
                                    className="m-0 d-flex flex-column gap-2"
                                    style={{ padding: "20px 24px" }}
                                  >
                                    <div className="d-flex justify-content-center">
                                      <label
                                        className={
                                          selectedOption === "option1"
                                            ? "uploadImgActive me-4"
                                            : "uploadImg me-4"
                                        }
                                      >
                                        <input
                                          className="me-2"
                                          type="radio"
                                          value="option1"
                                          checked={selectedOption === "option1"}
                                          onChange={handleOptionChange}
                                        />
                                        Upload Image
                                      </label>

                                      <label
                                        className={
                                          selectedOption === "option2"
                                            ? "uploadImgActive"
                                            : "uploadImg"
                                        }
                                      >
                                        <input
                                          className="me-2"
                                          type="radio"
                                          value="option2"
                                          checked={selectedOption === "option2"}
                                          onChange={handleOptionChange}
                                        />
                                        Upload Video/Audio Link
                                      </label>
                                    </div>

                                    {selectedOption === "option1" && (
                                      <>
                                        <div className="d-flex justify-content-center mt-4 mb-2">
                                          <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                          />
                                          {postCollection.base64Image ===
                                            "" && (
                                            <>
                                              <Button
                                                className="button py-4 px-5 text-center mb-2"
                                                style={{ width: "fit-content" }}
                                                onClick={handleEditButtonClick}
                                              >
                                                <FiUpload className="me-1" />
                                                Upload Image
                                              </Button>
                                            </>
                                          )}

                                          {postCollection.base64Image && (
                                            <div className="d-flex gap-2 flex-column align-items-center justify-content-start mt-3">
                                              <img
                                                src={postCollection.base64Image}
                                                alt="Uploaded"
                                                className="post-image"
                                              />
                                              <div className="w-100 d-flex gap-2 align-items-center justify-content-between">
                                                <div
                                                  className="m-0 p-0 w-100 overflow-hidden"
                                                  style={{ maxHeight: "48px" }}
                                                >
                                                  {
                                                    postCollection.post_image
                                                      .name
                                                  }
                                                </div>
                                                <button
                                                  onClick={handleResetImage}
                                                  className="cancelBtn"
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}

                                    {selectedOption === "option2" && (
                                      <>
                                        {postCollection.isValidUrl ===
                                          false && (
                                          <TextBox
                                            value={
                                              postCollection.videolink
                                                ? postCollection.videolink
                                                : ""
                                            }
                                            //readOnly={true}
                                            label="Enter your Video Link:"
                                            labelMode="floating"
                                            className=""
                                            onValueChanged={handlelinkVideo}
                                          />
                                        )}
                                        {postCollection.regex_msg_video && (
                                          <span className="error-message">
                                            {postCollection.regex_msg_video}
                                          </span>
                                        )}
                                        {postCollection.validation_msg2 && (
                                          <span className="error-message">
                                            {postCollection.validation_msg2}
                                          </span>
                                        )}

                                        {postCollection.isValidUrl ===
                                          false && (
                                          <TextBox
                                            value={
                                              postCollection.audiolink
                                                ? postCollection.audiolink
                                                : ""
                                            }
                                            //readOnly={true}
                                            label="Enter your Audio Link:"
                                            labelMode="floating"
                                            className=""
                                            onValueChanged={handlelinkAudio}
                                          />
                                        )}

                                        {postCollection.regex_msg_audio && (
                                          <span className="error-message">
                                            {postCollection.regex_msg_audio}
                                          </span>
                                        )}

                                        {postCollection.validation_msg2 && (
                                          <span className="error-message">
                                            {postCollection.validation_msg2}
                                          </span>
                                        )}
                                      </>
                                    )}

                                    {/* <TextBox
                                      value={
                                        postCollection.something_about
                                          ? postCollection.something_about
                                          : ""
                                      }
                                      //readOnly={true}
                                      label="Something About!.."
                                      labelMode="floating"
                                      className=""
                                      onValueChanged={handleSomethingAbout}
                                    /> */}

                                    <Button
                                      className="button mt-2 w-50 mx-auto"
                                      text="Done"
                                      onClick={handleSubmitAudorV}
                                    />
                                  </Modal.Body>
                                </Modal>
                              </div>

                              <div
                                className="d-flex align-items-center"
                                style={{ margin: "auto" }}
                              >
                                {postCollection.name_of_image && (
                                  <p
                                    className=" pt-3"
                                    style={{ color: "green" }}
                                  >
                                    {" "}
                                    {SuccessSVG} <b>Uploaded</b>
                                  </p>
                                )}

                                {postCollection.videolink && (
                                  <p
                                    className=" pt-3"
                                    style={{ color: "green" }}
                                  >
                                    {SuccessSVG} <b>Uploaded</b>
                                  </p>
                                )}
                                {postCollection.audiolink && (
                                  <p
                                    className=" pt-3"
                                    style={{ color: "green" }}
                                  >
                                    {SuccessSVG} <b>Uploaded</b>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div id="app">
                          {/* DISPLAYING ALL POSTS  */}

                          {displayProfilePosts.newfilteredGridData &&
                          displayProfilePosts.newfilteredGridData.length > 0 ? (
                            displayProfilePosts.newfilteredGridData.map(
                              (item) => (
                                <>
                                  {loading ? (
                                    <>
                                      <Skeleton height={50} />
                                      <Skeleton height={400} />
                                    </>
                                  ) : (
                                    <>
                                      <div className="headers">
                                        <div className="left-info">
                                          <div className="thumbnail">
                                            {user.rolename === "artist" ? (
                                              isValidImage(
                                                profileData?.photo
                                              ) &&
                                              imageExists(
                                                `${ArtistallImg}/${profileData?.photo}`
                                              ) ? (
                                                <img
                                                  src={`${ArtistallImg}/${profileData?.photo}`}
                                                  // onError={
                                                  //   handleimageErrorDisplayPost
                                                  // }
                                                  // onLoad={handleimageSuccessDisplayPost}
                                                  className="img-fluid object-cover"
                                                  alt="Artist"
                                                />
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : user.rolename ===
                                              "organiser" ? (
                                              isValidImage(
                                                profileData?.photo
                                              ) &&
                                              imageExists(
                                                `${ORGANISER_URL}/${profileData?.photo}`
                                              ) ? (
                                                <>
                                                  <img
                                                    src={`${ORGANISER_URL}/${profileData?.photo}`}
                                                    // onError={
                                                    //   handleimageErrorDisplayPost
                                                    // }
                                                    // onLoad={handleimageSuccessDisplayPost}
                                                    className="img-fluid object-cover"
                                                    alt="Organiser"
                                                  />
                                                </>
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : user.rolename === "fan" ? (
                                              isValidImage(
                                                profileData?.photo
                                              ) &&
                                              imageExists(
                                                `${Fan_images}/${profileData?.photo}`
                                              ) ? (
                                                <>
                                                  <img
                                                    src={`${Fan_images}/${profileData?.photo}`}
                                                    // onError={
                                                    //   handleimageErrorDisplayPost
                                                    // }
                                                    // onLoad={handleimageSuccessDisplayPost}
                                                    className="img-fluid object-cover"
                                                    alt="Fan"
                                                  />
                                                </>
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : (
                                              <Noimage_circle />
                                            )}
                                          </div>
                                          <div className="name-info">
                                            <div className="name">
                                              <a href="">
                                                {/* {organiserData?.result?.[0]?.organiser_name} */}
                                                {profileData.name || ""}
                                              </a>
                                            </div>
                                            <div className="time justify-content-start gap-2">
                                              {formatDate(item.postcreateddate)}
                                              <i className="global-icon"></i>
                                            </div>
                                          </div>
                                        </div>

                                        <div id="container">
                                          <Dropdown
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              variant="primary"
                                              id="dropdown-basic"
                                              className="bg-white button"
                                              style={{
                                                border: "none",
                                                width: "40px",
                                                height: "40px",
                                                color: "black",
                                                padding: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <BsThreeDots
                                                style={{ color: "black" }}
                                              />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              {user?.role_id && (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleModalDelete(
                                                      item.postid
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div
                                        className="content"
                                        key={item.postid}
                                        //onClick={() => handlePostClick(item.postid, user.role_id)}
                                      >
                                        {/* <p>{item.postdescr}</p> */}
                                        {item.postdescr &&
                                          (ReactPlayer.canPlay(
                                            item.postdescr
                                          ) ? (
                                            <ReactPlayer
                                              className="w-100"
                                              url={item.postdescr}
                                              controls={true}
                                            />
                                          ) : (
                                            <p>{item.postdescr}</p>
                                          ))}

                                        {isValidImage(item.postimage) &&
                                          imageExists(
                                            `${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`
                                          ) && (
                                            <img
                                              className="w-100"
                                              src={`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`}
                                              alt={item.postdescr}
                                            />
                                          )}

                                        {item.postvideo && (
                                          <ReactPlayer
                                            className="w-100"
                                            url={item.postvideo}
                                            controls={true}
                                          />
                                        )}
                                        {item.postaudio && (
                                          <ReactPlayer
                                            className="w-100"
                                            url={item.postaudio}
                                            controls={true}
                                          />
                                        )}

                                        <div className="feedback-info">
                                          <div className="threads-and-share">
                                            <div className="h-100 threads d-flex align-items-center ">
                                              <button
                                                onClick={() =>
                                                  handleNamesofAllThoseLiked(
                                                    item.postid,
                                                    item.postlikestatus
                                                  )
                                                }
                                                className=" h-100 like-button d-flex align-items-center me-2"
                                              >
                                                {item.countpostlike
                                                  ? item.countpostlike
                                                  : 0}{" "}
                                                <i
                                                  style={{ fontSize: "1.5vh" }}
                                                  class="fa-solid fa-heart ms-1"
                                                ></i>
                                              </button>{" "}
                                              {item.countpostcomment
                                                ? item.countpostcomment
                                                : 0}{" "}
                                              <i className="fb-icon response far fa-comment-alt ms-1"></i>
                                            </div>
                                          </div>
                                          <button
                                            className=""
                                            style={{ padding: "4px 4px" }}
                                            onClick={() =>
                                              handleShareClick(item)
                                            }
                                          >
                                            <IoShareSocial />
                                          </button>
                                        </div>

                                        <div className="feedback-action mt-3 pb-4 borderLine">
                                          <div className="fb-wrapper">
                                            {/* <i className="fb-icon thumb-up far fa-thumbs-up"></i> */}

                                            <button
                                              //className={`like-btn ${item.plikepostid ? 'liked' : ''}`}
                                              disabled={
                                                !!loadingLikes[item.postid]
                                              }
                                              onClick={() =>
                                                handleLikePost(
                                                  item.postid,
                                                  item.postlikestatus
                                                )
                                              }
                                            >
                                              {item.postlikestatus === "Y" && (
                                                <i class="fa-solid fa-heart"></i>
                                              )}

                                              {item.postlikestatus === "N" && (
                                                <i class="fa-regular fa-heart"></i>
                                              )}

                                              {(item.postlikestatus === null ||
                                                item.postlikestatus ===
                                                  undefined) && (
                                                <i class="fa-regular fa-heart"></i>
                                              )}
                                            </button>
                                          </div>
                                          <div className="fb-wrapper">
                                            <i className="fb-icon response far fa-comment-alt"></i>

                                            <button
                                              onClick={() =>
                                                handleComment(item.postid)
                                              }
                                            >
                                              Comment
                                            </button>
                                          </div>
                                        </div>

                                        {popup.commentbox_visible &&
                                          postCollection.id_for_comment ===
                                            item.postid && (
                                            <div>
                                              <Modal
                                                show={popup.commentbox_visible}
                                                onHide={
                                                  handleClose_commentpopup
                                                }
                                                centered
                                              >
                                                <Modal.Header className="p-2 border-bottom-0">
                                                  {GridPostHeader(item.postid)}
                                                </Modal.Header>
                                                <Modal.Body className="p-2 commentPopScroll">
                                                  {GridPostDisplayinPopup(
                                                    item.postid
                                                  )}

                                                  {commentsComponent()}
                                                </Modal.Body>

                                                {checkereditComment === 0 && (
                                                  <>
                                                    <Modal.Footer className="d-flex justify-content-end border-top-0 p-2">
                                                      <>
                                                        <div className="w-100 d-flex m-0 align-items-center">
                                                          {user.rolename ===
                                                          "artist" ? (
                                                            isValidImage(
                                                              profileData?.photo
                                                            ) &&
                                                            imageExists(
                                                              `${ArtistallImg}/${profileData?.photo}`
                                                            ) ? (
                                                              <>
                                                                <img
                                                                  src={`${ArtistallImg}/${profileData?.photo}`}
                                                                  // onError={
                                                                  //   handleImageErrorMainPicture
                                                                  // }
                                                                  // onLoad={handleImageSuccessMainPicture}
                                                                  className="commentImg rounded-circle"
                                                                  alt="Artist"
                                                                />
                                                              </>
                                                            ) : (
                                                              <Noimage_circle />
                                                            )
                                                          ) : user.rolename ===
                                                            "organiser" ? (
                                                            isValidImage(
                                                              profileData?.photo
                                                            ) &&
                                                            imageExists(
                                                              `${ORGANISER_URL}/${profileData?.photo}`
                                                            ) ? (
                                                              <>
                                                                <img
                                                                  src={`${ORGANISER_URL}/${profileData?.photo}`}
                                                                  // onError={
                                                                  //   handleImageErrorMainPicture
                                                                  // }
                                                                  // onLoad={handleImageSuccessMainPicture}
                                                                  className="commentImg rounded-circle"
                                                                  alt="Organiser"
                                                                />
                                                              </>
                                                            ) : (
                                                              <Noimage_circle />
                                                            )
                                                          ) : user.rolename ===
                                                            "fan" ? (
                                                            isValidImage(
                                                              profileData?.photo
                                                            ) &&
                                                            imageExists(
                                                              `${Fan_images}/${profileData?.photo}`
                                                            ) ? (
                                                              <>
                                                                <img
                                                                  src={`${Fan_images}/${profileData?.photo}`}
                                                                  // onError={
                                                                  //   handleImageErrorMainPicture
                                                                  // }
                                                                  // onLoad={handleImageSuccessMainPicture}
                                                                  className="commentImg rounded-circle"
                                                                  alt="Fan"
                                                                />
                                                              </>
                                                            ) : (
                                                              <Noimage_circle />
                                                            )
                                                          ) : user.rolename ===
                                                            "advertiser" ? (
                                                            isValidImage(
                                                              profileData?.photo
                                                            ) &&
                                                            imageExists(
                                                              `${AdvertiserImg}/${profileData?.photo}`
                                                            ) ? (
                                                              <>
                                                                <img
                                                                  src={`${AdvertiserImg}/${profileData?.photo}`}
                                                                  // onError={
                                                                  //   handleImageErrorMainPicture
                                                                  // }
                                                                  // onLoad={handleImageSuccessMainPicture}
                                                                  className="commentImg rounded-circle"
                                                                  alt="Advertiser"
                                                                />
                                                              </>
                                                            ) : (
                                                              <Noimage_circle />
                                                            )
                                                          ) : (
                                                            <Noimage_circle />
                                                          )}

                                                          <TextBox
                                                            className="w-100 textBoxBorder ms-2"
                                                            //value={text}
                                                            value={
                                                              postCollection.comment_text
                                                                ? postCollection.comment_text
                                                                : ""
                                                            }
                                                            onValueChanged={
                                                              handleCommentTextbox
                                                            }
                                                            label="Write a Comment!"
                                                            labelMode="floating"
                                                          />
                                                          {/* <EmojiPicker /> */}
                                                        </div>
                                                        <button
                                                          onClick={
                                                            handleCommentReset
                                                          }
                                                          className="CancelBtn"
                                                        >
                                                          Cancel
                                                        </button>
                                                        <button
                                                          className="button"
                                                          onClick={
                                                            handleCommentSubmitButton
                                                          }
                                                        >
                                                          Submit
                                                        </button>

                                                        {postCollection.validation_for_comments && (
                                                          <span style={{color:'red'}}>
                                                            {
                                                              postCollection.validation_for_comments
                                                            }
                                                          </span>
                                                        )}
                                                      </>
                                                    </Modal.Footer>
                                                  </>
                                                )}
                                              </Modal>
                                            </div>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            )
                          ) : (
                            <>
                              <Skeleton height={50} />
                              <Skeleton height={400} />
                            </>
                          )}

                          <>
                            <Modal
                              show={popup.deleteornot}
                              onHide={handleClose}
                              centered
                            >
                              {/* <Modal.Header
                               
                                className="m-0 d-flex align-items-center justify-content-center text-center border-light"
                                style={{ padding: "10px 10px" }}
                              >
                                <div className="w-100">
                                  <h5 className="d-flex align-items-center  justify-content-center text-center gap-2">
                                    Are you sure you want to delete this Post?
                                  </h5>
                                </div>
                              </Modal.Header> */}

                              <Modal.Body
                                className=""
                                style={{ padding: "20px 12px" }}
                              >
                                <div className="w-100 pb-3">
                                  <h5 className="d-flex align-items-center  justify-content-center text-center gap-2">
                                    Are you sure you want to delete this Post?
                                  </h5>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="CancelBtn me-3"
                                    onClick={handleYesDelete}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="button"
                                    onClick={handleNoDelete}
                                  >
                                    No
                                  </button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>

                          <>
                            {currentPost && showModal && (
                              <>
                                <Modal
                                  show={showModal}
                                  onHide={handleClose_share}
                                  centered
                                >
                                  <Modal.Header
                                    closeButton
                                    className="m-0 d-flex align-items-center text-center border-light"
                                    style={{ padding: "10px 10px" }}
                                  >
                                    <h5 className="text-nowrap text-center m-auto">
                                      Share Post
                                    </h5>
                                  </Modal.Header>
                                  <Modal.Body
                                    className="d-flex flex-column gap-3"
                                    style={{ padding: "20px 12px 30px 12px" }}
                                  >
                                    <ShareToSocialMedia_myprofile
                                      postdescr={currentPost.postdescr}
                                      postimage={
                                        currentPost.postimage
                                          ? `${BASEPATH}${GET_IMAGES_PROFILE}${currentPost.postimage}`
                                          : currentPost.postvideo
                                          ? currentPost.postvideo
                                          : currentPost.postaudio
                                          ? currentPost.postaudio
                                          : null
                                      }
                                      shareUrl={ShareSocialMediaURL}
                                    />
                                  </Modal.Body>
                                  {/* <Modal.Footer>
                                    <Button
                                      className="border-button"
                                      variant="secondary"
                                      onClick={handleClose_share}
                                    >
                                      Close
                                    </Button>
                                  </Modal.Footer> */}
                                </Modal>
                              </>
                            )}
                          </>
                          {popup.names_likes && (
                            <>
                              <div>
                                <Modal
                                  show={popup.names_likes}
                                  onHide={handleClose_namesLikes}
                                  onClose={handleClose_namesLikes}
                                  centered
                                >
                                  <Modal.Header
                                    closeButton
                                    className="m-0 d-flex align-items-center text-center border-light"
                                    style={{ padding: "10px 10px" }}
                                  >
                                    <h5 className="text-nowrap text-center m-auto">
                                      Total Likes
                                    </h5>
                                  </Modal.Header>

                                  <Modal.Body
                                    className="d-flex flex-column gap-3"
                                    style={{ padding: "20px 24px" }}
                                  >
                                    {arrayoflikenames &&
                                      arrayoflikenames.map((item) => (
                                        <>
                                          <div
                                            className="flex-shrink-0 d-flex gap-2 align-items-center "
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleLikeNameLeadstoProfile(item)
                                            }
                                          >
                                            {item?.role_name === "artist" ? (
                                              isValidImage(item.photo) &&
                                              imageExists(
                                                `${ArtistallImg}/${item?.photo}`
                                              ) ? (
                                                <img
                                                  src={`${ArtistallImg}/${item?.photo}`}
                                                  onClick={() =>
                                                    handleLikeNameLeadstoProfile(
                                                      item
                                                    )
                                                  }
                                                  // onError={
                                                  //   handleimageErrorNamesLikes
                                                  // }
                                                  // onLoad={handleimageSuccessNamesLikes}
                                                  className="commentImg me-2"
                                                  style={{
                                                    borderRadius: "50%",
                                                    overflow: "hidden",
                                                  }}
                                                  alt="Artist"
                                                />
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : item?.role_name ===
                                              "organiser" ? (
                                              isValidImage(item.photo) &&
                                              imageExists(
                                                `${ORGANISER_URL}/${item?.photo}`
                                              ) ? (
                                                <>
                                                  <img
                                                    src={`${ORGANISER_URL}/${item?.photo}`}
                                                    onClick={() =>
                                                      handleLikeNameLeadstoProfile(
                                                        item
                                                      )
                                                    }
                                                    // onError={
                                                    //   handleimageErrorNamesLikes
                                                    // }
                                                    // onLoad={handleimageSuccessNamesLikes}
                                                    className="commentImg me-2"
                                                    style={{
                                                      borderRadius: "50%",
                                                      overflow: "hidden",
                                                    }}
                                                    alt="Organiser"
                                                  />
                                                </>
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : item?.role_name === "fan" ? (
                                              isValidImage(item.photo) &&
                                              imageExists(
                                                `${Fan_images}/${item.photo}`
                                              ) ? (
                                                <>
                                                  <img
                                                    src={`${Fan_images}/${item.photo}`}
                                                    onClick={() =>
                                                      handleLikeNameLeadstoProfile(
                                                        item
                                                      )
                                                    }
                                                    // onError={
                                                    //   handleimageErrorNamesLikes
                                                    // }
                                                    // onLoad={handleimageSuccessNamesLikes}
                                                    className="commentImg me-2"
                                                    style={{
                                                      borderRadius: "50%",
                                                      overflow: "hidden",
                                                    }}
                                                    alt="Fan"
                                                  />
                                                </>
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : (
                                              <Noimage_circle />
                                            )}{" "}
                                            <p
                                              className="p-0 m-0 "
                                              onClick={() =>
                                                handleLikeNameLeadstoProfile(
                                                  item
                                                )
                                              }
                                            >
                                              {item.name}
                                            </p>
                                          </div>
                                        </>
                                      ))}
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* end of post */}

                  {activeTab === "contact" && (
                    <div
                      id="contact"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <Messages_myprofile />
                    </div>
                  )}

                  {activeTab === "landingPage" && (
                    <div id="landingPage">
                      <LandingPageDetails />
                    </div>
                  )}
                  {activeTab === "calender" && (
                    <div
                      id="calender"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <MyCalendar_myprofile />
                    </div>
                  )}

                  {activeTab === "videos" && (
                    <div
                      id="videos"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <MyVideos_myprofile />
                    </div>
                  )}
                  {/* BLOGS  */}
                  {activeTab === "blogs" && (
                    <div
                      id="blogs"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <div className="section-padding pt-3">
                        <div className="">
                          <div className="row row-cols-lg-5 row-cols-md-2 row-cols-1"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "taggedblogs" && (
                    <div
                      id="taggedblogs"
                      //className="tab-pane animated fadeInUp"
                      //role="tabpanel"
                    >
                      <TaggedBlogs_myprofile />
                    </div>
                  )}

                  {activeTab === "account" && (
                    <div id="account">
                      <AccountDetails_myprofile />
                    </div>
                  )}
                  {activeTab === "editprofile" && (
                    <div id="editprofile">
                      <Edit_myprofile
                        onProfileUpdateSuccess={handleProfileUpdateSuccess}
                      />
                    </div>
                  )}

                  {activeTab === "followers" && (
                    <div id="followers">
                      <Followers_myprofile />
                    </div>
                  )}
                  {activeTab === "following" && (
                    <div id="following">
                      <Following_myprofile />
                    </div>
                  )}
                  {activeTab === "forms" && (
                    <div id="forms">
                      <MyForms />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
