import React from "react";
import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import "./Styles/artist.css";
import { ArtistallImg } from "../../../Subpages/MainPage/constant.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GET_ARTIST } from "../../../Api/api.js";
import {
  ERROR,
  SUCCESS,
  DURATION,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { makePostRequest } from "../../../Api/requestMethods.js";
import { FaFilter } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { generateAlphabetCharacters } from "../../../utils/commonUtils.js";
import { MdKeyboardArrowRight } from "react-icons/md";

//newly added by vignesh
import { BASEPATH } from "../../../config/config.js";
import axios from "axios";
import {
  GET_COUNTRIES_ARTIST,
  GET_STATES_ARTIST,
  GET_CITIES_ARTIST,
  ARTIST_FILTER,
  FETCH_ALL_ARTIST_MAINCATEGORY,
  GET_ALL_ARTIST_SUBCATEGORY,
} from "../../../Api/api.js";
import { SelectBox } from "devextreme-react";

import NoDataFound from "../../../global/NoDataFound.js";
import { Spinner } from "react-bootstrap";
import "./ArtistProfile.css";
import _, { truncate } from "lodash";
import Noimage from "../../../global/NoImage.js";
import { isValidImage, imageExists } from "../../../global/ImageCheck.js";
import FollowIcon from "../../../assets/Icons/FollowIcon.svg";
import FollowingIcon from "../../../assets/Icons/FollowingIcon.svg";
import AuthContext from "../../../context/AuthContext.js";
import { FOLLOW_INITIAL_CHECK, FOLLOW_A_USER, UNFOLLOW_A_USER } from "../../../Api/api.js";
import { GET_FOLLOWING } from "../../../Api/api.js";
import HandleErrorToast from "../../../Toaster/Failure/Failure.js";
import { IoChevronBack } from "react-icons/io5";
import getProfileKey from "../../../Api/getProfileKey.js";

const ArtistProfile = () => {

  const PROFILE_KEY = getProfileKey().profileKey;
  const [checkinitialFollow, setCheckInitialFollow] = useState({});
  const [counterfollowing, setcounterfollowing] = useState(0);
  const { user } = useContext(AuthContext);
  const [iteration, setIteration] = useState(0);

  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const doFilter = () => {
    setFilter(!filter);
  };

  const [breadCrumbCollection, setbreadCrumbCollection] = useState({
    breadCrumbCountry: "",
    breadcountryid: "",
    breadCrumbCity: "",
    breadcityid: "",
    breadCrumbAge: "",
    breadCrumbSubCategory: "",
    breadmaincategoryid: "",
    breadCrumbMainCategory: "",
    breadsubcategoryid: "",
    breadCrumbCurrent: "",
    check_country: true,
    check_city: true,
    check_age: true,
    check_maincategory: true,
    check_subcategory: true

  })

  //const { genreName } = useParams();
  //console.log(genreName,"genreName inside next artist page");

  const location = useLocation();
  const artistId = location?.state?.mainCatId;

  //console.log(artistId, "artistId");

  const mainCatName = location?.state?.mainCatName;
  const [CatName, setCatName] = useState("");

  //new code
  const [artistIdstate, setArtistIdstate] = useState("");

  useEffect(() => {
    if (artistId) {
      setArtistIdstate(Number(artistId));
    }
  }, [artistId]);

  useEffect(() => {
    if (mainCatName) {
      setCatName(mainCatName);
    }
    else {
      const params = new URLSearchParams(location?.search);

      setCatName(params.get("mainCatName") || "");
    }
  }, [mainCatName, location,]);

  //console.log(CatName, "catName");

  //end of new code

  // const mainCatName = location?.state?.mainCatName;

  // console.log(mainCatName, "mainCatName");

  const [dataSource, setDataSource] = useState({
    allArtistData: "",
    //new code by vignesh
    selectedAge: "",
    country: "",
    state: "",
    city: "",
    selectedCountry: "",
    //selectedState: "",
    selectedCity: "",

    finalData: [],

    maincategory_all: "",
    selectedMainCategory: "",
    subCategory_all: "",
    selectedSubCategory: "",
  });

  //new code by vignesh...
  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limit = 15;

  const loadMore = useCallback(async () => {
    //console.log("inside load more");

    if (isFetching || !hasMoreData) return;
    setIsFetching(true);
    setLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${ARTIST_FILTER}`,
        data: {
          artist_maincate: dataSource.selectedMainCategory
            ? dataSource.selectedMainCategory
            : "",
          artist_subcate: dataSource.selectedSubCategory
            ? dataSource.selectedSubCategory === 1000
              ? null
              : dataSource.selectedSubCategory
            : "",
          country_id: dataSource.selectedCountry
            ? dataSource.selectedCountry
            : "",
          city_id: dataSource.selectedCity ? dataSource.selectedCity : "",
          age_status: dataSource.selectedAge ? dataSource.selectedAge : "",
          limit: limit,
          offset: currentOffset,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      //console.log(response.data,"RD");
      if (response?.data.status === "success") {
        //setsearchCollection((prevState) => ({ ...prevState, searchData: response.data.data }));
        setIsFetching(false);
        setIteration(currentOffset);
        setLoading(false);


        // Update hasMoreData based on response data length
        setHasMoreData(response?.data?.data.length === limit);

        //console.log(response.data.data,"response.data.data from backend"); 

        setDataSource((prevState) => {

          const existingArtistIds = prevState?.finalData ? new Set(prevState?.finalData?.map(item => item.artist_id)) :'';


          const filteredNewData = existingArtistIds ? response?.data?.data.filter(item => !existingArtistIds.has(item?.artist_id)) : response?.data?.data ;


          const isSameData = JSON.stringify(prevState?.finalData) === JSON.stringify(filteredNewData);

          return {
            ...prevState,
            finalData:
              currentOffset === 0
                ? response.data.data
                : currentOffset > 0 && !isSameData && hasMoreData
                  ? [...prevState.finalData, ...filteredNewData]
                  : dataSource.finalData,

          };
        });

      }
      if (response?.data?.data?.length > 0) {
        setCurrentOffset((prevOffset) => prevOffset + limit);
       
        //setHasMoreData(false);
      }
      // else {
      //   setDataSource((prevState) => ({ ...prevState, finalData: [] }));
      //   setHasMoreData(false);
      //   setIsFetching(true);
      // }



      //setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      
      setIsFetching(false);
      setHasMoreData(false);
      setLoading(false);
      setIteration(0);
      //setDataSource((prev) => ({ ...prev, finalData: '' }));
    }
    finally {
      setIsFetching(false);
    }
  }, [
    currentOffset,
    isFetching,
    dataSource.selectedCity,
    dataSource.selectedAge,
    dataSource.selectedSubCategory,
  ]);

  useEffect(() => {
    if (
      dataSource.selectedCity && dataSource.selectedCity !== "" &&
      dataSource.selectedAge && dataSource.selectedAge !== "" &&
      dataSource.selectedSubCategory && dataSource.selectedSubCategory !== ''
    ) {
      //console.log("check here");
      loadMore();
    }
    // else if(!breadCrumbCollection.check_country ||
    //   !breadCrumbCollection.check_city ||
    //   !breadCrumbCollection.check_age ||
    //   !breadCrumbCollection.check_maincategory ||
    //   !breadCrumbCollection.check_subcategory) {
    //     loadMore();

    // }
    else {
      return;
    }

  }, [
    dataSource.selectedCity,
    dataSource.selectedAge,
    dataSource.selectedSubCategory,
    dataSource.selectedMainCategory,

    // breadCrumbCollection.check_country,
    // breadCrumbCollection.check_city,
    // breadCrumbCollection.check_age,
    // breadCrumbCollection.check_maincategory,
    // breadCrumbCollection.check_subcategory,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.scrollHeight &&
        !isFetching &&
        hasMoreData
      ) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, hasMoreData]);

  // end of new code

  const encodeBase64 = (str,key) => {
    
    const combinedStr = key + str.toString();
    return btoa(combinedStr); 
  };

  const handleClick = (profileData) => {

    const artistName = profileData.artist_name.replace(/[\s.]+/g, '');

    navigate(`/artist/${artistName}?rid=${encodeBase64(profileData.role_id, PROFILE_KEY)}`, {
      state: { profileData, artistId: profileData.role_id },
    });
  };



  //new code by vignesh.................................................

  const token = localStorage.getItem("MDnD_auth");

  const handleAgelessthan20 = () => {
    setDataSource((prev) => ({ ...prev, selectedAge: "Junior" }));

    setbreadCrumbCollection((prev) => ({ ...prev, breadCrumbAge: "Junior", check_age: true }))
    setHasMoreData(true);

    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
    setDataSource((prev) => ({ ...prev, finalData: '' }));
  };

  const handleAgemorethan20 = () => {
    setDataSource((prev) => ({ ...prev, selectedAge: "Senior" }));
    setbreadCrumbCollection((prev) => ({ ...prev, breadCrumbAge: "Senior", check_age: true }))

    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
    setDataSource((prev) => ({ ...prev, finalData: '' }));

  };

  //console.log(dataSource, "dataSource");

  //country,state,city
  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES_ARTIST);

  const GET_COUNTRY = async (GET_COUNTRIES_ARTIST) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES_ARTIST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {

        const filteredCountries = data.data.filter(
          (country) => country.country_id != null && country.country_name != null
        );

        if (filteredCountries) { setDataSource((prevState) => ({ ...prevState, country: filteredCountries })); }
      } else {
        setDataSource((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      //setAddress((prevState) => ({ ...prevState, country: ""}));
    },
  });

  //senior, junior,  initial AGE .........
  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const urlage = params?.get("age");

    if (urlage && !artistId && !dataSource.selectedAge
      && breadCrumbCollection.check_age === true && breadCrumbCollection.breadCrumbAge === '') {
      setDataSource((prevState) => ({
        ...prevState,
        selectedAge: urlage,
      }));
    }

    else if (artistId && mainCatName) {
      {
        setDataSource((prevState) => ({
          ...prevState,
          selectedAge: "Senior",
        }));
      }
    } else if (breadCrumbCollection.check_age === false && breadCrumbCollection.breadCrumbAge) {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedAge: params.get("age") || "",
      }));
    }
  }, [location, mainCatName, artistId, breadCrumbCollection.check_age, breadCrumbCollection.breadCrumbAge]);

  // initial COuntry

  useEffect(() => {
    if (artistId) {
      {
        setDataSource((prevState) => ({
          ...prevState,
          selectedCountry: 101,
        }));
      }
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: Number(params.get("countryid")) || "",
      }));
    }
  }, [location, mainCatName, artistId]);

  console.log(artistId, "artistid");

  //cities fetch..
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_ARTIST}`,
          {
            country_id: dataSource.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status === "success") {

          const filteredCities = response?.data?.data.filter(
            (city) => city.city_id != null && city.city_name != null
          );


          if (filteredCities) {
            // setDataSource((prevState) => ({
            //   ...prevState,
            //   city: response.data.data.sort((a, b) => {

            //     if (a.city_name && b.city_name) {
            //       return a.city_name.localeCompare(b.city_name);
            //     }

            //     return a.city_name ? -1 : 1;
            //   }),
            // }));

            setDataSource((prevState) => ({
              ...prevState,
              city: filteredCities }));

          }


        } else {
          setDataSource((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };
    if (dataSource.selectedCountry) {
      GETCITIESDATA();
    }
  }, [dataSource.selectedCountry]);



  // setting initial city
  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const urlCityId = Number(params.get("cityid"));

    if (urlCityId && !mainCatName && !dataSource.city) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: urlCityId,
      }));
    }
    else if (
      !artistId &&
      !mainCatName &&
      dataSource.city &&
      !dataSource.selectedCity
      && breadCrumbCollection.check_city === true
      && breadCrumbCollection.breadCrumbCity !== ''
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: dataSource.city[0]?.city_id,
      }));
    }

    else if (
      artistId &&
      mainCatName &&
      dataSource.city &&
      dataSource.city.length > 0
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: 3659,
      }));
    }

    else if (
      dataSource.selectedCity &&
      !mainCatName
      && breadCrumbCollection.check_city === false
      // && breadCrumbCollection.breadCrumbCity !==''

    ) {
      const params = new URLSearchParams(location?.search);
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: Number(params.get("cityid")) || "",
      }));
    }
  }, [location, mainCatName, artistId, dataSource.city,
    breadCrumbCollection.check_city, breadCrumbCollection.breadCrumbCity]);

  const handleCountry = (e) => {
    //console.log("im inside");
    if (e.value) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setDataSource((prevState) => ({ ...prevState, city: [] }));
      setDataSource((prevState) => ({
        ...prevState,
        // selectedState: "",
        selectedCity: "",
      }));


      const CountryData = dataSource?.country && dataSource?.country.filter((item) => item.country_id === Number(e?.value))

      if (CountryData && CountryData.length > 0) {
        setbreadCrumbCollection((prev) => ({
          ...prev,
          breadCrumbCountry: CountryData[0].country_name,
          check_country: true,

        }))
      }

      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setIteration(0);
      setDataSource((prev) => ({ ...prev, finalData: '' }));
    }
    
  };
  
  
  

  const handleCity = (value) => {
    //console.log("check");
    if (value) {

      setDataSource((prevState) => ({ ...prevState, selectedCity: value }));


      const CityData = dataSource.city && dataSource.city.filter((item) => item.city_id === Number(value))

      if (CityData && CityData.length > 0) {
        setbreadCrumbCollection((prev) => ({
          ...prev,
          breadCrumbCity: CityData[0].city_name,
          check_city: true
        }))
      }

      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setIteration(0);

      //setDataSource((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  const handleMainCategory = (e) => {
    let artistName = {};
    let genreName;
    if (e) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: e.value,
      }));
      setDataSource((prevState) => ({ ...prevState, subCategory_all: [] }));
      setDataSource((prevState) => ({ ...prevState, selectedSubCategory: "" }));
    }
    let filteredArray =
      dataSource.maincategory_all &&
      dataSource.maincategory_all.filter(
        (item) => item.main_cate_id === e.value
      );
    //console.log(filteredArray, "filteredArray");

    if (filteredArray) {
      setCatName(filteredArray[0]?.main_cate_name);
    }

    const mainData = dataSource.maincategory_all && dataSource.maincategory_all.filter((item) => item.main_cate_id === Number(e?.value))

    if (mainData && mainData.length > 0) {
      setbreadCrumbCollection((prev) => ({
        ...prev,
        breadCrumbMainCategory: mainData[0].main_cate_name,
        check_maincategory: true
      }))
    }


    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
    setDataSource((prevState) => ({
      ...prevState,
      //finalData: [],
      selectedSubCategory: "",
    }));
    setDataSource((prev) => ({ ...prev, finalData: '' }));

    // if (artistName) {
    //   navigate(`/artists/${genreName}`, { state: { mainCatId: artistName.main_cat_id, mainCatName: artistName.main_cat_name } });
    // }
  };

  const handleSubCategory = (e) => {
    if (e) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: e.value,
      }));
      //setHasMoreData((prevHasMoreData) => !prevHasMoreData);
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setIteration(0);
      //setDataSource((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  //fetch for mainCategory and sub-Category......................................
  useEffect(() => {
    mutationMainCategory.mutate();
  }, []);

  const GetMainCategory = () => GET_MainCategory(FETCH_ALL_ARTIST_MAINCATEGORY);

  const GET_MainCategory = async (FETCH_ALL_ARTIST_MAINCATEGORY) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${FETCH_ALL_ARTIST_MAINCATEGORY}`,
        // headers: {
        //   'MDnD_auth': `${token}`,

        // },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationMainCategory = useMutation({
    mutationKey: ["get_maincategory_artist"],
    mutationFn: () => GetMainCategory(),
    onSuccess: (data) => {
      if (data.status === "success") {
        setDataSource((prevState) => ({
          ...prevState,
          maincategory_all: data.data,
        }));
      } else {
        setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
    },
  });

  // setting initial maincategory  when the page loads
  useEffect(() => {

    const params = new URLSearchParams(location?.search);
    const urlMCId = Number(params.get("mainCategory"));

    if (urlMCId && !mainCatName && !dataSource.selectedMainCategory
      && breadCrumbCollection.check_maincategory === true
      && breadCrumbCollection.breadCrumbMainCategory === ''
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: urlMCId,
      }));
    }



    else if (mainCatName && artistId) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: Number(artistId),
      }));
    }

    else if (
      breadCrumbCollection.check_maincategory === false
      && breadCrumbCollection.breadCrumbMainCategory
    ) {

      const params = new URLSearchParams(location.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: Number(params.get("mainCategory")) || "",
      }));
    }
  }, [artistId, location, mainCatName, breadCrumbCollection.check_maincategory]);

  //fetch sub-category

  useEffect(() => {
    const GETSUBCATEGORIES = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_ALL_ARTIST_SUBCATEGORY}`,
          {
            maincategory_id: dataSource.selectedMainCategory,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response?.data?.status === "success") {

          let copyArray = [...response?.data?.data];

          let DummyObject = {
            sub_cat_id: 1000, sub_cat_name: 'All'
          }



          copyArray.unshift(DummyObject);


          if (copyArray) {
            setDataSource((prevState) => ({
              ...prevState,
              subCategory_all: copyArray,
            }));
          }


        } else {
          setDataSource((prevState) => ({ ...prevState, subCategory_all: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };

    //mutationGetStates.mutate();
    if (dataSource?.selectedMainCategory) {
      GETSUBCATEGORIES();
    }
  }, [dataSource.selectedMainCategory]);

  // set initial subCategory when the page loads...
  useEffect(() => {
    if (
      mainCatName &&
      artistId &&
      dataSource.subCategory_all &&
      dataSource.subCategory_all?.length > 0

    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: dataSource.subCategory_all[0].sub_cat_id,
      }));
    }
    else if (dataSource.selectedSubCategory && !mainCatName) {
      //console.log("subCategory param set here");
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: Number(params.get("subCategory")) || "",
      }));
    } else if (
      !mainCatName &&
      !artistId &&
      dataSource.subCategory_all &&
      !dataSource.selectedSubCategory
      && breadCrumbCollection.check_subcategory === true
    ) {
      //console.log("im here");
      setDataSource((prevState) => {
        let newSelectedSubCategory = Number(
          dataSource?.subCategory_all[0]?.sub_cat_id
        );

        return {
          ...prevState,
          selectedSubCategory: newSelectedSubCategory,
        };
      });
    }
  }, [dataSource.subCategory_all, location, mainCatName, artistId,
  breadCrumbCollection.check_subcategory,]);

  //dynamicaly changing the URL
  useEffect(() => {
    if (
      dataSource.selectedCountry &&
      dataSource.selectedMainCategory &&
      dataSource.selectedAge &&
      CatName &&
      dataSource.selectedSubCategory &&
      dataSource.subCategory_all &&
      dataSource.city &&
      dataSource.selectedCity
    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(dataSource.selectedCountry),
        cityid: dataSource.selectedCity ? Number(dataSource.selectedCity) : "",
        age: dataSource.selectedAge,
        mainCategory: dataSource.selectedMainCategory
          ? Number(dataSource.selectedMainCategory)
          : "",
        subCategory: dataSource.selectedSubCategory
          ? Number(dataSource.selectedSubCategory)
          : "",
        mainCatName: CatName,
      });

      const newUrl = `/Creators?${queryParams}`;
      navigate(newUrl);
    } else if (
      !breadCrumbCollection.check_country ||
      !breadCrumbCollection.check_city ||
      !breadCrumbCollection.check_age ||
      !breadCrumbCollection.check_maincategory ||
      !breadCrumbCollection.check_subcategory

    ) {
      const queryParams = new URLSearchParams({
        countryid: breadCrumbCollection.breadcountryid ? Number(breadCrumbCollection.breadcountryid) : "",
        cityid: breadCrumbCollection.breadcityid ? Number(breadCrumbCollection.breadcityid) : "",
        age: breadCrumbCollection.breadCrumbAge ? breadCrumbCollection.breadCrumbAge : "",
        mainCategory: breadCrumbCollection.breadmaincategoryid
          ? Number(breadCrumbCollection.breadmaincategoryid)
          : "",
        subCategory: breadCrumbCollection.breadsubcategoryid
          ? Number(breadCrumbCollection.breadsubcategoryid)
          : "",
        mainCatName: breadCrumbCollection.breadCrumbMainCategory ?
          breadCrumbCollection.breadCrumbMainCategory : "",
      });

      const newUrl = `/Creators?${queryParams}`;
      navigate(newUrl);
    }
  }, [
    dataSource.selectedCountry,
    dataSource.selectedCity,
    dataSource.selectedAge,
    CatName,
    dataSource.selectedMainCategory,
    dataSource.selectedSubCategory,
    navigate,
    dataSource.subCategory_all,
    dataSource.city,

    breadCrumbCollection.check_country,
    breadCrumbCollection.check_city,
    breadCrumbCollection.check_age,
    breadCrumbCollection.check_maincategory,
    breadCrumbCollection.check_subcategory,
  ]);

  //newly added..
  const [imageErrorHeader, setImageErrorHeader] = useState(false);

  const handleImageError_Header = () => {
    setImageErrorHeader(true);
  };

  //********************BREAD CRUMBS ******************** */




  useEffect(() => {
    if (dataSource?.selectedCountry && dataSource?.selectedCountry !== '' && dataSource.country) {
      const filteredData = dataSource.country && dataSource.country.filter((item) => item.country_id === Number(dataSource.selectedCountry))


      if (filteredData && filteredData.length > 0) {
        //setbreadcrumbCountry(filteredData[0]?.country_name)

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: filteredData[0]?.country_name,

          check_country: true,
          breadcountryid: filteredData[0]?.country_id,

        }));
      }
      else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry: '',

          breadcountryid: ""

        }));
      }

    }


  }, [dataSource?.selectedCountry, dataSource.country])

  useEffect(() => {
    if (dataSource?.selectedCity && dataSource?.selectedCity !== '' && dataSource?.city) {
      const cityData = dataSource?.city && dataSource?.city.filter((item) => item?.city_id === Number(dataSource.selectedCity))


      if (cityData && cityData.length > 0) {
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: cityData[0].city_name,

          check_city: true,
          breadcityid: cityData[0].city_id,


        }));
      }
      else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity: '',

          breadcityid: '',

        }));
      }

    }

  }, [dataSource?.selectedCity, dataSource?.city])


  useEffect(() => {
    if (dataSource.selectedSubCategory && dataSource.selectedSubCategory !== '' && dataSource.subCategory_all) {
      const subcategoryData = dataSource?.subCategory_all && dataSource?.subCategory_all.filter((item) => item?.sub_cat_id === dataSource.selectedSubCategory)

      console.log(subcategoryData, "subcategoryData");

      if (subcategoryData && subcategoryData.length > 0) {
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbSubCategory: subcategoryData[0].sub_cat_name,

          check_subcategory: true,
          breadsubcategoryid: subcategoryData[0].sub_cat_id


        }));
      }
      else {
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbSubCategory: '',

          breadsubcategoryid: ''

        }));
      }

    }


  }, [dataSource.selectedSubCategory, dataSource.subCategory_all])

  console.log(dataSource, "dataSource");

  useEffect(() => {
    if (dataSource.selectedAge) {
      //setbreadCrumbEventMode(dataSource.selectedEventMode);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbAge: dataSource.selectedAge,

        check_age: true

      }));
    }
    else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbAge: '',


      }));
    }

  }, [dataSource.selectedAge])


  useEffect(() => {
    if (dataSource.selectedMainCategory && dataSource.maincategory_all) {

      // const MCdata= dataSource.maincategory_all 
      //  && dataSource.maincategory_all.filter((item)=>item.main_cate_name === CatName);

      const MCdata = dataSource.maincategory_all
        && dataSource.maincategory_all.filter((item) => item.main_cate_id === Number(dataSource.selectedMainCategory));


      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbMainCategory: MCdata ? MCdata[0].main_cate_name : "",

        check_maincategory: true,
        breadmaincategoryid: MCdata ? MCdata[0].main_cate_id : "",

      }));

    }
    else {
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbMainCategory: '',

        breadmaincategoryid: ''


      }));
    }

  }, [dataSource.maincategory_all, dataSource.selectedMainCategory])

  const handleBreadCountry = () => {
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_country: false,
      breadCrumbCountry: "",
      breadcountryid: "",

      breadcityid: "",
      check_city: false,
      breadCrumbCity: ''

    }));

    setDataSource((prevState) => ({
      ...prevState,
      selectedCountry: '',
      selectedCity: '',
      city: '',
      finalData: []
    }));
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
  }

  const handleBreadCity = () => {
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_city: false,
      breadCrumbCity: "",
      breadcityid: ""

    }));
    setDataSource((prevState) => ({
      ...prevState,
      selectedCity: '',
      finalData: []
    }));
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
  }

  const handleBreadAge = () => {


    setbreadCrumbCollection((prevState) => ({
      ...prevState,

      breadCrumbAge: "",
      check_age: false,


    }));
    setDataSource((prevState) => ({
      ...prevState,
      selectedAge: '',
      finalData: []
    }));

    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
  }


  const handleBreadMainCategory = () => {
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_maincategory: false,
      breadCrumbMainCategory: "",
      breadmaincategoryid: "",

      breadCrumbSubCategory: "",
      breadsubcategoryid: "",
      check_subcategory: false

    }));
    setDataSource((prevState) => ({
      ...prevState,
      selectedMainCategory: '',
      selectedSubCategory: '',
      subCategory_all: '',
      finalData: []
    }));
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);
    setCatName('');
  }


  const handleBreadSubCategory = () => {
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      check_subcategory: false,
      breadCrumbSubCategory: "",
      breadsubcategoryid: ""

    }));

    if (dataSource.selectedSubCategory === 1000) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: '',

      }));
    }
    else {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: '',
        finalData: []
      }));
    }


    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setIteration(0);

  }

  console.log(breadCrumbCollection, "breadCrumbCollection.")


  //  useEffect(()=>{
  // if(dataSource.selectedCountry === "" 
  //   && dataSource.selectedCity === ""
  // && dataSource.selectedAge === ""
  // && dataSource.selectedMainCategory === ""
  // && dataSource.city === "" 
  // && dataSource.selectedSubCategory === ""

  // ){
  //   setbreadCrumbCollection((prevState) => ({
  //     ...prevState,
  //     check_country:true,
  //     check_city:true,
  //     check_age: true,
  //     check_maincategory: true,
  //     check_subcategory: true


  //   }));
  // }


  //  },[dataSource.selectedCountry,
  //     dataSource.selectedCity,
  //     dataSource.selectedAge,
  //     dataSource.selectedMainCategory,
  //     dataSource.selectedSubCategory,
  //     dataSource.subCategory_all,
  //     dataSource.city,])


  //FOLLOW/UNFOLLOW................


  // to find out initial status of follow.......................
  //   useEffect(() => {
  //     if (dataSource.finalData && dataSource.finalData.length > 0){



  // for (let i = iteration; i < dataSource.finalData.length; i++) {

  //         if(dataSource.finalData[i] && dataSource.finalData[i]['role_id'] ){
  //           mutationcheckfollowInitial.mutate(dataSource.finalData[i]['role_id']);
  //         }
  //       }



  //     }

  //    }, [dataSource.finalData])




  //click to follow/unfollow...........

  const handleFollow = (loginid, role_id) => {
    console.log("check");
    if (user) {
      if (checkinitialFollow[role_id] === "Follow") {
        mutationFollow.mutate({ loginid, role_id });
      } else if (checkinitialFollow[role_id] === "Unfollow") {
        mutationUnfollow.mutate({ loginid, role_id });
      }
      else if(Object.entries(checkinitialFollow).length === 0){
        mutationFollow.mutate({ loginid, role_id });
      }
    }
    else {

      HandleErrorToast('error', "Please Login to Continue", 4000);
      return;
    }
  };
  console.log(checkinitialFollow,"checkinitialFollow");

  const Follow_function = (loginid, role_id) =>
    FollowtoBackend(FOLLOW_A_USER, loginid, role_id);

  const FollowtoBackend = async (FOLLOW_A_USER, loginid, role_id) => {
    console.log("im here");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? Number(user.login_id) : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? Number(user.role_id) : "",
          user2_roleid: role_id ? Number(role_id) : "",
          follower_roleid: user ? Number(user.role_id) : "",
          following_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationFollow = useMutation({
    mutationKey: ["FollowUser"],
    mutationFn: ({ loginid, role_id }) => Follow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        //setcounterforinitialStatus(counterforinitialStatus + 1);
        checkinitialFollow[data?.data?.following_role_id] = "Unfollow";
        setcounterfollowing(counterfollowing + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }

    },
    onError: (error) => {
      console.error("Error fetching follow", error);
    },
  });

  // UNFOLLOW...
  const UnFollow_function = (loginid, role_id) =>
    UnFollowtoBackend(UNFOLLOW_A_USER, loginid, role_id);

  const UnFollowtoBackend = async (UNFOLLOW_A_USER, loginid, role_id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${UNFOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: loginid ? Number(loginid) : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: role_id ? Number(role_id) : "",
          unfollower_roleid: user ? user.role_id : "",
          unfollowing_roleid: role_id ? Number(role_id) : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationUnfollow = useMutation({
    mutationKey: ["UnFollowUser"],
    mutationFn: ({ loginid, role_id }) => UnFollow_function(loginid, role_id),
    onSuccess: (data) => {
      if (data.status === "success") {
        checkinitialFollow[data?.data?.unfollowing_role_id] = "Follow";
        setcounterfollowing(counterfollowing + 1);
      } else {
      }
    },
    onError: (error) => {
      console.error("Error fetching follow:", error);
    },
  });


  // new code for FOLLOW/unfollow...

  const [followCollection, setFollowCollection] = useState({
    following: "",

  })


  useEffect(() => {
    if (user) { mutationfetchFollowingList.mutate(); }
    else {
      return;
    }
  }, [counterfollowing])
  const Getting_Following = () =>
    GET_FOLLOWING_ALL(GET_FOLLOWING);

  const GET_FOLLOWING_ALL = async (
    GET_FOLLOWING) => {

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FOLLOWING}`,
        data: {
          role_id: user ? user.role_id : ""

        },

        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    }
    catch (error) {
      console.error(error);
    }
  };

  const mutationfetchFollowingList = useMutation({
    mutationKey: ["get_All_following"],
    mutationFn: (fid) => Getting_Following(),

    onSuccess: (data) => {

      let filteredData = data?.data && data?.data.filter((item) => item.role_name !== null && item.role_name !== "");

      const uniqueData = filteredData && filteredData?.reduce((acc, current) => {
        const duplicate = acc.find(item => item.following_id === current.following_id);

        if (!duplicate) {
          acc.push(current);
        }

        return acc;
      }, []);



      if (data.Status === "success") {



        if (uniqueData) { setFollowCollection((prevState) => ({ ...prevState, following: uniqueData })); }
      }
      else {
        setFollowCollection((prevState) => ({ ...prevState, following: uniqueData ? uniqueData : "" }));
      }
    },
    onError: (error) => {


      setFollowCollection((prevState) => ({ ...prevState, following: "" }));
    },
  });

  console.log(followCollection, "followCollection");

  //checkinitialfollow set............

  useEffect(() => {
    if (followCollection.following && followCollection.following.length > 0 &&
      dataSource.finalData && dataSource.finalData.length > 0) {


      const newCheckInitialFollow = { ...checkinitialFollow };


      dataSource.finalData.forEach(followItem => {
        const roleId = followItem['role_id'];
        
        const filteredIndex = followCollection.following.findIndex(item => item.following_id === roleId);

        if (filteredIndex !== -1) {
          newCheckInitialFollow[Number(roleId)] = "Unfollow"
        }
        else {
          newCheckInitialFollow[Number(roleId)] = "Follow"
        }


      });

      if (newCheckInitialFollow) {
        setCheckInitialFollow(prevState => ({
          ...prevState,
          ...newCheckInitialFollow
        }));
      }
    }
  }, [followCollection.following, dataSource.finalData]);

  console.log(checkinitialFollow, "checkInitialFollow");


  const handlebackButton=()=>{
    navigate('/artist');
  }

  console.log(dataSource?.finalData,"dataSource?.finalData")

  return (
    <>
      <div className="filter-page iq-breadcrumb eventsBanner ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  {CatName}
                </h2>
                <h4 className="mt-4 mt-lg-0 text-white">
                  Discover {CatName} artists all over the world
                </h4>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
              onClick={doFilter}
            >
              {/*Mobile breadcrumb */}
              <div
                className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              >

                <div
                  className="p-auto rounded d-block d-lg-none backBtn cursor-pointer"
                  style={{ border: "1px solid #ddd" }}
                >
                  <IoChevronBack
                    style={{ fontSize: "24px" }}
                  onClick={handlebackButton}
                  />
                </div>&nbsp;&nbsp;

                {breadCrumbCollection.check_age &&

                  <><p className="m-0 p-0" style={{ flexShrink: 0 }}
                  //onClick={handleBreadAge}
                  >
                    {breadCrumbCollection.breadCrumbAge || ''}
                  </p>
                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i></>
                }

                {breadCrumbCollection.check_country &&
                  breadCrumbCollection?.breadCrumbCountry !== '' &&
                  <>
                    <p className="m-0 p-0" style={{ flexShrink: 0 }}
                    //onClick={handleBreadCountry}
                    >
                      {breadCrumbCollection?.breadCrumbCountry || ''}
                    </p>

                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i></>
                }

                {breadCrumbCollection.check_city &&
                  breadCrumbCollection?.breadCrumbCity !== '' &&
                  <><p className="m-0 p-0" style={{ flexShrink: 0 }}
                  //onClick={handleBreadCity}
                  >
                    {breadCrumbCollection?.breadCrumbCity || ''}
                  </p>
                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i></>
                }






                {breadCrumbCollection.check_maincategory &&
                  breadCrumbCollection?.breadCrumbMainCategory !== '' &&
                  <><p className="m-0 p-0" style={{ flexShrink: 0 }}
                  //onClick={handleBreadMainCategory}
                  >
                    {breadCrumbCollection?.breadCrumbMainCategory || ''}
                  </p>
                    <i style={{ fontSize: "16px" }}>
                      <MdKeyboardArrowRight />
                    </i>
                  </>}


                {breadCrumbCollection.check_subcategory && breadCrumbCollection.breadCrumbSubCategory !== undefined &&
                  breadCrumbCollection.breadCrumbSubCategory !== "" &&
                  <p className="m-0 p-0" style={{ color: "#3F00FF", whiteSpace: "nowrap" }}
                    onClick={handleBreadSubCategory}
                  >
                    {breadCrumbCollection?.breadCrumbSubCategory || ''}
                  </p>}





              </div>



              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">


              <>
                <div className="filterBg pb-2">
                  <div className="">
                    <h5>
                      <FaFilter /> Filters
                    </h5>
                  </div>

                  <div className="w-auto">
                    <h6 className="mb-2">Age </h6>
                    <div className="w-auto d-flex gap-2">
                      <button
                        onClick={handleAgemorethan20}
                        className={
                          dataSource?.selectedAge && dataSource?.selectedAge === "Senior"
                            ? "btn py-1 px-3 act filterBtn m-0 text-nowrap col-6 d-flex justify-content-center"
                            : "btn py-1 px-3 filterBtn m-0 text-nowrap col-6 d-flex justify-content-center"
                        }
                      >
                        {" "}
                        Above 20 years
                      </button>
                      <button
                        onClick={handleAgelessthan20}
                        className={
                          dataSource?.selectedAge && dataSource?.selectedAge === "Junior"
                            ? "btn py-1 px-3 act filterBtn m-0 text-nowrap col-5 d-flex justify-content-center"
                            : "btn py-1 px-3 filterBtn m-0 text-nowrap col-5 d-flex justify-content-center"
                        }
                      >
                        Upto 20 years
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h6 className="mb-2">Location</h6>

                    <SelectBox
                      dataSource={dataSource.country || []}
                      displayExpr="country_name"
                      valueExpr="country_id"
                      label="Select Country"
                      searchEnabled={true}
                      onValueChanged={handleCountry}
                      labelMode="floating"
                      value={dataSource.selectedCountry}
                    />

                    <SelectBox
                      dataSource={dataSource.city || []}
                      label="Select City"
                      labelMode="floating"
                      displayExpr="city_name"
                      valueExpr="city_id"
                      searchEnabled={true}
                      onValueChanged={(e) => handleCity(e.value)}
                      value={dataSource.selectedCity}
                    />
                  </div>

                  <div className="mt-4">
                    <h6 className="mt-4 mb-2">Categories</h6>
                    <div className="accordion mt-0" id="accordionExample">
                      <SelectBox
                        dataSource={dataSource.maincategory_all || []}
                        displayExpr="main_cate_name"
                        valueExpr="main_cate_id"
                        label="Change Category"
                        searchEnabled={true}
                        onValueChanged={handleMainCategory}
                        labelMode="floating"
                        value={dataSource.selectedMainCategory}
                      />
                    </div>

                    <h6 className="mt-4 mb-2">Sub-Categories</h6>

                    <div className="accordion mt-0" id="accordionExample">
                      <SelectBox
                        dataSource={dataSource.subCategory_all || []}
                        displayExpr="sub_cat_name"
                        valueExpr="sub_cat_id"
                        label="Select Sub-Category"
                        searchEnabled={true}
                        onValueChanged={handleSubCategory}
                        labelMode="floating"
                        value={dataSource.selectedSubCategory}
                      />
                    </div>
                  </div>
                </div>
              </>

            </div>
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                {/* ------------------------------------------------------------ */}
                <Modal
                  className="filterPopUp"
                  show={filter}
                  onHide={doFilter}
                  style={{ zIndex: "10001" }}
                >
                  <Modal.Header
                    closeButton
                    className="m-0 d-flex align-items-center text-center border-light"
                    style={{ padding: "10px 10px" }}
                  >
                    <div className="w-100 filterBg pb-2">
                      <h5 className="d-flex align-items-center gap-2">
                        <FaFilter /> Filters
                      </h5>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className=" pb-2">
                      <div className="w-auto">
                        <h6 className="mb-2">Age </h6>
                        <div className="w-auto d-flex gap-2">
                          <button
                            onClick={handleAgemorethan20}
                            className={
                              dataSource.selectedAge && dataSource.selectedAge === "Senior"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            {" "}
                            20 years
                          </button>
                          <button
                            onClick={handleAgelessthan20}
                            className={
                              dataSource.selectedAge && dataSource.selectedAge === "Junior"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Upto 20 years
                          </button>
                        </div>
                      </div>
                      <div className="mt-4">
                        <h6 className="mb-2">Location</h6>

                        <SelectBox
                          dataSource={dataSource.country || []}
                          displayExpr="country_name"
                          valueExpr="country_id"
                          label="Select Country"
                          searchEnabled={true}
                          onValueChanged={handleCountry}
                          labelMode="floating"
                          value={dataSource.selectedCountry}
                          dropDownOptions={{ container: ".modal" }}
                        />

                        <SelectBox
                          dataSource={dataSource.city || []}
                          label="Select City"
                          labelMode="floating"
                          displayExpr="city_name"
                          valueExpr="city_id"
                          searchEnabled={true}
                          onValueChanged={(e) => handleCity(e.value)}
                          value={dataSource.selectedCity}
                          dropDownOptions={{ container: ".modal" }}
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="mt-4 mb-2">Categories</h6>
                        <div className="accordion mt-0" id="accordionExample">
                          <SelectBox
                            dataSource={dataSource.maincategory_all || []}
                            displayExpr="main_cate_name"
                            valueExpr="main_cate_id"
                            label="Change Category"
                            searchEnabled={true}
                            onValueChanged={handleMainCategory}
                            labelMode="floating"
                            value={dataSource.selectedMainCategory}
                            dropDownOptions={{ container: ".modal" }}
                          />
                        </div>

                        <h6 className="mt-4 mb-2">Sub-Categories</h6>

                        <div className="accordion mt-0" id="accordionExample">
                          <SelectBox
                            dataSource={dataSource.subCategory_all || []}
                            displayExpr="sub_cat_name"
                            valueExpr="sub_cat_id"
                            label="Select Sub-Category"
                            searchEnabled={true}
                            onValueChanged={handleSubCategory}
                            labelMode="floating"
                            value={dataSource.selectedSubCategory}
                            dropDownOptions={{ container: ".modal" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <button className="button" onClick={doFilter}>
                      Apply
                    </button>
                  </Modal.Footer>
                </Modal>
                {/* ---------------------------------------------------------- */}
              </div>
              {/* {loading ? (
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <span className="col " key={index}>
                      <Skeleton className="artistImgSize" />
                    </span>
                  ))}
                </div>
              ) : */}




              <div className="d-flex flex-column gap-2">
              
                <>

                
                  {/* Website breadcrumb */}
                  < div className="d-flex flex-column gap-2">
                  

                    <div className="d-none d-lg-block">
                    <div className="d-flex">
                    
                    
                    <div
                  className="p-auto rounded d-none d-lg-block backBtn cursor-pointer"
                  style={{ border: "1px solid #ddd" }}
                >
                  <IoChevronBack
                    style={{ fontSize: "24px" }}
                    onClick={handlebackButton}
                  />
                </div>&nbsp;&nbsp;&nbsp;



                      <div
                        className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                        style={{ gap: "2px", overflow: "scroll" }}
                      >
                        {breadCrumbCollection.check_age &&

                          <>
                            <p className="m-0 p-0" style={{ flexShrink: 0 }}
                            //onClick={handleBreadAge}
                            >
                              {breadCrumbCollection.breadCrumbAge || ''}
                            </p>


                            <i style={{ fontSize: "16px" }}>
                              <MdKeyboardArrowRight />
                            </i></>
                        }


                        {/* <p className="m-0 p-0"
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      >{breadCrumbCollection.breadCrumbCountry}</p> */}


                        {breadCrumbCollection.check_country &&
                          breadCrumbCollection?.breadCrumbCountry !== '' &&
                          <>
                            <p className="m-0 p-0" style={{ flexShrink: 0 }}
                            //onClick={handleBreadCountry}
                            >
                              {breadCrumbCollection?.breadCrumbCountry || ''}
                            </p>
                            <i style={{ fontSize: "16px" }}>
                              <MdKeyboardArrowRight />
                            </i></>
                        }





                        {/* <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbCity}
                      </p> */}

                        {breadCrumbCollection.check_city &&
                          breadCrumbCollection?.breadCrumbCity !== '' &&
                          <><p className="m-0 p-0" style={{ flexShrink: 0 }}
                          //onClick={handleBreadCity}
                          >
                            {breadCrumbCollection?.breadCrumbCity || ''}
                          </p>
                            <i style={{ fontSize: "16px" }}>
                              <MdKeyboardArrowRight />
                            </i></>
                        }




                        {/* <p className="m-0 p-0" 
                      // style={{ color: breadCrumbCollection.breadCrumbCurrent === "Age" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                     

                      >
                      {breadCrumbCollection.breadCrumbAge}
                      </p> */}





                        {/* <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Main Category" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbMainCategory}
                      </p> */}

                        {breadCrumbCollection.check_maincategory &&
                          breadCrumbCollection?.breadCrumbMainCategory !== '' &&
                          <><p className="m-0 p-0" style={{ flexShrink: 0 }}
                          //onClick={handleBreadMainCategory}
                          >
                            {breadCrumbCollection?.breadCrumbMainCategory || ''}
                          </p>
                            <i style={{ fontSize: "16px" }}>
                              <MdKeyboardArrowRight />
                            </i>
                          </>}





                        {/* <p className="m-0 p-0" 
                       style={{ color: breadCrumbCollection.breadCrumbCurrent === "SubCategory" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbSubCategory}
                      </p> */}

                        {breadCrumbCollection.check_subcategory && breadCrumbCollection.breadCrumbSubCategory !== undefined &&
                          breadCrumbCollection.breadCrumbSubCategory !== "" &&
                          <p className="m-0 p-0" style={{ color: "#3F00FF", whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={handleBreadSubCategory}
                          >
                            {breadCrumbCollection?.breadCrumbSubCategory || ''}
                          </p>}





                      </div>
                      </div>
                    </div>
                    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                      {dataSource?.finalData && dataSource?.finalData?.length > 0 ? (
                        dataSource?.finalData?.map((artist) => (
                          <div className="col" key={artist.artist_id}>
                            <div
                              className="iq-cast rounded-3 overflow-hidden"
                            //onClick={() => handleClick(artist)}
                            >
                              <div
                                className="img-container artistImgSize"
                                style={{
                                  backgroundColor: artist.artist_photo
                                    ? "transparent"
                                    : "#dddddd",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {artist.artist_photo && artist.artist_photo !== '' ? (
                                  <img
                                    src={`${ArtistallImg}/${artist.artist_photo}`}
                                    className="img-fluid object-cover"
                                    //onError={handleImageError_Header}
                                    alt="person"
                                    onClick={() => handleClick(artist)}
                                    style={{ objectFit: "cover", height: "100%",cursor: 'pointer' }}
                                  />
                                ) : (
                                  <h1 
                                  style={{ color: '#ffffff', fontSize: '4rem', cursor: 'pointer' }}
                                  onClick={() => handleClick(artist)}
                                  >
                                  {generateAlphabetCharacters(artist?.artist_name)}
                                  </h1>
                                )}
                              </div>

                              <div className="card-img-overlay d-flex justify-content-between artistTextBg position-relative">
                                <p
                                  className="cast-title fw-500 text-black mb-0"
                                  onClick={() => handleClick(artist)}
                                >
                                  {artist.artist_name}
                                </p>
                                <span>



                                  <>
                                    <button>
                                      {checkinitialFollow[artist.role_id] === "Unfollow" ? (
                                        <>
                                          <img
                                            src={FollowingIcon}
                                            onClick={() =>
                                              handleFollow(artist.login_id, artist.role_id)
                                            }
                                            alt="Following"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          onClick={() =>
                                            handleFollow(artist.login_id, artist.role_id)
                                          }
                                          src={FollowIcon}
                                          alt="Follow"
                                        />
                                      )}
                                    </button>

                                  </>

                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>

                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArtistProfile;
