import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { GET_FORMS_FROM_BACKEND } from "../../../Api/api";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import { useMutation } from "@tanstack/react-query";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function MyForms() {

    const { user } = useContext(AuthContext);

    const token = localStorage.getItem("MDnD_auth");

    const [formsFromBackend, setFormsFromBackend] = useState({
        formData: "",
        profileDetails: "",
        singleData: ""
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const triggerMutation = () => {
            if (user) {
                mutationgetListAllCreatedForms.mutate();
            }
        };
        triggerMutation();

    }, []);

    const GetAllForms = () =>
        GET_FORMS(GET_FORMS_FROM_BACKEND);

    const GET_FORMS = async (
        GET_FORMS_FROM_BACKEND) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_FORMS_FROM_BACKEND}`,
                data: {
                    role_id: user ? Number(user.role_id) : ""
                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetListAllCreatedForms = useMutation({
        mutationKey: ["get_all_createdForms"],
        mutationFn: () => GetAllForms(),

        onSuccess: (data) => {
            setLoading(false);


            if (data.status === "success") {

                let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                setFormsFromBackend((prevState) => ({ ...prevState, formData: filteredArray }));


            }
            else {
                setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
            }
        },
        onError: (error) => {
            setLoading(false);
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            //HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
            setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
        },
    });

    console.log(formsFromBackend, "formsFromBackend");

    const handleDate = ({ data }) => {

        const date = new Date(data.created_date);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleRegFormLink = (cellInfo) => {

        const rowData = cellInfo.data;
        const buttonStyle = {
            backgroundColor: "#e6ddff",
            color: "black",
            padding: "5px"
        };
        return (
            <>
                <button style={buttonStyle} onClick={() => handleRegisterFormtoNextPage(rowData)}>View Form</button>
            </>)

    }

    const encodeBase64 = (str) => {
        const prefix = "xy4AT";
        const combinedStr = prefix + str.toString();
        return btoa(combinedStr);
    };

    const handleRegisterFormtoNextPage = async (rowData) => {
        if (rowData) {

            const formName = rowData.form_name.replace(/ /g, "_");

            const formid = rowData.form_id;
            localStorage.setItem('formidmain', formid);
            const basePath = window.location?.origin;

            const encodedFormId = formid && encodeBase64(formid);
            const url = `${basePath}/Form/${formName}?fid=${encodedFormId}`;


            window.open(url, '_blank');
        }
        else { return; }

    }


    return (<>
        <div>My Forms</div>


        <div className='mt-2'>
            {loading ? (
                <Skeleton count={10} height={60} />
            ) : (
                <DataGrid
                    dataSource={formsFromBackend.formData}
                    showBorders={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    rowAlternationEnabled={true}
                //onRowExpanding={handleExpand}
                >
                    <Column
                        caption="Sl.No"
                        cellRender={(cellInfo) => {
                            const { rowIndex, component } = cellInfo;
                            const pageSize = component.pageSize();
                            const pageIndex = component.pageIndex();
                            const totalCount = component.totalCount();


                            const descendingIndex = totalCount - (pageIndex * pageSize + rowIndex);

                            return descendingIndex;
                        }}
                        width={80}
                        allowEditing={false}
                    />

                    <Column
                        dataField="form_id"
                        visible={false}

                    />
                    <Column
                        dataField="table_name"
                        visible={false}
                    />
                    <Column
                        dataField="form_name"
                        caption='Title'
                    />

                    <Column
                        dataField="created_date"
                        caption="Created on"
                        cellRender={handleDate}
                    />

                    <Column
                        //dataField="Registration Form List"
                        caption="Form Link"
                        cellRender={handleRegFormLink}

                    />


                </DataGrid>
            )}

        </div>

    </>)
}