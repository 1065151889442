import React, { createContext, useState } from "react";
export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    profileDetails: "",
    countryDetails: "",
    selectedCountry: "",
    cityDetails: "",
    selectedCities: "",
    eventsDetails: "",
  });

  const [eventsDatasource, setEventDataSource] = useState({
    selectedMainCategory: "",
    selectedMainCategoryStr: "",
    eventsDetails: "",
    allEventsDetails: "",
    cityDetails: "",
    selectedCity: null,
    countryDetails: "",
    selectedCountry: null,
    selectedCountryDisplayValue: "",
    selectedCityDisplayValue: "",
    GrandTotal: "",
    RowTotal: "",
    formdata: "",

    // Season
    seasonGrandTotal: "",
    seasonRowTotal: "",

    eventDetails: "",
    invoiceDetails: "",
    selectedFromDate: "",
    selectedToDate: "",
    eventFilters: [
      {
        eventMode: "",
        pricingDetails: [],
        dateBatch: "",
        dateMode: "",
      },
    ],
    isHeaderEnabled: false,
    numberedTicketAmountDetails: [],
    numberedChoosedTicketAmount: null,
    trigger: 0,
    numberedEventTempBookedDetails: "",
    numberedEventDetails: "",

    svgWidth: null,
    svgHeight: null,

    saveDraftRefetchTrigger: 0,
    multiple_tickets: "",
    nonNumberedTicketingDetails : "",

    quickAddDataSourceGlobal : "",

    membersOnlyName : "",
    membersOnlyEmail : "",
    membersOnlyMobile : ""
  });

  const [cartDetails, setCartDetails] = useState({
    cartValue: 0,
    fromCheckOutCart: false,
  });

  const globalData = {
    cartDetails,
    setCartDetails,
    userDetails,
    setUserDetails,
    eventsDatasource,
    setEventDataSource,
  };

  return (
    <GlobalContext.Provider value={globalData}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
