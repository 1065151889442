import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../global/provider.js";
import { Helmet } from "react-helmet";
import {
  MAIN_CATEGORY_IMAGES,
  countryParams,
  cityParams,
  cityIdChennai,
  countryIdIndia,
} from "../../global/constants.js";
import { IoChevronBack } from "react-icons/io5";
import NoImage from "../../components/static/noImage.png";
import NoEventFound from "../../assets/images/noEventFound.jpg";
import "./artist.css";
import { SelectBox } from "devextreme-react";
import { ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { useQuery } from "@tanstack/react-query";
import {
  GET_COUNTRIES,
  GET_CITIES_BY_COUNTRIES,
  GET_EVENTS,
} from "../../Api/api.js";
import { makePostRequest, makeGetRequest } from "../../Api/requestMethods.js";
import { useMutation } from "@tanstack/react-query";
import { FaFilter } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { getUserNameById } from "../../utils/commonUtils.js";
import useCustomSearchParams from "../../Pages/Components/filterPage/locationSearchParams.js";
import { isValidValue } from "../../utils/commonUtils.js";
import { MdKeyboardArrowRight } from "react-icons/md";
//import { Helmet } from 'react-helmet';

//newly added
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MetadataSchema } from "hls.js";

const Event = () => {
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState({
    cityDetails: "",
    selectedCountry: null,
    selectedCity: null,
    eventsDetails: "",
    selectedMainCategory: "",
  });
  const { insertOrUpdateParams, removeParams } = useCustomSearchParams();
  const [previousValue, setPreviousValue] = useState({
    countryPreviousValue: null,
    cityPreviousValue: null,
    currentCity: "",
    currentCountry: "",
    Trigger: 0,
    currentCity: "",
    currentCountry: "",
    Trigger: 0,
  });

  const doFilter = () => {
    setFilter(!filter);
  };
  const navigate = useNavigate();
  const handleClick = (main_cat_id, cat_name) => {
    setDataSource((prev) => ({
      ...prev,
      selectedMainCategory: main_cat_id,
    }));

    //added new- AUG 8TH

    navigate(
      `/ViewEvents/${cat_name}?c=${dataSource.selectedCity}&ci=${dataSource.selectedCountry}&mc=${main_cat_id}`,
      { state: { mainCatId: main_cat_id, mainCatName: cat_name } }
    );
  };

  const cityMutation = useMutation({
    mutationFn: (countryId) =>
      countryId !== "" && countryId !== null && getCityDetails(countryId),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        cityDetails: data?.data,
      }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (isValidValue(dataSource?.selectedCountry)) {
      const body = {
        country_id: dataSource?.selectedCountry,
      };
      cityMutation.mutate(body);
    }
  }, [dataSource?.selectedCountry, dataSource.selectedCity]);

  const getCityDetails = async (userId) => {
    try {
      if (userId) {
        const response = await makePostRequest(GET_CITIES_BY_COUNTRIES, userId);
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  const getEvents = async (data) => {
    try {
      if (data) {
        const response = await makePostRequest(GET_EVENTS, data);
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  // Function to fetch countries data
  const countryFetch = async () => await makeGetRequest(GET_COUNTRIES);

  // React Query hook to fetch and cache country data
  const { data: countryData } = useQuery({
    queryKey: ["countryFetch"], // Unique key for this query
    queryFn: () => countryFetch(), // Function to execute for fetching data
    onError: (error) => {
      // Handling errors
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // React Query mutation hook for fetching events
  const eventMutation = useMutation({
    mutationFn: (data) => getEvents(data), // Function to execute when mutation is triggered
    onSuccess: (data) => {
      // On successful fetch, update the dataSource state with new events
      setDataSource((prev) => ({ ...prev, eventsDetails: data?.data }));
      setLoading(false);
    },
    onError: (error) => {
      // Handling errors
      notify(error.response.data.message, ERROR, DURATION);
      setLoading(false);
    },
  });

  useEffect(() => {
    // Check if both city and country are selected
    const isValidSelection =
      isValidValue(dataSource?.selectedCity) &&
      isValidValue(dataSource?.selectedCountry);

    if (isValidSelection) {
      // Prepare the request body
      const body = {
        country_id: dataSource.selectedCountry,
        city_id: dataSource.selectedCity,
      };
      // Trigger the event mutation
      setLoading(true);
      eventMutation.mutate(body);
    }
  }, [dataSource?.selectedCity, dataSource?.selectedCountry]);

  // Effect to set default city when no city is selected and no events are loaded
  useEffect(() => {
    if (
      dataSource &&
      dataSource.selectedCity === null &&
      dataSource?.eventsDetails?.length === 0
    ) {
      // Set the default city to Chennai
      setDataSource((prev) => ({
        ...prev,
        selectedCity: cityIdChennai,
      }));

      // Increment the Trigger in previousValue to cause a re-render
      setPreviousValue((prev) => ({ ...prev, Trigger: prev.Trigger + 1 }));
    }
  }, [dataSource.selectedCity]); // This effect runs when selectedCity changes

  // Effect to set default country when no country is selected
  useEffect(() => {
    if (dataSource && dataSource.selectedCountry === null) {
      // Set the default country to India
      setDataSource((prev) => ({
        ...prev,
        selectedCountry: countryIdIndia,
      }));

      // Increment the Trigger in previousValue to cause a re-render
      setPreviousValue((prev) => ({
        ...prev,
        Trigger: prev.Trigger + 1,
      }));
    }
  }, [countryData]); // This effect runs when countryData changes

  // Handle country events
  const handleCountryEvent = (e) => {
    // update the onChange value to the country state and making city datasource empty whenever coutry get changes
    setDataSource((prev) => ({
      ...prev,
      selectedCountry: e.value,
      selectedCity: null,
      cityDetails: [],
    }));

    // updating the params whenver the country gets changed
    insertOrUpdateParams(countryParams, e.value);
    // removing the city whenever contry getting changed
    removeParams(cityParams);
  };

  // To insert the URL parameters initially when the component gets mounted
  useEffect(() => {
    // Update the country
    insertOrUpdateParams(countryParams, countryIdIndia);
    // update the city
    insertOrUpdateParams(cityParams, cityIdChennai);
  }, []);

  // Handle city event
  const handleCityEvent = (e) => {
    // update the city value to the local state
    setDataSource((prev) => ({
      ...prev,
      selectedCity: e.value,
    }));
    if (e.value && e.value !== null) {
      // update the params only if the city value is not Null
      insertOrUpdateParams(cityParams, e.value);
    }
  };

  // Effect hook to update previousValue when relevant data changes
  useEffect(() => {
    // Check if a city is selected
    if (dataSource.selectedCity !== null) {
      // Get the country name based on the selected country ID
      const getcountry = getUserNameById(
        countryData?.data,
        dataSource.selectedCountry,
        "country_id",
        "country_name"
      );

      // Get the city name based on the selected city ID
      const getCity = getUserNameById(
        dataSource?.cityDetails,
        dataSource.selectedCity,
        "city_id",
        "city_name"
      );

      // Update previousValue state with the new city and country names
      setPreviousValue((prev) => ({
        ...prev,
        currentCity: getCity,
        currentCountry: getcountry,
      }));
    }
    const metaTitle = 'Explore the Finest Indian Music, Dance, and Drama Events | Free and Ticketed Near You';
    document.title = metaTitle;
    const metaDescription = 'Discover a list of top music, dance, and drama events near you, free and ticketed, all in one place';
    const descriptionTag = document.querySelector('meta[name="description"]');
    if (descriptionTag) {
      descriptionTag.setAttribute("content", metaDescription);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = metaDescription;
      document.head.appendChild(meta);
    }
  }, [
    dataSource?.cityDetails,
    countryData?.data,
    dataSource?.selectedCity,
    dataSource?.selectedCountry,
    previousValue?.Trigger,
  ]);


  // new code........................................
  const [breadcrumbCountry, setbreadcrumbCountry] = useState("");
  const [breadCrumbCity, setbreadCrumbCity] = useState("");



  useEffect(() => {
    if (dataSource?.selectedCountry && dataSource?.selectedCountry != '') {
      const filteredData = countryData?.data && countryData?.data.filter((item) => item.country_id === Number(dataSource.selectedCountry))


      if (filteredData && filteredData.length > 0) { setbreadcrumbCountry(filteredData[0]?.country_name) }

    }


  }, [dataSource?.selectedCountry, countryData?.data])

  useEffect(() => {
    if (dataSource?.selectedCity && dataSource?.selectedCity !== '') {
      const cityData = dataSource?.cityDetails && dataSource?.cityDetails.filter((item) => item?.city_id === dataSource.selectedCity)


      if (cityData) {
        setbreadCrumbCity(cityData[0].city_name);
      }

    }

  }, [dataSource?.selectedCity, dataSource?.cityDetails])



  return (
    <>
     
      <div className="">
        <div className="filter-page iq-breadcrumb eventBanner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb" className="text-center">
                  <h2 className="mt-4 mt-lg-0 header-title text-white">
                    Events
                  </h2>
                  <h2 className="mt-4 mt-lg-0 header-title text-white">
                    Discover Music, Dance & Drama events
                  </h2>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <section className={`pt-0 mt-sm-0`}>
          <div className="container-fluid d-flex pt-3">
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              <div className="filterBg">
                <div>
                  <Modal
                    className="filterPopUp"
                    show={filter}
                    onHide={doFilter}
                  >
                    <Modal.Header
                      closeButton
                      className="m-0 d-flex align-items-center text-center border-light"
                      style={{ padding: "10px 10px" }}
                    >
                      <div className="w-100 filterBg pb-2">
                        <h5 className="d-flex align-items-center gap-2">
                          <FaFilter /> Filters
                        </h5>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="p-3">
                      <div className="">
                        <div className="">
                          <SelectBox
                            dataSource={countryData?.data || null}
                            displayExpr="country_name"
                            valueExpr="country_id"
                            label="Country"
                            labelMode="floating"
                            // defaultValue={101}
                            searchEnabled={true}
                            onValueChanged={handleCountryEvent}
                            value={dataSource?.selectedCountry || null}
                          />

                          <SelectBox
                            dataSource={dataSource?.cityDetails || null}
                            displayExpr="city_name"
                            valueExpr="city_id"
                            label="City"
                            //  defaultValue={3659}
                            labelMode="floating"
                            searchEnabled={true}
                            onValueChanged={handleCityEvent}
                            value={dataSource?.selectedCity || null}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="py-1 px-2 border-0">
                      <button className="button" onClick={doFilter}>
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="filterBg">
                <div className="">
                  <h5>
                    <FaFilter /> Filters
                  </h5>
                </div>

                <div className="mt-2">
                  {loading ? (
                    <>
                      <Skeleton height={40} className="mb-2" />
                      <Skeleton height={40} />
                    </>
                  ) : (
                    <>
                      <SelectBox
                        dataSource={countryData?.data || []}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Country"
                        labelMode="floating"
                        searchEnabled={true}
                        onValueChanged={handleCountryEvent}
                        value={dataSource?.selectedCountry || null}
                      />

                      <SelectBox
                        dataSource={dataSource?.cityDetails || []}
                        displayExpr="city_name"
                        valueExpr="city_id"
                        label="City"
                        labelMode="floating"
                        searchEnabled={true}
                        onValueChanged={handleCityEvent}
                        value={dataSource?.selectedCity || null}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column col-xl-10 col-lg-9 col-12 px-0 px-md-3 gap-3 filtermh">
              <div
                className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
                style={{ padding: "12px", whiteSpace: "nowrap" }}
                onClick={doFilter}
              >
                
                {/*Mobile breadcrumb */}
                <div
                  className="p-auto rounded d-block d-lg-none backBtn cursor-pointer"
                  style={{ border: "1px solid #ddd" }}
                >
                  <IoChevronBack
                    style={{ fontSize: "24px" }}
                  //onClick={handlebackButton}
                  />
                </div>

                <div
                  className="w-100 d-flex align-items-center hide-scrollbar breadcrumbCountryWarp"
                  style={{ gap: "2px", overflowX: "auto", textWrap: "nowrap" }}
                >
                  <p className="m-0 p-0" style={{ display: "inline-block", flexShrink: 0, whiteSpace: "nowrap" }}>
                    {breadcrumbCountry}
                  </p>

                  <i style={{ fontSize: "16px", flexShrink: 0 }}>
                    <MdKeyboardArrowRight />
                  </i>
                  <p className="m-0 p-0" style={{ display: "inline-block", flexShrink: 0, whiteSpace: "nowrap" }}>
                    {breadcrumbCountry}
                  </p>

                </div>
                <div className="filter-button align-items-center">
                  <FaFilter />
                  Filters
                </div>
              </div>
              {/*website breadcrumb */}

              <div className="d-none d-lg-block">
                <div className="d-flex">
                  <div
                    className="p-auto me-3 rounded d-none d-md-block backBtn cursor-pointer"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <IoChevronBack
                      style={{ fontSize: "24px" }}
                    //onClick={handlebackButton}
                    />
                  </div>
                  <div
                    className="w-100 d-flex flex-row align-items-center hide-scrollbar breadcrumbCountryWarp"
                    style={{ gap: "2px", overflow: "scroll", whiteSpace: "nowrap" }}
                  >
                    <p className="m-0 p-0" style={{ flexShrink: 0 }}>{breadcrumbCountry}</p>

                    <i style={{ fontSize: "16px", flexShrink: 0 }}>
                      <MdKeyboardArrowRight />
                    </i>
                    <p className="m-0 p-0" style={{ color: "#3F00FF", whiteSpace: "nowrap" }}>
                      {breadCrumbCity}
                    </p>
                  </div>
                </div>

              </div>
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 ">
                {loading ? (
                  [...Array(8)].map((_, index) => (
                    <Skeleton
                      className="col mb-2 mb-lg-4 px-2"
                      height={
                        window.innerWidth < 576
                          ? 180
                          : window.innerWidth < 768
                            ? 150
                            : 200
                      }
                      style={{ width: "100%" }}
                    />
                  ))
                ) : dataSource?.eventsDetails &&
                  dataSource?.eventsDetails.length > 0 ? (
                  dataSource?.eventsDetails?.map((genre) => (
                    <div
                      className="col mb-2 mb-lg-4 px-2"
                      key={genre.main_cat_id}
                    >
                      <div
                        className="iq-card-geners card-hover-style-two"
                        onClick={() =>
                          handleClick(genre.main_cat_id, genre.main_cat_name)
                        }
                      >
                        <div className="blockImages position-relative w-100">
                          <div className="img-box rounded position-relative">
                            <img
                              src={
                                genre?.img_name === ""
                                  ? NoImage
                                  : `${MAIN_CATEGORY_IMAGES}${genre?.img_name}`
                              }
                              alt="genre-img"
                              className="img-fluid object-cover w-100 rounded"
                            />
                            <div className="blog-description overflow-hidden">
                              <h6
                                className="w-100 mb-0 text-white text-wrap"
                                style={{ fontWeight: "300", marginTop: "4px" }}
                              >
                                {genre.main_cat_name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center w-100">
                    <img src={NoEventFound} alt="genre-img" className="w-25" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Event;
