import React from "react";
import Scheduler from 'devextreme-react/scheduler';
import AuthContext from "../../../context/AuthContext";
import { GET_CALENDAR } from "../../../Api/api.js";
import { BASEPATH } from "../../../config/config.js";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import NoDataFound from "../../../global/NoDataFound.js";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./MyCalendar_myprofile.css";
import { useNavigate } from "react-router-dom";
import { customEncrypt } from "../../../utils/encryptAndDecrypt.js";
import getEventKeys from "../../../Api/getEventKeys.js";

export default function MyCalendar_myprofile() {

    const COUNTRY_KEY= getEventKeys().countryKey;
    const EVENT_KEY= getEventKeys().eventKey;


    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const token = localStorage.getItem("MDnD_auth");

    const [calendarCollection, setCalendarCollection] = useState({
        calendarData: [],
        firstdate: null,
    });

    useEffect(() => {
        mutationGetCalendar.mutate();
    }, []);

    const GetCalendar = () => GET_MYCALENDAR_FROM_BACKEND(GET_CALENDAR);

    const GET_MYCALENDAR_FROM_BACKEND = async (GET_CALENDAR) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_CALENDAR}`,
                data: {
                    country_id: user.country_id ? user.country_id : 101,
                    role_id: user ? user.role_id : ""
                },
                headers: {
                    'MDnD_auth': `${token}`,
                },
                timeout: 60000,
                responseType: 'json',
            });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetCalendar = useMutation({
        mutationKey: ["get_my_calendar"],
        mutationFn: () => GetCalendar(),
        onSuccess: (data) => {
            if (data.status === "success") {
                let filteredArray = data.data.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
                let formattedData = filteredArray.map(event => ({
                    text: event.event_title,
                    startDate: new Date(event.event_date),
                    endDate: new Date(event.event_date),
                    event_id: event.event_id
                }));
                setCalendarCollection((prevState) => ({
                    ...prevState,
                    calendarData: formattedData,
                    firstdate: formattedData.length > 0 ? formattedData[0].startDate : null
                }));
            } else {
                setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
            }
        },
        onError: (error) => {
            setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
        },
    });

    const handleAppointmentClick = (e) => {
        console.log(e,"event calendar");

        const encyptedData = customEncrypt("no");
        const modifiedTitle = e.appointmentData.text ? e.appointmentData.text.split(" ").join("_") : "Event"; 
        navigate(`/event/${modifiedTitle}?ci=${encodeBase64_new(user?.country_id, COUNTRY_KEY)}&e=${encodeBase64_new(e.appointmentData.event_id, EVENT_KEY)}&editEvent=${encyptedData}`);
    };

    const handleDateChange = (e) => {
        setCalendarCollection((prevState) => ({
            ...prevState,
            firstdate: e.value
        }));
    };

    const encodeBase64 = (str) => {
        const prefix = "xy4AT";
        const combinedStr = prefix+ str.toString();
        return btoa(combinedStr);
      };

      const encodeBase64_new = (str,key) => {
        const prefix = key?.toString();
        const combinedStr = prefix+ str.toString();
        return btoa(combinedStr);
      };

    const views = ["month"];

    return (
        <>
            {mutationGetCalendar.isLoading ? (
                <div className="skeleton-loader">
                    <Skeleton count={5} height={650} />
                </div>
            ) : (
                <>
                    {user && calendarCollection.calendarData && calendarCollection.calendarData.length > 0 ? (
                        <>
                            <Scheduler
                                timeZone="America/Los_Angeles"
                                dataSource={calendarCollection.calendarData}
                                views={views}
                                defaultCurrentView="month"
                                height={650}
                                onAppointmentClick={handleAppointmentClick}
                                currentDate={calendarCollection.firstdate}
                                showCurrentTimeIndicator={false}
                                startDayHour={1}
                                onAppointmentDblClick={(e) => (e.cancel = true)}
                                onAppointmentAdding={(e) => (e.cancel = true)}
                                onAppointmentFormOpening={(e) => (e.cancel = true)}
                                onAppointmentDeleting={(e) => (e.cancel = true)}
                                onAppointmentDeleted={(e) => (e.cancel = true)}
                                onCellClick={(e) => (e.cancel = true)}
                                onDragStart={(e) => (e.cancel = true)}
                                onRemove={(e) => (e.cancel = true)}
                                onDragEnd={(e) => (e.cancel = true)}
                                onAdd={(e) => (e.cancel = true)}
                                editing={{
                                    allowDragging: false
                                }}
                                appointmentDragging={{
                                    onDragStart: (e) => {
                                        e.cancel = true;
                                    },
                                }}
                            />
                        </>
                    ) : (
                        <NoDataFound />
                    )}
                </>
            )}
        </>
    );
}
